import React, { useEffect, useState, useRef } from "react";
import GamesMenu from "common/components/GamesMenu";
import BackgroundGame from "common/components/BackgroundGame";
import BackgroundMusic from "common/modules/backgroundMusic";
import StartScreen from "./components/StartScreen/index";
import castingBackgroundImg from "./img/casting-background.png";

import soundOnImg from "common/imgs/soundon.png";
import soundOffImg from "common/imgs/soundoff.png";

import FingerPointer from "common/components/FingerPointer";

import ControllerHUD from "common/components/ControllerHUD";
import Multiplayer from "common/modules/multiplayer";
import PlayerGame from "./components/PlayerGame";

import startScreenBackgroundMusic from "./sfx/Wii-Music_-_Background-Music.mp3";

import fingerCardsImg from "./img/finger-on-cards.png";

import "./style.css";

import {
  usePlayersList,
  useGlobalState,
  usePlayerState,
} from "common/hooks/multiplayer";
import PlayerIndicators from "./components/PlayerIndicators";
import bgTimerSound from "./sfx/timer.mp3";
// import "./style.css";


export default function GamePage({ gameInfo }) {
  const multiplayer = Multiplayer();
  const winner = useGlobalState("winner");
  const myState = multiplayer.getMyPlayerState();
  const playInstructions = useGlobalState("playInstructions");

  const players = usePlayersList();

  const playInstructionsRef = useRef(null);

  return (
    <>
      <BackgroundGame
        style={{
          // backgroundImage: `url(${castingBackgroundImg})`,
          background: `#FFB0CC`,
          backgroundSize: "cover",
        }}
      ></BackgroundGame>

      <GamesMenu
        key="gamesmenu"
        gameInfo={gameInfo}
        showRestart={winner && multiplayer.isHost}
        onRestart={async () => {
          multiplayer.setState("newGame", Math.random());
        }}
      />

      {multiplayer.isSpectator ? ( // tv/laptop screen
        <StartScreen 
          InstructionsCard={
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: "'Lilita One'"
          }}>
            <h1 className="game-title" style={{fontFamily: "inherit"}}>Welcome to fastest <br /> finger showdown!</h1> 
            <img width={"50%"} src={fingerCardsImg} alt={"Cover Image with fingers on cards"} />
          </div>
          }
          startScreenBackgroundMusic={startScreenBackgroundMusic}
          videoLink="https://www.youtube.com/watch?v=KxUuF6rz8WM"
          multiplayer={multiplayer}>
          <>
            {/* 
            Until we get a host detection mechanism on 
            Cast Screen, this will have to do 
            */}
            <div
              // className="banana-container"
              style={{
                position: "absolute",
                right: "2rem",
                bottom: "3rem",
                padding: "2rem 3rem",
                fontFamily: "'Lilita One'",
              }}
            >
              Host press Start
            </div>
            {/* <PlayerIndicators players={players} showPlayerScore={false}/> */}
          </>
        </StartScreen>
      ) : (
        <ControllerHUD
          breadcrumbs={["Fastest Fingers"]}
          backgroundStyle={{
            background: `#FFB0CC`,
          }}
          avatarStyle={{
            background: `linear-gradient(180deg, #8D6BED 49.66%, #76A7B7 104.47%, #6BFFB8 157.42%)`,
          }}
          timerProps={{
            color: "#FFF858",
          }}
          // avatarUrl={avatarUrlImg}
        >
          <div
            className="banana-container"
            style={{
              position: "absolute",
              top: "0rem",
              right: "2rem",
              height: "5.1rem",
              width: "5.1rem",
              borderRadius: "50%",
              alignItems: "center",
              fontSize: "2rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span>{myState ? myState.getState("points") : ""}</span>
          </div>

          <PlayerGame myState={myState} multiplayer={multiplayer} />

          <div
            style={{
              position: "fixed",
              zIndex: 9999,
              left: 0,
              top: 0,
              display: "flex",
              flexDirection: "column",
              width: "100vw",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              pointerEvents: "none",
              fontSize: "1.3rem",
            }}
          >
             
             <FingerPointer
                pointAt={playInstructionsRef}
                text={"Recommended for first time"}
                quickPositions={["bottom", "left"]}
                fingerPointerStyles={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "0rem",
                  marginLeft: "0rem",
                  height: "fit-content",
                  fontSize: "0.9rem",
                }}
                hideOnClick={true}
                hideOnClickAnywhere={true}
              />

            {multiplayer.isHost ? (
              <div style={{
                pointerEvents: "auto",
              }}>
                <button
  
                  ref={playInstructionsRef}

                  onClick={() => {
                    multiplayer.setState("playInstructions", !playInstructions);
                  }}
                  className="banana-container btn"
                  style={{
                    padding: "2rem 1rem",
                    marginBottom: "2rem",
                    fontFamily: "'Lilita One'",
                    width: "17.5rem",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    fontSize: "inherit",
                  }}
                >
                  <img
                    src={playInstructions ? soundOffImg : soundOnImg}
                    style={{
                      marginRight: "1rem",
                      height: "1.5rem",
                      width: "auto",
                      filter: "invert(1)",
                      fontFamily: "inherit",
                      fontSize: "0.3rem"
                    }}
                  />{" "}
                  {playInstructions ? "Hide " : "Play "}
                  Instructions
                </button>
                <button
                  onClick={() => {
                    multiplayer.setState("playInstructions", false);
                    multiplayer.navigate("/games/fastestfingers/game");
                  }}
                  className="banana-container btn"
                  style={{
                    padding: "2rem 1rem",
                    fontFamily: "'Lilita One'",
                    width: "17.5rem",
                    marginBottom: "20rem",
                    fontSize: "1.8rem",
                  }}
                >
                  Start Game
                </button>
              </div>
            ) : (
              <h3
                style={{
                  marginBottom: "20rem",
                }}
              >
                Waiting for host to start...
              </h3>
            )}
          </div>
        </ControllerHUD>
      )}
    </>
  );
}
