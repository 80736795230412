// given player count and screen width, calculate player's width and player x (starting from center of screen) with gap in between such that they all fit in screen width
function calculatePlayerSizeAndPosition(
    playerCount,
    index,
    screenWidth,
    playerCurrentWidth,
    gap = 50,
    emptySpaceOnEachSide = 100
) {
    const availableWidth = screenWidth - emptySpaceOnEachSide * 2;
    const totalWidth = playerCount * playerCurrentWidth + (playerCount - 1) * gap;
    if (totalWidth > availableWidth) {
        // scale down player's width
        const playerScale = availableWidth / totalWidth;
        const playerWidth = playerScale * playerCurrentWidth;
        const newTotalWidth =
        playerCount * playerWidth + (playerCount - 1) * (gap * playerScale);
        const playerPosX =
        emptySpaceOnEachSide +
        availableWidth / 2 -
        newTotalWidth / 2 +
        index * (playerWidth + gap * playerScale) +
        playerWidth / 2;
        return { playerScale, playerPosX };
    }
    // no need to scale down, just calculate x
    const playerPosX =
        emptySpaceOnEachSide +
        availableWidth / 2 -
        totalWidth / 2 +
        index * (playerCurrentWidth + gap) +
        playerCurrentWidth / 2;
    return { playerScale: 1, playerPosX };
}

export default calculatePlayerSizeAndPosition;