import { useEffect } from "react";
import Multiplayer from "common/modules/multiplayer";
import { useGlobalState } from "common/hooks/multiplayer";

export default function RoundsWrapper({ totalRounds, RoundComponent }) {
  const multiplayer = Multiplayer();
  const currentRound = useGlobalState("_round");
  useEffect(() => {
    multiplayer.setState("_round", 0);
    multiplayer.setState("_totalRounds", totalRounds || 1);
  }, []);

  return (
    <div key={currentRound}>
      <RoundComponent
        totalRounds={totalRounds}
        currentRound={currentRound}
        isLastRound={currentRound === totalRounds - 1}
        endRound={() => {
          multiplayer.resetRoundState();
          multiplayer.setState("_round", currentRound + 1);
        }}
      />
    </div>
  );
}
