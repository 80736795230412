import React, { useEffect } from "react";
import QRCode from "react-qr-code";
import { Portal } from "react-portal";
import Multiplayer from "../../modules/multiplayer";
import "./style.css";

export default function JoinRoomCodeModal({ zIndex, demoMode }) {
  const multiplayer = Multiplayer();
  const roomCode = multiplayer.currentRoom;
  console.log(
    "QR",
    `https://joinplayroom.com/${roomCode ? roomCode : ""}${
      demoMode ? "#creation=1" : ""
    }`
  );
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  return (
    <Portal>
      <div className="room-code-sticker" style={zIndex ? { zIndex } : null}>
        <div className="qr-code">
          {/*
          References:
          - Responsive QR, from the demo of react-qr-code: https://github.com/rosskhanas/react-qr-code/blob/master/demo/src/components/App.js
          */}

          <QRCode
            id="QRCodeScaled"
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={
              demoMode
                ? "https://joinplayroom.com/demohost"
                : `https://joinplayroom.com/R${roomCode ? roomCode : ""}`
            }
            viewBox={`0 0 256 256`}
          />
        </div>
        {roomCode && (
          <div className="room-code">
            <b>{roomCode}</b>
            <span>Scan QR code to join the room</span>
          </div>
        )}
        {!roomCode && (
          <div className="room-code">
            <b>Scan to start</b>
            <span>Download the game from the Appstore</span>
          </div>
        )}
      </div>
    </Portal>
  );
}
