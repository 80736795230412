import React, { useEffect, useState } from "react";
import GamesMenu from "common/components/GamesMenu";
import BackgroundGame from "common/components/BackgroundGame";
import Multiplayer from "common/modules/multiplayer";
import TouchJoystick from "common/modules/controllers/touch";
import { useGlobalState } from "common/hooks/multiplayer";
import Viewer from "./viewer";
import "./style.scss";

import { FullscreenCountdown } from "common/components/Countdown";

function resetGame() {
  const multiplayer = Multiplayer();
  multiplayer.setState("live", undefined);
  multiplayer.setState("winner", undefined);
  multiplayer.setState("startGame", undefined);
  multiplayer.setState("currentRound", 1);
  multiplayer.setState("selectedColors", undefined);
  const players = multiplayer.getPlayers();
  Object.keys(players).forEach((playerId) => {
    const playerState = players[playerId];
    playerState.setState("selectedColor", undefined);
    playerState.setState("score", 0);
    // playerState.setState("done", undefined);
  });
}

export default function GamePage({ gameInfo }) {
  const multiplayer = Multiplayer();
  const currentRound = useGlobalState("currentRound");
  const gameIsLive = useGlobalState("live");
  // const startGame = useGlobalState("startGame");
  const winner = useGlobalState("winner");
  const newGameRequested = useGlobalState("newGame");
  const [countdown, setCountdown] = useState(false);

  const stopCountdown = () => {
    // setTimeout(() => {
    multiplayer.setState("live", true);
    setCountdown(false);
    // }, 2000);
  };

  // reset game state if host requests it (and the first time)
  useEffect(() => {
    var controller = null;
    if (multiplayer.isRenderServer()) {
      resetGame();
      setCountdown(true);
    }

    if (!multiplayer.isSpectator) {
      controller = new TouchJoystick({
        dpad: true,
        labels: { dpad: "MOVE", b1: "CAPTURE" },
      });
      multiplayer.attachController(controller);
    }

    return () => {
      if (controller) {
        multiplayer.detachController();
        controller.destroy();
        controller = null;
      }
      resetGame();
    };

    // eslint-disable-next-line
  }, [newGameRequested]);

  return (
    <div className="game-pickthelargest">
      <BackgroundGame
        style={{
          background:
            "linear-gradient(112.6deg, rgba(255, 153, 226, 0) -20.45%, rgba(150, 122, 145, 0.65) 102.78%), #FFF0D2;",
        }}
      ></BackgroundGame>
      <GamesMenu
        key="gamesmenu"
        gradient
        gameInfo={gameInfo}
        showRestart={multiplayer.isHost && winner}
        // showRestart={winner && multiplayer.isHost}
        onRestart={async () => {
          multiplayer.setState("newGame", Math.random());
        }}
      />

      {multiplayer.isSpectator ? ( // tv/laptop screen
        <>
          <Viewer newGameRequested={newGameRequested} />

          {countdown ? (
            <FullscreenCountdown
              key={gameIsLive || "fullscreen-countdown"}
              onFinish={stopCountdown}
            />
          ) : (
            <></>
          )}

          {!gameIsLive ? (
            <div className="round-text">
              <div className="dots">
                {[1, 2, 3, 4, 5].map((val, index) => (
                  <div className={`dot ${currentRound >= val ? "done" : ""}`} />
                ))}
              </div>
              Round {currentRound}
            </div>
          ) : (
            false
          )}
          {winner ? (
            <div className="round-text">
              {multiplayer.getPlayers()[winner].getState("profile").name} wins!
            </div>
          ) : (
            false
          )}
        </>
      ) : (
        false // just joystick is rendered on player screens
      )}
    </div>
  );
}
