import React, { useEffect, useState } from "react";
import { Card } from "common/components/CardGame";
import CardData, { VersusCardsData } from "./src/cardData";
import Multiplayer from "common/modules/multiplayer";
import Timer from "common/components/Timer";
import {
  usePlayersList,
  useGlobalState,
  useGlobalRoundState,
  usePlayerState,
  useAllPlayersState,
  useStateIsSetForAllPlayers,
} from "common/hooks/multiplayer";
import UIfx from "common/modules/uifx";
import BackgroundMusic from "common/modules/backgroundMusic";
import { BackgroundNarrator } from "common/modules/backgroundNarrator";

import roundStartSound from "./sfx/new-round.mp3";
import bgTimerSound from "./sfx/timer.mp3";

// import voices for wait and point
import { voicesWait, voicesPoint } from "./sounds";

import { toTitleCase, setIntervalX, makeString } from "./utils";

const roundDurationInS = 150;
let sfxWinSounds = {};

const resetGame = () => {
  const multiplayer = Multiplayer();

  multiplayer.setRoundState("challengeWinners", []);
  multiplayer.setRoundState("currentChallenge", undefined);
  multiplayer.setRoundState("showAllCards", false);
  multiplayer.setRoundState("allPlayersChose", false);

  const players = multiplayer.getPlayers();
  Object.keys(players).forEach((playerId) => {
    const playerState = players[playerId];
    playerState.setState("chosenCard", undefined);
    playerState.setState("done", undefined);
  });
};

function Round({ currentRound, totalRounds, endRound, isLastRound }) {
  const multiplayer = Multiplayer();
  const players = usePlayersList();
  const winner = useGlobalState("winner");
  const [isMidGame, setIsMidGame] = useState(false);
  const allPlayersChose = useGlobalRoundState("allPlayersChose", false);
  const challengeWinners = useGlobalRoundState("challengeWinners", []);
  const currentChallenge = useGlobalRoundState("currentChallenge", undefined);

  const timer = useGlobalState("timer");

  const [BGNarrator, setBGNarrator] = useState(null);

  useEffect(() => {
    setBGNarrator(new BackgroundNarrator(2000, "game"));
  }, []);

  const sfxRoundStart = UIfx(roundStartSound);

  // constant states and values for
  // 1. Display
  // 2. Scoring
  const [winnerCard, setWinnerCard] = useState(null);
  const [otherCards, setOtherCards] = useState(null);
  const [losers, setLosers] = useState(null);

  /*
  whenever the currentChallenge changes, recalculate
  the Winner Cards, Other Cards, and Losers
  */
  useEffect(() => {
    if (currentChallenge) {
      const _winnerCard = currentChallenge
        ? CardData.find((c) => c.id === currentChallenge.winner)
        : null;

      const _otherCards =
        currentChallenge && currentChallenge.winner !== -1
          ? CardData.filter((c) => {
              console.log("othercards c: ", c);
              return (
                currentChallenge.challengers.includes(c.id) &&
                c.id !== currentChallenge.winner
              );
            })
          : null;

      const _losers =
        currentChallenge && _otherCards
          ? players.filter((player) => {
              return _otherCards.find((card) => {
                console.log("looking in otherCards: card.id -> ", card.id);
                return (
                  player.getState("chosenCard") &&
                  card.id === player.getState("chosenCard").id
                );
              });
            })
          : null;

      setWinnerCard(_winnerCard);
      setOtherCards(_otherCards);
      setLosers(_losers);
    } else {
      setWinnerCard(null);
      setOtherCards(null);
      setLosers(null);
    }
  }, [currentChallenge]);

  // usePlayerState((id, key, state) => {
  //   if (key === "chosenCard") {
  //     // We wait 1 second before making decisions, because
  //     // state takes time to propogate.
  //     // TODO: A bit of a rough hack. Find a better way.
  //     setTimeout(() => {
  //       let allChosen = true;
  //       for (let i = 0; i < players.length; i++) {
  //         if (!players[i].getState("chosenCard")) {
  //           allChosen = false;
  //           players[i].setState("done", undefined, true);
  //         } else {
  //           players[i].setState("done", true, true);
  //         }
  //       }

  //       if (allChosen) multiplayer.setRoundState("allPlayersChose", true);
  //       else multiplayer.setRoundState("allPlayersChose", false);
  //     }, 1500);
  //   }
  // });

  const _allPlayersChose = useStateIsSetForAllPlayers("chosenCard");

  useEffect(() => {
    // update just once, to prevent race conditions
    if (multiplayer.isSpectator) {
      multiplayer.setRoundState("allPlayersChose", _allPlayersChose);
    }
  }, [_allPlayersChose]);

  useEffect(() => {
    const setTimer = (_timer) => {
      if (multiplayer.getRoundState("allPlayersChose")) return;
      multiplayer.setState("timer", _timer);
    };

    // if game has ended, don't init
    if (winner) return;

    console.log("internal round changed");
    var intervalId;
    if (multiplayer.isSpectator) {
      // try-to: clear previous timer
      if (window.timerIntervalId) {
        window.clearInterval(window.timerIntervalId);
        clearInterval(window.timerIntervalId);
      }

      // Initialize a new timer on each round
      intervalId = Timer({
        isNonDisplay: true,
        setTimer: setTimer,
        durationInMs: roundDurationInS * 1000,
        onAllFinish: (x, intervalId) => {
          clearInterval(intervalId);
          window.clearInterval(intervalId);

          // All players are assumed to have chosen, even if they haven't.

          players.forEach((player) => {
            if (!player.getState("chosenCard"))
              player.setState("chosenCard", {
                id: 0 - players.length + 1,
              });
          });
        },
      });
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
      if (window.timerIntervalId) {
        window.clearInterval(window.timerIntervalId);
        clearInterval(window.timerIntervalId);
      }
    };
  }, []);

  useEffect(() => {
    const determineGameWinner = () => {
      // TODO: What if multiple players win?

      // https://stackoverflow.com/questions/4020796/finding-the-max-value-of-an-attribute-in-an-array-of-objects
      const maxPlayer = players.reduce((prev, current) =>
        prev.getState("points") > current.getState("points") ? prev : current
      );
      if (maxPlayer.getState("points")) {
        // get ALL players with highest score
        const winnerPlayers = players.filter(
          (player, idx) =>
            player.getState("points") === maxPlayer.getState("points")
        );

        const winnerPlayerIds = winnerPlayers.map((winnerPlayer, idx) => {
          return winnerPlayer.id;
        });
        multiplayer.setState("winner", winnerPlayers);
        multiplayer.addToWinLog("rockpaperscissors", winnerPlayerIds);
      } else {
        multiplayer.setState("winner", -1);
        multiplayer.addToWinLog("rockpaperscissors", -1);
      }
    };

    let intervalXId = null;
    if (allPlayersChose && multiplayer.isSpectator) {
      // multiplayer.setState("done", true)

      BackgroundMusic().stop();

      console.log("All players have chosen! Now what?");
      setTimeout(() => {
        console.log("Showing all the cards!");
        multiplayer.setRoundState("showAllCards", true);
      }, 2400);

      // These contain all the possible challenges
      let challenges = [];
      let isStillChosen = true;

      // eslint-disable-next-line
      let chosenCards = players.map((player, idx) => {
        if (player.getState("chosenCard")) {
          return player.getState("chosenCard").id;
        } else {
          isStillChosen = false;

          return 0 - players.length + 1;
        }
      });

      let chosenCardsUnique = [...new Set(chosenCards)];

      // find all possible challenges for which cards have been
      // chosen by players
      for (let i = 0; i < VersusCardsData.length; i++) {
        challenges.push(
          VersusCardsData.filter((versus) => {
            let _challengers = versus.challengers;

            let num_challengers = 0;
            for (let j = 0; j < _challengers.length; j++) {
              if (chosenCardsUnique.includes(_challengers[j])) {
                console.log("versus.winner:", versus.winner);
                num_challengers++;

                // if the number of challengers is greater than one,
                // it is a valid challenge
                if (num_challengers > 1) {
                  return versus;
                }
              }
            }

            return false;
          })
        );
      }

      // ----------- PRINT DEBUG -----------
      console.log("[BEFORE] Possible challenges: ", challenges);
      // ----------- PRINT DEBUG -----------

      // keep only unique challenges
      challenges = challenges[0];
      challenges = [...new Set(challenges)];

      // ----------- PRINT DEBUG -----------
      console.log("[AFTER] Possible challenges: ", challenges);
      // ----------- PRINT DEBUG -----------

      // Now that we have all the possible valid challenges,
      // Let's start showing them and scoring the users

      if (
        !challenges ||
        (challenges &&
          challenges.length === 1 &&
          challenges[0] === undefined) ||
        (challenges && challenges.length === 0)
      ) {
        console.log("No possible challenges! Now what?");
        // multiplayer.setRoundState("currentChallenge", null);
        // multiplayer.setRoundState("challengeWinners", []);

        let isDraw = true;

        let _chosenCard = players[0].getState("chosenCard");
        console.log("_chosenCard:", _chosenCard);
        if (_chosenCard && _chosenCard.id >= 0) {
          players.forEach((player, idx) => {
            console.log(
              'player.getState("chosenCard").id !== _chosenCard.id)',
              player.getState("chosenCard").id,
              _chosenCard.id
            );
            if (player.getState("chosenCard").id !== _chosenCard.id) {
              isDraw = false;
            }
          });
        } else {
          isDraw = false;
        }

        console.log("isDraw:", isDraw);

        if (isDraw) {
          challenges.push({
            challengers: [_chosenCard.id],
            winner: -2,
          });
        } else {
          challenges.push({
            winner: -1,
          });
        }
      }

      // We'll return a callback
      let challengeCallbacks = challenges.map((challenge, idx) => {
        return () => {
          // ----------- PRINT DEBUG -----------
          // console.log("challenge is:", challenge);
          // ----------- PRINT DEBUG -----------

          multiplayer.setRoundState("currentChallenge", challenge);

          let winnerCard = CardData.find((c) => c.id === challenge.winner);

          if (winnerCard < 0 || !winnerCard) return;

          // play winning sound for this card
          let sfxWin =
            sfxWinSounds[winnerCard.winSound] || UIfx(winnerCard.winSound);
          sfxWinSounds[winnerCard.winSound] = sfxWin;

          // setTimeout(() => {
          sfxWin.play();
          // }, 500);

          if (!challenge) return;

          // ----------- PRINT DEBUG -----------
          // console.log("Challenge Winners are", players.filter((player, idx) => {
          // console.log("player:", player);
          // console.log("player.getState(\"chosenCard\"):", player.getState("chosenCard"));
          // console.log("player.getState(\"chosenCard\").id:", player.getState("chosenCard").id);
          // console.log("challenge.winner:", challenge.winner);
          //   return player.getState("chosenCard").id === challenge.winner;
          // }));
          // ----------- PRINT DEBUG -----------

          let winnerPlayers = players.filter(
            (player, idx) =>
              player.getState("chosenCard")?.id === challenge.winner
          );

          // ----------- PRINT DEBUG -----------
          // console.log("winnerPlayers:", winnerPlayers);
          // ----------- PRINT DEBUG -----------

          let winnerPlayerIds = winnerPlayers.map(
            (winnerPlayer) => winnerPlayer.id
          );

          // setTimeout(() => {
          multiplayer.setRoundState("challengeWinners", winnerPlayerIds);
          // }, 500);
        };
      });

      console.log("challenges:", challenges);
      console.log("challengeCallbacks:", challengeCallbacks);
      console.log("challengeCallbacks.length:", challengeCallbacks.length);

      intervalXId = setIntervalX(
        challengeCallbacks,
        4500,
        challengeCallbacks.length,
        () => {
          if (isLastRound) {
            multiplayer.setState("live", false);
            determineGameWinner();
          }
          resetGame();
          endRound();
        }
      );
    }

    return () => {
      if (intervalXId) clearInterval(intervalXId);
    };
  }, [allPlayersChose]);

  const [lastPlay, setLastPlay] = useState(Date.now());

  useEffect(() => {
    let randomVoice;
    if (multiplayer.isSpectator) {
      if (
        // Must be one of these values; 5, 15, 25, 35, 45...
        timer &&
        (5 + timer) % 10 === 0 &&
        // 5 seconds must have passed to play the sound again
        Date.now() - lastPlay > 5000
      ) {
        randomVoice = voicesWait[Math.floor(Math.random() * voicesWait.length)];
        // fade out background music and play the voice
        BGNarrator.play(randomVoice.file);
        setLastPlay(Date.now());
      }
    }

    // prevent sound leaking and/or double sounds.
    // return () => {
    //   if (randomVoice) randomVoice.stop();
    //   BackgroundMusic("timer").setVolume(1);
    // }
  }, [timer]);

  const [twoKillCountAchieved, setTwoKillCountAchieved] = useState(false);
  const [threeKillCountAchieved, setThreeKillCountAchieved] = useState(false);

  // What to do when the winners are updated?
  // Increase their scores, of course!

  // (Also play a sound if their killcount is greater than 1)
  useEffect(() => {
    if (challengeWinners && multiplayer.isSpectator) {
      challengeWinners.forEach((challengeWinner, idx) => {
        let player = players.find((player) => player.id === challengeWinner);

        if (player < 0) {
          return;
        }

        let existingPoints = player.getState("points");
        let killCount = losers && losers.length ? losers.length : 1;
        console.log("killCount: ", killCount);

        if (killCount === 2 && !twoKillCountAchieved) {
          console.log("Kill count === 2, play sound!");
          setTimeout(() => {
            BGNarrator.play(voicesPoint[0].file);
          }, 600);
          setTwoKillCountAchieved(true);
        } else if (killCount === 3 && !threeKillCountAchieved) {
          setTimeout(() => {
            BGNarrator.play(voicesPoint[1].file);
          }, 600);
          setThreeKillCountAchieved(true);
        } else if (killCount > 3) {
          setTimeout(() => {
            BGNarrator.play(voicesPoint[1].file);
          }, 600);
          setThreeKillCountAchieved(true);
        }

        player.setState(
          "points",
          existingPoints ? existingPoints + killCount : killCount
        );
      });
    }
    // eslint-disable-next-line
  }, [challengeWinners]);

  // useEffect(() => {
  //   // reset on each round
  //   setTwoKillCountAchieved(false);
  //   setThreeKillCountAchieved(false);
  // }, [currentRound]);

  // What to do when the winners are updated?
  // Remove all players' chosen cards from their hands, of course!
  // useEffect(() => {
  //   if (challengeWinners && multiplayer.isSpectator) {
  //     players.forEach((player, idx) => {
  //       console.log("player: ", player);

  //       if (player < 0) {
  //         return;
  //       }

  //       let handData = player.getState("handData");

  //       const chosenCard = player.getState("chosenCard");

  //       // https://stackoverflow.com/questions/53534721/find-and-remove-first-matching-element-in-an-array-of-javascript-objects
  //       if (chosenCard) {
  //         var idx = handData.findIndex((c) => c.id === chosenCard.id);
  //         var removedData = idx !== -1 && handData.splice(idx, 1);

  //         player.setState("handData", handData);
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [challengeWinners]);

  // init for sounds
  useEffect(() => {
    console.log("round mount");

    // Playing in the main channel doesn't work
    // specially when i try to run the timer sound right after startscreen.
    BackgroundMusic().play(bgTimerSound);
    sfxRoundStart.play();
    // BackgroundMusic().stop();

    return () => {
      console.log("round unmount");
      setTwoKillCountAchieved(false);
      setThreeKillCountAchieved(false);
      BackgroundMusic().stop();
      // BackgroundMusic().play();
    };
  }, []);
  return (
    <div
      className="centered-container"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flex: "1",
        width: "max-content",
      }}
    >
      {winnerCard && !winner ? (
        <DisplayCard card={winnerCard} position={"left"} />
      ) : (
        <></>
      )}

      <div className="space-container dialog-container">
        <div>
          {winner ? (
            <></>
          ) : currentChallenge ? (
            <h2>Points time!</h2>
          ) : (
            <h2>{`Round ${currentRound + 1} / ${totalRounds}`}</h2>
          )}
          {winner === undefined || winner === null ? (
            isMidGame ? (
              <h1>Hurry! Choose a card!</h1>
            ) : currentChallenge ? (
              currentChallenge.winner === -1 ||
              currentChallenge.winner === undefined ? (
                <h1>No one won this round.</h1>
              ) : currentChallenge.winner === -2 ? (
                <h1>Draw!</h1>
              ) : (
                <h1>
                  {toTitleCase(currentChallenge.winner)} beats{" "}
                  {currentChallenge.challengers
                    .filter(
                      (challenger) => challenger !== currentChallenge.winner
                    )
                    .map((challenger) =>
                      challenger !== -1 ? toTitleCase(challenger) : "everyone!"
                    )}
                </h1>
              )
            ) : (
              <h1>Throw a card</h1>
            )
          ) : winner !== -1 ? (
            <h2>
              {makeString(
                winner?.map((_winner) => {
                  // console.log("_winner:", _winner)
                  return _winner.state.profile.name;
                })
              ) +
                " win" +
                (winner.length === 1 ? "s" : "") +
                " the game!"}
            </h2>
          ) : (
            <h2>No one won.</h2>
          )}
        </div>
      </div>

      {otherCards && otherCards.length && otherCards[0] && !winner ? (
        <DisplayCard card={otherCards[0]} position={"right"} />
      ) : (
        <></>
      )}
    </div>
  );
}

export default Round;

const DisplayCard = ({ card, position }) => {
  return (
    <Card
      backgroundColor={card.backgroundColor}
      backgroundImage={card.backgroundImage}
      cardImage={`url("${card.image}")`}
      className="card"
      cardStyle={{
        ...card.cardStyles,
        ...{
          padding: "10px 20px",
          // https://stackoverflow.com/questions/12991164/maintaining-the-final-state-at-end-of-a-css3-animation
          animation:
            "fly-in 0.6s forwards" +
            (position === "left"
              ? ", rotate-left 0.4s forwards"
              : position === "right"
              ? ", rotate-right 0.4s forwards"
              : ""),
        },
      }}
      style={{
        position: "absolute",
      }}
    />
  );
};
