import React, { useEffect, useState, useRef } from "react";
import GamesMenu from "common/components/GamesMenu";
import BackgroundGame from "common/components/BackgroundGame";
import BackgroundMusic from "common/modules/backgroundMusic";
import StartScreen from "./components/StartScreen/index";
import castingBackgroundImg from "./img/casting-background.png";

import soundOnImg from "common/imgs/soundon.png";
import soundOffImg from "common/imgs/soundoff.png";

import CardData, { VersusCardsData } from "./src/cardData";

import FingerPointer from "common/components/FingerPointer";

import ControllerHUD from "common/components/ControllerHUD";
import Multiplayer from "common/modules/multiplayer";
import PlayerGame from "./components/PlayerGame";

import RotatingStars from "./components/RotatingStars";

import {
  usePlayersList,
  useGlobalState,
  usePlayerState,
} from "common/hooks/multiplayer";
import PlayerIndicators from "./components/PlayerIndicators";
import bgTimerSound from "./sfx/timer.mp3";
import "./style.css";
import "./card-fan.css";
import "./starry-night.css";

import UIfx from "common/modules/uifx";
import cardShuffleSound from "./sfx/shuffle.mp3";

import soundBgMusic from "common/sfx/theme.mp3";

export default function GamePage({ gameInfo }) {
  const multiplayer = Multiplayer();
  const myState = multiplayer.getMyPlayerState();
  const playInstructions = useGlobalState("playInstructions");

  const players = usePlayersList();

  const playInstructionsRef = useRef(null);

  useState(() => {
    try {
      // initialize all players with 2x of each card
      console.log("initializing all players with a card.");
      players.forEach((player) => {
        player.setState("handData", CardData.concat(CardData), true);
        player.setState("points", 0, true);
      });
    } catch (e) {
      console.log("error: ", e);
    }

    return () => {
      console.log("game unmount");
      // BackgroundMusic.play(soundBgMusic);
      // BackgroundMusic.setVolume(1);
    };
  }, []);

  return (
    <>
      {/* <div className="starry-night-container">
        <div className="sky">
          <div className="stars"></div>
          <div className="stars1"></div>
          <div className="stars2"></div>
          <div className="shooting-stars"></div>
        </div>
      </div> */}

      <RotatingStars />

      {/* mid-game sky */}
      <BackgroundGame
        style={{
          backgroundImage: `url(${castingBackgroundImg})`,
          backgroundSize: "cover",
          // filter: "brightness(1.5)",
          // height: "100%",
          // width: "100%",
        }}
      ></BackgroundGame>

      <GamesMenu
        key="gamesmenu"
        // gradient
        // hideRoomCode
        gameInfo={gameInfo}
        // showRestart={multiplayer.isHost}
      />

      {multiplayer.isSpectator ? ( // tv/laptop screen
        <StartScreen multiplayer={multiplayer}>
          <>
            {/* 
            Until we get a host detection mechanism on 
            Cast Screen, this will have to do 
            */}
            <div
              className="space-container"
              style={{
                position: "absolute",
                right: "2rem",
                bottom: "3rem",
                padding: "2rem 3rem",
              }}
            >
              Host press Start
            </div>
            <PlayerIndicators
              players={players}
              showPlayerScore={false}
              delayBetweenPlayers={1.5}
            />
          </>
        </StartScreen>
      ) : (
        <ControllerHUD
          breadcrumbs={["Rock, Paper, Scissors!"]}
          backgroundStyle={{
            background: `#28425B`,
          }}
          avatarStyle={{
            background: `linear-gradient(180deg, #8D6BED 49.66%, #76A7B7 104.47%, #6BFFB8 157.42%)`,
          }}
          timerProps={{
            color: "#FFF858",
          }}
          // avatarUrl={avatarUrlImg}
        >
          <div
            className="space-container"
            style={{
              position: "absolute",
              top: "0rem",
              right: "2rem",
              height: "5.1rem",
              width: "5.1rem",
              borderRadius: "50%",
              alignItems: "center",
              fontSize: "2rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span>{myState ? myState.getState("points") : ""}</span>
          </div>

          <PlayerGame myState={myState} multiplayer={multiplayer} />

          <div
            style={{
              position: "fixed",
              zIndex: 9999,
              left: 0,
              top: 0,
              display: "flex",
              flexDirection: "column",
              width: "100vw",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              pointerEvents: "none",
              fontSize: "1.3rem",
            }}
          >
            <FingerPointer
              pointAt={playInstructionsRef}
              text={"Recommended for first time"}
              quickPositions={["bottom", "left"]}
              fingerPointerStyles={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: "0rem",
                marginLeft: "0rem",
                height: "fit-content",
                fontSize: "0.9rem",
              }}
              hideOnClick={true}
              hideOnClickAnywhere={true}
            />

            {multiplayer.isHost ? (
              <div
                style={{
                  pointerEvents: "auto",
                }}
              >
                <button
                  ref={playInstructionsRef}
                  onClick={() => {
                    multiplayer.setState("playInstructions", !playInstructions);
                  }}
                  className="space-container space-btn"
                  style={{
                    padding: "2rem 1rem",
                    marginBottom: "2rem",
                    fontFamily: "'Russo One'",
                    width: "17.5rem",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    fontSize: "inherit",
                  }}
                >
                  <img
                    src={playInstructions ? soundOffImg : soundOnImg}
                    style={{
                      marginRight: "1rem",
                      height: "1.5rem",
                      width: "auto",
                    }}
                  />{" "}
                  {playInstructions ? "Hide " : "Play "}
                  Instructions
                </button>
                <button
                  onClick={() => {
                    multiplayer.setState("playInstructions", false);
                    multiplayer.navigate("/games/rockpaperscissors/game");
                  }}
                  className="space-container space-btn"
                  style={{
                    padding: "2rem 1rem",
                    fontFamily: "'Russo One'",
                    width: "17.5rem",
                    marginBottom: "20rem",
                    fontSize: "inherit",
                  }}
                >
                  Start Game
                </button>
              </div>
            ) : (
              <h3
                style={{
                  marginBottom: "20rem",
                }}
              >
                Waiting for host to start...
              </h3>
            )}
          </div>
        </ControllerHUD>
      )}
    </>
  );
}
