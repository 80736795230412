import React from "react";
import "./style.css";

export default function BackgroundGame({ children, style }) {
  return (
    <div className="background-game-container" style={style}>
      {children}
    </div>
  );
}
