import React, { useEffect, useState } from "react";
import Game from "common/components/GameRenderer";
import GameScene from "./src/scene";
import GamesMenu from "common/components/GamesMenu";
import Multiplayer from "common/modules/multiplayer";
import BackgroundGame from "common/components/BackgroundGame";
import GyroController from "common/modules/controllers/gyro";

// import "./style.css";

export default function GamePage({ gameInfo }) {
  const multiplayer = Multiplayer();
  const [winnerFound, setWinnerFound] = useState(
    multiplayer.getState("winner") || false
  );

  useEffect(() => {
    var controller = null;
    var cleanupFns = [];
    cleanupFns.push(
      multiplayer.on("state", (state, key) => {
        setWinnerFound(state["winner"] || false);
      })
    );

    if (!multiplayer.isSpectator) {
      controller = new GyroController();
      multiplayer.attachController(controller);
    }

    return () => {
      cleanupFns.forEach((cleanup) => cleanup());
      if (!multiplayer.isSpectator) {
        multiplayer.detachController();
        if (controller) controller.destroy();
        controller = null;
      }
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <BackgroundGame style={{ backgroundColor: "#6BDCFF" }}></BackgroundGame>
      <GamesMenu
        key="gamesmenu"
        inGame
        showLogo
        gameInfo={gameInfo}
        showRestart={winnerFound && multiplayer.isHost}
      />
      {multiplayer.isSpectator ? (
        <Game
          key="game"
          fullScreen
          gameInfo={gameInfo}
          scenes={[GameScene]}
          physics={{
            default: "matter",
            matter: {
              gravity: {
                y: 1,
              },
              // debug: true,
            },
          }}
        />
      ) : (
        false
      )}
    </>
  );
}
