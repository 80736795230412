import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import AutoplayNextGameModal from "./modal";
import Multiplayer from "common/modules/multiplayer";
import { resetAllNonPlayroomStates } from "common/modules/multiplayer/state";
import GamesDirectory from "pages/games/directory";
import { getExpandedPlaylistFromId } from "pages/games/playlists";
import BackgroundNarrator from "common/modules/backgroundNarrator";
import BackgroundMusic from "common/modules/backgroundMusic";
import soundLetsGo from "common/voice/play-1.mp3";
import { useGlobalState } from "common/hooks/multiplayer";
import { LOGTYPES } from "common/modules/db";
import "./style.css";

import soundBgMusic from "common/sfx/theme.mp3";

function AutoplayScreen() {
  const multiplayer = Multiplayer();
  var playlist = getExpandedPlaylistFromId(
    multiplayer.getState("playlist")
  ).gamesList.map((game, idx) => {
    return GamesDirectory.find((g) => g.id === game);
  });
  console.log("Expanded playlist", playlist);

  const history = useHistory();
  const location = useLocation();
  const BGNarrator = BackgroundNarrator();
  const currentGameIndex = useGlobalState("playlistCurIndex", -1);

  const game =
    location.state && location.state.nextGameId
      ? playlist.filter((game) => game.id === location.state.nextGameId)[0] // pre-chosen specific game
      : playlist[(currentGameIndex + 1) % playlist.length]; // next game in the list

  const hasReachedEndOfPlaylist =
    location.state &&
    !location.state.nextGameId &&
    currentGameIndex + 1 === playlist.length;
  const isRenderServer = multiplayer.isRenderServer();
  const isSpectator = multiplayer.isSpectator;

  const isRound = playlist[currentGameIndex] === game;

  useEffect(() => {
    setTimeout(() => {
      // reset all non-Playroom states
      if (isRenderServer) {
        resetAllNonPlayroomStates(multiplayer);
      // ---- Reset Sounds to Playroom-only sounds
      // reset BackgroundMusic
      if (BackgroundMusic().getCurrentlyPlaying() !== soundBgMusic) {
          BackgroundMusic("game").stop();
          BackgroundMusic().play(soundBgMusic);
          BackgroundMusic().setVolume(1);
      }
      }
    }, 2000); // we wait a bit to make sure the game has unloaded

    if (isSpectator) {
      if (location.state && location.state.firstTime) {
        multiplayer.roomLog(LOGTYPES.ROOMSTATE, "playlist_started", {
          playlist: multiplayer.getState("playlist"),
        });
        BGNarrator.play(soundLetsGo); // only play the first time.

        // Uncomment to make a random playlist on the first time
        // this screen appears.

        //  -------------- UNCOMMENT -----------------
        // multiplayer.setState(
        //   "playlist",
        //   RandomGamesOrder.map((game) => game.id)
        // );
        //  -------------- UNCOMMENT -----------------
      }
      // see if we reached end of the playlist, redirect to endscreen then
      if (hasReachedEndOfPlaylist) {
        history.push(`/endscreen`);
        return;
      }
      console.log(
        "NEXT GAME CHOSEN",
        game.id,
        "why?",
        location,
        currentGameIndex,
        playlist
      );
    }
    // eslint-disable-next-line
  }, []);

  if (hasReachedEndOfPlaylist) return false;

  return (
    <>
      {isSpectator ? (
        <AutoplayNextGameModal
          open={true}
          nextGame={game}
          slideIn={location.state.firstTime}
          lastGameId={location.state.currentGameId}
          onFinish={() => {
            if (isRenderServer) {
              const nextGameIndex =
                location.state && location.state.nextGameId
                  ? playlist.findIndex(
                      (game) => game.id === location.state.nextGameId
                    )
                  : currentGameIndex + 1;
              multiplayer.setState("playlistCurIndex", nextGameIndex);
              history.push(`/games/${game.pages[0].slug}`);
            }
          }}
          countdownTime={isRound ? 3 : 20}
        />
      ) : (
        <AutoplayNextGameModal
          open={true}
          controllerMode={true}
          lastGameId={location.state.currentGameId}
          nextGame={game}
          countdownTime={isRound ? 3 : 20}
        />
      )}
    </>
  );
}

export default AutoplayScreen;
