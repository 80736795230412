import React, { useEffect, useState } from "react";
import Game from "common/components/GameRenderer";
import { GameScene } from "./src/game";
import GamesMenu from "common/components/GamesMenu";
import Multiplayer from "common/modules/multiplayer";
import BackgroundGame from "common/components/BackgroundGame";
import TouchJoystick from "common/modules/controllers/touch";
import "./style.css";
import { useGlobalState } from "common/hooks/multiplayer";

import { FullscreenCountdown } from "common/components/Countdown";

export default function GamePage({ gameInfo }) {
  const multiplayer = Multiplayer();
  const gameIsLive = useGlobalState("live");
  const [winnerFound, setWinnerFound] = useState(
    multiplayer.getState("winner") || false
  );


    const stopCountdown = () => {
      multiplayer.setState("live", true);
    }

  useEffect(() => {
    var controller = null;
    var cleanupFns = [];
    cleanupFns.push(
      multiplayer.on("state", (state, key) => {
        setWinnerFound(state["winner"] || false);
      })
    );
    if (!multiplayer.isSpectator) {
      controller = new TouchJoystick({
        labels: { dpad: "move", b1: "jump" },
      });
      multiplayer.attachController(controller);
    }

    return () => {
      cleanupFns.forEach((cleanup) => cleanup());
      if (!multiplayer.isSpectator) {
        multiplayer.detachController();
        if (controller) controller.destroy();
        controller = null;
      }
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <BackgroundGame style={{ backgroundColor: "#6BDCFF" }}>
        {/* <Audience key="audience" multiplayer={multiplayer} /> */}
      </BackgroundGame>
      <GamesMenu
        key="gamesmenu"
        inGame
        showLogo
        gameInfo={gameInfo}
        showRestart={winnerFound && multiplayer.isHost}
      />
      {multiplayer.isSpectator ? 
      
      gameIsLive ? (
        <Game
          key="game"
          gameInfo={gameInfo}
          scenes={[GameScene]}
          physics={{
            default: "matter",
            matter: {
              runner: {
                fps: 15,
                isFixed: true,
              },
              gravity: {
                y: 2,
              },
              debug: false,
            },
          }}
        />
      )
      : (
        <FullscreenCountdown key={multiplayer.getState("live") || "fullscreen-countdown"} onFinish={stopCountdown} />
      )
      : (
        false
      )}
    </>
  );
}
