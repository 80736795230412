import React from "react";
import RocketImage from "./rocketImage";

import Smoke from "./components/Smoke";

import "./rocket.scss";

export default function PlayerRocket({
  color,
  fuelPercent,
  hasWon,
  isDone,
  playerName,
  playerState,
  avatarContainerStyles,  
  showFire,
}) {
  return (
    <div className="player-rocket-container">
      <div
        style={{ color: color }}
        className={"winner-container " + (hasWon ? "visible" : false)}
      >
        {playerName + " wins!"}
      </div>
      <div className={(isDone ? "" : "") + " new-rocket-ship"} style={avatarContainerStyles}>
        <RocketImage color={color} size={15} playerState={playerState} />
        <div className="flame left"></div>
        <div className="flame right"></div>


        {
          showFire ?
          <>
        <div className="fire-container" style={{
          padding: "0rem 4rem",
        }}>
          <div className="fires">
            {/* <div className="fires"> */}
              <div className="fire"></div>
              <div className="fire"></div>
            {/* </div> */}
          </div>
        </div>

        <div className="fire-container" style={{
          padding: "0rem 2rem",
        }}>
          <div className="fires">
            {/* <div className="fires"> */}
              <div className="fire"></div>
              <div className="fire"></div>
            {/* </div> */}
          </div>
        </div>
        </>
        :
        <></>
      }

      </div>


      {/* more smoke on fire */}
      {
      showFire ?
      <>
      <Smoke containerStyles={{
        transform: "scale(1.2, -1.2) translateY(-3rem)",
        left: "3.3rem",
        zIndex: -3,
    }}/>
          <Smoke containerStyles={{
        transform: "scale(1.2, -1.2) translateY(-3rem)",
        // left: 0,
        left: "11rem",
        zIndex: -3,
    }}/>
    </>
    :
    <></>
  }


      <Smoke containerStyles={{
          transform: "scaleY(-1)",
      }}/>


      <div className="launch-pad-platform" style={isDone ? {} : {}}></div>
      <div className="launch-pad">
        <div
          className="launch-bar"
          role="progressbar"
          style={{ height: `${fuelPercent}%`, backgroundColor: color }}
        ></div>
      </div>
    </div>
  );
}
