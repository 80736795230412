import "./style.css";
import { useEffect, useRef, useState } from "react";
import betaImg from "./img/beta.png";

import { useForceUpdate } from "../../hooks/useForceUpdate";

import PropTypes from "prop-types";

/*
References:
- https://codepen.io/itsmikemoran/details/yOqNGB

Research:
- Get the width of an element via ref: https://bobbyhadz.com/blog/react-get-element-width-ref#:~:text=To%20get%20the%20width%20of,the%20width%20of%20the%20element.
- width doesn't update sometimes! Needs timeout: 
    - https://stackoverflow.com/questions/55275076/offsetwidth-of-ref-not-updating-in-react
    - https://stackoverflow.com/questions/294250/how-do-i-retrieve-an-html-elements-actual-width-and-height
*/

const BetaTag = ({
  text = "Remote play is in beta. You may experience low quality.",
  expand = false,
  position = "center",
  triggerAfter = 0,
}) => {
  const [expanded, setExpanded] = useState(expand);
  const betaTagBubbleRef = useRef();
  const forceUpdate = useForceUpdate();

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (triggerAfter) {
      var triggerTimeout = setTimeout(() => {
        setExpanded(!expanded);
      }, triggerAfter);
    }

    return () => {
      if (triggerTimeout) clearTimeout(triggerTimeout);
    };
  }, [triggerAfter, expanded]);

  useEffect(() => {
    const dimensions = betaTagBubbleRef.current.getBoundingClientRect();
    setWidth(dimensions.width);
    setHeight(dimensions.height);
  }, [betaTagBubbleRef]);

  useEffect(() => {
    forceUpdate();
    setTimeout(() => {
      setWidth(betaTagBubbleRef.current.offsetWidth);
      setHeight(betaTagBubbleRef.current.offsetHeight);
    }, (1 - expanded) * 300);

    // eslint-disable-next-line
  }, [expanded]);

  console.log(width, height);

  return (
    <div
      className={`beta-tag-bubble ${expanded ? "expanded" : ""}`}
      // needed for hover
      // onMouseOver={()=>{
      //     setWidth(betaTagBubbleRef.current.offsetWidth);
      //     setHeight(betaTagBubbleRef.current.offsetHeight);
      // }}

      // onMouseOut={()=>{
      //     forceUpdate();
      //     setTimeout(()=>{
      //         setWidth(betaTagBubbleRef.current.offsetWidth);
      //         setHeight(betaTagBubbleRef.current.offsetHeight);
      //     }, 300);
      // }}

      ref={betaTagBubbleRef}
      style={{
        transform:
          // expanded
          // &&
          position === "center"
            ? // && position === "center"
              `translate(-${(width - height) / 2}px, 0px)`
            : // : `translate(${width-height/2}px, 0px)`,
              undefined,
        left: position === "left" || position === "center" ? "50%" : undefined,
        right: position === "right" ? "50%" : undefined,
      }}
    >
      <img
        alt="Beta mode symbol"
        src={betaImg}
        style={{
          height: "100%",
          width: "auto",
          verticalAlign: "center",
        }}
      />
      <div className={`unexpanded-text ${expanded ? "expanded-text" : ""}`}>
        <span>{text}</span>
      </div>
    </div>
  );
};

// for storybook
BetaTag.propTypes = {
  // pointAt: PropTypes.bool,
  text: PropTypes.string,
  // children: PropTypes.bool,
  expanded: PropTypes.bool,
  position: PropTypes.oneOf(["left", "center", "right"]),
  triggerAfter: PropTypes.number,
  // quickPositions: PropTypes.array,
  // animate: PropTypes.bool,
  // fingerPointerStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  // textSpanStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default BetaTag;
