import "./style.css";

import React, { useState, useCallback, useRef, useEffect } from "react";
import sampleCardData from "./sampleData";
import NonPassiveTouchTarget from "./utils/NonPassiveTouchTarget";
import TouchCarousel, { clamp } from "react-touch-carousel";
import touchWithMouseHOC from "react-touch-carousel/lib/touchWithMouseHOC";

import { useLongPress } from "use-long-press";

import PropTypes from "prop-types";

import UIfx from "common/modules/uifx";

import sfxCardInteraction from "./sfx/card-selected.mp3";
import { data } from "flickity/js/flickity";

/*
References:
- Base UI and carousel: https://www.npmjs.com/package/react-touch-carousel
- Card Fan: https://github.com/xiaody/react-touch-carousel/tree/master/examples/rolling
- Long press: https://github.com/minwork/use-long-press
*/

/*
Research:
- Get global mouse position: https://bobbyhadz.com/blog/react-get-mouse-position#:~:text=To%20get%20the%20mouse%20position,properties%20on%20the%20event%20object.
- addEventListener does not have latest State values because it saves a "snapshot" when it initializes: https://stackoverflow.com/a/67244193
- Different ways of comparing two arrays: https://stackoverflow.com/a/33034768
- Touch Events docs, Mozilla: https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Using_Touch_Events
- Basic CSS animations: https://codepen.io/nelledejones/pen/gOOPWrK
*/

/*
Possible other animation
- Zoom card on onPress / onHover
- onTap should play the card
- PinchZoomIn/Out should increase/decrease the number of cards
- Arrows to cycle through the carousel
*/

const CardGame = ({
  cardData = sampleCardData,
  throwDisabled = false,
  onDropzoneCardsChange = undefined,
  onHandCardsChange = undefined,
  dropzoneText = undefined,
  title = "",

  dropzoneStyles = {},
  gameContainerStyles = {},
  throwButtonStyles = {},
  cardStyles = {},
  touchCarouselStyles = {},

  cardSize = 130,
  cardPadCount = 3,
  carouselWidth = clamp(window.innerWidth, 0, 960),

  resetToggle = undefined,

  discardUsedCards = false,
  disableThrowOnDropzone = false,

  touchCarouselProps = {},

  /*
  Change how the cards are
  shown.
  Possible values=["fan", "carousel"]
  */
  cardHandType = "fan",

  /*
  Compatible with interactionMode which
  use SwapCards instead of an actual dropzone
  e.g. "throw", "tap"
  */
  showDropzone = true,

  /* 
  Change this prop to change the interaction mode.
  Possible values=["longpress", "swipe", "throw", "tap"]
  */
  interactionMode = ["throw"],

  /*
  Control infinity looping of cards
  */
  loop = true,
}) => {
  const cardInteractionSound = UIfx(sfxCardInteraction);

  // necessary state variables which hold the game data
  const [handData, setHandData] = useState(cardData?.filter((c) => !c.chosen));
  const [flyingData, setFlyingData] = useState([]);
  const [dropzoneData, setDropzoneData] = useState(
    cardData?.filter((c) => c.chosen)
  );

  const [showThrowButton, setShowThrowButton] = useState(
    interactionMode.includes("throw")
  );
  const [isRested, setIsRested] = useState(true);
  const [centerCardIndex, setCenterCardIndex] = useState(0);

  // a reference to the flyingCard
  const flyingCard = useRef(null);

  // a reference to the game container
  const gameContainer = useRef(null);

  // a reference to the touch carousel
  const carousel = useRef(null);

  // const carouselWidth = gameContainer.current ? gameContainer.current.innerWidth : 0;

  // the flying card is a bit far from the cursor.
  // This offset tries to correct that
  //              y  , x
  const offset = [280, cardSize + cardSize / 2];

  // The initial position of the card when it is in Flying state
  const [resetPosition, setResetPosition] = useState({
    x: 250,
    y: 400,
  });

  const _showThrowButton =
    showThrowButton &&
    isRested &&
    !throwDisabled &&
    !(disableThrowOnDropzone && dropzoneData.length);

  useEffect(() => {
    if (cardInteractionSound) cardInteractionSound.setVolume(0.4);
  }, []);

  useEffect(() => {
    onHandCardsChange && onHandCardsChange(handData);
    // eslint-disable-next-line
  }, [handData]);

  useEffect(() => {
    if (dropzoneData.length) cardInteractionSound.play();
    onDropzoneCardsChange && onDropzoneCardsChange(dropzoneData);
    // eslint-disable-next-line
  }, [dropzoneData]);

  useEffect(() => {
    // ----------- PRINT DEBUG -----------
    // console.log("resetToggle has changed...", resetToggle);
    // ----------- PRINT DEBUG -----------

    if (resetToggle === true || resetToggle === false) {
      if (!discardUsedCards) setHandData(cardData);
      setFlyingData([]);
      setDropzoneData([]);
      console.log("I've reset the card game!");
    }
  }, [
    resetToggle,
    // cardData,
  ]);

  const swapCenterCard = (_centerCardIndex = centerCardIndex) => {
    // remove center card from hand
    let centerCard = handData[_centerCardIndex];
    let newHandData = handData.filter((c, idx) => idx !== _centerCardIndex);

    // ----------- PRINT DEBUG -----------
    // console.log("centerCardIndex:", centerCardIndex);
    // console.log("centerCard:", centerCard);
    // console.log("newHandData:", newHandData);
    // ----------- PRINT DEBUG -----------

    // if card(s) are in the dropzone, bring them
    // back to the hand

    // concat two arrays while keeping it unique: https://stackoverflow.com/questions/1584370/how-to-merge-two-arrays-in-javascript-and-de-duplicate-items
    if (dropzoneData.length) {
      var c = newHandData.concat(dropzoneData);
      newHandData = c.filter((item, pos) => c.indexOf(item) === pos);
    }

    setHandData(newHandData);
    setDropzoneData([centerCard]);
  };

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [swipeDirection, setSwipeDirection] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setSwipeDirection(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e) => {
    if (!touchStart || !e.targetTouches) return;
    const distance = touchStart - e.targetTouches[0].clientY;
    const isUpSwipe = distance > minSwipeDistance;
    const isDownSwipe = distance < -minSwipeDistance;
    // if (isUpSwipe || isDownSwipe) console.log('swipe', isUpSwipe ? 'up' : 'down')

    if (!swipeDirection) {
      if (isUpSwipe) {
        callback(e);
        setSwipeDirection("up");
      }
      if (isDownSwipe) {
        const el = e.target;
        const cardEl = el.closest(".carousel-card");
        let isInDropzone = dropzoneData.find((i) => i.id === cardEl.id);
        if (isInDropzone) callback(e);
        setSwipeDirection("down");
      }
    }

    setTouchEnd(e.targetTouches[0].clientY);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    setSwipeDirection(null);
    // const distance = touchStart - touchEnd
    // const isUpSwipe = distance > minSwipeDistance
    // const isDownSwipe = distance < -minSwipeDistance
    // if (isUpSwipe || isDownSwipe) console.log('swipe', isUpSwipe ? 'up' : 'down')
    // add your conditional logic here
  };

  useEffect(() => {
    if (swipeDirection) {
      console.log("swiped:", swipeDirection);
    }
  }, [swipeDirection]);

  // Save the global state of the mouse / touch here
  const [globalCoords, setGlobalCoords] = useState({ x: 0, y: 0 });

  // 👇️ get global mouse coordinates
  useEffect(
    () => {
      // ----------- PRINT DEBUG -----------
      // console.log("I'm reinitialized:", dropzoneData, flyingData)
      // ----------- PRINT DEBUG -----------

      const handleWindowTouchEnd = (event) => {
        // TODO: Could cause issues if there are other buttons in the page, maybe?
        // update: it did ;) it prevented button touches

        // event.preventDefault();
        // event.stopPropagation();

        var coordinates;
        if (!event.touches)
          coordinates = {
            x: event.screenX,
            y: event.screenY,
          };
        else
          coordinates = {
            x: event.changedTouches[0].clientX,
            y: event.changedTouches[0].clientY,
          };

        // - https://stackoverflow.com/a/12596216
        // - https://developer.mozilla.org/en-US/docs/Web/API/Document/elementsFromPoint
        var elems = document.elementsFromPoint(coordinates.x, coordinates.y);

        setGlobalCoords(resetPosition);

        // https://stackoverflow.com/questions/8217419/how-to-determine-if-javascript-array-contains-an-object-with-an-attribute-that-e
        if (elems.some((el) => el.id === "dropzone")) {
          // ----------- PRINT DEBUG -----------
          // console.log("the elems are:", elems);
          // console.log("The flying data is:", flyingData);
          // console.log("The flyingcard is:", flyingCard);
          // ----------- PRINT DEBUG -----------

          if (flyingCard.current) {
            // ----------- PRINT DEBUG -----------
            // console.log("flyingCard.current.id:", flyingCard.current.id);
            // console.log("[cardData.find(c => c.id === flyingCard.current.id)]:", [cardData.find(c => c.id === flyingCard.current.id)])
            // ----------- PRINT DEBUG -----------

            // https://stackoverflow.com/questions/60540985/react-usestate-doesnt-update-in-window-events
            if (dropzoneData.length)
              setHandData((currentHandData) => {
                let newHandData = currentHandData;
                // https://stackoverflow.com/questions/1988349/array-push-if-does-not-exist
                if (newHandData.indexOf(dropzoneData[0]) === -1)
                  newHandData.push(dropzoneData[0]);
                return newHandData;
              });
            setDropzoneData((dropzoneData) => {
              return [cardData.find((c) => c.id === flyingCard.current.id)];
            });
          }
        } else {
          // ----------- PRINT DEBUG -----------
          // console.log("dropzoneData -> ", dropzoneData);
          // console.log("cardData.filter(x => !dropzoneData.includes(x)) -> ",
          //         cardData.filter(x => !dropzoneData.includes(x))
          // );
          // ----------- PRINT DEBUG -----------

          if (flyingCard.current)
            setHandData((currentHandData) => {
              // https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript
              return cardData.filter((x) => !dropzoneData.includes(x));
            });
        }
        setFlyingData((currentFlyingData) => {
          return [];
        });
      };

      const handleWindowMouseMove = (event) => {
        // ----------- PRINT DEBUG -----------
        // console.log("I believe I can fly!")
        // ----------- PRINT DEBUG -----------

        setGlobalCoords({
          x: event.screenX,
          y: event.screenY,
        });
      };

      const handleWindowTouchMove = (event) => {
        // ----------- PRINT DEBUG -----------
        // console.log(event.touches)
        // ----------- PRINT DEBUG -----------

        if (!event.touches) return;

        setGlobalCoords({
          x: event.touches[0].clientX,
          y: event.touches[0].clientY,
        });
      };

      window.addEventListener("touchmove", handleWindowTouchMove);
      window.addEventListener("mousemove", handleWindowMouseMove);

      window.addEventListener("touchend", handleWindowTouchEnd);
      window.addEventListener("mouseup", handleWindowTouchEnd);

      return () => {
        window.removeEventListener("touchmove", handleWindowTouchMove);
        window.removeEventListener("mousemove", handleWindowMouseMove);

        window.removeEventListener("touchend", handleWindowTouchEnd);
        window.removeEventListener("mouseup", handleWindowTouchEnd);
      };
    },

    // https://stackoverflow.com/a/67244193
    [handData, dropzoneData, flyingData, resetPosition, cardData]
  );

  useEffect(() => {
    // ----------- PRINT DEBUG -----------
    // console.log("resetPosition:", resetPosition);
    // ----------- PRINT DEBUG -----------

    if (resetPosition) setGlobalCoords(resetPosition);
  }, [resetPosition]);

  const selectACard = (cardEl) => {
    let isInHand = handData.find((i) => i.id === cardEl.id);
    let isInDropzone = dropzoneData.find((i) => i.id === cardEl.id);

    // ----------- PRINT DEBUG -----------
    // console.log("isInHand:", isInHand);
    // console.log("isInDropzone:", isInDropzone);
    // ----------- PRINT DEBUG -----------

    let pressed_one;

    if (isInHand) pressed_one = isInHand;
    if (isInDropzone) pressed_one = isInDropzone;

    pressed_one = [pressed_one];

    // ----------- PRINT DEBUG -----------
    // console.log("find the pressed one!", pressed_one);
    // ----------- PRINT DEBUG -----------

    setFlyingData(pressed_one);

    if (isInHand) {
      let _count = 0;
      setHandData(
        handData.filter((item, idx) => {
          // ----------- PRINT DEBUG -----------
          // console.log("dropzoneData:", dropzoneData)
          // console.log("dropzoneData.find(c => c.id === item.id):", dropzoneData.find(c => c.id === item.id))
          // ----------- PRINT DEBUG -----------

          if (
            _count < 1 &&
            // if the card was long-pressed,
            (item.id === cardEl.id ||
              //  or,
              // if the card is in the dropzone
              (dropzoneData && dropzoneData.find((c) => c.id === item.id)))
          ) {
            // don't show it in the hand
            item.chosen = true;
            _count += 1;
            return false;
          } else {
            item.chosen = false;
            return item;
          }
        })
      );
    }
    if (isInDropzone) {
      let _count = 0;
      setDropzoneData(
        dropzoneData.filter((item, idx) => {
          // ----------- PRINT DEBUG -----------
          // console.log("handData:", handData)
          // console.log("handData.find(c => c.id === item.id):", handData.find(c => c.id === item.id))
          // ----------- PRINT DEBUG -----------

          if (
            _count < 1 &&
            // if the card was long-pressed,
            (item.id === cardEl.id ||
              //  or,
              // if the card is in the hand
              (handData && handData.find((c) => c.id === item.id)))
          ) {
            // don't show it in the dropzone
            item.chosen = true;
            _count += 1;
            return false;
          } else {
            item.chosen = false;
            return item;
          }
        })
      );
    }
  };

  const callback = useCallback(
    (event) => {
      if (throwDisabled || (disableThrowOnDropzone && dropzoneData.length))
        return;

      const el = event.target;

      // find the closest parent which is a carousel-card
      // https://stackoverflow.com/a/27037567
      const cardEl = el.closest(".carousel-card");

      // ----------- PRINT DEBUG -----------
      // console.log("long-pressed ->", cardEl.id);
      // ----------- PRINT DEBUG -----------

      selectACard(cardEl);
    },

    // https://stackoverflow.com/a/67244193
    // eslint-disable-next-line
    [handData, dropzoneData, throwDisabled]
  );

  const bind = useLongPress(
    interactionMode.includes("longpress")
      ? callback
      : () => {
          console.log("ignore longpress");
        },
    {
      onStart: (event) => {
        // ----------- PRINT DEBUG -----------
        // console.log("event.screenX:", event.touches[0].clientX);
        // console.log("event.screenY:", event.touches[0].clientY);
        // ----------- PRINT DEBUG -----------

        if (!event.touches) return;
        if (interactionMode.includes("swipe")) onTouchStart(event);

        setResetPosition({
          x: event.touches[0].clientX,
          y: event.touches[0].clientY,
        });
      },
      onMove: (event) => {
        if (interactionMode.includes("swipe")) onTouchMove(event);
      },
      onCancel: (event) => {
        if (interactionMode.includes("swipe")) onTouchEnd(event);
      },
      onFinish: (event) => {
        if (interactionMode.includes("swipe")) onTouchEnd(event);
      },
      threshold: 98,
      captureEvent: true,
      cancelOnMovement: 5,
      detect: "both",
    }
  );
  function CarouselContainer(props) {
    const { cursor, carouselState, ...rest } = props;
    // console.log ("cursor: ", cursor);
    // console.log("carouselWidth:", carouselWidth);
    // console.log("cardSize:", cardSize);

    // Put current card at center
    const translateX = loop
      ? (cursor - cardPadCount) * cardSize + (carouselWidth - cardSize) / 2
      : (cursor - cardPadCount) * cardSize +
        (carouselWidth - cardSize) / 2 +
        cardSize * cardPadCount;
    // ------------- PRINT DEBUG
    // console.log("Cursor:", cursor);
    // console.log("carouselState:", carouselState);
    // console.log("...rest:", rest);
    // console.log("translateX:", translateX);
    // console.log(`style="transform: translate3d(${translateX}px, 0, 0)"`)
    // ------------- PRINT DEBUG

    return (
      <NonPassiveTouchTarget
        className="carousel-container"
        style={{
          // marginBottom: "12rem",
          position: "absolute",
          top: 0,
          bottom: 0,
          margin: "auto",
          transform: "translate(0rem, -3rem)",
        }}
      >
        <NonPassiveTouchTarget
          className="carousel-track"
          style={{
            transform: `translate3d(${translateX}px, 0, 0)`,
          }}
          {...rest}
        />
      </NonPassiveTouchTarget>
    );
  }

  const Container = touchWithMouseHOC(CarouselContainer);

  function renderCard(index, modIndex, cursor) {
    // ------------- PRINT DEBUG
    // console.log("index, modIndex, cursor:", index, modIndex, cursor)
    // ------------- PRINT DEBUG

    let indexToSelect = modIndex;

    // https://www.w3schools.com/jsref/jsref_isnan.asp
    if (isNaN(indexToSelect)) {
      return <p>something is wrong with this card!</p>;
    }

    const item = handData[indexToSelect];

    // ----------- PRINT DEBUG -----------
    // console.log("indexToSelect is:", indexToSelect);
    // console.log("Item is:", item);
    // console.log("(index + cursor):", (index + cursor))
    // ----------- PRINT DEBUG -----------

    const isFan = cardHandType === "fan" ? 1 : 0;

    const rotate = 25 * (index + cursor) * isFan;
    const marginTop = Math.abs(50 * (index + cursor)) * isFan;
    const onTop = Math.abs(index + cursor) < 0.5;

    // prevent crashes on item undefined
    if (!item) {
      return <></>;
    }

    return (
      <div
        key={index}
        id={item.id}
        className="carousel-card"
        {...bind()}
        style={{
          marginTop: marginTop + "px",
        }}
        onClick={(event) => {
          carousel.current.go(loop ? cardData.length - index : -index);
          if (interactionMode.includes("tap") && isRested) {
            console.log("tapped a card which was at rest");
            !throwDisabled &&
              !(disableThrowOnDropzone && dropzoneData.length) &&
              swapCenterCard(index);
          }
        }}
        onTouchStart={() => {
          // When finger is put on the card
          cardInteractionSound.play();
        }}
        // onTouchEnd={() => {
        //   // When finger is lifted from the card
        //   cardInteractionSound.play();
        // }}
      >
        <Card
          backgroundColor={item.backgroundColor}
          backgroundImage={item.backgroundImage}
          title={item.title}
          cardImage={`url("${item.image}")`}
          cardStyle={{
            ...item.cardStyles,
            ...{
              transform: `rotate(${rotate}deg)`,
              zIndex: onTop ? 1 : 0,
            },
          }}
        />
      </div>
    );
  }

  return (
    <div>
      {flyingData.length ? (
        <div
          id={flyingData[flyingData.length - 1].id}
          className="carousel-card"
          ref={flyingCard}
          style={{
            position: "absolute",
            zIndex: 2,
            userSelect: "none",
          }}
        >
          <Card
            backgroundImage={flyingData[flyingData.length - 1].backgroundImage}
            backgroundColor={flyingData[flyingData.length - 1].backgroundColor}
            selectable={false}
            cardImage={`url("${flyingData[flyingData.length - 1].image}")`}
            title={flyingData[flyingData.length - 1].title}
            cardStyle={{
              ...flyingData[flyingData.length - 1].cardStyles,
              ...{
                animation: "bounce-in 0.4s",
                transition: "0.15s ease-out",
                top: globalCoords.y - offset[0],
                left: globalCoords.x - offset[1],
              },
            }}
          />
        </div>
      ) : (
        <></>
      )}

      <div
        style={{
          ...{
            // marginTop: "150px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",

            // marginLeft: "-10px",
            // height: "100vh",
            // width: "100%",
          },
          ...gameContainerStyles,
        }}
        ref={gameContainer}
      >
        {title ? <h1> {title} </h1> : <></>}

        {showDropzone ? (
          <div
            className="dropzone"
            id="dropzone"
            style={{
              ...{
                background: "lightblue",
                height: "200px",
                width: "50%",
                position: "relative",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              },
              ...dropzoneStyles,
            }}
          >
            <h2
              style={{
                position: "absolute",
                textAlign: "center",
                opacity: 0.2,
                color: "inherit",
              }}
            >
              {dropzoneText
                ? dropzoneText
                : interactionMode + " a card from below"}
            </h2>
            {dropzoneData.map((item, idx) => {
              // ----------- PRINT DEBUG -----------
              // console.log("I'm a card being rendered in dropzone");
              // console.log("Background: ", item.background)
              // ----------- PRINT DEBUG -----------

              if (!item) return <></>;

              return (
                <div
                  key={idx}
                  id={item.id}
                  className="carousel-card dropzone-card"
                  {...bind()}
                >
                  <Card
                    backgroundImage={item.backgroundImage}
                    backgroundColor={item.backgroundColor}
                    cardImage={`url("${item.image}")`}
                    title={item.title}
                    cardStyle={{
                      ...item.cardStyles,
                      ...{
                        boxShadow: "rgba(0, 0, 0, 0.90) 0rem 2rem 6rem -4rem",
                      },
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}

        <button
          className="button-black"
          style={{
            ...{
              width: "fit-content",
              display: "flex",
              transition: "0.2s linear",
              margin: "3rem 0rem 2rem",
              opacity: _showThrowButton ? 1 : 0,
              pointerEvents: _showThrowButton ? "auto" : "none",
            },
            ...throwButtonStyles,
          }}
          onClick={() => {
            // ----------- PRINT DEBUG -----------
            // console.log("throw the center card!")
            // ----------- PRINT DEBUG -----------

            !throwDisabled && swapCenterCard();
          }}
        >
          Throw!
        </button>

        {handData.length ? (
          <div
            style={{
              position: "relative",
              flex: "1",
              // position: "absolute",
              // bottom: 0,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <TouchCarousel
              ref={carousel}
              component={Container}
              cardSize={cardSize}
              cardCount={handData.length}
              cardPadCount={cardPadCount}
              renderCard={renderCard}
              onDragStart={(event) => {
                setIsRested(false);
                setShowThrowButton(false);
              }}
              onRest={(index) => {
                // ----------- PRINT DEBUG -----------
                // console.log("rested...", index);
                // ----------- PRINT DEBUG -----------

                // cardInteractionSound.play();

                if (interactionMode.includes("throw")) setShowThrowButton(true);
                setIsRested(true);
                setCenterCardIndex((handData.length + index) % handData.length);
              }}
              // key={handData.length > 3 ? "touch-carousel-1" : "touch-carousel-2"}
              // loop={handData.length > 3 ? true : false}
              loop={loop}
              {...touchCarouselProps}

              // THIS WAS CAUSING ALL THE BUGS!
              // style={touchCarouselStyles}

              // setcentercardindex={setCenterCardIndex}
            />

            <span
              style={{
                position: "absolute",
                // top: "-11rem",
                left: "0",
                right: "0",
                margin: "auto",
                top: "0",
                bottom: "0",

                height: "fit-content",
                transform: "translate(0rem, 8rem)",

                width: "fit-content",
                // transform: 'translate(50%, -10rem)',
                padding: "1rem 1.5rem",
                backgroundColor: "#00000076",
                color: "white",
                borderRadius: "1rem",
              }}
            >
              {handData.length} cards
            </span>

            <div
              style={{
                position: "relative",
                // alignItems: "center",
                width: "100vw",
                textAlign: "center",
              }}
            >
              {/* <span style={{
                position: "absolute",
                top: "-11rem",
                left: "0",
                right: "0",
                margin: "auto",
                width: "fit-content",
                // transform: 'translate(50%, -10rem)',
                padding:"1rem 1.5rem",
                backgroundColor: "#00000076",
                color: "white",
                borderRadius: "1rem",
        }}>
        {handData.length} cards
      </span> */}
            </div>
          </div>
        ) : (
          <div className="carousel-track">
            <p>No cards left in your hand!</p>
          </div>
        )}
      </div>
    </div>
  );
};

// for storybook
CardGame.propTypes = {
  // cardData = sampleCardData,
  throwDisabled: PropTypes.bool,
  dropzoneText: PropTypes.string,
  title: PropTypes.string,

  // dropzoneStyles = {},
  // gameContainerStyles = {},
  // throwButtonStyles = {},
  // cardStyles = {},
  // touchCarouselStyles = {},

  // cardSize = 100,
  // cardPadCount = 3,
  // carouselWidth = clamp(window.innerWidth, 0, 960),

  resetToggle: PropTypes.bool,

  /*
  Change how the cards are
  shown.
  Possible values=["fan", "carousel"]
  */
  cardHandType: PropTypes.oneOf(["fan", "carousel"]),

  /*
  Compatible with interactionMode which
  use SwapCards instead of an actual dropzone
  e.g. "throw", "tap"
  */
  showDropzone: PropTypes.bool,

  /* 
  Change this prop to change the interaction mode.
  Possible values=["longpress", "swipe", "throw", "tap"]
  */
  interactionMode: PropTypes.array,
};

export default CardGame;

export const Card = ({
  cardStyle = {},
  selectable = true,

  title = "",
  backgroundColor = undefined,
  backgroundImage = undefined,
  cardImage,
}) => {
  return (
    <div
      className="carousel-card-inner"
      style={{
        ...{
          backgroundColor: backgroundColor,
          backgroundImage: backgroundImage,
          backgroundSize: "cover",
          // prevent this element from being selected
          userSelect: selectable ? undefined : "none",
          padding: "0px 0px",
        },
        ...cardStyle,
      }}
    >
      <div
        className={"image-container" + (selectable ? "" : " noselect")}
        style={{
          backgroundImage: cardImage,
          userSelect: selectable ? undefined : "none",
          backgroundSize: "contain",
          backgroundPosition: "50% 50%",
          height: "100%",
          width: "100%",
        }}
      ></div>
      {title ? (
        <div
          className={"carousel-title" + (selectable ? "" : " noselect")}
          style={{
            userSelect: selectable ? undefined : "none",
          }}
        >
          {title}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
