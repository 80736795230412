import styles from "./style.module.css";

const CartoonDialogBox = ({ text, children, containerStyles = {} }) => {
  return (
    <span className={`${styles.cartoonDialogBox}`} style={containerStyles}>
      {children}
      {text}
    </span>
  );
};

export default CartoonDialogBox;
