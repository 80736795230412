import React, { useState, useEffect } from "react";
import AvatarIcon from "common/components/AvatarIcon";

import Multiplayer from "common/modules/multiplayer";
import {
  useMyPlayerState,
  useOtherPlayerState,
} from "common/hooks/multiplayer";

export default function ComposedAvatar({ color, size, playerState, idx=0, showPlayerScore = true }) {
  const multiplayer = Multiplayer();
  const points = useOtherPlayerState(playerState, "points") || 0;
  const _id = useOtherPlayerState(playerState, "id") || 0;

  const [_points, setPoints] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setPoints(points)
    }, 1000);
  }, [points])

  return (
    <div
      className="composed-avatar"
      style={{
        position: "relative",
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "5.5rem",
        overflow: "visible",
      }}
    >
      <AvatarIcon
        playerState={playerState}
        style={{
          position: "relative",
          top: 0,
          left: 0,
          height: "5.5rem",
          width: "5.5rem",
          zIndex: 3,
          opacity: 1,
          visibility: "visible",
        }}
      />

      <h2>{playerState.getState("profile").name}</h2>

      {/* Don't show if the game hasn't started yet */}
      {showPlayerScore ? (
        <div
          key={_id + "-points-" + _points}
          className="space-container"
          style={{
            position: "absolute",
            zIndex: 5,
            top: "-1rem",
            // left:`${6-(2*idx)}rem`,
            right: "-2rem",
            padding: "0.4rem 1.5rem",
            borderRadius: "3rem",
            fontSize: "1.2rem",
            animation: "bounce-in 0.2s",
          }}
        >
          {_points}
        </div>
      ) :
      // multiplayer.isHost ?
      //   (
      //     <div
      //       className="space-container"
      //       style={{
      //         position: "absolute",
      //         zIndex: 5,
      //         top: "-31px",
      //         left: "50px",
      //         padding: "0.3rem 1.2rem",
      //         borderRadius: "1.5rem",
      //         fontSize: "1.2rem",
      //         padding: "2rem 3rem",
      //         whiteSpace: "nowrap",
      //       }}
      //     >
      //       Host press Start
      //     </div>
      //   )
      //   :
        <></>
      }
    </div>
  );
}
