function shuffle(array) {
  return array.sort(function () {
    return 0.5 - Math.random();
  });
}

export default function resetGameState(multiplayer) {
  multiplayer.setState("teamSelect", true);
  multiplayer.setState("points", undefined);
  multiplayer.setState("winner", undefined);

  // auto assign team
  const shuffledPlayers = shuffle(Object.keys(multiplayer.getPlayers()));
  console.log("shuffledPlayers: ", shuffledPlayers);
  multiplayer.setState("spotsTaken", {
    team1: shuffledPlayers.slice(0, shuffledPlayers.length / 2),
    team2: shuffledPlayers.slice(shuffledPlayers.length / 2),
  });
}
