import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
// import BackgroundRain from "common/components/BackgroundRain";
import Multiplayer from "common/modules/multiplayer";
import RoomCodeWithQR from "common/components/RoomCodeWithQR";
import GamesMenu from "common/components/GamesMenu";
import MuteButton from "common/components/MuteButton";
// import useEmblaCarousel from "embla-carousel-react";
// import Autoplay from "embla-carousel-autoplay";
import Leaderboard from "common/components/Leaderboard";
import flagGif from "common/imgs/flag-color.gif";
import Carousel from "./infiniteScroller";
import "./style.css";
import "./embla.css";

function WaitScreen() {
  const history = useHistory();
  const multiplayer = Multiplayer();
  const [isCasting, setIsCasting] = useState(false);
  const [playerCount, setPlayerCount] = useState(1);
  const isHost = multiplayer.isHost;
  const isRenderServer = multiplayer.isRenderServer();
  const isSpectator = multiplayer.isSpectator;

  useEffect(() => {
    const resetValues = () => {
      const casting = multiplayer.getState("casting");
      const pCount = Object.keys(multiplayer.getPlayers()).length;
      setIsCasting(casting);
      setPlayerCount(pCount);
      if (pCount >= 2 && isRenderServer) {
        history.push("/games/lobby");
      }
    };

    var cleanupFns = [];
    resetValues();
    cleanupFns.push(
      multiplayer.on("state", (state, key) => {
        resetValues();
      }),
      multiplayer.on("joined", (state, key) => {
        resetValues();
      })
    );
    return () => {
      cleanupFns.forEach((cleanup) => cleanup());
    };
    // eslint-disable-next-line
  }, []);

  if (isHost) return <GamesMenu key="gamesmenu" keepOpen hideMenuButton />;
  return (
    <div className="main-menu dark-bg">
      {/* <BackgroundRain key="rain" className="waitscreen-gradient" numDrops={5} /> */}
      <div className="wait-screen-container gradient">
        {isCasting && isSpectator ? <RoomCodeWithQR /> : false}
        {!isCasting ? (
          isHost ? (
            <p className="instruction-heading">
              To begin game, cast the room on TV or Laptop
            </p>
          ) : (
            <p className="instruction-heading center">
              Waiting for host to cast screen...
            </p>
          )
        ) : playerCount < 2 ? (
          isSpectator ? (
            <div className="waiting-for-players-container">
              <Leaderboard />
              <MuteButton castMode />
              <Carousel />
              <div className="waiting-for-players-container-hint">
                <img
                  className="flag-gif"
                  src={flagGif}
                  alt=""
                  style={{
                    height: "6rem",
                    marginLeft: "-1.5rem",
                  }}
                />
                <div
                  className="join-instructions-tv"
                  style={{
                    textAlign: "left",
                  }}
                >
                  <p className="instruction-heading">
                    Waiting! Need 2 players to start the room
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <p className="instruction-heading center">
              Waiting for more players to join...
            </p>
          )
        ) : (
          false
        )}
      </div>
    </div>
  );
}

export default WaitScreen;
