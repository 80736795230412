import React, { useEffect, useState } from "react";
import GamesMenu from "common/components/GamesMenu";
import BackgroundGame from "common/components/BackgroundGame";
import BackgroundMusic from "common/modules/backgroundMusic";
import TwoButtonJoystick from "common/modules/controllers/twobuttons";
import ControllerHUD from "common/components/ControllerHUD";
import Multiplayer from "common/modules/multiplayer";
import imgRocket from "./img/rocket.png";
import sfxLaunch from "./sfx/launch.mp3";
import UIfx from "common/modules/uifx";
import {
  usePlayersList,
  useGlobalState,
  usePlayerState,
} from "common/hooks/multiplayer";
import PlayerRocket from "./rocket";
import bgTimerSound from "common/sfx/timer.mp3";
import "./style.css";

import { FullscreenCountdown } from "common/components/Countdown";

const FUEL_PER_PUSH = 3;

function randRange(minNum, maxNum) {
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}

function bot(controller) {
  const interval = setInterval(async () => {
    controller.emit("keydown", "b1");
  }, randRange(1000, 2000));

  controller.on("destroy", () => clearInterval(interval));
}

function useForceUpdate() {
  // eslint-disable-next-line
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

function resetGame() {
  const multiplayer = Multiplayer();
  multiplayer.setState("live", undefined);
  multiplayer.setState("winner", undefined);
  const players = multiplayer.getPlayers();
  Object.keys(players).forEach((playerId) => {
    const playerState = players[playerId];
    playerState.setState("fuel", undefined);
    playerState.setState("done", undefined);
  });
}

function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export default function GamePage({ gameInfo }) {
  const multiplayer = Multiplayer();
  const forceUpdate = useForceUpdate();
  const myState = multiplayer.getMyPlayerState();
  const myColor = myState && myState.getState("profile")?.color;
  const myFuel = myState && (myState.getState("fuel") || 0);
  const players = usePlayersList();
  // const [lastPump, setLastPump] = useState(0);
  const [isMidGame, setIsMidGame] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const winner = useGlobalState("winner");
  // const gameIsLive = useGlobalState("live");
  const newGameRequested = useGlobalState("newGame");

  usePlayerState((playerId, changedKey, playerState, playerObj) => {
    forceUpdate();
    if (multiplayer.isSpectator) {
      if (playerState["fuel"] >= 100 && !playerState["done"]) {
        if (!multiplayer.getState("winner")) {
          UIfx(sfxLaunch).play(0.7);
          multiplayer.setState("winner", playerId);
          multiplayer.addToWinLog("rocketfuel", playerId);
          BackgroundMusic("timer").stop();
        }
        playerObj.setState("done", true);
        forceUpdate();
      }
    }
  });


  const stopCountdown = () => {
    multiplayer.setState("live", true);
    setCountdown(false);
    setIsMidGame(false);
    BackgroundMusic("timer").play(bgTimerSound);
  }

  // reset game state if host requests it (and the first time)
  useEffect(() => {

    if (multiplayer.isSpectator) {
      resetGame();
    }
    var controller = null;
    if (!multiplayer.isSpectator) {
      controller = new TwoButtonJoystick({
        onebutton: true,
        className: "rocketfuel",
        labels: { b1: "<span>tap here to fuel up</span>" },
      });
      if (window.ISBOT) bot(controller);
      var lastPump = 0;
      controller.on("keydown", (key) => {
        const gameIsLive = multiplayer.getState("live");
        console.log(key, gameIsLive, lastPump, lastPump + 250 <= Date.now());
        if (
          !multiplayer.getState("winner") &&
          gameIsLive &&
          lastPump + 250 <= Date.now()
        ) {
          // setLastPump(Date.now());
          lastPump = Date.now();
          var lastFuel = myState.getState("fuel") || 0;
          if (lastFuel < 100) {
            myState.setState(
              "fuel",
              Math.min(lastFuel + FUEL_PER_PUSH, 100),
              true
            );
          }
        }
      });
      // multiplayer.attachController(controller);
    }

    return () => {
      if (multiplayer.isSpectator) {
        BackgroundMusic("timer").stop();
      }
      if (!multiplayer.isSpectator) {
        multiplayer.detachController();
        if (controller) controller.destroy();
        controller = null;
      }
    };
    // eslint-disable-next-line
  }, [newGameRequested]);
  if (!isMidGame) {
    players.forEach((playerState) => {
      if (playerState.getState("fuel") > 50) {
        setIsMidGame(true);
      }
    });
  }
  return (
    <>
      {/* mid-game sky */}
      <BackgroundGame
        style={{
          background:
            "linear-gradient(180.27deg, #4622AD 16.85%, #8D6CEE 120.37%, #6BDCFF 140.73%, #6BFFB8 158.06%)",
        }}
      ></BackgroundGame>
      {/* 
      
      <BackgroundGame
        style={{ opacity: winner?1:0, transition: "opacity 3s", background: "linear-gradient(180deg, #8D6BED 49.66%, #76A7B7 104.47%, #6BFFB8 157.42%)" }}
      ></BackgroundGame> */}

      {/* end-game sky 2 aka space! */}
      <BackgroundGame
        style={{
          opacity: winner ? 1 : 0,
          transition: "opacity 5s",
          background:
            "linear-gradient(180.27deg, #0D0425 16.85%, #34109F 120.37%, #6BDCFF 140.73%, #6BFFB8 158.06%)",
        }}
      >
        <div className="star topleft" />
        <div className="star topright" />
        <div className="star bottomleft" />
        <div className="star bottomright" />
      </BackgroundGame>
      <GamesMenu
        key="gamesmenu"
        gradient
        hideRoomCode
        gameInfo={gameInfo}
        showRestart={winner && multiplayer.isHost}
        onRestart={async () => {
          multiplayer.setState("newGame", Math.random());
        }}
      />

      {multiplayer.isSpectator ? ( // tv/laptop screen
        <>
        {
        countdown ? (
              <FullscreenCountdown key={multiplayer.getState("live") || "fullscreen-countdown"} onCountdownChange={setCountdown} onFinish={stopCountdown} />
            ) : <></>
        }
          <div className="rocket-countdown-container">
            {!countdown && !winner ? (
              isMidGame ? (
                "Tap!"
              ) : (
                <>
                  Tap to pump
                  <br />
                  the fuel
                </>
              )
            ) : (
              ""
            )}
          </div>
          <Rockets players={players} winner={winner} />
        </>
      ) : (
        <ControllerHUD
          breadcrumbs={["Rocket Fuel"]}
          backgroundStyle={{
            background: `linear-gradient(180.27deg, #4622AD 16.85%, #8D6CEE 120.37%, #6BDCFF 140.73%, #6BFFB8 158.06%)`,
          }}
          avatarStyle={{
            background: `linear-gradient(180deg, #8D6BED 49.66%, #76A7B7 104.47%, #6BFFB8 157.42%)`,
          }}
          avatarUrl={imgRocket}
        >
          <div className="controller-progressbar">
            <div className="finish-line" />
            <div
              className="bar"
              style={{
                height: `calc(${myFuel}% - 0.6rem)`,
                backgroundColor: myColor,
              }}
            ></div>
          </div>
        </ControllerHUD>
      )}
    </>
  );
}

function Rockets({ players, winner }) {
  return (
    <div className={"rockets-container " + (winner ? "won" : "")}>
      <div className="rockets">
        {players.map((playerState) => {
          const profile = playerState.getState("profile");
          return (
            <PlayerRocket
              playerName={profile.name}
              fuelPercent={playerState.getState("fuel") || 0}
              color={profile?.color || "red"}
              playerState={playerState}
              isDone={playerState.getState("done")}
              hasWon={winner === playerState.id}
            />
          );
        })}
      </div>
    </div>
  );
}
