import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import BackgroundRain from "common/components/BackgroundRain";
import AvatarCreator from "common/modules/avatarCreator";
import AvatarCropper from "common/components/cropper";
import Modal from "common/components/Modal";
import ResizeImage from "common/modules/resizeImage";
import Multiplayer from "common/modules/multiplayer";
import { usePlayerState } from "common/hooks/multiplayer";
import BrowserStorage from "common/modules/localStorageWrapper";
import Spritesheet from "react-responsive-spritesheet";
import * as loadImage from "blueimp-load-image";

// import Chroma from "chroma-js";
import avatarImg from "./img/avatar.png";
import avatarEyes from "../games/Lobby/img/avatar-eyes.png";
import avatarShadow from "./img/shadow.png";
import { setUserProperties } from "common/modules/db";

import "./style.css";

const URLHash = require("common/modules/urlhash");

function randRange(minNum, maxNum) {
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}

const allAvatarColors = [
  "#59BF82",
  "#FFF27A",
  "#F14EEB",
  "#FF7F56",
  "#6BDCFF",
  "#3905F5",
  "#FF3666",
];
const RandomNames = [
  "Eclipse",
  "Crankshaft",
  "Firetruck",
  "President",
  "Bat",
  "Avocado",
  "Holiday",
  "Salad",
  "Boom",
  "Cornflakes",
];

let blinkTimeout = null;

function Profile() {
  const location = useLocation();
  const history = useHistory();
  const isDemoMode = URLHash.getUrlHashParameter("demo");
  const isHost = location.state && location.state.create;
  const multiplayer = Multiplayer();
  const [availableColors, setAvailableColors] = useState(allAvatarColors);
  const curProfileFromServer = multiplayer.getMyPlayerState()
    ? multiplayer.getMyPlayerState().getState("profile")
    : {
        name:
          RandomNames[Math.floor(Math.random() * RandomNames.length)] +
          randRange(10, 99),
        color:
          allAvatarColors[Math.floor(Math.random() * allAvatarColors.length)],
      };
  const [profile, setProfile] = useState({
    color: curProfileFromServer.color || getAvailableColorsForMe()[0],
    name: BrowserStorage.get("playerName") || curProfileFromServer.name,
    photo:
      BrowserStorage.get("playerPhoto") &&
      BrowserStorage.get("playerPhoto") !== "false"
        ? BrowserStorage.get("playerPhoto")
        : false,
  });

  usePlayerState((playerId, changedKey, playerState, playerObj) => {
    // const curColor = BrowserStorage.get("playerColor") || allAvatarColors[0];
    const availableColors = getAvailableColorsForMe();
    // const myColor = availableColors.includes(curColor)
    //   ? curColor
    //   : availableColors[0];
    setAvailableColors(availableColors);
    // updateProfile({ ...profile, color: myColor });
    // console.log("availableColors", availableColors, myColor);
  });

  function getAvailableColorsForMe() {
    const allPlayerStates = multiplayer.getPlayers();
    if (!allPlayerStates) return allAvatarColors;
    const playerSelectedColors = Object.keys(allPlayerStates)
      .filter((playerId) => playerId !== multiplayer.connection.myId)
      .map((playerId) => allPlayerStates[playerId].state["profile"]?.color);
    const availableColors = allAvatarColors.filter(
      (color) => !playerSelectedColors.includes(color)
    );
    return availableColors;
  }

  function updateProfile(newProfile) {
    console.log("updateProfile", newProfile);
    BrowserStorage.set("playerName", newProfile.name);
    if (newProfile.photo) BrowserStorage.set("playerPhoto", newProfile.photo);
    if (isHost) {
      BrowserStorage.set("playerColor", newProfile.color);
    } else {
      const state = multiplayer.getMyPlayerState();
      state.setState("profile", newProfile);
    }
  }

  const [userUploadedRawPhoto, setUserUploadedRawPhoto] = useState(false);
  const [cropperOpen, setCropperOpen] = useState(false);
  const [cropperCurrentCanvas, setCropperCurrentCanvas] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [avatarShadowUrl, setAvatarShadowUrl] = useState(null);

  // add blink animation randomly
  const blink = (spritesheet) => {
    if (blinkTimeout) clearTimeout(blinkTimeout);
    blinkTimeout = setTimeout(() => {
      if (spritesheet) {
        spritesheet.goToAndPlay(1);
      }
      blink(spritesheet);
    }, randRange(3000, 7000));
  };

  useEffect(() => {
    if (isHost) return;
    console.log("useEffect");
    const state = multiplayer.getMyPlayerState();
    var cleanupFns = [];
    cleanupFns.push(
      state.on("profile", (curProfile) => {
        if (curProfile) {
          setProfile(curProfile);
          BrowserStorage.set("playerColor", curProfile.color);
          BrowserStorage.set("playerName", curProfile.name);
          BrowserStorage.set("playerPhoto", curProfile.photo);
        } else {
          updateProfile(profile);
        }
      })
    );

    if (isDemoMode) {
      setTimeout(() => {
        const curProfile = state.getState("profile");
        updateProfile({
          ...curProfile,
          photo: `/avatars/demo/a${randRange(1, 5)}.png`,
        });
      }, 2000);
    }
    if (window.ISBOT) {
      setTimeout(() => {
        // window.RotateScreen("landscape");
        const statePath = multiplayer.getState("path");
        history.push(statePath.pathname, statePath.state);
      }, 3000);
    }

    return () => {
      cleanupFns.forEach((cleanup) => cleanup());
    };
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  useEffect(async () => {
    const av = new AvatarCreator(avatarImg);
    await av.fillColor(profile.color);

    setAvatarUrl(av.toDataURL());

    const avShadow = new AvatarCreator(avatarShadow);
    await avShadow.fillColor(profile.color);
    setAvatarShadowUrl(avShadow.toDataURL());
  }, [profile]);
  return (
    <div className="main-menu">
      <BackgroundRain key="rain" className="back-dark-gradient" numDrops={50} />
      <div className="platform">
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={async (e) => {
            // open cropper with this photo selected.
            const data = await loadImage(e.target.files[0], {
              maxWidth: 600,
              meta: true,
              canvas: true,
            });
            setUserUploadedRawPhoto(data.image.toDataURL());
            setCropperOpen(true);
          }}
        />
        <label htmlFor="fileInput">
          <div
            className={
              "avatar-photo " + (!profile || !profile.photo ? "no-photo" : "")
            }
            style={{
              backgroundImage: `url(${
                (profile && profile.photo) || "/camera-icon.png"
              })`,
              borderColor: profile.color,
              boxShadow: `0 0 70px 50px ${profile.color}44`,
            }}
          />
        </label>
        <input
          type="text"
          value={profile.name}
          onChange={(e) => {
            setProfile({ ...profile, name: e.target.value });
            if (e.target.value)
              updateProfile({ ...profile, name: e.target.value });
          }}
          style={{ borderColor: profile.color }}
          className="input-name"
          maxLength={10}
        />
        {/* <div className="full-avatar-container">
          <div
            className="player-shadow"
            style={{ backgroundImage: `url(${avatarShadowUrl})` }}
          ></div>
          <div
            className="avatar-container"
            style={{ backgroundImage: `url(${avatarUrl})` }}
          >
            <Spritesheet
              image={avatarEyes}
              style={{ marginTop: "-0.8rem" }}
              widthFrame={220}
              heightFrame={128}
              steps={6}
              fps={20}
              autoplay={true}
              getInstance={(spritesheet) => {
                blink(spritesheet);
              }}
            />
          </div>
        </div> */}
        <Modal open={cropperOpen} onClose={() => setCropperOpen(false)}>
          {cropperOpen ? (
            <AvatarCropper
              onChange={(canvas) => {
                // setDisplayPhoto(dataURI);
                // setCropperOpen(false);
                setCropperCurrentCanvas(canvas);
              }}
              src={userUploadedRawPhoto}
            />
          ) : (
            false
          )}
          <button
            className="button-black button-crop"
            onClick={() => {
              // const imgData = cropperCurrentCanvas.toDataURL();
              const photo = ResizeImage.resize(
                cropperCurrentCanvas,
                100,
                100,
                ResizeImage.JPEG
              );
              console.log(
                "diff",
                cropperCurrentCanvas.toDataURL().length,
                photo.length
              );
              setProfile({ ...profile, photo: photo });
              updateProfile({ ...profile, photo: photo });
              setCropperOpen(false);
            }}
          >
            Crop
          </button>
        </Modal>
        <div className="color-tray">
          {allAvatarColors.map((color) => {
            const isAvailable = availableColors.includes(color);
            return (
              <span
                key={color}
                className={"color" + (color === profile.color ? " active" : "")}
                style={{
                  backgroundColor: color,
                  opacity: isAvailable ? 1 : 0.5,
                }}
                onClick={
                  isAvailable
                    ? () => {
                        setProfile({ ...profile, color });
                        updateProfile({ ...profile, color });
                      }
                    : () => {}
                }
              />
            );
          })}
        </div>
        <button
          id="btn-launchjoin"
          className="button-black"
          onClick={() => {
            if (isHost) {
              // HOST
              multiplayer.createRoom();
              multiplayer.on("room_created", ({ id }) => {
                BrowserStorage.set("myRoom", id);
                // NativeMethods.rotateLandscape();
                const state = multiplayer.getMyPlayerState();
                state.setState("profile", profile);
                history.push("/waitscreen"); // global state subscriber ignores nav changes when within /profile
                multiplayer.navigate("/waitscreen");
              });
            } else {
              // join game
              // window.RotateScreen("landscape");
              const statePath = multiplayer.getState("path");
              history.push(statePath.pathname, statePath.state);
            }

            setUserProperties({ profile });
          }}
        >
          {isHost ? "Launch" : "Join Room"}
        </button>
      </div>
    </div>
  );
}

export default Profile;
