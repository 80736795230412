import React from "react";
import AvatarIcon from "common/components/AvatarIcon";
function SVG({ color, size }) {
  return (
    <svg
      width={size + "rem"}
      height={size + "rem"}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2115_153)">
        <path
          d="M32.3048 177.906C34.3601 171.809 42.6396 171.809 44.6948 177.906L49.3332 191.666L27.6665 191.666L32.3048 177.906Z"
          fill="black"
        />
        <path
          d="M130.995 177.906C133.208 171.809 142.124 171.809 144.338 177.906L149.333 191.666L126 191.666L130.995 177.906Z"
          fill="black"
        />
        <path
          d="M55.2815 177.906C57.1787 171.809 64.8213 171.809 66.7185 177.906L71 191.666L51 191.666L55.2815 177.906Z"
          fill="black"
        />
        <path
          d="M155.282 177.906C157.179 171.809 164.821 171.809 166.718 177.906L171 191.666L151 191.666L155.282 177.906Z"
          fill="black"
        />
        <path
          d="M48.2424 109.893C51.3914 101.018 63.9419 101.018 67.0909 109.893L94.3333 186.667L21 186.667L48.2424 109.893Z"
          fill={color}
        />
        <path
          d="M133.242 109.893C136.391 101.018 148.941 101.018 152.09 109.893L179.333 186.667L106 186.667L133.242 109.893Z"
          fill={color}
        />
        <g filter="url(#filter0_i_2115_153)">
          <path
            d="M167.667 65.5738C167.667 101.789 119.389 200 101.833 200C79.8889 200 36 101.789 36 65.5738C36 29.3584 65.4746 -2.57675e-06 101.833 -5.75533e-06C138.192 -8.93392e-06 167.667 29.3584 167.667 65.5738Z"
            fill="url(#paint0_linear_2115_153)"
          />
        </g>
        <circle
          cx="102.667"
          cy="64.9998"
          r="48.3333"
          fill="url(#paint1_radial_2115_153)"
        />
        <circle
          cx="102.666"
          cy="64.9998"
          r="30.3333"
          fill={color}
          stroke={color}
          stroke-width="4"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M49.3455 155.264C50.1353 155.776 50.3779 156.857 49.8873 157.68L48.6405 159.772L52.6665 159.864L48.1594 167.498C47.6722 168.323 46.6352 168.581 45.8433 168.073C45.0514 167.565 44.8043 166.485 45.2916 165.659L46.7216 163.237L42.6665 163.144L47.027 155.829C47.5176 155.006 48.5556 154.753 49.3455 155.264Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M152.679 155.264C153.469 155.776 153.711 156.857 153.221 157.68L151.974 159.772L156 159.864L151.493 167.498C151.006 168.323 149.969 168.581 149.177 168.073C148.385 167.565 148.138 166.485 148.625 165.659L150.055 163.237L146 163.144L150.361 155.829C150.851 155.006 151.889 154.753 152.679 155.264Z"
          fill="black"
        />
        <rect
          x="96.0005"
          y="136.667"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="97.9575"
          y="136.667"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="97.9575"
          y="138.625"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="97.958"
          y="142.54"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="99.9155"
          y="136.667"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="99.9155"
          y="140.582"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="99.9155"
          y="144.498"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="101.873"
          y="136.667"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="101.873"
          y="138.625"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="101.873"
          y="142.54"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="101.873"
          y="146.455"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="103.831"
          y="140.582"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="103.831"
          y="144.498"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="103.831"
          y="148.413"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="103.831"
          y="150.371"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="105.788"
          y="140.582"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="105.788"
          y="142.54"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="105.788"
          y="146.455"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="105.788"
          y="150.371"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="107.746"
          y="140.582"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="107.746"
          y="150.371"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="107.746"
          y="148.413"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="107.746"
          y="146.455"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="107.746"
          y="144.498"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="107.746"
          y="142.54"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="96.0005"
          y="138.625"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="96.0005"
          y="140.582"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="96.0005"
          y="142.54"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="96.0005"
          y="144.498"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="97.9575"
          y="146.455"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="99.9155"
          y="146.455"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="96.0005"
          y="146.455"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="96.0005"
          y="148.413"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="96.0005"
          y="150.371"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
        <rect
          x="96.0005"
          y="152.329"
          width="1.95767"
          height="1.95767"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2115_153"
          x="36"
          y="0"
          width="131.667"
          height="204"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2115_153"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2115_153"
          x1="-11.5"
          y1="-10"
          x2="156.011"
          y2="-14.5811"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.46875" stop-color="#E9E9E9" />
          <stop offset="0.703125" stop-color="white" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_2115_153"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(63.5002 4.99984) rotate(70.1231) scale(115.196)"
        >
          <stop stop-color="#8FF8FF" />
          <stop offset="1" stop-color="#61F6FF" stop-opacity="0" />
        </radialGradient>
        <clipPath id="clip0_2115_153">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default function RocketImage({ color, size, playerState }) {
  return (
    <>
      <AvatarIcon playerState={playerState} />
      <SVG color={color} size={size} />
    </>
  );
}
