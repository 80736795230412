import SuperEventEmitter from "../superEventEmitter";
import Firebase from "./firebase";
import NativeMethods from "../NativeMethods";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import BrowserStorage from "../localStorageWrapper";
import { setUserProperties } from "../db";
import { nanoid } from "nanoid";

class Auth extends SuperEventEmitter {
  constructor() {
    super();
    this.user = null;
    Firebase();
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      console.log("onAuthStateChanged", user);
      if (!user) {
        if (NativeMethods.isController()) await signInAnonymously(auth);
        else this.emit("user", null);
      } else {
        this.user = user;
        const platformInfo = await NativeMethods.getPlatformInfo();
        setUserProperties({
          shortid: getMyId(),
          platformInfo,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        this.emit("user", user);
      }
    });
  }

  async getUser() {
    if (this.user) return this.user;
    if (NativeMethods.isController()) {
      return new Promise((resolve) => {
        this.once("user", (user) => {
          resolve(user);
        });
      });
    }
    return null;
  }
}

export function getMyId() {
  const myId = BrowserStorage.get("myId") || nanoid(9);
  BrowserStorage.set("myId", myId);
  return myId;
}

const auth = new Auth();

export default function AuthSingleton() {
  return auth;
}
