import NativeMethods from "../../modules/NativeMethods";
import Multiplayer from "../../modules/multiplayer";
import BaseController from "./base";
import Nipple from "nipplejs";
// import KeyboardJS from 'keyboardjs'

// helpers
var s = function (sel) {
  return document.querySelector(sel);
};

export default class TouchJoystick extends BaseController {
  constructor(config) {
    super();

    config = config || {};
    this.config = config;
    this.currentKeysDown = {};
    this.buttons = config.buttons || 1;
    this.overlayVisible = true;
    this.className = config.className;
    this.labels = config.labels || {};

    this.joysticks = {
      dynamic: {
        zone: s(".touch-joystick-zone .dpad"),
        color: "white",
        size: 200,
        multitouch: true,
        // threshold: 0.5
      },
    };

    if (this.className) {
      s(".touch-joystick-zone").classList.add(this.className);
    }

    if (this.labels.dpad) {
      s(".touch-joystick-zone .dpad .circle").innerHTML = this.labels.dpad;
    }

    if (this.labels.b1) {
      s(".touch-joystick-zone .button1").innerHTML = this.labels.b1;
    }

    if (this.labels.b2) {
      s(".touch-joystick-zone .button2").innerHTML = this.labels.b2;
    }

    const multiplayer = Multiplayer();
    const unsub = multiplayer.getMyPlayerState().on("profile", (profile) => {
      if (profile.color) {
        s(".touch-joystick-zone .button1").style =
          "background-color: " + profile.color;
      }
    });

    this.unSubMultiplayer = unsub;
    // multiplayer.on('state', (state)=>{
    //   if (state.casting && state.casting.screens>0){
    //     this.overlayVisible = true;
    //     this.joysticks['dynamic'].zone.parentElement.classList.add("overlay-visible");
    //   }
    //   else{
    //     this.overlayVisible = false;
    //   }
    // });

    this.createNipple();
    this.createButtons();
  }

  createNipple() {
    if (global.__touchJoystick) {
      global.__touchJoystick.destroy();
    }

    this.joysticks["dynamic"].zone.parentElement.classList.add("active");
    this.joysticks["dynamic"].zone.parentElement.classList.add(
      "overlay-visible"
    );

    global.__touchJoystick = Nipple.create(this.joysticks["dynamic"]);
    this.bindNipple(global.__touchJoystick);
    // bindNipple(touchJoysticks2);
  }

  bindNipple(joystick) {
    joystick
      .on("start", (evt, data) => {
        // dump(evt.type);
        console.log("start");
        if (!this.overlayVisible) {
          this.joysticks["dynamic"].zone.parentElement.classList.remove(
            "overlay-visible"
          );
        }
        this.processDpadMovement({ x: 0, y: 0 }, { degree: "off" });
      })
      .on("end", (data) => {
        console.log("end");
        this.processDpadMovement({ x: 0, y: 0 }, { degree: "off" });
      })
      .on("move", (evt, data) => {
        // this.debug(data.vector);
        this.processDpadMovement(data.vector, data.angle);
      });
  }

  createButtons() {
    const parentDom = this.joysticks["dynamic"].zone.parentElement;
    if (this.buttons === 2) {
      parentDom.classList.add("two-buttons");
    }
    parentDom
      .querySelector(".button1")
      .addEventListener(
        "touchstart",
        this.handleButtonDown.bind(this, "button1")
      );
    parentDom
      .querySelector(".button1")
      .addEventListener("touchend", this.handleButtonUp.bind(this, "button1"));
    parentDom
      .querySelector(".button1")
      .addEventListener(
        "mousedown",
        this.handleButtonDown.bind(this, "button1")
      );
    parentDom
      .querySelector(".button1")
      .addEventListener("mouseup", this.handleButtonUp.bind(this, "button1"));

    parentDom
      .querySelector(".button2")
      .addEventListener(
        "touchstart",
        this.handleButtonDown.bind(this, "button2")
      );
    parentDom
      .querySelector(".button2")
      .addEventListener("touchend", this.handleButtonUp.bind(this, "button2"));
    parentDom
      .querySelector(".button2")
      .addEventListener(
        "mousedown",
        this.handleButtonDown.bind(this, "button2")
      );
    parentDom
      .querySelector(".button2")
      .addEventListener("mouseup", this.handleButtonUp.bind(this, "button2"));
  }

  handleButtonDown(buttonId, ev) {
    ev.preventDefault();
    NativeMethods.haptic("light");
    // console.log("buttonD", buttonId)
    if (buttonId === "button1") this.emit("keydown", "b1");
    if (buttonId === "button2") this.emit("keydown", "b2");
  }

  handleButtonUp(buttonId, ev) {
    ev.preventDefault();
    NativeMethods.haptic("light");
    // console.log("buttonUp", buttonId)
    if (buttonId === "button1") this.emit("keyup", "b1");
    if (buttonId === "button2") this.emit("keyup", "b2");
  }

  emit(key, value) {
    console.log(key, value);
    if (key === "dpad" && this.config.raw) {
      super.emit(key, value);
    } else if (
      value === "up" ||
      value === "down" ||
      value === "left" ||
      value === "right"
    ) {
      if (!this.config.raw) {
        super.emit(key, value);
      }
    } else {
      super.emit(key, value);
    }
  }

  emitRawDpadValues(vector, angle) {
    if (this.config.degree) {
      if (vector.x === 0 && vector.y === 0) {
        this.emit("dpad", "off");
      } else {
        this.emit("dpad", parseInt(angle.degree));
      }
    } else if (this.config.dpad) {
      const decimalPlaces = 1;
      const x = parseFloat(vector.x.toFixed(decimalPlaces));
      const y = parseFloat(vector.y.toFixed(decimalPlaces));
      this.emit("dpad", [x, y]);
    }
  }

  processDpadMovement(vector, angle) {
    const threshold = 0.1;
    this.emitRawDpadValues(vector, angle);
    // first "keyUp" any keys that are not active anymore
    Object.keys(this.currentKeysDown).forEach((key) => {
      if (key === "up" && vector.y < threshold) {
        delete this.currentKeysDown[key];
        NativeMethods.haptic("light");
        this.emit("keyup", "up");
      } else if (key === "down" && vector.y > threshold * -1) {
        delete this.currentKeysDown[key];
        NativeMethods.haptic("light");
        this.emit("keyup", "down");
      }

      if (key === "left" && vector.x > threshold * -1) {
        delete this.currentKeysDown[key];
        NativeMethods.haptic("light");
        this.emit("keyup", "left");
      } else if (key === "right" && vector.x < threshold) {
        delete this.currentKeysDown[key];
        NativeMethods.haptic("light");
        this.emit("keyup", "right");
      }
    });

    // now keyDown new keys
    if (vector.y >= threshold && !this.currentKeysDown["up"]) {
      this.currentKeysDown["up"] = true;
      NativeMethods.haptic("light");
      this.emit("keydown", "up");
    }

    if (vector.y <= threshold * -1 && !this.currentKeysDown["down"]) {
      this.currentKeysDown["down"] = true;
      NativeMethods.haptic("light");
      this.emit("keydown", "down");
    }

    if (vector.x >= threshold && !this.currentKeysDown["right"]) {
      this.currentKeysDown["right"] = true;
      NativeMethods.haptic("light");
      this.emit("keydown", "right");
    }

    if (vector.x <= threshold * -1 && !this.currentKeysDown["left"]) {
      this.currentKeysDown["left"] = true;
      NativeMethods.haptic("light");
      this.emit("keydown", "left");
    }
  }

  // Print data for debugging
  debug(obj) {
    return console.log("debug", obj);
  }

  // Dump data
  dump(evt) {
    return console.log("dump", evt);
  }

  destroy() {
    this.emit("destroy");
    this.removeAllListeners();
    if (this.unSubMultiplayer) this.unSubMultiplayer();
    if (global.__touchJoystick) {
      global.__touchJoystick.destroy();
    }

    if (this.className) {
      s(".touch-joystick-zone")?.classList.remove(this.className);
    }

    this.joysticks["dynamic"].zone.parentElement.classList.remove("active");
  }
}

export function toggleJoystickVisibility(visible) {
  if (visible)
    s(".touch-joystick-zone .dpad").parentElement.classList.add("active");
  else s(".touch-joystick-zone .dpad").parentElement.classList.remove("active");
}
