import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
// import BackgroundRain from "common/components/BackgroundRain";
import Multiplayer from "common/modules/multiplayer";
import BackgroundMusic from "common/modules/backgroundMusic";
import BackgroundNarrator from "common/modules/backgroundNarrator";
import GamesMenu from "common/components/GamesMenu";
import MuteButton from "common/components/MuteButton";
// import VoiceWelcome1 from "common/voice/welcome-1.mp3";
// import VoiceWelcome2 from "common/voice/welcome-2.mp3";
import VoiceWelcome3 from "common/voice/welcome-3.mp3";
import soundBgMusic from "common/sfx/theme.mp3";
import flagGif from "../../common/imgs/flag-color.gif";

import "./style.css";

export default function GamePage() {
  const history = useHistory();
  const BGMusic = BackgroundMusic();
  const BGNarrator = BackgroundNarrator();
  const multiplayer = Multiplayer();
  const isHost = multiplayer.isHost;
  useEffect(() => {
    if (!multiplayer.isSpectator) {
      // window.RotateScreen("landscape");
    } else {
      setTimeout(() => {
        BGMusic.setVolume(0.6, true);
        BGMusic.play(soundBgMusic);
        if (window.DEV) {
          if (window.parent && window.parent.onCastLoadingComplete) {
            window.parent.onCastLoadingComplete(multiplayer.currentRoom);
          }
          history.push("/waitscreen/wait");
          return;
        }

        setTimeout(() => {
          // BGNarrator.play(Math.random() > 0.5 ? VoiceWelcome1 : VoiceWelcome2);
          BGNarrator.play(VoiceWelcome3);
        }, 2000);
        if (multiplayer.isRenderServer()) {
          setTimeout(() => {
            if (window.parent && window.parent.onCastLoadingComplete) {
              window.parent.onCastLoadingComplete(multiplayer.currentRoom);
            }
            history.push("/waitscreen/wait");
          }, 10000);
        }
      }, 1000);
    }
    // eslint-disable-next-line
  }, []);

  if (isHost) {
    return (
      <GamesMenu
        key="gamesmenu"
        keepOpen
        hideMenuButton
        showCastingInstructions
        showWelcomePopup
      />
    );
  }
  return (
    <>
      <div
        className={
          "main-menu wait-screen-container " + (isHost ? " is-host" : "")
        }
      >
        {/* <BackgroundRain
          key="rain"
          className="waitscreen-gradient"
          numDrops={0}
        /> */}

        {multiplayer.isSpectator ? (
          <>
            <div className="splash-screen">
              <div className="logo-big" />
              <div className="msg-container">
                <img className="flag-gif" src={flagGif} alt="" />
                <p className="loading-message">Loading room</p>
              </div>
            </div>
            <MuteButton castMode />
          </>
        ) : (
          false
        )}
        {isHost && (
          <>
            <GamesMenu key="gamesmenu" showIfHost hideMenuButton />
            <div
              onClick={() => {
                multiplayer.leaveRoom();
              }}
              className="logo-small"
            />
            <div className="roomcode-container">
              <span>ROOM CODE</span>
              <span className="roomcode">
                {multiplayer.currentRoom.split("").map((ch) => (
                  <span>{ch}</span>
                ))}
              </span>
            </div>
            <div className="cast-instructions">
              <p className="instruction-title laptop">Join on Laptop or iPad</p>
              <p className="instruction-text highlighted">
                joinplayroom.com/R{multiplayer.currentRoom}
              </p>

              <p className="instruction-title airplay">
                Join via Airplay on Tv
              </p>
              <p className="instruction-text">
                <ol>
                  <li>
                    Connect your iPhone, iPad, or iPod touch to the same Wi-Fi
                    network as your Apple TV.
                  </li>
                  <li>
                    Swipe down from the upper-right corner of the screen to open
                    Control Center on this phone.
                  </li>
                  <li>
                    Tap Screen Mirroring{" "}
                    <span className="icon-screenmirror"></span>.
                  </li>
                  <li>
                    Select your Apple TV or any AirPlay compatible device.
                  </li>
                  <li>
                    If an AirPlay passcode appears on your TV screen, enter the
                    passcode on your iOS or iPadOS device.
                  </li>
                  <li>Return to this app to continue.</li>
                </ol>
              </p>
            </div>
          </>
        )}
        {!isHost && !multiplayer.isSpectator && (
          <p className="instruction-heading center">
            Waiting for host to cast screen...
          </p>
        )}
      </div>
    </>
  );
}
