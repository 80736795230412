import UIfx from "common/modules/uifx";

import voiceWait1Sound from "./sfx/voice/voice-wait-1.mp3";
import voiceWait2Sound from "./sfx/voice/voice-wait-2.mp3";
import voiceWait3Sound from "./sfx/voice/voice-wait-3.mp3";
import voiceWait4Sound from "./sfx/voice/voice-wait-4.mp3";
import voiceWait5Sound from "./sfx/voice/voice-wait-5.mp3";

import voicePoint1Sound from "./sfx/voice/voice-point-1.mp3";
import voicePoint2Sound from "./sfx/voice/voice-point-2.mp3";

export const sfxVoiceWait1 = UIfx(voiceWait1Sound);
export const sfxVoiceWait2 = UIfx(voiceWait2Sound);
export const sfxVoiceWait3 = UIfx(voiceWait3Sound);
export const sfxVoiceWait4 = UIfx(voiceWait4Sound);
export const sfxVoiceWait5 = UIfx(voiceWait5Sound);

export const sfxVoicePoint1 = UIfx(voicePoint1Sound);
export const sfxVoicePoint2 = UIfx(voicePoint2Sound);

export const voicesWait = [
  {
    file: voiceWait1Sound,
    sound: sfxVoiceWait1,
    duration: 3000,
  },
  {
    file: voiceWait2Sound,
    sound: sfxVoiceWait2,
    duration: 3000,
  },
  {
    file: voiceWait3Sound,
    sound: sfxVoiceWait3,
    duration: 4000,
  },
  {
    file: voiceWait4Sound,
    sound: sfxVoiceWait4,
    duration: 2000,
  },
  {
    file: voiceWait5Sound,
    sound: sfxVoiceWait5,
    duration: 4000,
  },
]

export const voicesPoint = [
    {
      file: voicePoint1Sound,
      sound: sfxVoicePoint1,
      duration: 2000,
    },
    {
      file: voicePoint2Sound,
      sound: sfxVoicePoint2,
      duration: 2000,
    },
]

