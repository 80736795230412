import React from "react";
import RocketImage from "./rocketImage";
export default function PlayerRocket({
  color,
  fuelPercent,
  hasWon,
  isDone,
  playerName,
  playerState,
}) {
  return (
    <div className="player-rocket-container">
      <div
        style={{ color: color }}
        className={"winner-container " + (hasWon ? "visible" : false)}
      >
        {playerName + " wins!"}
      </div>
      <div className={(isDone ? "launched" : "") + " rocket-ship"}>
        <RocketImage color={color} size={15} playerState={playerState} />
        <div className="flame left"></div>
        <div className="flame right"></div>
      </div>
      <div className="launch-pad-platform" style={isDone ? {} : {}}></div>
      <div className="launch-pad">
        <div
          className="launch-bar"
          role="progressbar"
          style={{ height: `${fuelPercent}%`, backgroundColor: color }}
        ></div>
      </div>
    </div>
  );
}
