import anime from "animejs/lib/anime.es.js";
import { useEffect, useImperativeHandle, useRef, forwardRef } from "react";

var lastRef = null;

//supported properties: https://animejs.com/documentation/#CSStransforms
function Animation(
  {
    children,
    animations = {},
    originX = "50%",
    originY = "50%",
    x = null,
    y = null,
    className = "",
    autoplay = null,
    autoplayDelay = 1,
    style = {},
  },
  ref
) {
  const animationRef = useRef(null);
  const elementRef = useRef(null);
  console.log(
    "rerender",
    lastRef === elementRef.current,
    lastRef,
    elementRef.current
  );
  window.anime = animationRef.current;
  lastRef = elementRef.current;
  useImperativeHandle(
    ref,
    () => ({
      play(animationName, options = { startDelay: 0, endDelay: 0 }) {
        console.log(
          "animation::play",
          animationName,
          lastRef === elementRef.current
        );
        const animationConfig = animations[animationName];
        if (!animationConfig) return;
        // console.log("rerender", lastRef===elementRef.current);
        if (animationRef.current) {
          animationRef.current.pause();
        }
        return new Promise((resolve, reject) => {
          animationRef.current = anime({
            targets: elementRef.current,
            ...animationConfig,
            delay: options.startDelay || animationConfig.delay || 0,
            endDelay: options.endDelay || animationConfig.endDelay || 0,
            complete: function (anim) {
              resolve();
            },
          });
        });
      },
      reset() {
        if (!animationRef.current) return;
        animationRef.current.seek(0);
      },
      seek(val) {
        if (!animationRef.current) return;
        animationRef.current.seek(val);
      },
    }),
    [animationRef, elementRef]
  );
  useEffect(() => {
    if (!elementRef.current) return;
    if (animationRef.current) {
      animationRef.current.pause();
    }

    if (autoplay) {
      const animConfig = animations[autoplay];
      setTimeout(() => {
        animationRef.current = anime({
          targets: elementRef.current,
          ...animConfig,
        });
      }, autoplayDelay || 1);
    }
  }, [elementRef, animations]);

  let finalStyle = {
    position: "absolute",
    transformOrigin: `${originX} ${originY}`,
  };
  if (x !== null || y !== null) {
    finalStyle = { ...finalStyle, top: `${y}px`, left: `${x}px` };
  }
  finalStyle = { ...finalStyle, ...style };

  return (
    <div
      key="base_element"
      className={className}
      ref={elementRef}
      style={finalStyle}
    >
      {children}
    </div>
  );
}

export default forwardRef(Animation);
