import NativeMethods from "../NativeMethods";
import Multiplayer from "../multiplayer";
import BaseController from "./base";

// helpers
var s = function (sel) {
  return document.querySelector(sel);
};

export default class TwoButtons extends BaseController {
  constructor(config) {
    super();

    config = config || {};
    this.config = config;
    this.isDestroyed = false;
    this.currentKeysDown = {};
    this.overlayVisible = true;
    this.className = config.className;
    this.labels = config.labels || {};
    this.parentDom = s(".touch-twobutton-zone");
    this.parentDom.classList.add("active");

    if (config.onebutton) {
      this.parentDom.classList.add("onebutton");
    } else {
      this.parentDom.classList.remove("onebutton");
    }

    if (config.overlay) {
      this.parentDom.classList.add("overlay-mode");
    }

    if (this.className) {
      if (Array.isArray(this.className)){
        for (let i=0; i<this.className.length; i++){
          s(".touch-twobutton-zone").classList.add(this.className[i]);          
        }
      }
      else {
        s(".touch-twobutton-zone").classList.add(this.className);
      }
    }

    if (this.labels.b1) {
      s(".touch-twobutton-zone .button1 .circle").innerHTML = this.labels.b1;
    }

    if (this.labels.b2) {
      s(".touch-twobutton-zone .button2").innerHTML = this.labels.b2;
    }

    const multiplayer = Multiplayer();
    const unsub = multiplayer.getMyPlayerState().on(
      "profile",
      (profile) => {
        if (profile?.color) {
          s(".touch-twobutton-zone .button1 .circle").style =
            "background-color: " + profile.color;
        }
      },
      true
    );

    this.unSubMultiplayer = unsub;
    this.createButtons();
  }

  createButtons() {
    const parentDom = this.parentDom;
    parentDom
      .querySelector(".button1")
      .addEventListener(
        "touchstart",
        this.handleButtonDown.bind(this, "button1")
      );
    parentDom
      .querySelector(".button1")
      .addEventListener("touchend", this.handleButtonUp.bind(this, "button1"));
    parentDom
      .querySelector(".button1")
      .addEventListener(
        "mousedown",
        this.handleButtonDown.bind(this, "button1")
      );
    parentDom
      .querySelector(".button1")
      .addEventListener("mouseup", this.handleButtonUp.bind(this, "button1"));

    parentDom
      .querySelector(".button2")
      .addEventListener(
        "touchstart",
        this.handleButtonDown.bind(this, "button2")
      );
    parentDom
      .querySelector(".button2")
      .addEventListener("touchend", this.handleButtonUp.bind(this, "button2"));
    parentDom
      .querySelector(".button2")
      .addEventListener(
        "mousedown",
        this.handleButtonDown.bind(this, "button2")
      );
    parentDom
      .querySelector(".button2")
      .addEventListener("mouseup", this.handleButtonUp.bind(this, "button2"));
  }

  handleButtonDown(buttonId, ev) {
    ev.preventDefault();
    if (this.isDestroyed) return;
    NativeMethods.haptic("light");
    if (buttonId === "button1") this.emit("keydown", "b1");
    if (buttonId === "button2") this.emit("keydown", "b2");
  }

  handleButtonUp(buttonId, ev) {
    ev.preventDefault();
    if (this.isDestroyed) return;
    NativeMethods.haptic("light");
    if (buttonId === "button1") this.emit("keyup", "b1");
    if (buttonId === "button2") this.emit("keyup", "b2");
  }

  destroy() {
    this.isDestroyed = true;
    this.emit("destroy");
    this.removeAllListeners();

    if (this.className) {
      s(".touch-twobutton-zone").classList.remove(this.className);
    }

    this.parentDom.classList.remove("active");
  }
}
