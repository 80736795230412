import AvatarIcon from "common/components/AvatarIcon";
import "./style.css";

export default function InGameLeaderboard({ playerStatesAndScores }) {
  return (
    <div className="ingame-player-scores">
      {playerStatesAndScores.map((playerStateAndScore) => (
        <div
          className="player-score-container"
          style={{
            backgroundColor:
              playerStateAndScore.state.getState("profile").color,
          }}
        >
          <AvatarIcon
            key={playerStateAndScore.state.id}
            playerState={playerStateAndScore.state}
          />
          <span>{playerStateAndScore.score}</span>
        </div>
      ))}
    </div>
  );
}
