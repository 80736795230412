import React, { useEffect, useRef } from "react";
import Phaser from "phaser";
import Multiplayer from "../../modules/multiplayer";
import GameConfig from "./config";

import BetaTag from "../../components/BetaTag";

const isServer = () => typeof window === "undefined";

const component = function GameRenderer({
  scenes,
  physics,
  fullScreen,
  controllerMode,
  fit,
  canvasStyle,
  backgroundColor,
}) {
  const multiplayer = Multiplayer();
  // TODO: Figure out why this crashes the game in multicast
  // const location = useLocation();
  // console.log("multiplayer.getState('path'):", multiplayer.getState('path'));
  const videoElement = useRef(null);
  const canvasElement = useRef(null);
  useEffect(() => {
    if (isServer()) return false;
    if (multiplayer.isRenderServer() && !canvasElement.current) return;
    var config = {
      type: Phaser.AUTO,
      width: GameConfig.width,
      height: GameConfig.height,
      scale: {
        mode: Phaser.Scale.ENVELOP,
      },
      parent: canvasElement.current,
      transparent: backgroundColor ? false : true,
      physics: physics,
      scene: scenes,
    };
    if (backgroundColor) {
      config.backgroundColor = backgroundColor;
    }

    if (fit) {
      config.scale.mode = Phaser.Scale.FIT;
    }

    if (fullScreen) {
      config.width = (window.innerWidth / window.innerHeight) * config.height;
      config.scale.mode = Phaser.Scale.FILL_WINDOW;
    }

    if (multiplayer.isRenderServer()) {
      var game = new Phaser.Game(config);
      window.game = game;
      const unsubFn = multiplayer.addRenderStream(game.canvas.captureStream());
      return function cleanup() {
        unsubFn();
        multiplayer.removeRenderStream(game.canvas.captureStream());
        game.destroy(true);
      };
    } else if (multiplayer.isSpectator) {
      return multiplayer.getMyPlayerState().on("stream", (stream) => {
        console.log("gotStream", stream);
        // setVideoStream(stream);
        const videoEl = videoElement.current;
        if (videoEl) {
          if ("srcObject" in videoEl) {
            videoEl.srcObject = stream;
          } else {
            videoEl.src = window.URL.createObjectURL(stream); // for older browsers
          }
        }
      });
    } else if (controllerMode) {
      var controllerGame = new Phaser.Game(config);
      window.game = game;
      return () => {
        controllerGame.destroy(true);
      };
    }
  }, [canvasElement]);
  console.log("rerendering game");
  if (multiplayer.isSpectator && !multiplayer.isRenderServer()) {
    return (
      <>
        <div
          className={`beta-tag-container ${
            multiplayer.getState("path").pathname === "/games/lobby"
              ? ""
              : "right"
          }`}
        >
          <BetaTag
            triggerAfter={4000}
            position={`${
              multiplayer.getState("path").pathname === "/games/lobby"
                ? "center"
                : "right"
            }`}
          />
        </div>

        <video
          style={{
            width: "100%",
            height: "100vh",
            objectFit: "contain",
          }}
          ref={videoElement}
          playsInline
          autoPlay
          muted
        ></video>
      </>
    );
  }
  return (
    <>
      <div
        key="game-canvas"
        id="canvas-container"
        ref={canvasElement}
        style={canvasStyle ? canvasStyle : {}}
      ></div>
    </>
  );
};
const areEqual = (prevProps, nextProps) => true;
const memoComponent = React.memo(component, areEqual);
export default memoComponent;
