import rockImg from "../img/rock.png";
import paperImg from "../img/paper.png";
import scissorsImg from "../img/scissors.png";

import sfxRockWin from "../sfx/rock-wins.mp3";
import sfxPaperWin from "../sfx/paper-wins.mp3";
import sfxScissorsWin from "../sfx/scissors-wins.mp3";

const CardData = [
  {
    id: "rock",
    // title: 'rock',
    // image: rockImg,
    backgroundImage: `url("${rockImg}")`,
    // backgroundColor: "#1B612A",
    cardStyles: {
      border: "none",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
    //   backgroundImage: `url(${pikachuImg})`,
    //   text: ``,
    chosen: false,
    winSound: sfxRockWin,
  },
  {
    id: "paper",
    // title: 'paper',
    // image: paperImg,
    backgroundImage: `url("${paperImg}")`,
    // backgroundColor: "#D1189D",
    cardStyles: {
      border: "none",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
    //   backgroundImage: `url(${charizardImg})`,
    //   text: ``,
    chosen: false,
    winSound: sfxPaperWin,
  },
  {
    id: "scissors",
    // title: 'scissors',
    // image: scissorsImg,
    backgroundImage: `url("${scissorsImg}")`,
    // backgroundColor: "#FFA319",
    cardStyles: {
      border: "none",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
    //   backgroundImage: `url(${blastoiseImg})`,
    //   text: ``,
    chosen: false,
    winSound: sfxScissorsWin,
  },
];

export const VersusCardsData = [
  {
    challengers: ["rock", "scissors"],
    winner: "rock",
  },
  {
    challengers: ["paper", "scissors"],
    winner: "scissors",
  },
  {
    challengers: ["paper", "rock"],
    winner: "paper",
  },

  // -1 indicates that there are only two players, and -1 is the player whose turn got missed
  {
    challengers: ["rock", -1],
    winner: "rock",
  },
  {
    challengers: ["paper", -1],
    winner: "paper",
  },
  {
    challengers: ["scissors", -1],
    winner: "scissors",
  },
];

export default CardData;
