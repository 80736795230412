import { useGlobalRoundState, useGlobalState } from "../../hooks/multiplayer";
import Multiplayer from "../../modules/multiplayer";
// import GamesMenu from "../../components/GamesMenu";
import TouchJoystick from "../../modules/controllers/touch";
import TwoButtonJoystick from "../../modules/controllers/twobuttons";
import GyroController from "../../modules/controllers/gyro";
import { useStateIsSetForAllPlayers } from "../../hooks/multiplayer";
import { useState } from "react";
import gameDimensionConfig from "../../components/GameRenderer/config";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import PhaserRenderer from "../GameRenderer";
import Timer from "../Timer";
import "./style.scss";
import Toast from "../Toast";
import StartButtonWithGyroPermissions from "../StartButtonWithGyroPermissions";
import AvatarIconStrip from "../AvatarIconStrip";

// auto scales the children to fit the screen. Also renders the controller if this is a controller screen.
export default function SceneRenderer({
  gameConfig,
  sceneConfig,
  children,
  portrait,
}) {
  const winner = useGlobalState("winner");
  const windowSize = useWindowDimensions();
  const [renderPhaser, setRenderPhaser] = useState(true);
  const allPlayersHaveGivenGyroPermission = useStateIsSetForAllPlayers(
    "gyroPermissionGiven"
  );
  const timer = useGlobalState("timer");
  const timerRound = useGlobalRoundState("timer");
  const [gyroPermissionGiven, setGyroPermissionGiven] = useState(false);
  var scale = Math.min(
    windowSize.width / gameDimensionConfig.baseWidth,
    windowSize.height / gameDimensionConfig.baseHeight
  );

  if (portrait) {
    scale = Math.min(
      windowSize.height / gameDimensionConfig.baseWidth,
      windowSize.width / gameDimensionConfig.baseHeight
    );
  }
  const multiplayer = Multiplayer();
  let containerStyle = {};
  if (sceneConfig?.background?.color) {
    containerStyle.backgroundColor = sceneConfig.background.color;
  }
  if (sceneConfig?.background?.style) {
    containerStyle = sceneConfig?.background?.style;
  }

  let controllerContainerStyle =
    sceneConfig?.controller?.background?.style || {};
  if (sceneConfig?.controller?.background?.color) {
    controllerContainerStyle.backgroundColor =
      sceneConfig?.controller.background.color;
  }

  let avatarComponent;
  if (sceneConfig?.avatars) {
    const avatarConfig = sceneConfig?.avatars;
    if (avatarConfig.type === "strip") {
      avatarComponent = (
        <AvatarIconStrip
          showNames={avatarConfig.showNames}
          mobileMode={!children}
        />
      );
    }
  }

  useState(() => {
    window.refreshPhaser = () => {
      setRenderPhaser(false);
      setTimeout(() => {
        setRenderPhaser(true);
      }, 300);
    };
    var controller = null;
    if (!multiplayer.isSpectator && sceneConfig && sceneConfig.controller) {
      if (sceneConfig.controller.type === "joystick") {
        controller = new TouchJoystick(sceneConfig.controller.config);
      }
      if (sceneConfig.controller.type === "gyro") {
        // controller = new GyroController(sceneConfig.controller.config);
      }
      if (sceneConfig.controller.type === "onebutton") {
        controller = new TwoButtonJoystick({
          onebutton: true,
          ...sceneConfig.controller.config,
        });
      }
      if (controller) {
        multiplayer.attachController(controller);
      }
    }
    console.log("useState CALLED");
    return () => {
      console.log("Detaching controller?? CALLED", multiplayer.isSpectator);
      if (!multiplayer.isSpectator) {
        const controller = multiplayer.detachController();
        console.log("Detaching controller CALLED");
        if (controller) controller.destroy();
      }
    };
  }, [sceneConfig]);

  let GamesMenu = null;

  if (!gameConfig.external) {
    GamesMenu = require("../../components/GamesMenu").default;
  }

  let gyroPermissionUI = null;
  if (sceneConfig?.controller?.type === "gyro") {
    if (multiplayer.isSpectator && !allPlayersHaveGivenGyroPermission) {
      gyroPermissionUI = (
        <div className="scene-renderer">
          <Toast visible center>
            Waiting for all players to give permission to use device sensors.
          </Toast>
        </div>
      );
    } else if (!multiplayer.isSpectator && !gyroPermissionGiven) {
      gyroPermissionUI = (
        <div className="scene-renderer">
          <Toast visible center>
            <StartButtonWithGyroPermissions
              onPermissionGranted={() => {
                setGyroPermissionGiven(true);
                multiplayer
                  .getMyPlayerState()
                  .setState("gyroPermissionGiven", true);
                const controller = new GyroController(
                  sceneConfig.controller.config
                );
                multiplayer.attachController(controller);
              }}
            />
          </Toast>
        </div>
      );
    } else if (!multiplayer.isSpectator && !allPlayersHaveGivenGyroPermission) {
      gyroPermissionUI = (
        <div className="scene-renderer">
          <Toast visible center>
            Waiting for others players to give permission to use device sensors.
          </Toast>
        </div>
      );
    }
  }

  let PhaserScene = null;
  if (multiplayer.isSpectator || !children) {
    // tv/laptop screen
    if (sceneConfig.phaser && renderPhaser) {
      PhaserScene = (
        <PhaserRenderer
          key="game"
          fit
          // backgroundColor="#ff00ff"
          gameInfo={gameConfig}
          scenes={[sceneConfig.phaser.scene]}
          physics={sceneConfig.phaser.physics}
        />
      );
    }
    return (
      <>
        {avatarComponent}
        {gyroPermissionUI ? (
          gyroPermissionUI
        ) : (
          <div style={containerStyle} className="scene-renderer">
            {(timer || timerRound) !== undefined && (
              <div
                style={{
                  top: "1rem",
                  left: "1rem",
                  ...sceneConfig?.timer?.style,
                }}
                className="timer-container"
              >
                <Timer
                  height="6rem"
                  width="6rem"
                  color={sceneConfig?.timer?.color || "#00000090"}
                  timer={timer || timerRound}
                />
              </div>
            )}
            {GamesMenu && (
              <GamesMenu
                key="gamesmenu"
                gameInfo={gameConfig}
                hideRoomCode
                showRestart={winner && multiplayer.isHost}
              />
            )}
            {sceneConfig.phaser && !sceneConfig.phaser.onTop
              ? PhaserScene
              : false}
            <div
              className="scene-renderer-content"
              style={
                portrait //inverse the height/width for portrait
                  ? {
                      height: gameDimensionConfig.baseWidth,
                      width: gameDimensionConfig.baseHeight,
                      transform: `translate(-50%, -50%) scale(${scale})`,
                    }
                  : {
                      width: gameDimensionConfig.baseWidth,
                      height: gameDimensionConfig.baseHeight,
                      transform: `translate(-50%, -50%) scale(${scale})`,
                    }
              }
            >
              {children ? children : sceneConfig.controller?.component()}
            </div>
            {sceneConfig.phaser && sceneConfig.phaser.onTop
              ? PhaserScene
              : false}
          </div>
        )}
      </>
    );
  } else {
    // player controller screen
    return (
      <>
        {gyroPermissionUI}
        <div
          style={
            gyroPermissionUI ? { display: "none" } : controllerContainerStyle
          }
          className="scene-renderer-controller"
        >
          {GamesMenu && (
            <GamesMenu
              key="gamesmenu"
              gameInfo={gameConfig}
              hideRoomCode
              showRestart={winner && multiplayer.isHost}
            />
          )}
          {sceneConfig.controller?.component()}
        </div>
      </>
    );
  }
}
