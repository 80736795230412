import { useEffect, useState } from "react";
import { useGlobalState } from "common/hooks/multiplayer";
import BackgroundGame from "common/components/BackgroundGame";
import Player from "./player";

import calculatePlayerSizeAndPosition from "common/utils/scalePlayers";
import { useMeasure } from "react-use";

import Timer from "common/components/Timer";
import CircularProgress from "common/components/CircularProgress";

const ROUND_TIME = 30

const Viewer = ({ players, multiplayer, gameIsLive, showText=true }) => {
  const [count, setCount] = useState(ROUND_TIME);
  const hasWon = multiplayer.getRoundState("winner");


  // ---------------- START SCALABLE ROW ---------------- 
  // we just need the width of the first player
  const [ref, { width }] = useMeasure();

  // We can use playerScale to scale down each player.
  // We don't need X, it will be automatically calculated with justifyContent.
  const [playerSizeAndPosition, setPlayerSizeAndPosition] = useState(calculatePlayerSizeAndPosition(
    players.length, 
    0, 
    window.innerWidth,
    width,
  ))

  useEffect(() => {
    setPlayerSizeAndPosition(calculatePlayerSizeAndPosition(
      players.length, 
      0, 
      window.innerWidth,
      width,
      50, 
      200,
    ))
  }, [width]);
  // ---------------- END SCALABLE ROW ---------------- 
  const timer = useGlobalState("timer");

  useEffect(() => {


    // Set timer to undefined if game is not live.
    // This will keep the Circular Progress hidden, until it's needed.
    if (!gameIsLive) {
      multiplayer.setState("timer", undefined);
      return;
    }



    const setTimer = (_timer) => {
      multiplayer.setState("timer", _timer);
    };

    var intervalId;
    if (multiplayer.isSpectator && gameIsLive) {
      // try-to: clear previous timer
      if (window.timerIntervalId) {
        window.clearInterval(window.timerIntervalId);
        clearInterval(window.timerIntervalId);
      }

      // Initialize a new timer on each round
      intervalId = Timer({
        isNonDisplay: true,
        setTimer: setTimer,
        durationInMs: ROUND_TIME * 1000,
      });
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
      if (window.timerIntervalId) {
        window.clearInterval(window.timerIntervalId);
        clearInterval(window.timerIntervalId);
      }
    };
  }, [gameIsLive]);

  useEffect(() => {
    let interval;
    if (gameIsLive) {
      interval = setInterval(() => {
        if (gameIsLive) {
          setCount((count) => {
            if (count === 0) {
              clearInterval(interval);
              multiplayer.setRoundState("done", true);
              return count;
            } else {
              return count - 1;
            }
          });
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [gameIsLive]);

  return (
    <div className="viewer-container">
      <BackgroundGame
        style={{
          background: `linear-gradient(180deg, ${
            hasWon && hasWon.color ? hasWon.color : "#D5FAFF"
          } 0%, #FED8FF 100%)`,
        }}
      ></BackgroundGame>

        
    {timer !== undefined && (
        <div style={{
          position: "fixed",
          top: "3rem",
          left: "2rem",
        }}>
        <CircularProgress 
          height={60} 
          width={60} 
          color={"#2ECE5B"} 
          timer={timer} 
        />
        </div>
      )}

      {!hasWon && showText && (
        <div className="rocket-countdown-container banana-text" style={{
          opacity: 1
        }}>
          {count > 25 ? (
            <>
            <span style={{
                fontSize: "7.5rem", lineHeight: "auto", display: "block",
              }}>
              Check your phone!
            </span>
              <span style={{
                marginTop: "0.8rem", fontSize: "4.5rem", lineHeight: "auto", display: "block",
              }}>
                Catch as many beans as you can
              </span>
              </>
          ) : (
            count
          )}
        </div>
      )}
      {hasWon && (
        <div className="winner-container">{hasWon && hasWon.name} wins!</div>
      )}
      {players.map((playerState, i) => {
        const profile = playerState.getState("profile");
        return (
          <Player
            containerRef={i===0 ? ref : undefined}
            containerStyles={{
              transform: playerSizeAndPosition?.playerScale ? `scale(${playerSizeAndPosition.playerScale})` : undefined,
              margin: playerSizeAndPosition?.playerScale ? `0rem ${playerSizeAndPosition.playerScale * 2}rem` : "0rem 4rem",
            }}
            state={playerState}
            name={profile.name}
            score={playerState.getState("score") || 0}
            color={profile?.color || "red"}
            key={i}
            
          />
        );
      })}
    </div>
  );
};

export default Viewer;