import React, { useEffect, useState } from "react";
import { usePlayersList, useGlobalState } from "common/hooks/multiplayer";
import Multiplayer from "common/modules/multiplayer";
import AvatarIcon from "common/components/AvatarIcon";
import Crown from "./crown.png";
import "./style.scss";

export default function Leaderboard({
  showScore,
  slideIn,
  showPing,
  showHost,
  _multiplayer,
}) {
  // for tracking the previous win
  // const leaderboardHistory = useState(null);
  const multiplayer = _multiplayer || Multiplayer();

  // TODO: This doesn't work. Find a way to check who's the host
  // when the current view isSpectator
  const [isHost, setIsHost] = useState(multiplayer.isHost);

  useEffect(() => {
    setIsHost(multiplayer.isHost);
  }, [multiplayer.isHost]);

  useEffect(() => {
    const fnOnHostUpdated = multiplayer.on("host_updated", (isHost) => {
      console.log("host_updated", isHost);
      setIsHost(isHost);
    });

    return () => {
      fnOnHostUpdated();
    };
  });

  const players = usePlayersList(true);
  const winslog = useGlobalState("winslog");
  const leaderboard = calculateScores(winslog);
  let highestScore = 1;
  Object.keys(leaderboard).forEach((playerId) => {
    if (leaderboard[playerId] > highestScore) {
      highestScore = leaderboard[playerId];
    }
  });

  // useEffect (() => {
  //   console.log("winslog in useEffect:", winslog);
  // }, [winslog]);

  return (
    <LeaderboardPlayers
      players={players}
      winslog={winslog}
      slideIn={slideIn}
      leaderboard={leaderboard}
      highestScore={highestScore}
      showScore={showScore}
      showPing={showPing}
      isHost={isHost}
      showHost={showHost}
    />
  );
}

export const LeaderboardPlayers = ({
  players,
  winslog,
  slideIn,
  leaderboard,
  highestScore,
  showScore,
  showPing,
  isHost,
  showHost,
}) => {
  console.log("players:", players);

  const [animate, setAnimate] = useState(false);

  // find the logs in winlog where timestamps are equivalent to the last's
  const [prevGameLogs, setPrevGameLogs] = useState([]);
  const [prevGameWinners, setPrevGameWinners] = useState([]);

  useEffect(() => {
    if (winslog && winslog.length) {
      setPrevGameLogs(
        winslog.filter((log) => log[2] === winslog[winslog.length - 1][2])
      );
    }
  }, [winslog]);

  useEffect(() => {
    prevGameLogs &&
      prevGameLogs.length &&
      console.log(
        "(prevGameLogs[prevGameLogs.length-1][2] - Date.now()): ",
        prevGameLogs[prevGameLogs.length - 1][2] - Date.now()
      );
    if (
      prevGameLogs &&
      prevGameLogs.length &&
      // only show winners if the game is recent
      Date.now() - prevGameLogs[prevGameLogs.length - 1][2] < 15000
    ) {
      setPrevGameWinners(prevGameLogs.map((log) => log[1]));
    }
  }, [prevGameLogs]);

  console.log("prevGameLogs:", prevGameLogs);
  console.log("prevGameWinners:", prevGameWinners);

  useEffect(() => {
    if (showScore) {
      setTimeout(() => {
        setAnimate(true);
      }, 3600);
    }
  }, [showScore]);

  return (
    <div className={"leaderboard" + (slideIn ? " slide-in" : "")}>
      <div className="logo-small"></div>
      {players.map((playerState, idx) => {
        const profile = playerState.getState("profile") || {};

        // console.log("profile:", profile)

        const ping = playerState.getState("p") || 0;
        const score = leaderboard[playerState.id]
          ? leaderboard[playerState.id]
          : 0;
        // console.log("playerState.id:", playerState.id)

        const isWinnerFromPrevGame =
          winslog &&
          winslog.length > 0 &&
          prevGameWinners.includes(playerState.id);
        // || (
        //   typeof winslog === "object" &&
        //     winslog[winslog.length - 1][1].includes(playerState.id)
        // )
        // console.log ("isWinnerFromPrevGame, playerState.id:", isWinnerFromPrevGame, playerState.id)

        if (!profile.color || !profile.name) return false;
        return (
          <div
            className={"leaderboard-player" + (slideIn ? " slide-in" : "")}
            key={playerState.id}
          >
            {isWinnerFromPrevGame ? (
              <div
                className="winner-background-gradient"
                style={{
                  position: "absolute",
                  top: "-1rem",
                  left: "-10rem",
                  background:
                    "linear-gradient(95.59deg, #F8BD4B -93.04%, #F8BD4B -23.42%, #FEC0FF 14.77%, #F647DA 29.84%, #F6C544 62.83%, #F6C544 74.52%, #F6C544 100%), #F6C544",
                  backgroundSize: "200% 200%",
                  border: "3px solid #FF7F56",
                  boxShadow: "0px 3px 0px #6046FF",
                  borderRadius: "55.0547px",
                  width: "calc(100% + 10rem)",
                  height: "calc(100% + 2rem)",
                  zIndex: -1,
                  // gradient-change-1 2s forwards ease-in-out,
                  animation: `gradient-shift 3.2s forwards, 
            0.9s gradient-change-1 3.2s forwards ease-in-out`,

                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  textAlign: "right",
                  padding: "1rem 2rem",

                  // color: "#F6C544",
                }}
              >
                <span
                  className="point-text"
                  style={{
                    animation: `0.4s fadeInText 1.0s forwards ease-in-out,
              0.2s fadeInText 3s reverse forwards
              `,
                  }}
                >
                  +1
                </span>
              </div>
            ) : (
              <></>
            )}

            <AvatarIcon
              playerState={playerState}
              // add a bouncing animation if the player won in the previous round!
              // TODO: Change from inline into somethin else
              style={{
                animation:
                  isWinnerFromPrevGame && animate
                    ? "bounce-small 0.9s ease-in-out"
                    : "",
                animationDelay: "0.2s",
              }}
            />
            {showScore && (
              <>
                {score >= highestScore && animate ? (
                  <img src={Crown} alt="winner" className="player-crown" />
                ) : (
                  <></>
                )}

                {animate && (
                  <div
                    className="player-score"
                    // add a bouncing animation if the player won in the previous round!
                    // TODO: Change from inline into somethin else
                    style={{
                      animation:
                        isWinnerFromPrevGame && animate
                          ? "bounce-big 0.8s ease-in-out"
                          : "",
                      animationDelay: "0s",
                    }}
                  >
                    {score}
                  </div>
                )}
              </>
            )}
            <div
              className="player-name"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {profile.name}
                {isHost && showHost ? <span>HOST</span> : <></>}
              </span>
              {showPing && (
                <span style={{ marginLeft: "0.5rem", fontSize: "1rem" }}>
                  {" "}
                  ({ping}ms)
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

function calculateScores(winslogs) {
  const scores = {};
  // console.log("calculateScores winslogs:", winslogs);
  if (winslogs) {
    winslogs.forEach((log) => {
      scores[log[1]] = (scores[log[1]] || 0) + 1;
    });
  }
  return scores;
}
