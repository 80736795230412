import React, { useEffect, useState } from "react";
import CardGame, { Card } from "common/components/CardGame";

import {
  usePlayersList,
  useGlobalState,
  useGlobalRoundState,
  usePlayerState,
  useOtherPlayerState,
  useMyPlayerState,
} from "common/hooks/multiplayer";

function randRange(minNum, maxNum) {
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}

const PlayerGame = ({ myState, multiplayer }) => {
  const live = useGlobalState("live");
  const winner = useGlobalState("winner");
  return (
    // <p>Hello playergame</p>
    <></>
  );
};

export default PlayerGame;
