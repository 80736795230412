export default function setIntervalX(callback, delay, repetitions, onAllFinish) {
    var x = 0;
    var _isArray = callback instanceof Array;
    var intervalId = window.setInterval(() => {
      if (x === repetitions) {
        if (onAllFinish) onAllFinish(x, intervalId);
        window.clearInterval(intervalId);
        clearInterval(intervalId);
        return;
      }
  
      if (_isArray) callback[x](x, intervalId);
      else callback(x, intervalId);
  
      x++;
    }, delay);
    return intervalId;
  }