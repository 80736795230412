import { useEffect, useState } from "react";
import BackgroundGame from "common/components/BackgroundGame";
import Player from "../components/player";

const Viewer = ({ players, multiplayer, gameIsLive }) => {
  const [count, setCount] = useState(30);
  const hasWon = multiplayer.getState("winner");

  useEffect(() => {
    let interval;
    if (gameIsLive) {
      interval = setInterval(() => {
        if (gameIsLive) {
          setCount((count) => {
            if (count === 0) {
              clearInterval(interval);
              multiplayer.setState("done", true);
              return count;
            } else {
              return count - 1;
            }
          });
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [gameIsLive]);

  return (
    <div className="viewer-container">
      <BackgroundGame
        style={{
          background: `linear-gradient(180deg, ${
            hasWon && hasWon.color ? hasWon.color : "#D5FAFF"
          } 0%, #FED8FF 100%)`,
        }}
      ></BackgroundGame>
      {!hasWon && (
        <div className="counter-container">
          {count > 25 ? (
            <>
              Collect money
              <br />
              on your phone
            </>
          ) : (
            count
          )}
        </div>
      )}
      {hasWon && (
        <div className="winner-container">{hasWon && hasWon.name} wins!</div>
      )}
      {players.map((playerState, i) => {
        const profile = playerState.getState("profile");
        return (
          <Player
            state={playerState}
            name={profile.name}
            score={playerState.getState("score") || 0}
            color={profile?.color || "red"}
            key={i}
          />
        );
      })}
    </div>
  );
};

export default Viewer;
