import React from "react";
import ReactDOM from "react-dom";
import "common/css/bootstrap-grid.css";
import "./index.css";
import App from "./App";

if (!("process" in window)) {
  // @ts-ignore
  window.process = {
    nextTick: (cb, arg1, arg2) => setTimeout(() => cb(arg1, arg2), 0),
  };
}

ReactDOM.render(<App />, document.getElementById("root"));
