
// https://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript
export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const setIntervalX = (callback, delay, repetitions, onAllFinish) => {
  var x = 0;
  var _isArray = callback instanceof Array;
  var intervalId = window.setInterval(() => {
    if (x === repetitions) {
      if (onAllFinish) onAllFinish();
      window.clearInterval(intervalId);
      return;
    }

    if (_isArray) callback[x](x, intervalId);
    else callback(x, intervalId);

    x++;
  }, delay);
  return intervalId;
}

export const makeString = (arr) => {
  if (arr.length === 1) return arr[0];
  const firsts = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];
  return firsts.join(", ") + " and " + last;
};