import BackgroundGame from "common/components/BackgroundGame";
import AvatarCreator from "common/modules/avatarCreator";
import Multiplayer from "common/modules/multiplayer";
import { useEffect, useState } from "react";
import { useGlobalRoundState, useGlobalState } from "common/hooks/multiplayer";

import Timer from "common/components/Timer";

import "./style.css";

export default function ControllerHUD({
  showRoomCode,
  breadcrumbs,
  avatarText,
  avatar,
  avatarUrl,
  avatarStyle,
  backgroundStyle,
  hudStyle,
  timerProps,
  className,
  children,
}) {
  const [avatarDataUrl, setAvatarDataUrl] = useState(null);
  const multiplayer = Multiplayer();
  const timer = useGlobalState("timer");
  const timerRound = useGlobalRoundState("timer");

  useEffect(() => {
    if (avatarUrl) {
      const myState = multiplayer.getMyPlayerState();
      const profile = myState.getState("profile");
      const av = new AvatarCreator(avatarUrl);
      av.fillColor(profile.color).then(() => {
        setAvatarDataUrl(av.toDataURL());
      });
    }
    // eslint-disable-next-line
  }, [avatarUrl]);

  return (
    <>
      <BackgroundGame style={backgroundStyle}></BackgroundGame>
      <div
        style={hudStyle}
        className={
          "controller-hud-container" + (className ? ` ${className}` : "")
        }
      >
        <div className="breadcrumbs">
          {breadcrumbs.map((crumb, i) => {
            return typeof crumb === "string" ? (
              <span key={i}>{crumb}</span>
            ) : (
              crumb
            );
          })}
          <Timer
            circularProgressStyles={{
              zIndex: 99999,
              // backgroundColor: "red",
              // position: "fixed",
            }}
            height={20}
            width={20}
            color={timerProps?.color || "#00000090"}
            timer={timer || timerRound}
          />

          {showRoomCode && (
            <span className="roomcode">
              {multiplayer.currentRoom.split("").map((ch) => (
                <span>{ch}</span>
              ))}
            </span>
          )}
        </div>
        <div style={avatarStyle} className="hud-avatar">
          {avatarDataUrl && (
            <div
              className="avatar"
              style={{ backgroundImage: `url(${avatarDataUrl})` }}
            ></div>
          )}
          {avatarText !== undefined && (
            <div className="avatar-text">{avatarText}</div>
          )}
          {avatar}
        </div>
        <div className="controller-hud-center">{children}</div>
      </div>
    </>
  );
}
