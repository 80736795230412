import Multiplayer from "../modules/multiplayer";
import UIfx from "./uifx";

export class BackgroundMusic {
  constructor(defaultFadeDuration) {
    this.currentAudio = null;
    this.currentAudioFile = null;

    this.fadeDuration =
      defaultFadeDuration === undefined ? 1000 : defaultFadeDuration;
    this.volumeOverride = 1;
    this.multiplayer = Multiplayer();
    this.multiplayer.on("state", (state, key) => {
      if (key === "muted") {
        if (this.currentAudio) {
          this.currentAudio.setMute(state[key]);
        }
      }
    });
  }

  async play(
    audio,
    fadeDuration = this.fadeDuration,
    crossFade = false,
    loop = true,
    onComplete
  ) {
    var newAudio = new UIfx(audio, { loop });
    var isPlayingNewAudio = false;
    const muteState = this.multiplayer.getState("muted");
    if (this.currentAudio) {
      if (crossFade) {
        newAudio.fadeInAndPlay(fadeDuration, this.volumeOverride, onComplete);
        newAudio.setMute(muteState);
        isPlayingNewAudio = true;
      }
      await this.currentAudio.fadeOutAndStop(fadeDuration);
    }
    this.currentAudio = newAudio;
    this.currentAudioFile = audio;
    if (!isPlayingNewAudio) {
      this.currentAudio.setMute(muteState);
      await this.currentAudio.fadeInAndPlay(
        fadeDuration,
        this.volumeOverride,
        onComplete
      );
    }
  }

  async stop(fadeDuration = this.fadeDuration) {
    if (this.currentAudio) {
      await this.currentAudio.fadeOutAndStop(fadeDuration);
      this.currentAudio = null;
      this.currentAudioFile = null;
    }
  }

  setVolume(volume, override) {
    if (override) this.volumeOverride = volume;
    if (this.currentAudio) {
      this.currentAudio.setVolume(volume);
    }
  }

  getVolume() {
    return this.currentAudio ? this.currentAudio.volume : 0;
  }

  getCurrentlyPlaying() {
    return this.currentAudioFile ? this.currentAudioFile : null;
  }
}

export default function createSingleton(channel = "default") {
  if (!window._bgmusic) window._bgmusic = {};
  if (!window._bgmusic[channel]) {
    window._bgmusic[channel] = new BackgroundMusic();
  }

  return window._bgmusic[channel];
}
