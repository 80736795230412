import { Portal } from "react-portal";
import React from "react";
import "./style.css";
export default function Modal({
  open,
  onClose,
  className,
  children,
  fullScreen,
  noOverlay,
}) {
  return (
    <Portal>
      <div
        className={
          "modal-container" +
          (fullScreen ? " fullscreen" : "") +
          " " +
          (className ? className : "")
        }
      >
        <div data-pop="slide-up" id="popup" className={open ? "show" : ""}>
          <div className="popupcontrols">
            {onClose ? (
              <span onClick={onClose} id="popupclose">
                &times;
              </span>
            ) : (
              false
            )}
          </div>
          <div className="popupcontent">{children}</div>
        </div>

        {!noOverlay ? (
          <div
            onClick={onClose}
            id="overlay"
            className={open ? "show" : ""}
          ></div>
        ) : (
          false
        )}
      </div>
    </Portal>
  );
}
