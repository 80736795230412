import Grid from "./grid";
import { useEffect, useState } from "react";
import AvatarIcon from "common/components/AvatarIcon";
import { useGlobalState, usePlayersList } from "common/hooks/multiplayer";
import Multiplayer from "common/modules/multiplayer";
import InGameLeaderboard from "common/components/InGameLeaderboard";

const GRID_CELL_SIZE = 100;
const PLAYER_AVATAR_SIZE = 70;

const grid = new Grid({ width: 9, height: 5 });
function useForceUpdate() {
  // eslint-disable-next-line
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

function Viewer({ newGameRequested }) {
  const multiplayer = Multiplayer();
  const [showColors, setShowColors] = useState(false);
  const [gridRect, setGridRect] = useState({
    width: 0,
    height: 0,
    left: 0,
    top: 0,
  });
  const cells = useGlobalState("grid");
  const playerSelectedColors = useGlobalState("selectedColors");
  const playerPos = useGlobalState("pos");
  const [countingMode, setCountingMode] = useState(false);
  const [showRoundScores, setShowRoundScores] = useState(false);
  const forceUpdate = useForceUpdate();
  const players = usePlayersList();

  function setValue(grid, x, y, value) {
    grid.set(x, y, value);
    forceUpdate();
  }

  function getGridColors() {
    const COLORS = [
      "blue",
      "red",
      "green",
      "yellow",
      "orange",
      "purple",
      "pink",
      "black",
      "white",
      "brown",
      "cyan",
      "magenta",
    ].slice(0, Math.max(players.length, 4));
    return COLORS;
  }

  function getRandomEmptyPos(grid) {
    var it = 1000;
    var x = Math.floor(Math.random() * grid.width);
    var y = Math.floor(Math.random() * grid.height);
    while (grid.get(x, y) !== undefined) {
      x = Math.floor(Math.random() * grid.width);
      y = Math.floor(Math.random() * grid.height);
      if (it-- <= 0) return { x: -1, y: -1 };
    }
    return { x, y };
  }

  function createClusters(colors) {
    const gridTemp = new Grid({ width: grid.width, height: grid.height });
    console.log("createClusters", colors);
    console.log("createClusters reset done");
    console.log("createClusters filling");
    // choose initial position of each color
    colors.forEach((color) => {
      var { x, y } = getRandomEmptyPos(gridTemp);
      setValue(gridTemp, x, y, color);
    });

    while (!gridTemp.isFilled()) {
      console.log("grid not filled", gridTemp.isFilled());
      colors.forEach((color) => {
        if (!gridTemp.isFilled()) {
          var pos = gridTemp.getEmptyCellNearValue(color);
          if (pos) {
            setValue(gridTemp, pos.x, pos.y, color);
          } else {
            console.log(
              "Could not find empty cell near value",
              color,
              pos,
              gridTemp.isFilled()
            );
          }
        }
      });
    }
    return gridTemp.cells;
  }

  useEffect(() => {
    var allPos = {};
    const gridRectCurrent = document
      .getElementsByClassName("grid")[0]
      .getBoundingClientRect();
    setGridRect(gridRectCurrent);

    // reset Pos
    players.forEach((player) => {
      // TODO: use random pos for each player
      allPos[player.id] = {
        x: Math.floor(Math.random() * gridRectCurrent.width),
        y: Math.floor(Math.random() * gridRectCurrent.height),
      };
    });

    console.log("allPos", allPos);

    // playerPos = allPos;
    multiplayer.setState("pos", allPos);
    const COLORS = getGridColors();
    const cells = createClusters(COLORS);
    multiplayer.setState("grid", cells);
    // grid.cells = multiplayer.getState('grid');
    // forceUpdate();

    // window.grid = grid;
    // window.createClusters = createClusters;
    // window.COLORS = COLORS;

    // start an interval to check for player inputs and move cursors
    const inputInterval = setInterval(() => {
      let playerPosTemp = multiplayer.getState("pos")
        ? JSON.parse(JSON.stringify(multiplayer.getState("pos")))
        : {};
      const gridRectCurrent = document
        .getElementsByClassName("grid")[0]
        .getBoundingClientRect();
      setGridRect(gridRectCurrent);
      const speed = 70;
      players.forEach((player) => {
        if (player.getState("selectedColor")) return; // skip if player has already selected a color
        const inputVector = player.inputState?.dpad || [0, 0];
        if (isNaN(playerPosTemp[player.id].x)) playerPosTemp[player.id].x = 0;
        if (isNaN(playerPosTemp[player.id].y)) playerPosTemp[player.id].y = 0;
        var { x, y } = playerPosTemp[player.id] || { x: 0, y: 0 };
        if (inputVector[0] !== 0) {
          x += inputVector[0] * speed;
        }
        if (inputVector[1] !== 0) {
          y += inputVector[1] * speed * -1;
        }

        // check if the player is out of the window
        // if (x < gridRectCurrent.left) x = gridRectCurrent.left;
        // if (x > gridRectCurrent.right - PLAYER_AVATAR_SIZE)
        //   x = gridRectCurrent.right - PLAYER_AVATAR_SIZE;
        // if (y < gridRectCurrent.top) y = gridRectCurrent.top;
        // if (y > gridRectCurrent.bottom - PLAYER_AVATAR_SIZE)
        //   y = gridRectCurrent.bottom - PLAYER_AVATAR_SIZE;

        if (x < 0) x = 0;
        if (x > gridRectCurrent.width - PLAYER_AVATAR_SIZE)
          x = gridRectCurrent.width - PLAYER_AVATAR_SIZE;
        if (y < 0) y = 0;
        if (y > gridRectCurrent.height - PLAYER_AVATAR_SIZE)
          y = gridRectCurrent.height - PLAYER_AVATAR_SIZE;

        // console.log(x,y, gridRectCurrent.y + gridRectCurrent.height - 100);
        playerPosTemp[player.id] = { x: parseInt(x), y: parseInt(y) };

        if (player.inputState["b1"]) {
          // compute which grid cell the player is in
          const gX = Math.floor((x + PLAYER_AVATAR_SIZE / 2) / GRID_CELL_SIZE);
          const gY = Math.floor((y + PLAYER_AVATAR_SIZE / 2) / GRID_CELL_SIZE);

          const color = grid.get(gX, gY);
          console.log(gX, gY, color);
          if (color) {
            const selectedColors = multiplayer.getState("selectedColors") || {};
            if (
              multiplayer.getState("live") &&
              !selectedColors[color] &&
              !player.getState("selectedColor")
            ) {
              const newSelectedColors = {
                ...selectedColors,
                [color]: player.id,
              };
              multiplayer.setState("selectedColors", newSelectedColors);
              player.setState("selectedColor", color);
            }
          }
        }
      });

      multiplayer.setState("pos", playerPosTemp, false);

      forceUpdate();
    }, 100);

    return () => {
      clearInterval(inputInterval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const COLORS = getGridColors();
    const newSelectedColors = playerSelectedColors;
    if (!newSelectedColors || countingMode) return;
    let countdownInterval;
    // see if game has ended
    if (Object.keys(newSelectedColors).length === players.length) {
      setCountingMode(true);
      var winners = [];
      countdownInterval = setInterval(() => {
        Object.keys(newSelectedColors).forEach((color) => {
          const playerId = newSelectedColors[color];
          const remainingCells = grid.getAllOfValue(color);
          if (remainingCells.length === 0) {
            if (winners.findIndex((p) => p.id === playerId) === -1) {
              winners.push(players.find((p) => p.id === playerId));
            }
          } else {
            // deduce one cell
            const { x, y } = remainingCells[0];
            grid.set(x, y, undefined);
          }
        });

        // selected all winners?
        if (winners.length === Object.keys(newSelectedColors).length) {
          clearInterval(countdownInterval);
          countdownInterval = 0;

          console.log("WINNER CALL", "createClusters", winners);
          // assign round scores (winners list is reverse sorted)
          var roundScores = {};
          winners.reverse().forEach((winner, i) => {
            if (i === 0) roundScores[winner.id] = 5;
            if (i === 1) roundScores[winner.id] = 3;
            if (i === 2) roundScores[winner.id] = 1;
            if (i === players.length - 1) delete roundScores[winner.id]; // last player gets no points

            if (roundScores[winner.id]) {
              winner.setState(
                "score",
                (winner.getState("score") || 0) + roundScores[winner.id]
              );
            }
          });

          setShowRoundScores(roundScores);
          // reset game
          setTimeout(() => {
            setShowRoundScores(false);
            setCountingMode(false);
            // multiplayer.setState("newGame", Math.random());
            const currentRound = multiplayer.getState("currentRound");
            if (currentRound === 5) {
              // multiplayer.setState("newGame", Math.random());
              // find winner
              var highestScorer;
              players.forEach((player) => {
                if (
                  !highestScorer ||
                  player.getState("score") > highestScorer.getState("score")
                ) {
                  highestScorer = player;
                }
              });
              multiplayer.setState("winner", highestScorer.id);
              multiplayer.addToWinLog("pickthelargest", highestScorer.id);
            } else {
              multiplayer.setState("currentRound", currentRound + 1);
              // TODO: set score

              // reset grid
              multiplayer.setState("live", undefined);
              multiplayer.setState("selectedColors", undefined);
              players.forEach((playerState) => {
                playerState.setState("selectedColor", undefined);
              });

              const cells = createClusters(COLORS);
              multiplayer.setState("grid", cells);
              setTimeout(() => {
                multiplayer.setState("live", true);
              }, 2000);
            }
          }, 3000);
        }
      }, 300);
    }
    return () => {
      clearInterval(countdownInterval);
    };
  }, [playerSelectedColors]);

  useEffect(() => {
    if (cells && cells.length > 0) {
      grid.cells = cells;
      forceUpdate();
    }
    // eslint-disable-next-line
  }, [cells]);

  return (
    <div className="view-container">
      <div className="player-cursors">
        {players.map((playerState) => (
          <div
            className={
              "player-cursor-container" +
              (playerState.getState("selectedColor") ? " disabled" : "")
            }
            style={{
              top:
                (playerPos ? playerPos[playerState.id]?.y || 0 : 0) +
                gridRect.top,
              left:
                (playerPos ? playerPos[playerState.id]?.x || 0 : 0) +
                gridRect.left,
            }}
          >
            {showRoundScores && showRoundScores[playerState.id] ? (
              <span className="round-score">
                +{showRoundScores[playerState.id]}
              </span>
            ) : (
              false
            )}
            <AvatarIcon
              key={playerState.id}
              playerState={playerState}
              style={{
                width: `${PLAYER_AVATAR_SIZE}px`,
                height: `${PLAYER_AVATAR_SIZE}px`,
              }}
            />
          </div>
        ))}
      </div>
      <div
        onMouseDown={() => setShowColors(true)}
        onMouseUp={() => setShowColors(false)}
        className="grid"
      >
        {grid.cells.map((row, i) => (
          <div className="row" key={i}>
            {row.map((value, j) => {
              const isSelected = (multiplayer.getState("selectedColors") || {
                x: 1,
              })[value];
              var colorOfCell = value;
              if (isSelected) {
                const playerId = multiplayer.getState("selectedColors")[value];
                const player = players.find((player) => player.id === playerId);
                colorOfCell = player.getState("profile").color;
              }
              if (showColors) {
                colorOfCell = value;
              }
              const style = computeBorderStyleOfCell(
                grid,
                j,
                i,
                isSelected || showColors
                  ? countingMode
                    ? false
                    : colorOfCell
                  : "transparent"
              );
              const countingModeStyle = {
                boxShadow: "inset 5px 4px 10px rgba(255, 255, 255, 0.25)",
                borderRadius: "10px",
              };
              return (
                <div
                  className="cell"
                  key={j}
                  style={{
                    ...style,
                    width: `${GRID_CELL_SIZE}px`,
                    height: `${GRID_CELL_SIZE}px`,
                    backgroundColor:
                      isSelected || showColors ? colorOfCell : "transparent",
                    ...(countingMode && countingModeStyle),
                  }}
                />
              );
            })}
          </div>
        ))}
      </div>

      <InGameLeaderboard
        playerStatesAndScores={players.map((playerState) => {
          const score = playerState.getState("score") || 0;
          return {
            state: playerState,
            score: score,
          };
        })}
      />
    </div>
  );
}

function computeBorderStyleOfCell(grid, x, y, colorOverride) {
  const value = grid.get(x, y);
  const left =
    grid.get(x - 1, y) !== value
      ? "2px solid #EA6BFF"
      : `2px solid ${colorOverride || value}`;
  const right =
    grid.get(x + 1, y) !== value
      ? "2px solid #EA6BFF"
      : `2px solid ${colorOverride || value}`;
  const top =
    grid.get(x, y - 1) !== value
      ? "2px solid #EA6BFF"
      : `2px solid ${colorOverride || value}`;
  const bottom =
    grid.get(x, y + 1) !== value
      ? "2px solid #EA6BFF"
      : `2px solid ${colorOverride || value}`;
  return {
    borderLeft: left,
    borderRight: right,
    borderTop: top,
    borderBottom: bottom,
  };
}

export default Viewer;
