import imgFastestFingers from "common/imgs/playlists/fastestfingers.png";
import imgArcade from "common/imgs/playlists/arcade.png";
import imgAllGames from "common/imgs/playlists/allgames.png";
import imgTest from "common/imgs/playlists/test.png";
import imgDemo from "common/imgs/playlists/demo.png";
import imgDiscord from "common/imgs/playlists/discord.png";
import imgNerdOut from "common/imgs/playlists/nerdout.png";
import imgSnap from "common/imgs/playlists/snap.png";
import imgRPS from "common/imgs/playlists/rps.png";
import imgChicken from "common/imgs/playlists/notachicken.png";
import imgMessyKeys from "common/imgs/playlists/messykeys.png";

const prodPlaylists = [
  {
    id: "demo",
    title: "Demo",
    author: "Playroom",
    description: "",
    thumbnail: imgDemo,
    metadata: {
      ages: "5+",
      type: "Competitive",
    },
    gamesList: [
      { id: "dogfight" },
      { id: "rockpaperscissors" },
      { id: "fallingmnms" },
      { id: "rocketfuel" },
    ],
  },
  // {
  //   id: "fastestfingers",
  //   title: "Fastest Fingers",
  //   author: "Playroom",
  //   description: "",
  //   thumbnail: imgFastestFingers,
  //   metadata: {
  //     ages: "10+",
  //     type: "Silly",
  //   },
  //   gamesList: [
  //     { id: "rocketfuel" },
  //     { id: "fallingmnms" },
  //     { id: "richyrich" },
  //     { id: "pickthelargest" },
  //   ],
  // },
  {
    id: "arcade",
    title: "Arcade",
    author: "Playroom",
    description: "",
    thumbnail: imgArcade,
    metadata: {
      ages: "10+",
      type: "Competitive",
    },
    gamesList: [
      { id: "dogfight" },
      { id: "smashdown" },
      { id: "icetruck" },
      // { id: "shakethejar" },
    ],
  },
  {
    id: "rps",
    title: "Rock Paper Scissors",
    author: "Playroom",
    description: "",
    thumbnail: imgRPS,
    metadata: {},
    gamesList: [{ id: "rockpaperscissors" }],
  },
  {
    id: "messykeys",
    title: "Messy Keys",
    author: "Playroom",
    description: "",
    thumbnail: imgMessyKeys,
    metadata: {
      ages: "10+",
      type: "Silly",
    },
    gamesList: [{ id: "messykeys" }],
  },
  {
    id: "fastestfingersfullgame",
    title: "Fastest Fingers",
    author: "Playroom",
    description: "",
    thumbnail: imgFastestFingers,
    metadata: {
      ages: "10+",
      type: "Silly",
    },
    gamesList: [{ id: "fastestfingers" }],
  },
  {
    id: "chicken",
    comingsoon: true,
    title: "Not a Chicken",
    author: "Playroom",
    description: "",
    thumbnail: imgChicken,
    metadata: {},
    gamesList: [],
  },
  {
    id: "snap",
    comingsoon: true,
    title: "Snap",
    author: "Playroom",
    description: "",
    thumbnail: imgSnap,
    metadata: {},
    gamesList: [],
  },
  {
    id: "nerdout",
    comingsoon: true,
    title: "Nerd Out",
    author: "Playroom",
    description: "",
    thumbnail: imgNerdOut,
    metadata: {},
    gamesList: [],
  },
  {
    id: "discord",
    comingsoon: true,
    title: "Discord",
    author: "Playroom",
    description: "",
    thumbnail: imgDiscord,
    metadata: {},
    gamesList: [],
  },
];

const devPlaylists = [
  {
    id: "allgames",
    title: "All Games",
    author: "Playroom",
    description: "",
    thumbnail: imgAllGames,
    metadata: {
      ages: "5+",
      type: "Competitive",
    },
    gamesList: [
      "dogfight",
      "fallingmnms",
      "pickthelargest",
      "smashdown",
      "richyrich",
      "rocketfuel",
      "icetruck",
      "shakethejar",
      "quickgolf",
      "rockpaperscissors",
    ].map((id) => {
      return { id };
    }),
  },
  {
    id: "test",
    title: "Evil Experiments",
    author: "saadb",
    description: "",
    thumbnail: imgTest,
    metadata: {
      ages: "5+",
      type: "Competitive",
    },
    gamesList: [{ id: "messykeys" }],
  },
];

// const Playlists = prodPlaylists.concat(
//   devPlaylists.map((devPlaylist, idx) => {
//     return {
//       ...devPlaylist,
//       dev: true, // add this flag to dev playlists so we can hide them later
//     };
//   })
// );

const Playlists = devPlaylists
  .map((p) => ({ ...p, dev: true })) // add this flag to dev playlists so we can hide them later
  .concat(prodPlaylists);

export default Playlists;

export function getPlaylistFromId(id) {
  return Playlists.find((pl) => pl.id === id);
}

export function getExpandedPlaylistFromId(id) {
  const playlist = getPlaylistFromId(id);
  if (playlist) {
    const newPlaylist = JSON.parse(JSON.stringify(playlist)); // clone the playlist
    let gamesList = [];
    newPlaylist.gamesList.forEach((game) => {
      [...Array(game.rounds || 1).keys()].forEach(() =>
        gamesList.push(game.id)
      );
    });
    newPlaylist.gamesList = gamesList;
    return newPlaylist;
  }
}
