import React, { useEffect, useState } from "react";
import AvatarIcon from "common/components/AvatarIcon";
import Game from "common/components/GameRenderer";
import { GameScene } from "./src/game";
import GamesMenu from "common/components/GamesMenu";
import Multiplayer from "common/modules/multiplayer";
import BackgroundGame from "common/components/BackgroundGame";
import TouchJoystick from "common/modules/controllers/touch";
import ControllerHUD from "common/components/ControllerHUD";
import { useMyPlayerState } from "common/hooks/multiplayer";
import imgPlane from "./img/avatar.png";
import "./style.css";

function randRange(minNum, maxNum) {
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}

function bot(controller) {
  const waitFor = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const waitForRandom = (min, max) => waitFor(randRange(min, max));
  const interval = setInterval(async () => {
    controller.emit("dpad", 50);
    await waitForRandom(1000, 1500);
    controller.emit("dpad", 0);
    await waitForRandom(500, 1500);
    controller.emit("dpad", 330);
    await waitForRandom(500, 2000);
    controller.emit("dpad", 0);
  }, 4000);

  controller.on("destroy", () => clearInterval(interval));
}

export default function GamePage({ gameInfo }) {
  const multiplayer = Multiplayer();
  const [players, setPlayers] = useState([]);
  const [playerCoins, setPlayerCoins] = useState({});
  const [winnerFound, setWinnerFound] = useState(
    multiplayer.getState("winner") || false
  );
  const myScore = useMyPlayerState("coins") || 0;

  const [renderGame, setRenderGame] = useState(true);

  useEffect(() => {
    window.restartGame = () => {
      setRenderGame(false);
      setTimeout(() => {
        setRenderGame(true);
      }, 300);
    };
    function refreshPlayers() {
      const playersList = multiplayer.getPlayers();
      const playersArr = Object.keys(playersList).map(
        (pid) => playersList[pid]
      );
      var coins = {};
      setPlayers(playersArr);
      playersArr.forEach((player) => {
        coins[player.id] = player.getState("coins") || 0;
      });
      setPlayerCoins(coins);
    }
    var controller = null;
    var cleanupFns = [];
    cleanupFns.push(
      multiplayer.on("state", (state, key) => {
        setWinnerFound(state["winner"] || false);
      })
    );

    if (multiplayer.isSpectator) {
      cleanupFns.push(
        multiplayer.on("players", (playersList) => {
          refreshPlayers();
        })
      );

      cleanupFns.push(
        multiplayer.on("joined", (player) => {
          cleanupFns.push(
            player.on("state", (key, newState) => {
              if (key === "coins") {
                refreshPlayers();
              }
            })
          );
        })
      );
    }

    if (!multiplayer.isSpectator) {
      controller = new TouchJoystick({
        degree: true,
        className: "dogfight",
        labels: { dpad: "MOVE", b1: " " },
      });
      multiplayer.attachController(controller);
      if (window.ISBOT) bot(controller);
    }

    return () => {
      cleanupFns.forEach((cleanup) => cleanup());
      if (!multiplayer.isSpectator) {
        multiplayer.detachController();
        if (controller) controller.destroy();
        controller = null;
      }
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {multiplayer.isSpectator && (
        <BackgroundGame style={{ backgroundColor: "#e0b1c5" }} />
      )}
      {!multiplayer.isSpectator && (
        <BackgroundGame
          style={{
            background:
              "linear-gradient(180deg, #FFFBA3 0%, rgba(255, 255, 255, 0) 100%), #FF7F56",
          }}
        />
      )}
      <GamesMenu
        key="gamesmenu"
        inGame
        showLogo
        gameInfo={gameInfo}
        showRestart={winnerFound && multiplayer.isHost}
      />
      {multiplayer.isSpectator ? (
        <div className="dogfight-player-scores">
          {players.map((playerState) => (
            <div
              className="player-score-container"
              style={{ backgroundColor: playerState.getState("profile").color }}
            >
              <AvatarIcon key={playerState.id} playerState={playerState} />
              <span>{playerCoins[playerState.id]}</span>
            </div>
          ))}
        </div>
      ) : (
        <ControllerHUD
          className="hud-dogfight"
          breadcrumbs={["Dog Fight"]}
          backgroundStyle={{
            background: `linear-gradient(180deg, #FFFBA3 0%, rgba(255, 255, 255, 0) 100%), #FF7F56`,
          }}
          avatarStyle={{
            background: `#ffffff`,
          }}
          avatarUrl={imgPlane}
        >
          <div className="hud-player-score">{myScore || 0}</div>
        </ControllerHUD>
      )}
      {renderGame && multiplayer.isSpectator ? (
        <Game
          key="game"
          fullScreen
          gameInfo={gameInfo}
          scenes={[GameScene]}
          physics={{
            default: "arcade",
            // default: "matter",
            // matter: {
            //   debug: true,
            // },
          }}
        />
      ) : (
        false
      )}
    </>
  );
}
