import Connection from "./connection";
import randomString from "../randomString";
import SuperEventEmitter from "../superEventEmitter";
import BrowserStorage from "../localStorageWrapper";
import { nanoid } from "nanoid";
import { addUserLog } from "../db";
import { addLog, LOGTYPES } from "../db";

class Multiplayer extends SuperEventEmitter {
  constructor() {
    super();
    this.connection = false;
    this.currentRoom = false;
    this.isConnected = false;
  }

  createRoom(roomId) {
    if (!roomId) {
      roomId = randomString(4);
    }
    roomId = roomId.toUpperCase();
    this.connection = new Connection({ isHost: true, roomId });
    this.currentRoom = roomId;
    this.listenToEvents();
  }

  isRenderServer() {
    if (this.connection) return this.connection.isRenderServer();
  }

  get isHost() {
    if (this.connection) return this.connection.isHost;
    return false;
  }

  get isSpectator() {
    if (this.connection) return this.connection.isSpectator;
    return false;
  }

  get isRenderServerOrHostIfNotCasting() {
    if (this.connection && this.connection.isRenderServerOrHostIfNotCasting)
      return true;
    return false;
  }

  listenToEvents() {
    // forward some events
    this.connection.on("joined", (player) => {
      this.emit("joined", player);
      if (this.isRenderServerOrHostIfNotCasting) {
        this.roomLog(LOGTYPES.ROOMSTATE, "player_joined", {
          playerId: player.id,
        });
      }
    });

    this.connection.on("player_quit", (playerid) => {
      if (this.isRenderServerOrHostIfNotCasting) {
        this.roomLog(LOGTYPES.ROOMSTATE, "player_quit", {
          playerId: playerid,
        });
      }
    });

    this.connection.on("state", (state, key) => {
      this.emit("state", state, key);
    });
    this.connection.on("connected", () => {
      if (this.isHost) {
        if (!this.getState("uid")) {
          this.setState("uid", nanoid(7));
          this.setState("id", this.currentRoom);
          this.setState("meta", {
            creatorEmail: BrowserStorage.get("inviteemail"),
            creatorInviteCode: BrowserStorage.get("invitecode"),
            creatorUserId: this.connection.myId,
          });
        }
      } else if (!this.isSpectator && !BrowserStorage.get("invitecode")) {
        BrowserStorage.set("invitecode", "unlock_by_join");
        addUserLog("creation_unlock", {
          inviteCode: "unlock_by_join",
          unlockType: "join",
        });
      }
      this.isConnected = true;
      this.emit("connected");
      if (this.isHost) {
        this.emit("room_created", { id: this.currentRoom });
        this.roomLog(LOGTYPES.ROOMSTATE, "room_created", {
          host: this.connection.myId,
        });
      }
      // URLHash.setUrlHashParameters({room: this.currentRoom, host: this.isHost});
    });

    this.connection.on("reconnecting", () => {
      this.emit("reconnecting");
    });

    this.connection.on("permission_error", () => {
      if (this.isHost) {
        // probably the room id is owned by someone else. Create another with new id
        this.createRoom();
      } else {
        this.isConnected = false;
        this.emit("permission_error");
      }
    });
    this.connection.on("host_left", () => {
      this.isConnected = false;
      this.emit("host_left");
    });

    this.connection.on("disconnected", (args) => {
      this.isConnected = false;
      this.emit("disconnected", args);
    });

    this.connection.on("players", (players) => {
      this.emit("players", players);
    });
    this.connection.on("host_updated", (isHost) => {
      this.emit("host_updated", isHost);
    });
  }

  on(eventName, handler, isTemporary) {
    if (eventName === "joined" && this.connection) {
      Object.keys(this.connection.playerStates).forEach((key) => {
        handler(this.connection.playerStates[key]);
      });
    }
    if (eventName === "players" && this.connection) {
      handler(this.connection.playerStates);
    }
    return super.on(eventName, handler, isTemporary);
  }

  joinRoom(roomId) {
    roomId = roomId.toUpperCase();
    this.connection = new Connection({ isHost: false, roomId });
    this.currentRoom = roomId;
    this.listenToEvents();
  }

  spectateRoom(roomId) {
    roomId = roomId.toUpperCase();
    this.connection = new Connection({ isSpectator: true, roomId });
    this.currentRoom = roomId;
    this.listenToEvents();
  }

  leaveRoom() {
    this.connection.disconnect();
    this.currentRoom = false;
    delete this.connection;
  }


  getState(key) {
    if (!this.connection) return {};
    if (key) return this.connection.getState(key);
    else return this.connection.getState();
  }

  // host only: public method to change state object. This is then synced with all clients.
  setState(key, newState, reliable = true) {
    if (!this.connection) return;
    // silently skip setState calls for spectator mode
    if (this.isSpectator && !this.isRenderServer()) return;
    this.connection.setState(key, newState, reliable);
  } 


  setRoundState(key, newState, reliable = true) {
    if (!this.connection) return;
    // silently skip setState calls for spectator mode
    if (this.isSpectator && !this.isRenderServer()) return;
    this.connection.setState(`round.${key}`, newState, reliable);
  }

  getRoundState(key) {
    if (!this.connection) return {};
    if (key) return this.connection.getState(`round.${key}`);
    else {
      let roundState = {};
      Object.keys(this.connection.getState()).forEach((key) => {
        if (key.startsWith("round.")) {
          roundState[key.substring(6)] = this.connection.getState(key);
        }
      });
      return roundState;
    }
  }

  resetRoundState() {
    Object.keys(this.connection.getState()).forEach((key) => {
      if (key.startsWith("round.")) {
        this.connection.setState(key, undefined);
      }
    });

    Object.keys(this.connection.playerStates).forEach((key) => {
      const playerState = this.connection.playerStates[key];
      playerState.resetRoundState();
    });
  }

  // host only: we maintain a running log of winner per game, we use this to calculate leaderboard score
  addToWinLog(game, winnerPlayerId) {
    let currentLog = this.getState("winslog") || [];
    const timestamp = Date.now();

    console.log("winnerPlayerId in addToWinLog:", winnerPlayerId);

    if (typeof winnerPlayerId === "object") {
      const logData = winnerPlayerId.map((winner, idx) => {
        this.gameLog("winner", { winnerId: winner, gameId: game });
        return [game, winner, timestamp];
      });
      currentLog = currentLog.concat(logData);
    } else {
      this.gameLog("winner", { winnerId: winnerPlayerId, gameId: game });
      currentLog.push([game, winnerPlayerId, timestamp]);
    }

    console.log("currentLog (to be winslog): ", currentLog);

    this.setState("winslog", currentLog);
  }

  getMyPlayerState() {
    if (this.connection) {
      if (!this.isSpectator)
        return this.connection.playerStates[this.connection.myId];
      else return this.connection.spectatorStates[this.connection.myId];
    }
  }

  // RenderServer only: add a stream to all spectator's webrtc
  addRenderStream(stream) {
    if (!this.isRenderServer) return;
    const spectators = Object.values(this.connection.spectatorStates).filter(
      (state) => state.id !== this.connection.myId
    );
    console.log("addRenderStream", spectators);
    spectators.forEach((state) => state.peer.addStream(stream));

    // Subscribe to new spectators, add stream to them too.
    const unsubFn = this.connection.on("spectator_joined", (state) => {
      console.log("spectator_joined", state);
      state.once("webrtc_connected", () => {
        // TODO(asadm): figure out how to remove this delay
        // on('stream') is only fired if new stream was added
        // after we subscribed. So any ongoing streams are not provided
        // Lobby game shows "lobby starting" for some time and then loads the
        // game renderer, which listens to stream event. RenderServer had already
        // added the stream, which this new spectator misses.
        // In other words, when calling .addStream here, we expect the other side
        // is already listening for them ie. on('stream'), But that's not always true
        setTimeout(() => {
          console.log("adding stream");
          state.peer.addStream(stream);
        }, 5000);
      });
    });
    return unsubFn;
  }

  // RenderServer only: remove a stream to all spectator's webrtc
  removeRenderStream(stream) {
    if (!this.isRenderServer) return;
    const spectators = Object.values(this.connection.spectatorStates).filter(
      (state) => state.id !== this.connection.myId
    );
    console.log("removeRenderStream", spectators);
    spectators.forEach((state) => state.peer.removeStream(stream));
  }

  getPlayers() {
    if (this.connection) return this.connection.playerStates;
    return {};
  }

  attachController(controller) {
    this.detachController();
    var myState = this.connection.playerStates[this.connection.myId];
    if (myState) {
      myState.attachController(controller);
    }
  }

  detachController() {
    var myState = this.connection.playerStates[this.connection.myId];
    if (myState) {
      myState.detachController();
    }
  }

  navigate(pathname, state) {
    if (this.connection && (this.isRenderServer() || this.isHost)) {
      var newState = { pathname: pathname };
      if (state) {
        newState.state = JSON.stringify(state);
      }
      this.setState("path", newState);
      this.roomLog(LOGTYPES.ROOMSTATE, "navigate", newState);
    }
  }

  async roomLog(logType, eventName, data) {
    if (this.connection && this.isRenderServerOrHostIfNotCasting) {
      data = data || {};
      data.type = logType;
      data.event = eventName;
      // add room id, uid, and timestamp to data
      data.roomId = this.currentRoom;
      data.roomUid = this.getState("uid");
      if (this.getState("meta")) {
        data = { ...data, ...this.getState("meta") };
      }
      const playersInRoom = Object.keys(this.getPlayers());
      data.playersInRoom = playersInRoom.length;
      if (this.getState("playlist")) {
        data.playlist = this.getState("playlist");
      }
      await addLog(data);
    }
  }
  async gameLog(eventName, data) {
    if (this.connection && this.isRenderServerOrHostIfNotCasting) {
      data = data || {};
      await this.roomLog(LOGTYPES.GAMESTATE, eventName, data, true);
    }
  }
}

export default function createSingleton() {
  if (
    window.parent?._multiplayer &&
    window.location.hash.indexOf("NOPARENT") === -1
  ) {
    return window.parent?._multiplayer; // when running inside an iframe, fetch parent's multiplayer object.
  }
  if (!window._multiplayer) {
    window._multiplayer = new Multiplayer();
  }

  return window._multiplayer;
}
