import { useEffect, useRef } from "react";

// Option 1: Import the entire three.js core library.
import * as THREE from 'three';

/*
References:
- https://codepen.io/avanos/pen/QWWQwmG?editors=1111
*/

class Render {
    constructor({ el }) {
      this.el = el;
      this._time = this._random();
      this._init();
    }
    
    _init() {
      this._createScene();
      this._createCamera();
      this._createRenderer();
      this._createLight();
      this._createCubes();
     
      this._addEventListeners();
      this._resize();
      this._raf();
    };
    
    _addEventListeners() {
      window.addEventListener('resize', this._resizeHandler);
    }
  
    _createScene() {
      // Holds all the objects and light
      this._scene = new THREE.Scene();
      this._scene.fog = new THREE.Fog(0xFFFFFF, 2.5, 3.5);
    }
    
    _createCamera() {
      this._camera = new THREE.PerspectiveCamera(35, window.innerWidth / window.innerHeight, 1, 500);
      this._camera.position.z = 3;
    }
    
    _createRenderer() {
      this._renderer = new THREE.WebGLRenderer({ canvas: this.el, alpha: true  });
    }
    
    _createLight() {
      // var light = new THREE.HemisphereLight(0xffffff, 0x000000, 1);
      // this._scene.add(light);
      
      const light = new THREE.SpotLight(0xFFFFFF, 3);
      light.position.set(5, 5, 2);
      light.castShadow = true;
      light.shadow.mapSize.width = 10000;
      light.shadow.mapSize.height = light.shadow.mapSize.width;
      light.penumbra = 0.5;
      
      const lightBack = new THREE.PointLight(0x8291ff, 1);
      lightBack.position.set(0, -3, -1);
      
      const rectSize = 2;
      const intensity = 100;
      const rectLight = new THREE.RectAreaLight( 0xf2ccff, intensity, rectSize, rectSize );
      rectLight.position.set(0, 0, 3);
      rectLight.lookAt(0, 0, 0);
      
      this._scene.add(light);
      this._scene.add(lightBack);
      this._scene.add(rectLight);
    }
    
    _createCubes() {
      this._cubesGroup = new THREE.Object3D();
      this._scene.add(this._cubesGroup);
     
      for (let i = 0; i<30; i++) {
        this._cubesGroup.add(this._createCube(i%4));
      }
    }
    
    _createCube(i) {
        const geometry = 
        i >= 3 
        ? new THREE.BoxGeometry(1)
        : i >= 2
        ? new THREE.ConeGeometry( 1, 1, 1 )
        : i >= 1
        ? new THREE.CylinderGeometry( 1, 1, 1, 1 )
        : new THREE.SphereGeometry( 1, 1, 1 );
        const material = new THREE.MeshStandardMaterial({
          flatShading: true,
          color: 0xD4D5D6,
          transparent: false, 
          opacity: 0.6,
          wireframe: false
        });
        const cube = new THREE.Mesh(geometry, material);
      
        cube.speedRotation = Math.random() * 0.1;
        cube.positionX = this._random();
        cube.positionY = this._random();
        cube.positionZ = this._random();
        cube.position.set(cube.positionX, cube.positionY, cube.positionZ);
      
        cube.castShadow = true;
        cube.receiveShadow = true;
      
        const newScaleValue = this._random(0.3);  
        cube.scale.set(newScaleValue, newScaleValue, newScaleValue);
      
        cube.rotation.x = this._random(180 * Math.PI / 180);
        cube.rotation.y = this._random(180 * Math.PI / 180);
        cube.rotation.z = this._random(180 * Math.PI / 180);
      
      return cube;
    }
    
    _resize() {
      this._camera.aspect = window.innerWidth / window.innerHeight;
      this._camera.updateProjectionMatrix();
      this._renderer.setSize( window.innerWidth, window.innerHeight );
    }
  
    _update = () => {
      this._time += 0.008;
      
      for (var i = 0, l = this._cubesGroup.children.length; i<l; i++) {
        var newCubes = this._cubesGroup.children[i];
        newCubes.rotation.x += 0.008;
        newCubes.rotation.y += 0.005;
        newCubes.rotation.z += 0.003;
  
        newCubes.position.x = newCubes.positionX + Math.sin(this._time * newCubes.positionZ) * newCubes.positionY;
        newCubes.position.y = newCubes.positionY + Math.cos(this._time * newCubes.positionX) * newCubes.positionZ;
        newCubes.position.z = newCubes.positionY + Math.sin(this._time * newCubes.positionY) * newCubes.positionX;
      }
      
    }
    
    _render = () => {
      this._renderer.render(this._scene, this._camera);
    }
    
    _raf = () => {
      this._update();
      this._render();
      
      window.requestAnimationFrame(this._raf);
    }
    
    /**
     * Helpers
     */
    _random(num = 1) {
      const setNumber = - Math.random() * num + Math.random() * num;
      return setNumber;
    }
    
    /**
     * Handlers
     */
    _resizeHandler = () => {
      this._resize();
    }
    
  }
  


const FloatingShapes = () => {
const canvasRef = useRef(null);

useEffect(() => {
    if (canvasRef.current) {
        const render = new Render({
            el: canvasRef.current
        }
        );
    }
}, [canvasRef])

return (
    <canvas ref={canvasRef} className="fullscreen js-canvas"></canvas>
)
}

export default FloatingShapes;