import Cropper from "react-cropper";
import React, { useRef } from "react";
import "cropperjs/dist/cropper.css";
import "./styles.css";
export default function CropTool({ src, onChange }) {
  const cropperRef = useRef(null);
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    onChange(cropper.getCroppedCanvas());
    // console.log(cropper.getCroppedCanvas().toDataURL());
  };

  return (
    <Cropper
      src={src}
      style={{ maxHeight: "100vw", width: "100%" }}
      // Cropper.js options
      viewMode={3}
      dragMode={"move"}
      autoCropArea={1}
      restore={false}
      modal={true}
      guides={false}
      highlight={true}
      cropBoxMovable={false}
      cropBoxResizable={false}
      toggleDragModeOnDblclick={false}
      aspectRatio={1}
      crop={onCrop}
      ref={cropperRef}
    />
  );
}
