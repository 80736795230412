import pikachuImg from './imgs/pikachu.png';
import charizardImg from './imgs/charizard.png';
import blastoiseImg from './imgs/blastoise.png';
import alakazamImg from './imgs/alakazam.png';
import crobatImg from './imgs/crobat.png';
import eeveeImg from './imgs/eevee.png';
import galladeImg from './imgs/gallade.png';
import arceusImg from './imgs/arceus.png';
import kyogreImg from './imgs/kyogre.png';

const sampleCardData = [
    {
      id: 'rock',
      // image: rockImg,
      backgroundImage: `url(${pikachuImg})`,
      text: ``,
      chosen: false,
    },
    {
      id: 'paper',
      // image: paperImg,
      backgroundImage: `url(${charizardImg})`,
      text: ``,
      chosen: false,
    },
    {
      id: 'scissors',
      // image: scissorsImg,
      backgroundImage: `url(${blastoiseImg})`,
      text: ``,
      chosen: false,
    },
    {
      id: 'lizard',
      // image: scissorsImg,
      backgroundImage: `url(${alakazamImg})`,
      text: ``,
      chosen: false,
    },
    {
      id: 'spock',
      // image: scissorsImg,
      backgroundImage: `url(${crobatImg})`,
      text: ``,
      chosen: false,
    },
    {
      id: 'fire',
      // image: scissorsImg,
      backgroundImage: `url(${eeveeImg})`,
      text: ``,
      chosen: false,
    }, 
    {
      id: 'lightning',
      // image: scissorsImg,
      backgroundImage: `url(${galladeImg})`,
      text: ``,
      chosen: false,
    },
    {
      id: 'water',
      // image: scissorsImg,
      backgroundImage: `url(${arceusImg})`,
      text: ``,
      chosen: false,
    }, 
    {
      id: 'air',
      // image: scissorsImg,
      backgroundImage: `url(${kyogreImg})`,
      text: ``,
      chosen: false,
    },     
  ]
  
  export default sampleCardData