import mixpanel from "mixpanel-browser";
let hostname = "https://ws.joinplayroom.com";
if (process.env?.REACT_APP_SERVER) {
  const hostparts = process.env.REACT_APP_SERVER.split("://");
  hostname = `${hostparts[0] === "wss" ? "https" : "http"}://${hostparts[1]}`;
}
mixpanel.init("2647310162474a4f7ca66a277c543032", {
  debug: true,
  api_host: `${hostname}/__mix` /*ip: 0*/,
});

let env_check = process.env.NODE_ENV === "production";
let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export default actions;
