import CommonGameScene from "common/components/GameRenderer/gamescene";
import avatarCreatorSVG from "common/modules/avatarCreatorSVG";
import limbSvg from "./img/limb.svg";
import headSvg from "./img/head.svg";
import ropeSvg from "./img/rope.svg";
import torsoSvg from "./img/torso.svg";
import timeContainerSvg from "./img/timecontainer.svg";
import knifeImg from "./img/knife.png";

import sfxStab1 from "./sfx/stab1.mp3";
import sfxStab2 from "./sfx/stab2.mp3";
import sfxRopeCut from "./sfx/rope-stab.mp3";
import sfxScream from "./sfx/cut-scream.mp3";

function toRadians(degrees) {
  var pi = Math.PI;
  return degrees * (pi / 180);
}

export default class GameScene extends CommonGameScene {
  constructor() {
    super({ autoscale: false });
    this.playersSoFar = 0;
  }

  preload() {
    this.load.image("rope", ropeSvg);
    this.load.image("timecontainer", timeContainerSvg);
    this.load.image("knife", knifeImg);
    this.load.audio("stab1", sfxStab1);
    this.load.audio("stab2", sfxStab2);
    this.load.audio("ropecut", sfxRopeCut);
    this.load.audio("scream", sfxScream);
  }

  create() {
    super.create();
    this.matter.add.mouseSpring();
    this.playerDegrees = {};
    this.playerTextBoxes = {};
    this.playerLimbs = {};
    this.lastPlayersSortedByTime = [];
    // this.r = this.add.rectangle(
    //   0,
    //   0,
    //   this.adjustByScale(890),
    //   this.adjustByScale(400),
    //   0xff6699
    // );
    // this.r.setOrigin(0, 0);
    // this.r.x = this.adjustByScale(90);
    // this.r.y = this.adjustByScale(190);
    // this.r.alpha = 0.5;
    // this.addTimer(120000, () => this.checkWinner());
  }

  loadPlayerSpriteWithColor(svgUrl, key, color, width, height) {
    return new Promise(async (resolve, reject) => {
      const spriteSheetDataUri = await avatarCreatorSVG(svgUrl, color);
      var img = new Image();
      img.onload = () => {
        this.textures.addSpriteSheet(key, img, {
          frameWidth: width,
          frameHeight: height,
        });
        resolve(key);
      };
      img.src = spriteSheetDataUri;
    });
  }

  async addPlayerSprite(playerState, profile) {
    let { playerScale, playerPosX } = calculatePlayerSizeAndPosition(
      Object.keys(this.multiplayer.getPlayers()).length,
      this.playersSoFar++,
      this.gameSize.width,
      this.adjustByScale(80),
      this.adjustByScale(25),
      this.adjustByScale(20)
    );
    // const playerScale = 1.5;
    playerScale = this.adjustByScale(playerScale) / 2; // div 2 needed to scale down everything by half
    // playerPosX = this.adjustByScale(playerPosX)/2;
    const headSpriteKey = await this.loadPlayerSpriteWithColor(
      headSvg,
      "head-" + playerState.id,
      profile.color,
      60,
      60
    );

    const torsoSpriteKey = await this.loadPlayerSpriteWithColor(
      torsoSvg,
      "torso-" + playerState.id,
      profile.color,
      17,
      70
    );
    const limbSpriteKey = await this.loadPlayerSpriteWithColor(
      limbSvg,
      playerState.id,
      profile.color,
      17,
      50
    );

    // let playerPosX = Math.random() * 1000 * playerScale;
    let playerPosY = this.adjustByScale(-50);
    let ropeHolder = this.matter.add.image(
      playerPosX,
      playerPosY,
      headSpriteKey
    );
    ropeHolder.setStatic(true);
    ropeHolder.setScale(playerScale);
    let rope = this.matter.add.image(playerPosX, playerPosY + 100, "rope");
    rope.setScale(playerScale * 1.5);

    // limbs
    let leftArm = this.matter.add.image(
      playerPosX - 10,
      playerPosY + 200,
      limbSpriteKey
    );
    let rightArm = this.matter.add.image(
      playerPosX + 10,
      playerPosY + 200,
      limbSpriteKey
    );
    let leftLeg = this.matter.add.image(
      playerPosX - 10,
      playerPosY + 210,
      limbSpriteKey
    );
    let rightLeg = this.matter.add.image(
      playerPosX + 10,
      playerPosY + 210,
      limbSpriteKey
    );
    let torso = this.matter.add.image(
      playerPosX,
      playerPosY + 200,
      torsoSpriteKey
    );
    // torso.body.setScale(0.7)
    leftArm.setScale(playerScale);
    rightArm.setScale(playerScale);
    leftLeg.setScale(playerScale);
    rightLeg.setScale(playerScale);
    torso.setScale(playerScale);

    leftLeg.setBody({
      type: "rectangle",
      width: 17 * playerScale,
      height: 50 * playerScale,
      chamfer: { radius: 5 },
    });

    rightLeg.setBody({
      type: "rectangle",
      width: 17 * playerScale,
      height: 50 * playerScale,
      chamfer: { radius: 5 },
    });

    // head
    let playerHead = this.matter.add.image(
      playerPosX,
      playerPosY + 150,
      headSpriteKey
    );
    playerHead.setScale(1.5 * playerScale);
    playerHead.setBody({
      type: "circle",
      radius: 20 * playerScale,
    });
    // playerHead.setStatic(true);

    // set collision group to negative
    // leftArm.setCollisionGroup(-1);
    // rightArm.setCollisionGroup(-1);
    // leftLeg.setCollisionGroup(-1);
    // rightLeg.setCollisionGroup(-1);
    // torso.setCollisionGroup(-1);
    playerHead.setCollisionGroup(-1);
    rope.setCollisionGroup(-1);
    ropeHolder.setCollisionGroup(-1);

    this.playerLimbs[playerState.id] = [];
    // attach rope to holder
    this.playerLimbs[playerState.id].unshift(
      this.matter.add.constraint(ropeHolder, rope, 0, 0.7, {
        pointA: { x: 0, y: 60 * playerScale },
        pointB: { x: 0, y: -100 * playerScale },
      })
    );

    // attach head to rope
    this.playerLimbs[playerState.id].unshift(
      this.matter.add.constraint(rope, playerHead, 0, 0.7, {
        pointA: { x: 0, y: 100 * playerScale },
        pointB: { x: 0, y: -20 * playerScale },
      })
    );
    this.playerLimbs[playerState.id].unshift(
      this.matter.add.constraint(playerHead, torso, 0, 0.1, {
        pointA: { x: 0, y: 30 * playerScale },
        pointB: { x: 0, y: -25 * playerScale },
      })
    );

    this.playerLimbs[playerState.id].unshift(
      this.matter.add.constraint(torso, leftArm, 5 * playerScale, 0.1, {
        pointA: { x: -8 * playerScale, y: -25 * playerScale },
        pointB: { x: 0, y: 25 * playerScale },
      })
    );

    this.playerLimbs[playerState.id].unshift(
      this.matter.add.constraint(torso, rightArm, 5 * playerScale, 0.1, {
        pointA: { x: 8 * playerScale, y: -25 * playerScale },
        pointB: { x: 0, y: 25 * playerScale },
      })
    );

    this.playerLimbs[playerState.id].unshift(
      (this.legCon = this.matter.add.constraint(torso, leftLeg, 0, 0.1, {
        pointA: { x: 0, y: 30 * playerScale },
        pointB: { x: 0, y: 25 * playerScale },
      }))
    );

    this.playerLimbs[playerState.id].unshift(
      this.matter.add.constraint(torso, rightLeg, 0, 0.1, {
        pointA: { x: 0, y: 30 * playerScale },
        pointB: { x: 0, y: 25 * playerScale },
      })
    );

    let timecontainer = this.add.image(
      playerPosX,
      playerPosY + 480 * playerScale,
      "timecontainer"
    );
    timecontainer.setScale(playerScale * 1);
    let time = this.add.text(playerPosX, playerPosY + 480 * playerScale, "", {
      fontFamily: "Caveat Brush",
      fontSize: 34 * playerScale,
      color: "#ffffff",
    });
    time.setOrigin(0.5, 0.5);

    let knife = this.add.image(
      playerPosX - playerScale * 60,
      playerPosY + 460 * playerScale,
      "knife"
    );
    knife.setScale(playerScale * 0.5);

    this.playerTextBoxes[playerState.id] = [timecontainer, time, knife];

    return playerHead;
  }

  handlePlayerQuit(playerState) {}

  update() {
    super.update();
    if (
      this.multiplayer.getRoundState("playersSortedByTime") &&
      JSON.stringify(this.multiplayer.getRoundState("playersSortedByTime")) !==
        JSON.stringify(this.lastPlayersSortedByTime)
    ) {
      const gameMode = this.multiplayer.getRoundState("mode")?.type || "time";
      // console.log("playersSortedByTime changed",this.lastPlayersSortedByTime, this.multiplayer.getRoundState("playersSortedByTime"));
      this.lastPlayersSortedByTime = this.multiplayer.getRoundState(
        "playersSortedByTime"
      );

      let victims = [];
      let list = JSON.parse(JSON.stringify(this.lastPlayersSortedByTime));
      let N = this.lastPlayersSortedByTime.length > 3 ? 2 : 1; // just last one for now
      // first get all timeout people, ALL OF THEM
      while (
        list.length > 0 &&
        this.multiplayer
          .getPlayers()
          [list[list.length - 1]].getRoundState("done") >= 999999
      ) {
        if (
          !this.multiplayer.getPlayers()[list[list.length - 1]].getState("dead")
        ) {
          victims.push(list.splice(-1));
          N--;
        } else {
          list.splice(-1);
        }
      }
      // Next get all people who are in bottom N (if any N remain after timeout people)
      if (gameMode !== "time") {
        // can be singlecut, doublecut, ropecut
        // then get all people who are in bottom N.

        while (list.length > 0 && N > 0) {
          victims.push(list.splice(-1));
          N--;
        }
      }

      victims.forEach((victimId) => {
        this.playerTextBoxes[victimId][1].setColor("#ff0000");
        setTimeout(() => {
          this.playerTextBoxes[victimId][2].setAlpha(1);
          this.matter.world.removeConstraint(this.playerLimbs[victimId][0]);
          this.playerLimbs[victimId].shift();
          if (
            gameMode === "doublecut" &&
            this.playerLimbs[victimId].length >= 1
          ) {
            this.matter.world.removeConstraint(this.playerLimbs[victimId][0]);
            this.playerLimbs[victimId].shift();
            this.sound.play("stab1");
          }

          if (
            this.playerLimbs[victimId].length === 1 ||
            gameMode === "ropecut"
          ) {
            this.multiplayer.getPlayers()[victimId].setState("dead", true);
            // cut rope
            this.sound.play("ropecut");
            this.matter.world.removeConstraint(
              this.playerLimbs[victimId][this.playerLimbs[victimId].length - 1]
            );
            this.playerLimbs[victimId].shift();
            setTimeout(() => {
              this.sound.play("scream");
            }, 500);
          } else {
            this.sound.play("stab2");
          }
        }, 3000);
      });
    } else if (!this.multiplayer.getRoundState("playersSortedByTime")) {
      this.lastPlayersSortedByTime = [];
      Object.values(this.playerTextBoxes).forEach(
        ([timecontainer, time, knife]) => {
          time.setColor("#ffffff");
          knife.setAlpha(0);
        }
      );
    }
  }

  updateCommon(playerId, player, state) {
    if (state.inputState.gyro && state.inputState.gyro.r !== undefined) {
      // alpha orientation
      let desiredDegree = parseInt(state.inputState.gyro.r || 0); //convert clockwise to counterclockwise which phaser uses (no idea how math works, but it does)
      if (
        !this.playerDegrees[playerId] ||
        this.playerDegrees[playerId].d !== desiredDegree
      ) {
        this.playerDegrees[playerId] = { d: desiredDegree, t: Date.now() };
      }
    }

    if (state.getRoundState("done") && !state.getState("dead")) {
      this.playerTextBoxes[playerId][1]?.setText(
        state.getRoundState("done") >= 999999
          ? "TIME UP"
          : (state.getRoundState("done") / 1000).toFixed(2)
      );
      this.playerTextBoxes[playerId][1]?.setAlpha(1);
      this.playerTextBoxes[playerId][0]?.setAlpha(1);
    } else {
      this.playerTextBoxes[playerId][1]?.setAlpha(0);
      this.playerTextBoxes[playerId][0]?.setAlpha(0);
    }
  }

  // host does all the computation and inputs, other players only render player at x,y
  updatePlayerHost(playerId, player, state) {
    if (
      !this.playerDegrees[playerId] ||
      this.playerDegrees[playerId].t < Date.now() - 1000
    ) {
      return;
    }
    let degree = (this.playerDegrees[playerId]?.d || 180) + 180;
    if (degree < 30) degree = 30;
    if (degree > 150) degree = 150;
    const xComp = Math.cos(toRadians(degree));
    const yComp = Math.sin(toRadians(degree));
    const force = 0.005;
    // console.log(playerId, "xComp", xComp, "yComp", yComp);
    player.applyForce({ x: xComp * force, y: 0 });
  }
}

// given player count and screen width, calculate player's width and player x (starting from center of screen) with gap in between such that they all fit in screen width
function calculatePlayerSizeAndPosition(
  playerCount,
  index,
  screenWidth,
  playerCurrentWidth,
  gap = 50,
  emptySpaceOnEachSide = 100
) {
  const availableWidth = screenWidth - emptySpaceOnEachSide * 2;
  const totalWidth = playerCount * playerCurrentWidth + (playerCount - 1) * gap;
  if (totalWidth > availableWidth) {
    // scale down player's width
    const playerScale = availableWidth / totalWidth;
    const playerWidth = playerScale * playerCurrentWidth;
    const newTotalWidth =
      playerCount * playerWidth + (playerCount - 1) * (gap * playerScale);
    const playerPosX =
      emptySpaceOnEachSide +
      availableWidth / 2 -
      newTotalWidth / 2 +
      index * (playerWidth + gap * playerScale) +
      playerWidth / 2;
    return { playerScale, playerPosX };
  }
  // no need to scale down, just calculate x
  const playerPosX =
    emptySpaceOnEachSide +
    availableWidth / 2 -
    totalWidth / 2 +
    index * (playerCurrentWidth + gap) +
    playerCurrentWidth / 2;
  return { playerScale: 1, playerPosX };
}
