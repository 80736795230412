import BaseController from "./base";
import MovingAverage from "../../modules/movingAverage";
import Quaternion from "quaternion";
// require("common/modules/fulltilt");
let RADIAN = Math.PI / 180;
// helpers
var s = function (sel) {
  return document.querySelector(sel);
};
function radToDegree(radians) {
  var pi = Math.PI;
  return parseInt(radians * (180 / pi));
}
/*
function radToDegree(radians) {
  var pi = Math.PI;
  return parseInt(radians * (180 / pi));
}

function getRotationMatrix(alpha, beta, gamma) {
  var _x = beta ? beta * RADIAN : 0; // beta value
  var _y = gamma ? gamma * RADIAN : 0; // gamma value
  var _z = alpha ? alpha * RADIAN : 0; // alpha value

  var cX = Math.cos(_x);
  var cY = Math.cos(_y);
  var cZ = Math.cos(_z);
  var sX = Math.sin(_x);
  var sY = Math.sin(_y);
  var sZ = Math.sin(_z);

  //
  // ZXY rotation matrix construction.
  //

  var m11 = cZ * cY - sZ * sX * sY;
  var m12 = -cX * sZ;
  var m13 = cY * sZ * sX + cZ * sY;

  var m21 = cY * sZ + cZ * sX * sY;
  var m22 = cZ * cX;
  var m23 = sZ * sY - cZ * cY * sX;

  var m31 = -cX * sY;
  var m32 = sX;
  var m33 = cX * cY;

  return [m11, m12, m13, m21, m22, m23, m31, m32, m33];
}
*/
export default class GyroJoystick extends BaseController {
  constructor(config) {
    super();

    config = config || {};
    this.config = config;
    this.overlayVisible = true;
    this.className = config.className;
    this.lastGyroData = { r: -90 };
    this.fulltilt = null;
    this.rotationAvg = MovingAverage(10, -90);
    // this.quaternion = new Quaternion();

    const elem = s(".gyro-joystick-zone");

    if (this.className) {
      elem.classList.add(this.className);
    }

    elem.classList.add("active");
    this.handleBtnStart = this.handleBtnStart.bind(this);
    this.handleOrientation = this.handleOrientation.bind(this);
    this.handleAcceleration = this.handleAcceleration.bind(this);

    // assume we already have permission.
    this.initSensor = this.initSensor(this);
  }

  async initSensor() {
    // var promise = new window.FULLTILT.getDeviceOrientation({ type: "world" });

    // this.fulltilt = await promise;

    // window.addEventListener("deviceorientation", this.handleOrientation);
    window.addEventListener("devicemotion", this.handleAcceleration);
  }

  on(key, callback) {
    super.on(key, callback);
    if (key === "gyro") {
      this.emit("gyro", this.lastGyroData);
    }
  }

  handleBtnStart(e) {
    if (e) e.preventDefault();
    if (typeof DeviceMotionEvent.requestPermission === "function") {
      // Handle iOS 13+ devices.
      DeviceMotionEvent.requestPermission()
        .then((state) => {
          if (state === "granted") {
            this.initSensor();
          } else {
            console.error("Request to access the orientation was rejected");
          }
        })
        .catch(console.error);
    } else {
      // Handle regular non iOS 13+ devices.
      this.initSensor();
    }
  }

  handleOrientation(event) {
    var euler = this.fulltilt.getScreenAdjustedEuler();
    // Don't update if we are close to encountering gimbal lock
    // if (euler.beta > 85 && euler.beta < 95) {
    //   return;
    // }

    if (isNaN(euler.beta) || isNaN(euler.gamma)) return;
    // convert beta and gamma to rotation degree
    const beta = euler.beta / 90;
    const gamma = euler.gamma / 90;

    const rotation = (Math.atan2(gamma, beta) * 180) / Math.PI;
    const rotationNative =
      (Math.atan2(event.gamma, event.beta) * 180) / Math.PI;
    // console.log("fulltilt", euler.alpha, euler.beta, euler.gamma);
    let newData = { ...this.lastGyroData, r: parseInt(rotation) };
    // let newData = {...this.lastGyroData, a: parseInt(euler.alpha), b: parseInt(euler.beta), g: parseInt(euler.gamma)};
    // console.log(getRotationMatrix(event.alpha, event.beta, event.gamma));
    // console.log("orientation", event.alpha, event.beta, event.gamma);
    const quaternion = Quaternion.fromEuler(
      event.alpha * RADIAN,
      event.beta * RADIAN,
      event.gamma * RADIAN
    );
    // let vector3 = quaternion.rotateVector([1, 0, 0]);
    let copilotRotation = this.getScreenOrientation(quaternion);
    let euler2 = quaternion.toEuler();
    // console.log(vector3);
    // // let newData = {...this.lastGyroData, a: parseInt(event.alpha), b: parseInt(event.beta), g: parseInt(event.gamma)};
    // // let newData = {...this.lastGyroData, eu: {r: radToDegree(euler.roll), p: radToDegree(euler.pitch), y: radToDegree(euler.yaw)}}
    // let newData = {...this.lastGyroData, q: vector3}
    if (JSON.stringify(newData) !== JSON.stringify(this.lastGyroData)) {
      this.lastGyroData = newData;
      this.emit("gyro", newData);
    }

    const rollDegrees = radToDegree(euler2.roll);
    const yawDegrees = radToDegree(euler2.yaw);
    let rotationDegrees;

    // let gravityX = Math.cos(euler2.pitch) * Math.sin(euler2.roll);
    // let gravityY = Math.sin(euler2.pitch);

    if (rollDegrees < 0 && yawDegrees < 0) {
      // This is the condition where simply
      // summing yawDegrees with rollDegrees
      // wouldn't work.
      // Suppose yaw = -177 and pitch = -165.
      // rotationDegrees would then be -342,
      // making your rotation angle jump all
      // the way around the circle.
      rotationDegrees = 360 - -1 * (yawDegrees + rollDegrees);
    } else {
      rotationDegrees = yawDegrees + rollDegrees;
    }

    s(".gyro-joystick-zone .debug").innerHTML = `ft: ${parseInt(
      rotation
    )}\tnative:${parseInt(rotationNative)}<br/> a: ${parseInt(
      event.alpha
    )}\tb: ${parseInt(event.beta)}\tg: ${parseInt(event.gamma)}
    <br/>eu: r:${parseInt(radToDegree(euler2.roll))}\tp:${parseInt(
      radToDegree(euler2.pitch)
    )}\ty:${parseInt(radToDegree(euler2.yaw))}
    <br/>rot: ${parseInt(rotationDegrees)}
    <br/>copilot: ${parseInt(copilotRotation)}
    `;
  }

  handleAcceleration(event) {
    const { x, y, z } = event.accelerationIncludingGravity;
    const gravY = y / 9.80665; //gravity
    const gravX = x / 9.80665;
    if (isNaN(gravY)) return;
    const radiantsRes = Math.atan2(gravY * -1, gravX);
    const degreesRes = radToDegree(radiantsRes);
    const degree2 = (degreesRes - 360 * Math.floor(degreesRes / 360)) * -1;
    this.rotationAvg.push(parseInt(degree2));
    console.log(degreesRes.toFixed(2), radiantsRes.toFixed(10));
    let newData = { ...this.lastGyroData, r: parseInt(this.rotationAvg.get()) };
    if (JSON.stringify(newData) !== JSON.stringify(this.lastGyroData)) {
      this.lastGyroData = newData;
      this.emit("gyro", newData);
    }
    s(".gyro-joystick-zone .debug").innerHTML = `x: ${parseInt(
      x
    )}\ty: ${parseInt(y)}\tz: ${parseInt(z)}<br/>degrees: ${parseInt(
      degree2
    )} <br/>rotAvg: ${this.rotationAvg.get()} <br/>gravY: ${gravY}`;
  }

  destroy() {
    this.emit("destroy");
    this.removeAllListeners();

    if (this.className) {
      s(".gyro-joystick-zone").classList.remove(this.className);
    }

    s(".gyro-joystick-zone").classList.remove("active");

    window.removeEventListener("deviceorientation", this.handleOrientation);
    window.removeEventListener("devicemotion", this.handleAcceleration);
  }
}
