import Game from "common/components/GameRenderer";
import { GameScene } from "./control_game";
import BackgroundGame from "common/components/BackgroundGame";
import ControllerHUD from "common/components/ControllerHUD";
import { useMyPlayerState } from "common/hooks/multiplayer";
import Multiplayer from "common/modules/multiplayer";

export const Control = () => {
  const myProfile = Multiplayer().getMyPlayerState().getState("profile");
  const myScore = useMyPlayerState("score");
  return (
    <div className="control-container">
      <BackgroundGame
        style={{
          background: `linear-gradient(215.23deg, #D5FAFF 8.03%, rgba(213, 250, 255, 0) 131.56%)`,
        }}
      ></BackgroundGame>
      <ControllerHUD
        breadcrumbs={["Falling Beans"]}
        backgroundStyle={{
          background: `linear-gradient(215.23deg, #D5FAFF 8.03%, rgba(213, 250, 255, 0) 131.56%)`,
        }}
        avatarText={myScore || 0}
        avatarStyle={{
          background: `${myProfile?.color || "#000000"}`,
        }}
      />
      <Game
        key="game"
        fullScreen
        controllerMode
        scenes={[GameScene]}
        physics={{
          default: "matter",
          matter: {
            gravity: {
              y: 0.2,
              x: 0,
            },
            debug: false,
          },
        }}
      />
    </div>
  );
};

export default Control;
