import React, { useEffect } from "react";
import $ from "jquery";

import imgFastest from "./thumbnails/fastest.png";
import imgArcade from "./thumbnails/arcade.png";
import imgSmash from "./thumbnails/smash.png";
import imgSnap from "./thumbnails/snap.png";
import imgChill from "./thumbnails/chill.png";
import imgRainbow from "./thumbnails/rainbow.png";
import imgRPS from "./thumbnails/rps.png";
import imgChicken from "./thumbnails/chicken.png";
import imgDogfight from "./thumbnails/dogfight.png";

const THUMBS = [
  imgFastest,
  imgArcade,
  imgSmash,
  imgSnap,
  imgChill,
  imgRainbow,
  imgRPS,
  imgChicken,
  imgDogfight,
];

// from https://codepen.io/wschern/pen/gMXwMa
function initSmoothScrolling(container, animation) {
  /*
   * @param {String} container Class or ID of the animation container
   * @param {String} animation Name of the animation, e.g. smoothscroll
   */
  var sliderWidth = 0;
  var animationWidth = 0;
  //  var sliderHeight = $('>div>div:first-of-type',container).outerHeight(false);

  $(">div>div", container).each(function () {
    animationWidth += $(this).outerWidth(false);
  });

  // detect number of visible slides
  var slidesVisible =
    $(container).width() /
    $(">div>div:first-of-type", container).outerWidth(false);
  slidesVisible = Math.ceil(slidesVisible);

  // count slides to determine animation speed
  var slidesNumber = $(">div>div", container).length;
  var speed = slidesNumber * 8;

  // append the tail
  $(">div>div", container)
    .slice(0, slidesVisible)
    .clone()
    .appendTo($(">div", container));

  // Detect the slider width with appended tail
  $(">div>div", container).each(function () {
    sliderWidth += $(this).outerWidth(false);
  });

  // Insert styles to html
  $(
    "<style type='text/css'>@keyframes " +
      animation +
      " { 0% { margin-left: 0px; } 100% { margin-left: -" +
      animationWidth +
      "px; } } " +
      $(">div>div:first-of-type", container).selector +
      " { -webkit-animation: " +
      animation +
      " " +
      speed +
      "s linear infinite; -moz-animation: " +
      animation +
      " " +
      speed +
      "s linear infinite; -ms-animation: " +
      animation +
      " " +
      speed +
      "s linear infinite; -o-animation: " +
      animation +
      " " +
      speed +
      "s linear infinite; animation: " +
      animation +
      " " +
      speed +
      "s linear infinite; }</style>"
  ).appendTo("head");

  // set slider dimensions
  //  $('>div',container).css({'width':sliderWidth});

  // restart the animation (e.g. for safari & ie)
  //  var cl = $(container).attr("class");
  //  $(container).removeClass(cl).animate({'nothing':null}, 1, function () {
  //    $(this).addClass(cl);
  //  });
}

export default function Carousel({ options }) {
  useEffect(() => {
    document.querySelector(".scrolling-carousal").classList.add("visible");
    // initSmoothScrolling('.scrolling-carousal', 'smoothscroll')
    setTimeout(() => {
      initSmoothScrolling(".scrolling-carousal", "smoothscroll");
    }, 1000);
  }, []);

  return (
    <div className="scrolling-carousal">
      <div className="scrolling-carousal-inner">
        {THUMBS.map((img, i) => (
          <div key={i} className="scrolling-carousal-item">
            <img src={img} alt="thumbnail" />
          </div>
        ))}
        {/* {Playlists.filter(
          (p) => !p.dev && p.id !== "demo" && p.id !== "discord"
        ).map((playlist, idx) => {
          // console.log("playlist:", playlist);
          return (
            <PlaylistThumbnailContainer
              key={"playlist-slideshow-" + idx}
              className="embla__slide"
              playlist={playlist}
              playlistContainerStyles={{
                height: "auto",
                width: "27vw",
                padding: "0rem 0.3rem 0rem 0.9rem",
              }}
              imgStyles={{
                height: "auto",
                width: "100%",
              }}
            ></PlaylistThumbnailContainer>
          );
        })} */}
      </div>
    </div>
  );
}
// export default function initSmoothScrolling(container,animation){
//   /*
//    * @param {String} container Class or ID of the animation container
//    * @param {String} animation Name of the animation, e.g. smoothscroll
//    */
//    var sliderWidth = 0;
//    var animationWidth = 0;
//    var sliderHeight = container.querySelector('>div>div:first-child').offsetHeight;

//    Array.from(container.querySelectorAll('>div>div')).forEach((el)=>{
//      animationWidth += el.offsetWidth;
//    });

//    // detect number of visible slides
//    var slidesVisible = container.getBoundingClientRect().width / container.querySelector('>div>div:first-of-type').offsetWidth;
//    slidesVisible = Math.ceil(slidesVisible);

//    // count slides to determine animation speed
//    var slidesNumber = container.querySelectorAll('>div>div').length;
//    var speed = slidesNumber*2;

//   // append the tail
//   container.querySelector('>div').appendChild(
//     Array.from(container.querySelectorAll('>div>div')).slice(0,slidesVisible).cloneNode(true)
//   );
//   // Array.from(container.querySelectorAll('>div>div')).slice(0,slidesVisible).cloneNode(true).appendTo();

//    // Detect the slider width with appended tail
//    Array.from(container.querySelectorAll('>div>div')).forEach((el)=>{
//      sliderWidth += el.offsetWidth;
//    });

//    // set slider dimensions
//    container.querySelector('>div').style.width = sliderWidth + 'px';
//     container.querySelector('>div').style.height = sliderHeight + 'px';
//   //  $('>div',container).css({'width':sliderWidth,'height':sliderHeight});

//  // Insert styles to html
//    var style = document.createElement('style');
//    style.setAttribute('type', 'text/css');
//    style.innerHTML = "@keyframes "+animation+" { 0% { margin-left: 0px; } 100% { margin-left: -"+animationWidth+"px; } } "+container.getAttribute("class") + ">div>div:first-child" +" { -webkit-animation: "+animation+" "+speed+"s linear infinite; -moz-animation: "+animation+" "+speed+"s linear infinite; -ms-animation: "+animation+" "+speed+"s linear infinite; -o-animation: "+animation+" "+speed+"s linear infinite; animation: "+animation+" "+speed+"s linear infinite; }";
//    document.getElementsByTagName('head')[0].appendChild(style);

//    // restart the animation (e.g. for safari & ie)
//   //  var cl = container.getAttribute("class");
//   //  $(container).removeClass(cl).animate({'nothing':null}, 1, function () {
//   //    $(this).addClass(cl);
//   //  });
//  }
