import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./style.css";
var tintColors = ["one", "two", "three", "four"];

function randRange(minNum, maxNum, float) {
  const num = Math.random() * (maxNum - minNum + 1);
  if (float) {
    return num + minNum;
  } else {
    return Math.floor(num) + minNum;
  }
}

export default function Rain({ numDrops, className, dark, containerStyles={}}) {
  const [rainVisible, setRainVisible] = useState(true);
  useEffect(() => {
    window.onresize = () => {
      setRainVisible(false);
      setTimeout(() => {
        setRainVisible(true);
      }, 100);
    };
  }, []);
  const dropArr = new Array(numDrops || 100).fill(0);
  if (!rainVisible) return false;
  return (
    <AnimatePresence initial={true}>
      <div
        className={
          "rain-container " + (className || "") + (dark ? " dark" : "")
        }

        style={{...containerStyles}}
      >
        {dropArr.map((i, key) => {
          return (
            <motion.div
              key={key}
              className={
                "drop " +
                tintColors[Math.floor(Math.random() * tintColors.length)]
              }
              initial={{
                width: randRange(3, 13),
                left: randRange(0, window.innerWidth),
                top: -200,
              }}
              animate={{ top: window.innerHeight }}
              transition={{
                ease: "linear",
                repeat: Infinity,
                duration: randRange(1, 2, true),
                delay: randRange(0, 3, true),
              }}
            />
          );
        })}
      </div>
    </AnimatePresence>
  );
}
