import Phaser from "phaser";

var Bullet = new Phaser.Class({
  Extends: Phaser.GameObjects.Image,

  initialize: function Bullet(scene) {
    Phaser.GameObjects.Image.call(this, scene, 0, 0, "bullet");
    this.setScale(scene.adjustByScale(0.75));
    this.incX = 0;
    this.incY = 0;
    this.lifespan = 0;
    this.scene = scene;

    this.speed = Phaser.Math.GetSpeed(scene.adjustByScale(900), 1);
    this.worldWrapper = undefined;
  },

  setWrapper: function (wrapper) {
    this.worldWrapper = wrapper;
  },

  fire: function (x, y, angle) {
    this.setActive(true);
    this.setVisible(true);
    this.body.setVelocity(0);

    //  Bullets fire from the middle of the screen to the given x/y
    this.setPosition(x, y);
    this.setRotation(angle);

    this.incX = Math.cos(angle);
    this.incY = Math.sin(angle);

    this.body.setEnable(true);

    this.lifespan = 400;
  },

  update: function (time, delta) {
    this.lifespan -= delta;

    this.x += this.incX * (this.speed * delta);
    this.y += this.incY * (this.speed * delta);

    if (this.worldWrapper) {
      this.worldWrapper.checkWrap(this);
    }

    if (this.lifespan <= 0) {
      this.destroy();
    }
  },

  destroy: function () {
    this.setActive(false);
    this.setVisible(false);
    this.body.setEnable(false);
  },
});

export default Bullet;
