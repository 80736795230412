import React, { useEffect, useState } from "react";
import "./style.css";
import Multiplayer from "common/modules/multiplayer";
import {
  usePlayersList,
  useGlobalState,
  useGlobalRoundState,
  usePlayerState,
} from "common/hooks/multiplayer";

import GamesMenu from "common/components/GamesMenu";
import BackgroundGame from "common/components/BackgroundGame";
import Control from "./components/control";
import Viewer from "./components/viewer";

import {
  GenericStartScreen,
  StartScreenOverlay,
  StartScreenButtons,
} from "common/components/StartScreen";
import { FullscreenCountdown } from "common/components/Countdown";

import { useForceUpdate } from "common/hooks/useForceUpdate";

function resetGame() {
  const multiplayer = Multiplayer();
  multiplayer.setRoundState("live", undefined);
  multiplayer.setRoundState("winner", undefined);
  multiplayer.setRoundState("done", undefined);
  const players = multiplayer.getPlayers();
  Object.keys(players).forEach((playerId) => {
    const playerState = players[playerId];
    playerState.setState("score", 0);
  });
}

function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

function randRange(minNum, maxNum) {
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}

function bot() {
  const multiplayer = Multiplayer();
  const myState = multiplayer.getMyPlayerState();
  const interval = setInterval(() => {
    if (multiplayer.getRoundState("winner")) {
      clearInterval(interval);
      return;
    }
    if (multiplayer.getRoundState("live")) {
      const curScore = myState.getState("score");
      myState.setState("score", curScore + 1, true);
    }
  }, randRange(3000, 5000));
}

export default function GamePage({ gameInfo, endRound }) {
  const multiplayer = Multiplayer();
  const forceUpdate = useForceUpdate();
  const myState = multiplayer.getMyPlayerState();
  const players = usePlayersList();
  const [countdown, setCountdown] = useState(false);
  const winner = useGlobalRoundState("winner");
  const gameIsLive = useGlobalRoundState("live");
  const startGame = useGlobalRoundState("startGame");

  const done = useGlobalRoundState("done");

  useEffect(() => {
    if (done) {
      const wonPlayers = players
        .map((playerState) => {
          const score = playerState.getState("score");
          let wonPlayer = { score: 0, name: "No one", id: -1 };
          if (score > wonPlayer.score) {
            wonPlayer.score = score;
            wonPlayer.name = playerState.getState("profile").name;
            wonPlayer.id = playerState.id;
            wonPlayer.color = playerState.getState("profile").color;
          }
          return wonPlayer;
        })
        .sort((a, b) => b.score - a.score);

      // for display
      multiplayer.setRoundState("winner", wonPlayers[0]);

      // find the top winners
      determineWin(
        wonPlayers.filter((w) => w.score > 0).map((w) => w.id),
        players,
        [100, 75, 50],
        {
          4: 3,
          3: 2,
          2: 1,
        },
        (winners) => {
          console.log("YAY! Game ended. Winners are:", winners);

          // Ends the round!
          endRound(winners);
        }
      );
    }
  }, [done]);

  function determineWin(
    winnersSoFar,
    players,
    scoringArray,
    breakpoints,
    setState = () => {},
    forceEval = false
  ) {
    // check for force draw
    if (winnersSoFar === -1 || winnersSoFar.includes(-1)) {
      setState(-1);
      return -1;
    }

    console.log("winnersSoFar in determineWin:", winnersSoFar);

    if (winnersSoFar.length && players.length) {
      var limitingFactor = -1;
      var _sortedBreakpointKeys = Object.keys(breakpoints).sort();

      _sortedBreakpointKeys.forEach((key) => {
        if (players.length >= key) {
          limitingFactor = breakpoints[key];
        }
      });

      console.log("limitingFactor before:", limitingFactor);

      // incase the winnersSoFar are less than the minimum break, it should break the entire
      // function except when forceEval is true. (for example, it could become true if the timer
      // has ended)
      if (limitingFactor === -1 || winnersSoFar.length < limitingFactor) {
        if (!forceEval) {
          return false;
        } else {
          // get the minimum value,
          limitingFactor = breakpoints[_sortedBreakpointKeys[0]];
        }
      }

      console.log(
        "winnersSoFar.length, scoringArray.length, limitingFactor, after:",
        winnersSoFar.length,
        scoringArray.length,
        limitingFactor
      );

      let winnersArray = [];
      //            one of these three conditions will become the limiting factor
      for (
        let i = 0;
        i < winnersSoFar.length &&
        i < scoringArray.length &&
        i < limitingFactor;
        i++
      ) {
        winnersArray.push({
          id: winnersSoFar[i],
          score: scoringArray[i],
        });
      }

      setState(winnersArray);

      return winnersArray;
    }
    return false;
  }

  // usePlayerState((playerId, changedKey, playerState, playerObj) => {
  //   forceUpdate();
  //   if (multiplayer.isSpectator) {
  //     if (multiplayer.getRoundState("done")) {
  //       if (!multiplayer.getRoundState("winner")) {
  //       }
  //       forceUpdate();
  //     }
  //   }
  // });

  async function stopCountdown() {
    // start countdown.
    // setCountdown(3);
    // await sleep(1500);
    // setCountdown(2);
    // await sleep(1500);
    // setCountdown(1);
    // await sleep(1500);
    multiplayer.setRoundState("live", true);
    setCountdown(false);
  }
  // reset game state if host requests it (and the first time)
  useEffect(() => {
    if (multiplayer.isSpectator) {
      resetGame();
      setCountdown(false);
      // startCountdown();
    } else {
      if (window.ISBOT) bot();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!startGame) return;

    if (multiplayer.isSpectator) {
      // resetGame();
      // startCountdown();
      setCountdown(true);
    } else {
      if (window.ISBOT) bot();
    }
  }, [startGame]);

  return (
    <>
      <GamesMenu
        key="gamesmenu"
        gameInfo={gameInfo}
        hideRoomCode
        showRestart={false}
      />

      {multiplayer.isSpectator ? ( // tv/laptop screen
        <>
          {countdown ? (
            <FullscreenCountdown
              key={gameIsLive || "fullscreen-countdown"}
              startingFrom={4}
              textOverrides={["Ready"]}
              onCountdownChange={setCountdown}
              onFinish={stopCountdown}
              countdownContainerStyles={{
                fontFamily: "Lilita One",
                fontWeight: 400,
                textTransform: "none",
              }}
            />
          ) : (
            <></>
          )}
          <Viewer
            players={players}
            multiplayer={multiplayer}
            gameIsLive={gameIsLive}
            showText={!countdown}
          />
        </>
      ) : (
        <>
          <GenericStartScreen
            hide={startGame || gameIsLive}
            showButtons={multiplayer.isHost}
            multiplayer={multiplayer}
            textProps={{
              className: "banana-text",
              style: {
                display: "flex",
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
            onStart={() => {
              multiplayer.setRoundState("playInstructions", false);
              multiplayer.setRoundState("startGame", true);
            }}
          ></GenericStartScreen>

          {/* // player screens with falling m&ms */}
          <Control
            currentPlayer={myState}
            playerColor={myState.state.profile.color}
            players={players}
            gameIsLive={gameIsLive}
            winner={winner}
            colorCollected={(color) => {
              var lastScore = myState.getState("score") || 0;
              if (
                multiplayer.getRoundState("live") &&
                !multiplayer.getRoundState("done")
              ) {
                if (color === myState.state.profile.color) {
                  myState.setState("score", lastScore + 1, true);
                  return { scoreText: "+1", t: Date.now() };
                } else {
                  myState.setState("score", lastScore - 1, true);
                  return { scoreText: "-1", t: Date.now() };
                }
              }
            }}
          />
        </>
      )}
    </>
  );
}
