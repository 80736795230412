import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BackgroundElements from "common/components/BackgroundElements";
import NativeMethods from "common/modules/NativeMethods";
import JoinRoomCodeModal from "common/components/JoinRoomModal";
import {
  CreateRoomInviteCodeModal,
  JoinRoomEmailModal,
} from "common/components/InviteCodeModal";
import RoomCodeWithQR from "common/components/RoomCodeWithQR";
import Modal from "common/components/Modal";
import CartoonDialogBox from "common/components/CartoonDialogBox";
import BrowserStorage from "common/modules/localStorageWrapper";
import Analytics from "common/analytics";
import "./Home.css";

import lockImg from "common/imgs/lock.png";
import sadFaceImg from "common/imgs/sad-face.gif";
import thinkingFaceImg from "common/imgs/thinking-face.webp";
import awkwardSilenceFaceImg from "common/imgs/awkward-face.gif";
import wowFaceImg from "common/imgs/wow-face.gif";
import deadFaceImg from "common/imgs/dead-face.gif";
import awkwardFaceImg from "common/imgs/awkward-silence-face.gif";

import { setUserProperties } from "common/modules/db";
const URLHash = require("common/modules/urlhash");

const isController = NativeMethods.isController();

function Home() {
  const history = useHistory();
  const [joinRoomModalVisible, setJoinRoomModalVisible] = useState(false);
  const [inviteCodeModalVisible, setInviteCodeModalVisible] = useState(false);
  const [joinRoomSendEmailModalVisible, setJoinRoomSendEmailModalVisible] =
    useState(false);

  const [errorPopupVisible, setErrorPopupVisible] = useState(false);
  const [errorCode, setErrorCode] = useState(0);

  const [joinRoomCode, setJoinRoomCode] = useState(null);

  const [canCreateRoom, setCanCreateRoom] = useState(
    BrowserStorage.get("invitecode")
  );

  const [isDemoMode, setIsDemoMode] = useState(false);
  // const [joinRoomMode, setjoinRoomMode] = useState(false);
  const [joinError, setJoinError] = useState(false);

  const setMode = (type) => {
    if (type === "demo") {
      setCanCreateRoom(true);
      window.isDemoMode = true;
      Analytics.people.set({
        invitetoken: "demohost",
      });
      setIsDemoMode(true);
    } else if (type === "dev") {
      BrowserStorage.set("DEV", true);
      BrowserStorage.set("invitecode", "dev");
      window.location.hash = "";
      window.location.reload();
    }
  };

  const inviteemail = BrowserStorage.get("inviteemail");

  // if there is a room code,
  // and the user has already provided inviteemail,
  // join the room.
  useEffect(() => {
    console.log("joinRoomCode in useeffect:", joinRoomCode);
    const timeoutId = setTimeout(() => {
      if (joinRoomCode) {
        if (BrowserStorage.get("inviteemail")) {
          window.multiplayer.joinRoom(joinRoomCode);
        } else {
          setJoinRoomSendEmailModalVisible(true);
        }
      }
    }, 1500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [joinRoomCode, inviteemail]);

  useEffect(() => {
    const spectate = URLHash.getUrlHashParameter("spectate");
    if (spectate) {
      window.multiplayer.spectateRoom(spectate);
    }
    const demoMode = URLHash.getUrlHashParameter("demo");
    if (demoMode) {
      setMode("demo");
    }

    const devMode = URLHash.getUrlHashParameter("dev");
    if (devMode) {
      setMode("dev");
    }

    const onHashChange = () => {
      const disconnectedErrorCode = URLHash.getUrlHashParameter("disconnected");
      if (disconnectedErrorCode) {
        setErrorPopupVisible(true);
        setErrorCode(disconnectedErrorCode);

        // clear the hash param: https://stackoverflow.com/questions/1397329/how-to-remove-the-hash-from-window-location-url-with-javascript-without-page-r
        setTimeout(() => {
          window.location.href = window.location.href.split("#")[0] + "#reset";
        }, 1000);
      }
      // else {
      //   setErrorPopupVisible(false);
      //   setErrorCode(0);
      // }
    };

    // Check if hash changes: https://stackoverflow.com/questions/6390341/how-to-detect-if-url-has-changed-after-hash-in-javascript
    window.addEventListener("hashchange", onHashChange);

    const joinRoom = URLHash.getUrlHashParameter("join");
    console.log("joinRoom:", joinRoom);
    if (joinRoom) {
      console.log("join room", joinRoom);
      if (isController) {
        // if (BrowserStorage.get("inviteemail")) {
        window.multiplayer.joinRoom(joinRoom);
        // } else {
        //   setJoinRoomCode(joinRoom);
        //   setJoinRoomSendEmailModalVisible(true);
        // }
      } else {
        window.multiplayer.spectateRoom(joinRoom);
      }
    }

    if (BrowserStorage.get("invitecode")) {
      setUserProperties({ invitecode: BrowserStorage.get("invitecode") });
    }

    NativeMethods.getInvocationURL().then((url) => {
      var lastRoomConnectionAttempted = BrowserStorage.get(
        "lastRoomConnectionAttempted"
      );
      var lastRoomConnectionAttemptedTime = parseInt(
        BrowserStorage.get("lastRoomConnectionAttemptedTime") || 0
      );
      console.log(
        "invocation url ",
        url,
        Date.now() - lastRoomConnectionAttemptedTime
      );
      var urlPath;
      try {
        urlPath = new URL(url).pathname;
        urlPath = urlPath.substring(urlPath.lastIndexOf("/") + 1);
        if (urlPath.length === 5 && urlPath.toLowerCase().startsWith("r"))
          urlPath = urlPath.substring(1);
        if (urlPath.length === 4) {
          if (
            urlPath !== lastRoomConnectionAttempted ||
            Date.now() - lastRoomConnectionAttemptedTime > 30000
          ) {
            console.log(
              "connecting to ",
              urlPath,
              lastRoomConnectionAttempted,
              urlPath !== lastRoomConnectionAttempted,
              Date.now() - lastRoomConnectionAttemptedTime > 30000
            );
            BrowserStorage.set("lastRoomConnectionAttemptedTime", Date.now());
            BrowserStorage.set("lastRoomConnectionAttempted", urlPath);
            window.multiplayer.joinRoom(urlPath);
            console.log("setting joinRoomCode to urlPath");
            // setJoinRoomCode(urlPath);
            NativeMethods.disableShake();
          }
        } else if (urlPath === "demohost") {
          setMode("demo");
        }
      } catch (e) {
        console.log("invocation error:", e);
      }
    });

    if (BrowserStorage.get("invitecode") || BrowserStorage.get("inviteemail")) {
      Analytics.people.set({
        $email: BrowserStorage.get("inviteemail"),
        invitetoken: BrowserStorage.get("invitecode"),
      });
    }

    // cleanup listener
    return () => {
      if (onHashChange) window.removeEventListener("hashchange", onHashChange);
    };
  }, []);

  const JoinModal = (
    <JoinRoomCodeModal
      fullScreen={!isController}
      open={joinRoomModalVisible || !isController}
      error={joinError}
      onClose={isController ? () => setJoinRoomModalVisible(false) : false}
      onFinish={(val) => {
        setJoinRoomModalVisible(false);
        if (!isController) {
          console.log("spectateRoom", val);
          window.multiplayer.on("connected", () => {
            if (URLHash.getUrlHashParameter("demo")) {
              // TODO: this is ugly hack, fix it the right way.
              // somehow the state is not updated when done right after connecting.
              setTimeout(() => {
                console.log("connected", URLHash.getUrlHashParameter("demo"));
                window.multiplayer.setState("muted", true);
              }, 1000);
            }
          });
          window.multiplayer.spectateRoom(val);
        } else {
          console.log("joinRoom", val);
          window.multiplayer.joinRoom(val);
          NativeMethods.disableShake();
        }

        window.multiplayer.on("host_left", () => {
          setJoinRoomModalVisible(true);
          setJoinError(true);
        });
      }}
    />
  );

  if (!isController) {
    // a web visitor
    return (
      <div className="bootstrap-wrapper">
        <BackgroundElements extended />
        {isDemoMode && <RoomCodeWithQR zIndex={1000} demoMode />}
        <div className="container main-menu-web">
          <div className="top-bar row">
            <div className="col-md-2">
              <div className="logo"></div>
            </div>
            <div className="col-md-2 offset-md-8">
              <button
                className="button-black button-cast"
                onClick={() => {
                  setJoinRoomModalVisible(true);
                  // setjoinRoomMode("spectator");
                }}
              >
                Cast Room
              </button>
            </div>
          </div>
          <div className="row hero">
            <div className="col-lg-5">
              <h1>
                Ultimate couch
                <br />
                party games!
              </h1>
              <h3>
                Stop carrying board games and consoles to parties. Instantly
                play games with your Phones for party size of 2-12 players.
              </h3>
              <div className="input-group">
                <input type="email" placeholder="Email" />
                <button className="button-black" type="submit">
                  Join
                </button>
              </div>
            </div>
          </div>
        </div>
        {JoinModal}
      </div>
      // <div className="main-menu-web">

      // </div>
    );
  }

  return (
    // native app view
    <div className="main-menu">
      <ErrorModal
        open={errorPopupVisible}
        onClose={() => setErrorPopupVisible(false)}
        errorCode={errorCode}
        className="menu-welcome-popup"
      />

      <BackgroundElements />
      {/* <TransitionBubbles /> */}
      {/* <BackgroundRain numDrops={50} /> */}
      <div className="logo"></div>
      <br />
      {!canCreateRoom && (
        <button
          id="btn-createroom"
          className="button-black create-disabled"
          onClick={() => {
            setInviteCodeModalVisible(true);
          }}
        >
          <CartoonDialogBox
            containerStyles={{
              position: "absolute",
              top: "-39%",
              left: "0",
              right: "0",
              margin: "auto",
              paddingLeft: "5px 8px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: "1.2rem",
            }}
            text={"Early access"}
          >
            <img
              src={lockImg}
              style={{ marginRight: "0.5rem", height: "1.2rem" }}
              alt="Lock"
            />
          </CartoonDialogBox>
          Create Room
        </button>
      )}
      {canCreateRoom && (
        <button
          id="btn-createroom"
          className="button-black"
          onClick={() => {
            NativeMethods.disableShake();
            history.push("/profile", { create: true });
          }}
        >
          Create Room
        </button>
      )}
      <br />
      <button
        id="btn-joinroom"
        className="button-black"
        onClick={() => {
          setJoinRoomModalVisible(true);
        }}
      >
        Join Room
      </button>
      {/* <button className="button-black" onClick={()=>{
        setJoinRoomModalVisible(true);
        setjoinRoomMode("spectator");

      }}>Cast / Spectate Room</button> */}
      {JoinModal}
      <CreateRoomInviteCodeModal
        open={inviteCodeModalVisible}
        onClose={() => {
          setInviteCodeModalVisible(false);
          setCanCreateRoom(BrowserStorage.get("invitecode"));
          if (
            BrowserStorage.get("invitecode") ||
            BrowserStorage.get("inviteemail")
          ) {
            Analytics.people.set({
              $email: BrowserStorage.get("inviteemail"),
              invitetoken: BrowserStorage.get("invitecode"),
            });
          }
        }}
      />

      <JoinRoomEmailModal
        open={joinRoomSendEmailModalVisible}
        onClose={() => {
          setJoinRoomSendEmailModalVisible(false);

          console.log("joinRoomCode:", joinRoomCode);
          console.log(
            `BrowserStorage.get("inviteemail"):`,
            BrowserStorage.get("inviteemail")
          );

          // TODO: Is a bit hacky. there must be a better way.
          setTimeout(() => {
            if (BrowserStorage.get("inviteemail")) {
              if (joinRoomCode) {
                window.multiplayer.joinRoom(joinRoomCode);
              } else {
                setJoinRoomModalVisible(true);
              }
            }
          }, 1500);

          setCanCreateRoom(BrowserStorage.get("invitecode"));
          if (
            BrowserStorage.get("invitecode") ||
            BrowserStorage.get("inviteemail")
          ) {
            Analytics.people.set({
              $email: BrowserStorage.get("inviteemail"),
              invitetoken: BrowserStorage.get("invitecode"),
            });
          }
        }}
      />

      <br />
      {/* <button className="button-black" onClick={()=>{
        NativeMethods.rotatePortait();
      }}>Rotate Portrait</button>
      <button className="button-black" onClick={()=>{
        NativeMethods.showAirplay();
      }}>Rotate Landscape</button> */}

      {/* <button className="button-black" onClick={()=>{
        history.push("/profile")
      }}>Profile</button> */}
    </div>
  );
}

export default Home;

const ErrorModal = ({ errorCode, ...props }) => {
  const errors = {
    4003: {
      img_src: thinkingFaceImg,
      img_alt: "thinking",
      h3: `Seems like you're already playing.`,
      p: `Please check if you've already opened Playroom in another tab.`,
      dismissText: `Go Back`,
    },
    4002: {
      img_src: sadFaceImg,
      img_alt: "sad",
      h3: `Couldn't find this room.`,
      p: `Please recheck your code, or refresh your app/browser and try again.`,
      dismissText: `Go Back`,
    },
    4001: {
      img_src: awkwardSilenceFaceImg,
      img_alt: "awkward silence",
      h3: `The host has left the room.`,
      p: `Rage quit? Or just disconnected? You be the judge of that.`,
      dismissText: `Go Back`,
    },
    4000: {
      img_src: wowFaceImg,
      img_alt: "wow",
      h3: `Someone else owns this room.`,
      p: `Either you're super lucky, or you hit refresh at the wrong time.`,
      dismissText: `Go Back`,
    },
    1011: {
      img_src: deadFaceImg,
      img_alt: "dead",
      h3: `Your connection broke.`,
      p: `Please check your internet connection, and try again.`,
      dismissText: `Go Back`,
    },
    1000: {
      img_src: awkwardFaceImg,
      img_alt: "awkward",
      h3: `An unknown error occurred.`,
      p: `We don't know what went wrong, but you can try refreshing.`,
      dismissText: `Go Back`,
    },
  };

  // Taking 1000 as a default.
  const _currentError = errors[errorCode.toString()] || errors["1000"];

  return (
    <Modal {...props}>
      <img
        src={_currentError.img_src}
        alt={_currentError.img_alt}
        className="welcome-image"
      />
      <h3>{_currentError.h3}</h3>
      <p>{_currentError.p}</p>
      <div className="main-cta" onClick={props.onClose}>
        {_currentError.dismissText}
      </div>
    </Modal>
  );
};
