import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./style.css";

export default function BackgroundElements({ extended }) {
  return (
    <AnimatePresence initial={true}>
      <div className="background-elements-container">
        {extended ? <div className="triangle" /> : false}
        <motion.div
          className="bar-pink"
          animate={{ top: "-50px" }}
          transition={{
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "reverse",
            duration: 4,
          }}
        />
        {/* <div className="ball-yellow" style={{right: "-10px", top: "270px"}}></div> */}
        <motion.div
          className="ball-yellow"
          initial={{ right: "-10px", top: "270px" }}
          animate={{ right: "40px", top: "320px" }}
          transition={{
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "reverse",
            duration: 2,
          }}
        />
      </div>
    </AnimatePresence>
  );
}
