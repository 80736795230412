import CommonGameScene from "common/components/GameRenderer/gamescene";
import calculatePlayerSizeAndPosition from "common/utils/scalePlayers";
// import sfxJoin from "./assets/playerjoin.mp3";
// import sfxPush from "./assets/push.mp3";
// import car from "./assets/car.png";
import shapeJar from "./assets/jar.json";

import imgBg from "./assets/bg.png";
import imgJar from "./assets/jar.png";

// candies
import imgCane from "./assets/candies/cane.png";
import shapeCane from "./assets/candies/cane.json";
import imgBean from "./assets/candies/bean.png";
import shapeBean from "./assets/candies/bean.json";
import imgCorn from "./assets/candies/corn.png";
import shapeCorn from "./assets/candies/corn.json";
import imgLollypop from "./assets/candies/lollypop.png";
import shapeLollypop from "./assets/candies/lollypop.json";
import imgMint from "./assets/candies/mint.png";
import shapeMint from "./assets/candies/mint.json";
import imgRed from "./assets/candies/red.png";
import shapeRed from "./assets/candies/red.json";
import imgYellow from "./assets/candies/yellow.png";
import shapeYellow from "./assets/candies/yellow.json";

const CANDYSHAPES = {
  cane: shapeCane,
  bean: shapeBean,
  corn: shapeCorn,
  lollypop: shapeLollypop,
  mint: shapeMint,
  red: shapeRed,
  yellow: shapeYellow,
};

const CANDYSCALES = {
  bean: 0.5,
  mint: 0.4,
  red: 0.7,
  corn: 0.7,
};

class MyGame extends CommonGameScene {
  constructor(GameStart = true) {
    super();
    this.playerNameTags = {};
    this.playerAreaWidth = 100;
    this.playersSoFar = 0;
    this.playerCandyOrder = [
      { id: "corn", angle: 30 },
      { id: "lollypop", angle: 30 },
      { id: "mint", angle: 30 },
      { id: "red", angle: 30 },
      { id: "yellow", angle: 30 },
      { id: "bean", angle: 60 },
      { id: "corn", angle: 40 },
      { id: "lollypop", angle: 10 },
      { id: "mint", angle: 180 },
      { id: "red", angle: 180 },
      { id: "yellow", angle: 90 },
      { id: "bean", angle: 60 },
      { id: "bean", angle: 60 },
      { id: "bean", angle: 180 },
      { id: "bean", angle: 60 },
    ];
    this.player_index = 0;
    this.BigText = null;
    this.GameStart = GameStart;
    this.GameOver = false;
    this.GameSpeed = 1;
    this.allCandies = [];
  }

  preload() {
    //game assets
    this.load.image("bg", imgBg);
    this.load.image("jar", imgJar);

    this.load.image("cane", imgCane);
    this.load.image("bean", imgBean);
    this.load.image("corn", imgCorn);
    this.load.image("lollypop", imgLollypop);
    this.load.image("mint", imgMint);
    this.load.image("red", imgRed);
    this.load.image("yellow", imgYellow);
  }

  create() {
    super.create();
    // this.startCountdown();
    this.calculateJarPositions();
    //background image
    // let BG = this.add.image(0, 0, "bg").setOrigin(0, 0);
    // BG.setDisplaySize(this.cameras.main.width, this.cameras.main.height);

    var style = {
      font: "bold 60px Lilita One",
      fill: "#ffffff",
      align: "center",
      stroke: "#000000",
      strokeThickness: 8,
    };

    this.BigText = this.add.text(
      this.cameras.main.width / 2,
      300,
      "First to empty their candy jar wins!",
      style
    );
    this.BigText.setOrigin(0.5, 0.5);
  }

  calculateJarPositions() {
    if (!this.multiplayer.getRoundState("live")) return;

    const width = this.gameSize.width;
    const players = Object.values(this.multiplayer.getPlayers());
    const perPlayerWidth = width / players.length;
    this.playerAreaWidth = perPlayerWidth;
  }

  // startCountdown() {
  //   if (!this.GameStart) {
  //     //start count down animation
  //     this.time.addEvent({
  //       delay: 600,
  //       callback: () => {
  //         this.BigText.setText("3");
  //         // this.Zoom();
  //       },
  //       callbackScope: this,
  //       loop: false,
  //     });
  //     this.time.addEvent({
  //       delay: 1200,
  //       callback: () => {
  //         this.BigText.setText("2");
  //         // this.Zoom();
  //       },
  //       callbackScope: this,
  //       loop: false,
  //     });
  //     this.time.addEvent({
  //       delay: 1800,
  //       callback: () => {
  //         this.BigText.setText("1");
  //         // this.Zoom();
  //       },
  //       callbackScope: this,
  //       loop: false,
  //     });
  //     this.time.addEvent({
  //       delay: 2400,
  //       callback: () => {
  //         this.BigText.setText("Go!");
  //         // this.Zoom();
  //       },
  //       callbackScope: this,
  //       loop: false,
  //     });
  //     this.time.addEvent({
  //       delay: 3000,
  //       callback: () => {
  //         this.BigText.setText("");
  //         this.BigText.setScale(1);
  //         this.GameStart = true;
  //       },
  //       callbackScope: this,
  //       loop: false,
  //     });
  //   }
  // }

  addPlayerSprite(playerState, profile) {
    // console.log("addPlayerSprite",playerState, profile,this.players);
    console.log("addPlayerSprite , this.player_index", this.player_index);
    let { playerScale, playerPosX } = calculatePlayerSizeAndPosition(
      Object.keys(this.multiplayer.getPlayers()).length,
      this.playersSoFar++,
      this.gameSize.width,
      this.adjustByScale(190), //current width
      this.adjustByScale(
        450 / Object.keys(this.multiplayer.getPlayers()).length
      ), // gap
      this.adjustByScale(20) //empty space on sides
    );

    // const sprite = this.matter.add
    //   .sprite(0, 0, "jar", "", {
    //     label: "jar" + (this.player_index + 1).toString(),
    //   })

    const sprite = this.matter.add.sprite(0, 0, "jar", null, {
      isStatic: true,
      shape: shapeJar,
      label: "jar" + (this.player_index + 1).toString(),
    });

    // jar is taller than wider, and it can rotate in it's place.
    // So max height of jar can be the max player width.
    const height = playerScale * sprite.displayHeight - this.adjustByScale(150); // some gap too
    // const width = (sprite.displayWidth/sprite.displayHeight) * height;
    // sprite.
    sprite.setScale(height / sprite.displayHeight);

    sprite.setPosition(
      playerPosX,
      // this.playerAreaWidth * this.player_index + this.playerAreaWidth / 2,
      this.gameSize.height / 2
    );

    this.addPlayerCandies(sprite, playerState);
    this.player_index++;

    // sprite.setFrictionAir(0.1);
    // sprite.setMass(30);
    sprite.angle = 180;
    sprite.setFixedRotation();
    // sprite.setFrictionStatic(1);
    sprite.setName(playerState.id);
    // console.log(sprite)

    // add text for player name
    var style = {
      font: "29px Arial",
      fill: "#ffffff",
      wordWrap: true,
      align: "center",
      stroke: "#000000",
      strokeThickness: 8,
      /* backgroundColor: "#ffffff",
       padding: {
         left: 5,
         right: 5,
         top: 2,
         bottom: 2,
       },*/
    };

    // debugger;
    var text = this.add.text(
      sprite.x, //- sprite.displayWidth * 0.75,
      sprite.y + height / 2 + this.adjustByScale(50),
      profile.name,
      style
    );
    text.setOrigin(0.5, 0.5);

    sprite.setDataEnabled();
    sprite.data.set("player_name", profile.name);
    sprite.data.set("player_id", playerState.id);

    this.playerNameTags[playerState.id] = text;

    return sprite;
  }

  addPlayerCandies(playerJar, playerState) {
    let currentY =
      playerJar.y - playerJar.displayHeight / 2 + playerJar.scale * 100;
    this.playerCandyOrder.forEach((candyInfo, index) => {
      const sprite = this.matter.add.sprite(0, 0, candyInfo.id, null, {
        shape: CANDYSHAPES[candyInfo.id],
      });
      sprite.setDataEnabled();
      sprite.data.set("player_id", playerState.id);
      sprite.setScale(playerJar.scale * (CANDYSCALES[candyInfo.id] || 1));
      sprite.angle = candyInfo.angle;
      sprite.setPosition(playerJar.x, currentY + sprite.displayHeight / 2);
      this.allCandies.push(sprite);
    });
  }

  checkForWinner() {
    if (this.multiplayer.getRoundState("winner")) return;
    // check for out-of-bound candies
    this.allCandies.forEach((candy) => {
      if (candy.y > this.gameSize.height) {
        candy.data.set("dead", true);
      }
    });
    const playerIds = Object.keys(this.multiplayer.getPlayers());
    const playerIdsToCandyCount = {};
    playerIds.forEach((playerId) => {
      playerIdsToCandyCount[playerId] = 0;
    });
    this.allCandies.forEach((candy) => {
      if (!candy.data.get("dead")) {
        playerIdsToCandyCount[candy.data.get("player_id")]++;
      }
    });

    // check for winner
    const winnerId = Object.keys(playerIdsToCandyCount).find(
      (playerId) => playerIdsToCandyCount[playerId] === 0
    );
    if (winnerId) {
      console.log("winnerId", winnerId);
      this.BigText.setText(
        `${
          this.multiplayer.getPlayers()[winnerId].getState("profile").name
        } wins!`
      );
      this.multiplayer.setRoundState("winner", [{ id: winnerId, score: 100 }]);
      // this.multiplayer.addToWinLog("shakethejar", winnerId);
    }
  }

  update() {
    super.update();
    this.checkForWinner();
  }

  updateCommon(playerId, sprite, state) {
    if (!this.GameStart || this.GameOver) {
      return;
    }

    var desiredDegree = sprite.angle;

    if (state.inputState.gyro && state.inputState.gyro.r !== undefined) {
      // alpha orientation
      desiredDegree = 270 + parseInt(state.inputState.gyro.r || 0); //convert clockwise to counterclockwise which phaser uses (no idea how math works, but it does)
    }

    if (isNaN(desiredDegree)) {
      desiredDegree = sprite.angle;
    }

    if (
      sprite.angle !== desiredDegree &&
      this.multiplayer.getRoundState("live")
    ) {
      // console.log(
      //   Math.abs(sprite.angle - desiredDegree),
      //   sprite.angle,
      //   desiredDegree
      // );
      if (Math.abs(getHeadingDiff(sprite.angle, desiredDegree)) > 20) {
        // prevent sudden motion
        const dir = getLerpDirectionBetweenAngles(sprite.angle, desiredDegree);
        if (dir > 0) {
          sprite.angle += 10;
        } else if (dir < 0) {
          sprite.angle += -10;
        }
      } else {
        sprite.angle = desiredDegree;
      }
    }

    // this.playerNameTags[playerId].x = sprite.x - sprite.displayWidth * 0.75;
    // this.playerNameTags[playerId].y = sprite.y;
  }

  handlePlayerQuit(playerState) {
    if (this.playerNameTags[playerState.id]) {
      console.log("player quit");
      this.player_index--;
      this.playerNameTags[playerState.id].destroy();
      delete this.playerNameTags[playerState.id];
    }
  }
}

// // https://gamedev.stackexchange.com/questions/72348/how-do-i-lerp-between-values-that-loop-such-as-hue-or-rotation
function getLerpDirectionBetweenAngles(startAngle, endAngle) {
  var MAX_ANGLE = 360.0;
  function normalizeAngle(angle) {
    while (angle < 0) angle += MAX_ANGLE;
    while (angle >= MAX_ANGLE) angle -= MAX_ANGLE;
    return angle;
  }

  var distanceForward = 0.0; // Clockwise
  var distanceBackward = 0.0; // Counter-Clockwise

  // Calculate both distances, forward and backward:
  distanceForward = endAngle - startAngle;
  distanceBackward = startAngle - endAngle;

  if (normalizeAngle(distanceForward) < normalizeAngle(distanceBackward)) {
    // Adjust for 360/0 degree wrap
    if (endAngle < startAngle) endAngle += MAX_ANGLE; // Will be above 360
    return 1;
  }
  // Backward? (normalized to 285)
  else {
    // Adjust for 360/0 degree wrap
    if (endAngle > startAngle) endAngle -= MAX_ANGLE; // Will be below 0
    return -1;
  }
}

function getHeadingDiff(_Heading1, _Heading2) {
  return ((_Heading2 - _Heading1 + 540) % 360) - 180;
}

export default MyGame;
