export default class AvatarCreator {
  constructor(avatarSrc, canvas, dontResizeCanvas) {
    this.avatarSrc = avatarSrc;
    this.avatarLoaded = false;
    this.canvas = canvas || document.createElement("canvas");
    this.backCanvas = document.createElement("canvas");
    this.dontResizeCanvas = dontResizeCanvas;
    this.img = false;
  }

  loadAvatar() {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.onload = () => {
        if (!this.dontResizeCanvas) {
          this.canvas.width = img.width;
          this.canvas.height = img.height;
        }
        this.backCanvas.width = this.canvas.width;
        this.backCanvas.height = this.canvas.height;
        resolve();
      };
      img.onerror = (e) => {
        console.error(e);
        reject(e);
      };
      this.img = img;
      img.src = this.avatarSrc;
    });
  }

  clear() {
    this.canvas
      .getContext("2d")
      .clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  // inspired by https://stackoverflow.com/questions/28545747/fill-image-with-texture-pattern
  async fillColor(color) {
    if (!this.avatarLoaded) await this.loadAvatar();
    var ctx = this.canvas.getContext("2d");
    console.log("fillColor", color);
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this._prepareTransparentImage();
    ctx.drawImage(this.backCanvas, 0, 0, this.canvas.width, this.canvas.height);

    ctx.globalCompositeOperation = "destination-in";
    ctx.drawImage(this.img, 0, 0, this.canvas.width, this.canvas.height);

    // // default
    ctx.globalCompositeOperation = "source-over";
  }

  async fillRadialGradient(x0, y0, r0, x1, y1, r1, color0, color1) {
    var ctx = this.canvas.getContext("2d");
    var grd = ctx.createRadialGradient(x0, y0, r0, x1, y1, r1);
    grd.addColorStop(0, color0);
    grd.addColorStop(1, color1);
    return this.fillColor(grd);
  }

  _prepareTransparentImage(chromeColor, tolerance) {
    chromeColor = chromeColor || [255, 0, 255];
    tolerance = tolerance || 50;
    var ctx = this.backCanvas.getContext("2d");
    ctx.drawImage(this.img, 0, 0, this.canvas.width, this.canvas.height);
    var imgd = ctx.getImageData(0, 0, this.canvas.width, this.canvas.height),
      pix = imgd.data;

    for (var i = 0, n = pix.length; i < n; i += 4) {
      if (pix[i + 3] === 0) pix[i + 1] = 255;
      var r = pix[i],
        g = pix[i + 1],
        b = pix[i + 2];
      var diff =
        Math.abs(r - chromeColor[0]) +
        Math.abs(g - chromeColor[1]) +
        Math.abs(b - chromeColor[2]);
      pix[i + 3] = (diff * diff) / tolerance;
    }
    ctx.putImageData(imgd, 0, 0);
    return this.backCanvas;
  }

  async fillImageInCircle(imgUrl, width, x, y, imgWidth) {
    // return
    return new Promise((resolve, reject) => {
      var ctx = this.canvas.getContext("2d");
      ctx.beginPath();
      ctx.arc(x, y, width / 2, 0, 2 * Math.PI);
      ctx.clip();
      // ctx.stroke();
      var baseImage = new Image();
      baseImage.src = imgUrl;
      baseImage.onload = () => {
        imgWidth = imgWidth || baseImage.width;
        const ratio = baseImage.height / baseImage.width;
        ctx.drawImage(
          baseImage,
          x - width / 2,
          y - width / 2,
          imgWidth,
          imgWidth * ratio
        );
        resolve();
      };
    });
  }

  toDataURL() {
    return this.canvas.toDataURL();
  }

  getCanvas() {
    return this.canvas;
  }
}

export async function AvatarPhaserHelper(
  avatarImagePath,
  playerState,
  initialProfile,
  initialPosition,
  onSpriteCreate,
  onCanvasCreate,
  photoParams,
  gradientFillParamsFn
) {
  // use avatar creator to create customized avatar for this player
  const textureId = await generateAvatar(
    avatarImagePath,
    playerState.id,
    initialProfile,
    onCanvasCreate,
    photoParams
  );
  let player = onSpriteCreate(
    initialPosition[0],
    initialPosition[1],
    textureId
  );

  playerState.on("profile", async (newProfile) => {
    const textureId = await generateAvatar(
      avatarImagePath,
      playerState.id,
      newProfile,
      onCanvasCreate,
      photoParams,
      gradientFillParamsFn && newProfile && newProfile.color
        ? gradientFillParamsFn(newProfile)
        : false
    );
    const oldTexture = player.texture;
    player.setTexture(textureId);
    oldTexture.destroy();
  });

  return player;
}

async function generateAvatar(
  avatarImagePath,
  playerId,
  profile,
  onCanvasCreate,
  photoParams,
  gradientFillParams
) {
  const newTextureId = "player-" + playerId + "-" + Math.random();
  const texture = onCanvasCreate(newTextureId);

  const av = new AvatarCreator(avatarImagePath, texture.getCanvas(), true);
  const tint = profile && profile.color ? profile.color : "#FF3666";
  // console.log(this.gameId, "generateAvatar", tint, playerId, this.textures.exists(newTextureId));
  if (gradientFillParams) {
    await av.fillRadialGradient.apply(av, gradientFillParams);
  } else {
    await av.fillColor(tint);
  }
  if (photoParams) {
    if (profile && profile.photo) {
      await av.fillImageInCircle(
        profile.photo,
        photoParams.width,
        photoParams.x,
        photoParams.y,
        photoParams.imgWidth
      );
    } else {
      await av.fillImageInCircle(
        "/avatars/default-photo.png",
        photoParams.width,
        photoParams.x,
        photoParams.y,
        photoParams.imgWidth
      );
    }
  }

  texture.refresh();
  return newTextureId;
}
