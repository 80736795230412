import Multiplayer from "common/modules/multiplayer";
import RoundsWrapper from "common/components/RoundsWrapper";
import {
  usePlayersList,
  useGlobalState,
  useMyPlayerState,
  useGlobalRoundState,
} from "common/hooks/multiplayer";

import Round from "./round";
import NativeMethods from "common/modules/NativeMethods";

import React, { useEffect, useState } from "react";
import GamesMenu from "common/components/GamesMenu";
import BackgroundGame from "common/components/BackgroundGame";
import BackgroundMusic from "common/modules/backgroundMusic";
import castingBackgroundImg from "./img/casting-background.png";
import ControllerHUD from "common/components/ControllerHUD";
import Timer from "common/components/Timer";
import PlayerGame from "./components/PlayerGame";

import { FullscreenCountdown } from "common/components/Countdown";

import PlayerIndicators from "./components/PlayerIndicators";
import "./style.css";
import "./card-fan.css";
import "./starry-night.css";

import UIfx from "common/modules/uifx";

import RotatingStars from "./components/RotatingStars";

import cardShuffleSound from "./sfx/shuffle.mp3";
const sfxCardShuffle = UIfx(cardShuffleSound);

const BGMusic = BackgroundMusic();

function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export default function GamePage({ gameInfo }) {
  const multiplayer = Multiplayer();
  const [countdown, setCountdown] = useState(true);
  const players = usePlayersList();
  const myState = multiplayer.getMyPlayerState();
  const totalRounds = 3;
  const currentRound = useGlobalState("_round", 0);
  const winner = useGlobalState("winner");
  const timer = useGlobalState("timer");
  const gameIsLive = useGlobalState("live");

  const allPlayersChose = useGlobalRoundState("allPlayersChose", false);
  const showAllCards = useGlobalRoundState("showAllCards", false);
  const challengeWinners = useGlobalRoundState("challengeWinners", []);
  const myPoints = useMyPlayerState("points", 0);
  const [myPointsPrev, setMyPointsPrev] = useState(0);
  const [showPoints, setShowPoints] = useState(null);

  useEffect(() => {
    if (myPoints) {
      let scoreUpdatedBy = myPoints - myPointsPrev;
      console.log("scoreUpdatedBy: ", scoreUpdatedBy);
      console.log("My phone should vibrate!");
      NativeMethods.haptic("heavy");
      setMyPointsPrev(myPoints);
      setShowPoints(scoreUpdatedBy);
      setTimeout(() => {
        setShowPoints(null);
      }, 2500);
    }
  }, [myPoints]);

  async function stopCountdown() {
    multiplayer.setState("live", true);
    setCountdown(false);
  }

  useEffect(() => {
    // Reduce volume of playroom music
    // BGMusic.setVolume(0, true);
    // BackgroundMusic("timer").stop();

    if (multiplayer.isSpectator) {
      // startCountdown();
    } else {
      // play card shuffling sound the first time the cards appear
      sfxCardShuffle.play();
    }
    return () => {
      if (multiplayer.isSpectator) {
        BackgroundMusic("timer").stop();
      }
    };
  }, []);

  return (
    <>
      {/* <div className="starry-night-container">
        <div className="sky">
          <div className="stars"></div>
          <div className="stars1"></div>
          <div className="stars2"></div>
          <div key={"round-"+currentRound} className="shooting-stars"></div>
        </div>
      </div> */}

      <RotatingStars />

      {/* mid-game sky */}
      <BackgroundGame
        style={{
          backgroundImage: `url(${castingBackgroundImg})`,
          backgroundSize: "cover",
          // height: "100%",
          // width: "100%",
        }}
      ></BackgroundGame>

      <GamesMenu
        key="gamesmenu"
        gradient
        hideRoomCode
        gameInfo={gameInfo}
        showRestart={winner && multiplayer.isHost}
      />

      {/* both players and spectator have a countdown */}
      {countdown ? (
        <FullscreenCountdown
          key={"fullscreen-countdown"}
          onCountdownChange={setCountdown}
          onFinish={stopCountdown}
        />
      ) : (
        <></>
      )}

      {multiplayer.isSpectator ? ( // tv/laptop screen
        <>
          <div
            style={{
              position: "fixed",
              top: "3rem",
              left: "2rem",
            }}
          >
            {!allPlayersChose && (
              <Timer height={60} width={60} color={"#F9DA4B"} timer={timer} />
            )}
          </div>

          {gameIsLive ? (
            <div
              className="centered-container"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flex: "1",
                width: "max-content",
              }}
            >
              <RoundsWrapper totalRounds={totalRounds} RoundComponent={Round} />
            </div>
          ) : (
            <></>
          )}

          <PlayerIndicators
            players={players}
            winner={winner}
            showAllCards={showAllCards}
            challengeWinners={challengeWinners}
            delayBetweenPlayers={0.9}
          />
        </>
      ) : (
        <ControllerHUD
          breadcrumbs={["Rock, Paper, Scissors!"]}
          backgroundStyle={{
            background: `#28425B`,
          }}
          avatarStyle={{
            background: `linear-gradient(180deg, #8D6BED 49.66%, #76A7B7 104.47%, #6BFFB8 157.42%)`,
          }}
          timerProps={{
            color: "#FFF858",
          }}
          // avatarUrl={avatarUrlImg}
        >
          <div
            className="space-container"
            style={{
              position: "absolute",
              top: "0rem",
              right: "2rem",
              height: "5.1rem",
              width: "5.1rem",
              borderRadius: "50%",
              alignItems: "center",
              fontSize: "2rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span>{myPoints || "0"}</span>
          </div>

          {showPoints > 0 ? (
            <div className="space-container show-points-container">
              <span>You get +{showPoints} points</span>
            </div>
          ) : (
            <></>
          )}

          <PlayerGame
            key={currentRound}
            myState={myState}
            multiplayer={multiplayer}
          />
        </ControllerHUD>
      )}
    </>
  );
}
