import { useGlobalState } from "common/hooks/multiplayer";
import Multiplayer from "common/modules/multiplayer";
import styles from "./style.module.css";

export default function MuteButton({ castMode, children, round = true }) {
  const multiplayer = Multiplayer();

  return (
    <div
      className={`${castMode ? "" : styles.soundContainer}`}
      onClick={() => {
        multiplayer.setState(
          "muted",
          multiplayer.getState("muted") ? undefined : true
        );
      }}
    >
      <Mute
        muteButtonClassNames={`${children ? "" : styles.muteButtonContainer}  ${
          round ? styles.round : ""
        }  ${castMode ? styles.castMode : ""}`}
      />
      {children}
    </div>
  );
}

export const Mute = ({ muteButtonClassNames }) => {
  const muted = useGlobalState("muted");

  return (
    <div
      className={`${styles.muteButton} ${muteButtonClassNames} ${
        !muted ? styles.on : ""
      }
        }`}
    ></div>
  );
};
