import React, { useEffect, useState } from "react";
import Modal from "common/components/Modal";
import BackgroundRain from "common/components/BackgroundRain";
import UIfx from "common/modules/uifx";
import countdownSound from "common/sfx/countdown.mp3";
import BackgroundMusic from "common/modules/backgroundMusic";
import RoomCodeWithQR from "common/components/RoomCodeWithQR";
import Leaderboard from "common/components/Leaderboard";
import Multiplayer from "common/modules/multiplayer";
import RateGame from "common/components/RateGame";
import "./style.css";
import { addUserLog } from "common/modules/db";

const BGMusic = BackgroundMusic();

export default function AutoplayNextGameModal({
  open,
  nextGame,
  controllerMode,
  onFinish,
  slideIn,
  lastGameId,
  countdownTime = 20,
  show = true,
}) {
  // const [game, setGame] = useState(false); // selected game
  const multiplayer = Multiplayer();
  const [isHost] = useState(multiplayer.isHost);
  const [countdown, setCountdown] = useState(4);
  const [skipClicked, setSkipClicked] = useState(false);
  const [countdownInterval, setCountdownInterval] = useState(null);
  const [emojiInterval, setEmojiInterval] = useState(null);
  const emojisWaiting = ["🥵", "😅", "👻"];
  const emojisSatisfied = ["💯", "😄", "🙌"];
  const [currentEmoji, setCurrentEmoji] = useState(emojisWaiting[0]);
  const [currentEmojiState, setCurrentEmojiState] = useState(emojisWaiting);

  // show the rategame modal if there was a game before
  const [showRateGame, setShowRateGame] = useState(lastGameId ? true : false);
  const [ratingGiven, setRatingGiven] = useState(undefined);

  const sfxCountdown = UIfx(countdownSound);

  useEffect(() => {
    if (lastGameId) {
      setShowRateGame(true);
    }
  }, [lastGameId]);

  // useEffect(() => {
  //   setSfxCountdown(UIfx(countdownSound));
  // }, []);

  const startCountdown = () => {
    // sfxCountdown = UIfx(countdownSound);
    let c = countdownTime;
    multiplayer.setState("skip", undefined);
    if (window.location.hash.indexOf("debug") > -1) {
      c = 2;
    }
    setCountdown(c);
    const interval = setInterval(() => {
      c -= 1;
      if (multiplayer.getState("skip") && c > 3) {
        c = 3;
      }
      if (c === 3) {
        sfxCountdown.play(0.4);
        if (nextGame?.background?.music) {
          BackgroundMusic().stop();
        }
      }
      setCountdown(c);

      if (c <= 0) {
        if (multiplayer.isRenderServer()) {
          multiplayer.connection.setAutoAdmitPlayers(false);
        }
        clearInterval(interval);
        setCountdownInterval(null);
        multiplayer.setState("skip", undefined);
        BGMusic.setVolume(0.04);
        onFinish(nextGame);
      }
    }, 1000);
    setCountdownInterval(interval);
  };

  const wrapUp = () => {
    if (countdownInterval) {
      clearInterval(countdownInterval);
      setCountdownInterval(null);
      sfxCountdown.stop();
    }
    if (emojiInterval) {
      clearInterval(emojiInterval);
    }
  };

  useEffect(() => {
    if (multiplayer.isRenderServer()) {
      multiplayer.connection.setAutoAdmitPlayers(true);
    }
    if (open && !controllerMode) {
      // const newGame =
      //   nextGame ||
      //   GamesDirectory[Math.floor(Math.random() * GamesDirectory.length)];
      // setGame(newGame);
      if (countdownInterval === null) {
        BGMusic.setVolume(0.2);
        startCountdown();
      }
    } else {
      // Change the emoji every second :P

      // References:
      // - https://github.com/SaadBazaz/lightest-login-form-ever/blob/master/015%20-%20Grayhat/15.html

      const emojiinterval = setInterval(() => {
        setCurrentEmoji(
          currentEmojiState[
            Math.floor(Math.random() * currentEmojiState.length)
          ]
        );
      }, 1000);
      setEmojiInterval(emojiinterval);
    }
    return () => {
      wrapUp();
    };
    // eslint-disable-next-line
  }, []);

  if (!nextGame) {
    return <></>;
  }

  if (controllerMode) {
    return (
      <Modal className="joinRoomModal" open={open} fullScreen={true}>
        <BackgroundRain
          key="rain"
          className="autoplay-gradient"
          numDrops={50}
        />
        <div className="autoplay-content-container bootstrap-wrapper">
          <div style={{ height: "fit-content" }}>
            <div className="autoplay-buttons-container">
              <div className="autoplay-button">Game is starting...</div>
              {isHost && !skipClicked && countdownTime > 3 ? (
                <button
                  className={"button-menu start-button autoplay-skip-button"}
                  style={{
                    display: "inline-block",
                    position: "static",
                    width: "fit-content",
                    animationDuration: countdownTime + "s",
                  }}
                  onClick={() => {
                    multiplayer.setState("skip", Date.now());
                    setSkipClicked(true);
                  }}
                  onMouseOver={() => setCurrentEmojiState(emojisSatisfied)}
                  onMouseOut={() => setCurrentEmojiState(emojisWaiting)}
                >
                  <span
                    style={{
                      width: "100%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {"I can't wait " + currentEmoji}
                  </span>
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {showRateGame ? (
          <RateGame
            style={{ zIndex: 99 }}
            rating={ratingGiven}
            onRatingClick={(rating) => {
              setRatingGiven(rating);
              addUserLog("game_rating", { rating: rating, gameId: lastGameId });
            }}
          />
        ) : (
          <></>
        )}
      </Modal>
    );
  }
  return (
    <>
      <BackgroundRain key="rain" className="autoplay-gradient" numDrops={50} />
      <RoomCodeWithQR />
      <Leaderboard showScore />
      <div
        className={
          "autoplay-content-container bootstrap-wrapper instructions-mode " +
          // (slideIn ?
          " slide-in"
          // : "")
        }
      >
        {show ? (
          <div className="">
            <div className="game-info-container">
              <div
                className="game-info-image-container"
                style={{ backgroundImage: `url(${nextGame.screenshot})` }}
              ></div>
              <div className="game-info-text-container">
                <h1>{nextGame.title}</h1>
                <p>{nextGame.metadata.description}</p>
              </div>
            </div>
            <div className="autoplay-buttons-container">
              {/* <button className="autoplay-button" onClick={onRestart}>
            Restart
          </button> */}
              {nextGame.instructions && (
                <img
                  src={nextGame.instructions}
                  alt="Instructions"
                  className="game-instructions"
                />
              )}
              <div className="autoplay-button">
                Game begins in{" "}
                <span className="autoplay-countdown">{countdown}</span>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
