import Phaser from "phaser";
import CommonGameScene from "common/components/GameRenderer/gamescene";
//import logoImg from './assets/logo.png';
import sfxJoin from "./assets/playerjoin.mp3";
import sfxPush from "./assets/push.mp3";

import snd_hit1 from "./assets/snd_hit1.mp3";
import snd_hole1 from "./assets/snd_hole1.mp3";
import splashs from "./assets/splash.mp3";

import Background from "./assets/bg.png";
import Hole from "./assets/hole.png";
import hole_flag from "./assets/hole_flag.png";
import golfball from "./assets/golfball.png";
import splash from "./assets/splash.png";

class MyGame extends CommonGameScene {
  constructor() {
    super();
    this.playerNameTags = {};
    this.PlayersStartPositions = [];
    this.HolePositions = [];
    this.hole = null;
    this.player_index = 0;
    this.BigText = null;
    this.GameOver = false;
    this.allBall = {};
    this.Teta = 0;
    this.holeball = null;
    this.waterSplash = null;
    this.playerKeyIsDown = {};
    this.playerTeta = {};
  }

  preload() {
    // this.load.image('logo', logoImg);
    this.load.audio("join", sfxJoin);
    this.load.audio("push", sfxPush);
    this.load.audio("snd_hit1", snd_hit1);
    this.load.audio("snd_hole1", snd_hole1);
    this.load.audio("splashs", splashs);

    //game assets
    this.load.image("bg", Background);
    this.load.image("Hole", Hole);
    this.load.image("hole_flag", hole_flag);
    this.load.image("golfball", golfball);

    this.load.spritesheet("splash", splash, {
      frameWidth: 128 / 4,
      frameHeight: 32,
    });
    //this.load.image("bar", bar);
    let SJ = {
      generator_info:
        "Shape definitions generated with PhysicsEditor. Visit https://www.codeandweb.com/physicseditor",
      Background: {
        type: "fromPhysicsEditor",
        label: "water",
        isStatic: true,
        density: 0.1,
        restitution: 0,
        friction: 0.1,
        frictionAir: 0.55,
        frictionStatic: 1,
        collisionFilter: {
          group: 0,
          category: 1,
          mask: 255,
        },
        fixtures: [
          {
            label: "water",
            isSensor: true,
            vertices: [
              [
                { x: 785, y: 611 },
                { x: 861, y: 748 },
                { x: 834, y: 787 },
                { x: 733, y: 781 },
              ],
              [
                { x: 781, y: 455 },
                { x: 821, y: 495 },
                { x: 797, y: 544 },
              ],
              [
                { x: 797, y: 544 },
                { x: 785, y: 611 },
                { x: 733, y: 781 },
                { x: 656, y: 768 },
                { x: 588, y: 716 },
                { x: 582, y: 621 },
                { x: 619, y: 522 },
                { x: 718, y: 463 },
                { x: 781, y: 455 },
              ],
            ],
          },
          {
            label: "water",
            isSensor: true,
            vertices: [
              [
                { x: 877, y: 51 },
                { x: 858, y: 112 },
                { x: 790, y: 142 },
                { x: 715, y: 135 },
                { x: 679, y: 94 },
                { x: 696, y: 29 },
              ],
            ],
          },
          {
            label: "water",
            isSensor: true,
            vertices: [
              [
                { x: 1892, y: 377 },
                { x: 1790, y: 373 },
                { x: 1751, y: 353 },
                { x: 1779, y: 295 },
                { x: 1857, y: 223 },
              ],
              [
                { x: 1857, y: 223 },
                { x: 1913, y: 215 },
                { x: 1979, y: 255 },
                { x: 2019, y: 329 },
                { x: 2014, y: 411 },
                { x: 1996, y: 452 },
                { x: 1956, y: 459 },
                { x: 1892, y: 377 },
              ],
            ],
          },
          {
            label: "water",
            isSensor: true,
            vertices: [
              [
                { x: 1351, y: 1062 },
                { x: 1406, y: 1116 },
                { x: 1163, y: 1116 },
                { x: 1217, y: 1075 },
                { x: 1272, y: 1056 },
              ],
            ],
          },
        ],
      },
    };

    this.load.json("shapes", SJ);
  }

  create() {
    super.create();
    this.CreateMap();

    //prepare sounds
    this.sound.add("join", { volume: 1 });
    this.sound.add("push", { volume: 1 });
    this.sound.add("snd_hit1", { volume: 1 });
    this.sound.add("snd_hole1", { volume: 1 });
    this.sound.add("splashs", { volume: 1 });

    this.matter.world.on(
      "collisionstart",
      (event, bodyA, bodyB) => {
        console.log("bodyA", bodyA.label);
        console.log("bodyB", bodyB.label);
        if (
          bodyB.label.indexOf("Circle Body") !== -1 &&
          bodyA.label === "water"
        ) {
          bodyB.parent.gameObject.setFrictionAir(0.07);

          bodyB.parent.gameObject.setData({ focus: "on" });
          this.time.addEvent({
            delay: 1000,
            callback: () => {
              this.GoBack(bodyB.parent.gameObject);
            },
            callbackScope: this,
            loop: false,
          });
        } else if (
          bodyB.label.indexOf("Circle Body") !== -1 &&
          bodyA.label === "hole"
        ) {
          // console.log(bodyB.parent.gameObject)
          console.log(bodyB.parent.gameObject.getData("name") + " Win!");
          this.BigText.setText(
            bodyB.parent.gameObject.getData("name") + " Win!"
          );
          this.multiplayer.setState(
            "winner",
            bodyB.gameObject.getData("player_id")
          );
          this.multiplayer.addToWinLog(
            "quickgolf",
            bodyB.gameObject.getData("player_id")
          );
          bodyB.parent.gameObject.setVelocity(0, 0);
          bodyB.parent.gameObject.setPosition(this.hole.x, this.hole.y);

          this.sound.play("snd_hole1");
          //hide all speed/rotaions effect
          Object.keys(this.allBall).forEach((key) => {
            this.allBall[key].setData({ focus: "on" });
          });
          // this.allBall.forEach((ball) => {
          //   if (ball !== null) { ball.setData({ 'focus': 'on' }) }
          // });
          bodyB.parent.gameObject.setDepth(6);

          this.Zoom();
          this.GameOver = true;
        }
      },
      this
    );
  }

  addPlayerSprite(playerState, profile) {
    // console.log("addPlayerSprite",playerState, profile,this.players);
    // console.log('addPlayerSprite , this.player_index',this.player_index)

    // console.log('playerState' , playerState)
    //console.log('profile' ,profile)

    let COLOR = 0xffffff;
    if (profile.color) {
      COLOR = Phaser.Display.Color.HexStringToColor(profile.color).color;
      console.log("color", COLOR, profile.color);
    }
    // if (profile.color === 'red') { COLOR = 0xff0000; }
    // if (profile.color === 'blue') { COLOR = 0x0000ff; }
    // if (profile.color === 'green') { COLOR = 0x00ff00; }
    // if (profile.color === 'yellow') { COLOR = 0xffff00; }

    const sprite = this.matter.add.sprite(0, 0, "golfball", "").setScale(0.5);
    sprite.setPosition(
      this.PlayersStartPositions[this.player_index].x,
      this.PlayersStartPositions[this.player_index].y
    );
    this.player_index++;

    sprite.setBody({
      type: "circle",
      radius: 28,
    });

    sprite.setFrictionAir(0.01);
    sprite.setMass(3);
    sprite.setFixedRotation();
    sprite.setName(playerState.id);
    sprite.setBounce(1);
    sprite.setCollidesWith([this.holeball]);
    sprite.setTint(COLOR);
    //small circle rotate around
    let ind = this.add.sprite(sprite.x, sprite.y, "golfball").setScale(0.2);
    ind.setTint(0xffffff);
    ind.setAlpha(1);
    // add text for player name
    var style = {
      font: "20px Russo One",
      fill: "#000000",
      wordWrap: true,
      align: "center",
      backgroundColor: "#ffff0033",
      padding: {
        left: 20,
        right: 20,
        top: 10,
        bottom: 10,
      },
    };
    var text = this.add.text(sprite.x, sprite.y, profile.name, style);
    text.setOrigin(0.5, 0.5);
    this.playerNameTags[playerState.id] = text;

    sprite.setData({
      name: profile.name,
      X: sprite.x,
      Y: sprite.y,
      focus: "off",
      Graphic: this.add.graphics(),
      Linep: 100,
      _ind: ind,
      laps: 0,
      player_name: profile.name,
      player_id: playerState.id,
    });

    this.allBall[playerState.id] = sprite;
    // this.sound.play("join");

    return sprite;
  }
  GoBack(sp) {
    //play water animation
    this.waterSplash.setPosition(sp.x, sp.y);
    this.waterSplash.setVisible(true);

    this.sound.play("splashs");

    sp.setVisible(false);
    this.time.addEvent({
      delay: 1000,
      callback: () => {
        if (!this.GameOver) {
          sp.setData({ focus: "off" });
        }
        this.waterSplash.setVisible(false);
        sp.setVelocity(0, 0);
        sp.setPosition(sp.getData("X"), sp.getData("Y"));
        sp.setVisible(true);
        sp.setFrictionAir(0.01);
      },
      callbackScope: this,
      loop: false,
    });
  }
  Zoom() {
    this.BigText.setScale(4);
    this.BigText.setAlpha(0.5);
    this.tweens.add({
      targets: this.BigText,
      scale: 2,
      alpha: 1,
      delay: 50,
      ease: "Power1",
      duration: 500,
    });
  }
  shuffle(array) {
    let currentIndex = array.length,
      randomIndex;
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  looping() {
    this.Teta += 3 * Math.PI * 0.0025;
    // console.log('looping ',this.allBall)
    Object.keys(this.allBall).forEach((key) => {
      let p = this.allBall[key];
      let ind = p.getData("_ind");
      let Linep = parseInt(p.getData("Linep"));
      // console.log(key, 'Linep', Linep)
      let Graphic = p.getData("Graphic");

      let opacityline = 0.4;

      if (p.body.velocity.x !== 0 || p.body.velocity.y !== 0 || this.GameOver) {
        ind.setVisible(false);
        opacityline = 0;
        Graphic.clear();
      }

      if (p.getData("focus") === "off") {
        ind.setPosition(
          p.x + 96 * Math.cos(this.Teta),
          p.y + 96 * Math.sin(this.Teta)
        );
        p.setVelocity(0, 0);
        ind.setVisible(true);
        opacityline = 0;
      }

      if (
        p.getData("focus") === "on" &&
        p.body.velocity.x === 0 &&
        p.body.velocity.y === 0
      ) {
        if (p.getData("LinepIncrement")) {
          Linep += 3;
          if (Linep > 110) {
            p.toggleData("LinepIncrement");
          }
        } else {
          Linep -= 3;
          if (Linep <= 10) {
            p.toggleData("LinepIncrement");
          }
        }
        /*if (Linep > 90) { 
          Linep += 1; 
        } else { 
          Linep += 2; 
        }//correction for the speed*/
        // if (Linep > 110) {
        //   Linep = 10;
        //   p.toggleData('LinepInc')
        // }

        p.setData({ Linep: Linep });
        Graphic.clear();
        Graphic.lineStyle(10, 0xff0000, opacityline);
        let pinit = 18;
        let pfinal = 70;

        let Xi = p.x + pinit * Math.cos(this.Teta);
        let Yi = p.y + pinit * Math.sin(this.Teta);
        let Xii = p.x + (pinit + (pfinal * Linep) / 100) * Math.cos(this.Teta);
        let Yii = p.y + (pinit + (pfinal * Linep) / 100) * Math.sin(this.Teta);

        ind.setPosition(
          p.x + 96 * Math.cos(p.getData("MyAngle")),
          p.y + 96 * Math.sin(p.getData("MyAngle"))
        );
        Xi = p.x + pinit * Math.cos(p.getData("MyAngle"));
        Yi = p.y + pinit * Math.sin(p.getData("MyAngle"));
        Xii =
          p.x +
          (pinit + (pfinal * Linep) / 100) * Math.cos(p.getData("MyAngle"));
        Yii =
          p.y +
          (pinit + (pfinal * Linep) / 100) * Math.sin(p.getData("MyAngle"));
        p.setData({ Shoot: true });

        var L = new Phaser.Geom.Line(Xi, Yi, Xii, Yii);
        Graphic.strokeLineShape(L);
      }
    });
  }
  Actions(playerId) {
    let p = this.allBall[playerId];
    if (p === null) {
      return;
    }
    if (p.getData("focus") === "on") {
      return;
    }

    p.setData({ X: p.x, Y: p.y });

    let ind = p.getData("_ind");

    p.setData({
      focus: "on",
      MyAngle: Phaser.Math.Angle.Between(p.x, p.y, ind.x, ind.y),
    });
    console.log("--------- action----------");
  }
  ShootNow(playerId) {
    let p = this.allBall[playerId];
    if (p === null) {
      return;
    }
    let Linep = parseInt(p.getData("Linep"));

    if (p.getData("Shoot")) {
      console.log(p.getData("Shoot"), "---------ShootNow----------");
      this.sound.play("snd_hit1");
      p.setData({ Shoot: false });
      let Vx = Linep * 0.1 * Math.cos(p.getData("MyAngle"));
      let Vy = Linep * 0.1 * Math.sin(p.getData("MyAngle"));
      console.log("power", Linep);
      p.setVelocity(Vx, Vy);
      let SC = p.scaleX;
      this.tweens.add({
        targets: p,
        scale: SC * 1.1,
        delay: 10,
        ease: "Bounce.easeOut",
        duration: Linep * 5,
        yoyo: true,
        repeat: 1,
      });

      //50*Linep
      this.time.addEvent({
        delay: 30 * Linep,
        callback: () => {
          if (!this.GameOver) {
            p.setData({ focus: "off" });
          }
        },
        callbackScope: this,
        loop: false,
      });
    }
  }

  updateCommon(playerId, sprite, state) {
    if (this.GameOver) {
      return;
    }
    if (state.isKeyDown("b1") || state.isKeyDown("b2")) {
      this.playerKeyIsDown[playerId] = true;
      this.Actions(playerId);
    } else {
      if (this.playerKeyIsDown[playerId]) {
        delete this.playerKeyIsDown[playerId];
        console.log("-----> onUp l", state);
        this.ShootNow(playerId);
      }
    }

    this.playerNameTags[playerId].x = sprite.x - sprite.displayWidth * 2;
    this.playerNameTags[playerId].y = sprite.y;
  }

  handlePlayerQuit(playerState) {
    if (this.playerNameTags[playerState.id]) {
      console.log("player quit", playerState);
      if (this.allBall[parseInt(playerState.id.replace("p", "")) - 1]) {
        this.allBall[parseInt(playerState.id.replace("p", "")) - 1] = null;
        console.log(this.allBall);
      }
      this.player_index--;
      this.playerNameTags[playerState.id].destroy();
      delete this.playerNameTags[playerState.id];
    }
  }

  update() {
    super.update();
    this.looping();
  }

  /***************game code  ****************/
  CreateMap() {
    var gameSize = this.sys.game.scale.gameSize;
    console.log("CreateMap");
    let _w = this.cameras.main.width;
    let _h = this.cameras.main.height;
    this.holeball = this.matter.world.nextCategory();

    //custom world bounds
    this.CreateWall(_w * 0.5, -8, _w + 64, 32);
    this.CreateWall(_w * 0.5, _h + 8, _w + 64, -32);
    this.CreateWall(_w + 8, _h * 0.5, 32, _h + 64);
    this.CreateWall(-12, _h * 0.5, 32, _h + 64);

    //background image : used as refrence
    let BG = this.add.image(0, 0, "bg").setOrigin(0, 0);
    BG.setDisplaySize(this.cameras.main.width, this.cameras.main.height);
    BG.setVisible(true);

    var shapes = this.cache.json.get("shapes");
    let water = this.matter.add.sprite(
      this.cameras.main.width * 0.45,
      this.cameras.main.height * 0.5,
      "bg",
      null,
      { shape: shapes.Background }
    );

    // water.setOrigin(0,0)
    this.water = water;

    water.setScale((BG.displayHeight / gameSize.height) * 1.2);
    water.setCollisionCategory(this.holeball);

    // water.setVisible(false);
    console.log("water", water.body.parts);
    const PlayersStartPositions = [
      {
        x: BG.x + BG.displayWidth * 0.1,
        y: BG.x + BG.displayHeight * 0.2,
      },
      {
        x: BG.x + BG.displayWidth * 0.2,
        y: BG.x + BG.displayHeight * 0.85,
      },
      {
        x: BG.x + BG.displayWidth * 0.45,
        y: BG.x + BG.displayHeight * 0.5,
      },
      {
        x: BG.x + BG.displayWidth * 0.8,
        y: BG.x + BG.displayHeight * 0.05,
      },
      {
        x: BG.x + BG.displayWidth * 0.7,
        y: BG.x + BG.displayHeight * 0.65,
      },
    ];

    // this.PlayersStartPositions = this.shuffle(this.PlayersStartPositions);
    const randomPos = this.shuffle(PlayersStartPositions)[0];
    this.PlayersStartPositions = [1, 2, 3, 4, 5, 6, 7, 8].map((i) => {
      return {
        x: randomPos.x + Math.random() * 100,
        y: randomPos.y + Math.random() * 100,
      };
    });

    this.hole = this.matter.add.sprite(0, 0, "Hole");
    var Bodies = Phaser.Physics.Matter.Matter.Bodies;
    var circleD = Bodies.circle(0, 0, 28, {
      isSensor: true,
      label: "hole",
      isStatic: true,
    });
    this.hole.setExistingBody(circleD);

    this.hole.setCollisionCategory(this.holeball);

    this.HolePositions = [
      {
        x: BG.x + BG.displayWidth * 0.19,
        y: BG.x + BG.displayHeight * 0.52,
      },
      {
        x: BG.x + BG.displayWidth * 0.54,
        y: BG.x + BG.displayHeight * 0.76,
      },
      {
        x: BG.x + BG.displayWidth * 0.78,
        y: BG.x + BG.displayHeight * 0.9,
      },
      {
        x: BG.x + BG.displayWidth * 0.67,
        y: BG.x + BG.displayHeight * 0.23,
      },
      {
        x: BG.x + BG.displayWidth * 0.9,
        y: BG.x + BG.displayHeight * 0.4,
      },
    ];
    //random position of the hole
    this.HolePositions = this.shuffle(this.HolePositions);
    this.hole.setPosition(this.HolePositions[0].x, this.HolePositions[0].y);

    let hole_flag = this.add.image(0, 0, "hole_flag").setOrigin(0, 1);
    hole_flag.setScale(0.75, 0.75);
    hole_flag.setPosition(this.hole.x, this.hole.y);
    hole_flag.setDepth(5);

    this.waterSplash = this.add.sprite(200, 200, "splash", "0").setScale(3);
    this.anims.create({
      key: "splashA",
      frames: this.anims.generateFrameNumbers("splash", {
        frames: ["0", "1", "2", "3"],
      }),
      frameRate: 8,
      repeat: -1,
    });
    this.waterSplash.play("splashA");
    this.waterSplash.setVisible(false);

    var style = {
      font: "bold 54px Arial",
      fill: "#ffffff",
      align: "center",
      stroke: "#000000",
      strokeThickness: 6,
    };

    this.BigText = this.add.text(
      this.cameras.main.width * 0.5,
      this.cameras.main.height * 0.07,
      "First to reach the hole wins",
      style
    );

    this.BigText.setOrigin(0.5, 0.5);
  }
  CreateWall(x, y, w, h) {
    let wall = this.matter.add.sprite(0, 0, "");
    var Bodies = Phaser.Physics.Matter.Matter.Bodies;
    var Rect = Bodies.rectangle(x, y, w, h, { label: "wall", isStatic: true });
    wall.setExistingBody(Rect);
    wall.setCollisionCategory(this.holeball);
  }
}

export default MyGame;

//problem about players index when removing a player
