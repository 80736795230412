import Phaser from "common/components/GameRenderer/phaser";
import Multiplayer from "common/modules/multiplayer";
import imgMoney from "../img/money.png";
import NativeMethods from "common/modules/NativeMethods";
import GameConfig from "common/components/GameRenderer/config";

const SPAWN_EVERY = 500;
const allMoney = [
  {
    // green
    points: 1,
    color: "#59BF82", 
    speed: 0.6,
  },
  {
    // red
    points: 5,
    color: "#FFB0CC", 
    speed: 1.2,
  },
  {
    // gold
    points: 10,
    color: "#FDEF76",
    speed: 2.1,
  },
]

function randRange(minNum, maxNum) {
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}

// this is a control scene, no need to extend CommonGameScene
export class GameScene extends Phaser.Scene {
  constructor() {
    super({
      key: "GameScene",
    });
    window.currentScene = this;

    this.lastSpawn = 0;
    this.totalMyCirclesThatSpawn = 60;
    this.circles = [];
    this.circleTexts = [];
    this.multiplayer = Multiplayer();
    this.scaleFactor = 1;
    window.currentScene = this;


    // sinewave animation
    this.xl = 0;
    this.slices = [];
    this.waveform = 0;
  }

  preload() {
    this.load.image("money", imgMoney);
    const playerPhoto = this.multiplayer
      .getMyPlayerState()
      .getState("profile").photo;
    if (playerPhoto) {
      this.textures.addBase64("player", playerPhoto);
    } else {
      this.load.image("player", "/avatars/default-photo.png");
    }
  }

  create() {
    this.gameSize = this.sys.game.scale.gameSize;
    this.scaleFactor = this.gameSize.width / GameConfig.baseWidth;
    //  A drop zone
    const dropZoneSize = this.gameSize.width * 0.1;
    const borderWidth = 30;
    const margin = 30;
    const x = margin + borderWidth + dropZoneSize / 2;
    const y = this.gameSize.height - dropZoneSize / 2 - borderWidth - margin;
    // this.add
    //   .zone(x, y, dropZoneSize, dropZoneSize)
    //   .setRectangleDropZone(dropZoneSize, dropZoneSize);

    //  Just a visual display of the drop zone
    // this.add.circle(
    //   x,
    //   y,
    //   dropZoneSize / 2 + borderWidth,
    //   Phaser.Display.Color.HexStringToColor(
    //     this.multiplayer.getMyPlayerState().getState("profile").color
    //   ).color
    // );

    // const playerAvatarImg = this.add.image(x, y, "player");
    // playerAvatarImg.displayWidth = dropZoneSize;
    // playerAvatarImg.displayHeight = dropZoneSize;
    // const circle = this.add
    //   .graphics()
    //   .setPosition(x, y)
    //   .fillCircle(0, 0, dropZoneSize / 2);
    // playerAvatarImg.setMask(circle.createGeometryMask());
    // playerAvatarImg.depth = 3;

    // this.input.on("dragstart", (pointer, gameObject) => {
    //   this.children.bringToTop(gameObject);
    //   gameObject.setStatic(true);
    // });

    // this.input.on("drag", (pointer, gameObject, dragX, dragY) => {
    //   gameObject.x = dragX;
    //   gameObject.y = dragY;
    // });

    // this.input.on("drop", (pointer, gameObject, dropZone) => {
    //   gameObject.x = dropZone.x;
    //   gameObject.y = dropZone.y;
    //   gameObject.alpha = 0;

    //   gameObject.input.enabled = false;
    //   const points = gameObject.data.get("points");
    //   const myState = this.multiplayer.getMyPlayerState();
    //   myState.setState("score", (myState.getState("score") || 0) + points);
    //   this.plusOneText.setText(`${points === 1 ? "+1" : "-1"}`);
    //   if (points === 1) {
    //     NativeMethods.haptic("rigid");
    //     this.plusOneText.setColor("#000000");
    //   } else {
    //     NativeMethods.haptic("heavy");
    //     // this.cameras.main.shake(100);
    //     this.plusOneText.setColor("#ff0000");
    //   }
    //   this.tweens.add({
    //     targets: this.plusOneText,
    //     alpha: { from: 1, to: 0 },
    //     duration: 1000,
    //     ease: "Power1",
    //     repeat: 0,
    //     yoyo: false,
    //     y: { from: y - dropZoneSize, to: y - dropZoneSize * 2 },
    //   });
    // });

    // this.input.on("dragend", (pointer, gameObject, dropped) => {
    //   if (!dropped) {
    //     gameObject.x = gameObject.input.dragStartX;
    //     gameObject.y = gameObject.input.dragStartY;
    //     gameObject.setStatic(false);
    //   }
    // });

    var style = {
      font: this.adjustByScale(80) + "px 'Lilita One'",
      fill: "#000000",
      wordWrap: true,
      align: "center",
      padding: {
        left: 20,
        right: 20,
        top: 10,
        bottom: 10,
      },
    };

    this.plusOneText = this.add.text(
      x + dropZoneSize / 2,
      y - dropZoneSize,
      "",
      style
    );

    // sinewave animation 
    //  Generate our motion data
    // var game = this.sys.game;
    // var waveform = this.waveform;
    // var xl = this.xl;
    // var slices = this.slices;
    
    // var motion = { x: 0 };
    // var tween = game.add.tween(motion).to( { x: 200 }, 3000, "Bounce.easeInOut", true, 0, -1, true);
    
    // waveform = tween.generateData(60);

    // xl = waveform.length - 1;

    // var sprites = game.add.spriteBatch();

    // slices = [];

    // var picWidth = game.cache.getImage('money').width;
    // var picHeight = game.cache.getImage('money').height;

    // var ys = 4;

    // for (let y = 0; y < Math.floor(picHeight/ys); y++)
    // {
    //     var star = game.make.sprite(300, 100 + (y * ys), 'money');

    //     star.crop(new Phaser.Rectangle(0, y * ys, picWidth, ys));

    //     star.ox = star.x;

    //     star.cx = game.math.wrap(y * 2, 0, xl);

    //     star.anchor.set(0.5);
    //     sprites.addChild(star);
    //     slices.push(star);
    // }




    this.hapticSpam();
  }

  waitFor(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async hapticSpam() {
    NativeMethods.haptic("heavy");
    await this.waitFor(100);
    NativeMethods.haptic("heavy");
    await this.waitFor(100);
    NativeMethods.haptic("heavy");
    await this.waitFor(100);
    NativeMethods.haptic("heavy");
    await this.waitFor(100);
    NativeMethods.haptic("heavy");
    await this.waitFor(100);
  }

  spawnCircle() {
    console.log("spawning circle");
    // const players = this.multiplayer.getPlayers();

    // const myColor = this.multiplayer
    //   .getMyPlayerState()
    //   .getState("profile").color;

    // const otherColors = Object.keys(players)
    //   .map((playerId) => players[playerId].getState("profile").color)
    //   .filter((color) => color !== myColor);


    // TODO: Use a weighted distribution to automatically get
    // colors based on their position in the array
      const moneyIndex =
      this.totalMyCirclesThatSpawn > 0 
      && Math.random() < 0.85 // chance that it will be the first color
        ? 0 // pick the first color
        : Math.random() < 0.9
        ? 1
        : 2; // pick the final color

    this.totalMyCirclesThatSpawn--;
    console.log("spawning money", this.totalMyCirclesThatSpawn);

    var moneyInit = randRange(0, this.gameSize.width);
    const moneyAngle = randRange(0, 360);

    var money = this.matter.add.image(
      moneyInit,
      -100,
      "money",
      null,
      {
        restitution: 0.6, 
        frictionAir: 0, 
        mass: 0.1,
        gravityScale: {x: 0, y: 1.5 + this.adjustByScale(allMoney[moneyIndex].speed + 1)},
      }
    );

    money.setDataEnabled()  
    .setCollisionGroup(-1)
    .setTint(Phaser.Display.Color.HexStringToColor(allMoney[moneyIndex].color).color)
    .setInteractive()
    .setAngle(moneyAngle)
    .setScale(this.adjustByScale(2.1))

    money.data.set("points", allMoney[moneyIndex].points);
    money.depth = 4;

    var style = {
      font: this.adjustByScale(42) + "px 'Lilita One'",
      fill: "#FFFFFF",
      wordWrap: true,
      align: "center",
      padding: {
        left: 20,
        right: 20,
        top: 10,
        bottom: 10,
      },
    };

    var moneyTextOnly = this.add.text(
      moneyInit,
      -100,
      allMoney[moneyIndex].points.toString(),
      style,
    );

    var moneyText = this.matter.add.gameObject(
      moneyTextOnly, 
      { 
        shape: { type: 'polygon', sides: 8, radius: 64 }, 
        restitution: 0.6, 
        frictionAir: 0, 
        mass: 0.1,
        scale: this.adjustByScale(1.5),
        gravityScale: {x: 0, y: 1.5 + this.adjustByScale(allMoney[moneyIndex].speed + 1)},
      }
      )
    // .setFrictionAir(0.001).setBounce(0.9);

    moneyText  
    .setCollisionGroup(-1)
    .setAngle(moneyAngle+90);

    moneyText.depth = 5;


    // TODO: Investigate why this crashes when object is destroyed

    // const moneyMovement = randRange(0, this.gameSize.width);

    // this.tweens.add({
    //   targets: money,
    //   duration: 1000,
    //   ease: "Power1",
    //   repeat: -1,
    //   yoyo: true,

    //   // rotation: {
    //   //   from: 0,
    //   //   to: 270,
    //   // },
    //   x: { 
    //     from: moneyInit,
    //     to: this.adjustByScale(Math.abs(moneyMovement-moneyInit) % this.gameSize.width) 
    //   },
    // });

    // TODO: Investigate why this crashes when object is destroyed
  //   this.tweens.addCounter({
  //     targets: money,
  //     from: 270,
  //     to: 90,
  //     duration: 2000,
  //     ease: "Power2",
  //     repeat: -1,
  //     yoyo: true,
  //     onUpdate: (tween) =>
  //     {
  //         //  tween.getValue = range between 0 and 360
  //         if (tween && money) money.setAngle(tween.getValue());
  //     }
  // });



    money.on("pointerdown", (pointer) => {
      money.alpha = 0;
      moneyText.setVisible(false);

      money.input.enabled = false;
      // money.body.stop();
      money.body.allowGravity = false;
      const points = money.data.get("points");
      const myState = this.multiplayer.getMyPlayerState();
      if (!this.multiplayer.getState("done")){
        myState.setState("score", (myState.getState("score") || 0) + points);
        this.plusOneText.setText(`${(points > 0 ? "+" : points !== 0 ? "-" : "") 
        + points}`);
      }
      if (points === allMoney[0].points) {
        NativeMethods.haptic("rigid");
        this.plusOneText.setColor("#000000");
      } else {
        NativeMethods.haptic("heavy");
        // this.cameras.main.shake(100);
        this.plusOneText.setColor("#ff0000");
      }
      this.tweens.add({
        targets: this.plusOneText,
        alpha: { from: 1, to: 0 },
        duration: 1000,
        ease: "Power1",
        repeat: 0,
        yoyo: false,
        x: { from: pointer.x, to: pointer.x },
        y: { from: pointer.y, to: pointer.y - this.adjustByScale(100) },
      });
    });
    // this.input.setDraggable(money);
    this.circles.push(money);
    this.circleTexts.push(moneyText);
  }

  cleanupCircles() {
    this.circles.forEach((circle, index) => {
      if (circle.y > this.gameSize.height) {
        circle.destroy();
        this.circles.splice(index, 1);
      }
    });

    this.circleTexts.forEach((circle, index) => {
      try{
        if (circle.y > this.gameSize.height) {
          circle.destroy();
          this.circles.splice(index, 1);
        }
      }
      catch(err){
        console.log("Couldn't check for circle destruction:", err);
        circle.destroy();
        this.circles.splice(index, 1);        
      }
    });
    
  }

  update() {
    super.update();
    const gameIsLive = this.multiplayer.getState("live");
    const winner = this.multiplayer.getState("winner");
    if (gameIsLive && !winner) {
      if (Date.now() - this.lastSpawn > SPAWN_EVERY) {
        this.cleanupCircles();
        this.spawnCircle();
        this.lastSpawn = Date.now();
      }
    }

  }

  adjustByScale(value) {
    return value * this.scaleFactor;
  }
}
