import SuperEventEmitter from "../superEventEmitter";
import Peer from "simple-peer";

// polyfill process.nextTick
window.process = {
  ...window.process,
  nextTick: function (cb, arg1, arg2, arg3) {
    setTimeout(() => {
      cb(arg1, arg2, arg3);
    }, 0);
  },
};

export default class PlayerState extends SuperEventEmitter {
  constructor({
    websocketSend,
    id,
    myId,
    isRenderServer,
    playerIsSpectator,
    // broadcast,
    setPlayerState,
    bootDate,
  }) {
    super();
    this.websocketSend = websocketSend;
    // this.broadcast = broadcast;
    this.setPlayerState = setPlayerState;
    this.myId = myId;
    this.id = id;
    // this.isHost = isHost;
    this.isRenderServer = isRenderServer;
    this.playerIsSpectator = playerIsSpectator;
    this.state = {};
    this.bootDate = bootDate;
    this.stateKeyUpdateOrder = {}; // holds the key:date of last update of that value, 'date' is server's date
    this.inputState = {};
    this.peer = null;
    this.webrtcConnected = false;
    this.webrtcRetryCount = 0;
    this.controller = false;
    this.heartbeatInterval = 0;
    this.retryWebRtcTimeout = 0;
    this.isDestroyed = false;
    // this.startWebrtc();

    // non-host players (except spectators) will send their input to server
    this.on("input", (data) => {
      if (!isRenderServer() && !playerIsSpectator) {
        this.send({ pinput: data });
      }
    });
  }

  send(data, reliable) {
    // try sending via webrtc (unreliable but fast)
    if (this.webrtcConnected && !reliable) {
      try {
        // console.log(
        //   "webrtc >>",
        //   JSON.stringify(data),
        //   JSON.stringify(data).length
        // );
        this.peer.send(JSON.stringify(data));
      } catch (e) {
        console.log(e);
      }
    }
    // else send via websocket (reliable but slow)
    else {
      if (this.isRenderServer()) data.for = this.id;
      this.websocketSend(JSON.stringify(data));
    }
  }

  startWebrtc() {
    if (this.retryWebRtcTimeout) {
      clearTimeout(this.retryWebRtcTimeout);
      this.retryWebRtcTimeout = 0;
    }
    if (this.peer) {
      this.peer.destroy();
    }
    // if we are not render server and this state is for ourselves
    if (!this.isRenderServer() && this.myId === this.id) {
      console.log("webrtc::connecting");
      this.peer = new Peer({ initiator: true, objectMode: true });
      this.peer.on("signal", (data) => {
        // send signal to render server.
        this.send(
          {
            signal: data,
          },
          true
        );
      });

      this.peer.on("connect", () => {
        this.webrtcConnected = true;
        this.webrtcRetryCount = 0;
        this.emit("webrtc_connected");
        clearTimeout(this.retryWebRtcTimeout);
      });
      this.peer.on("data", (data) => {
        data = JSON.parse(data);
        // console.log("webrtc::host says:", data);
        if (data.ping) {
          this.send({ pong: data.ping }); // send time back so they can calculate rtt
        } else if (data.pstate) {
          // a state change for some player has arrived
          this.setPlayerState(data);
        } else if (data.gstate) {
          this.emit("global_state", data.gstate);
        }
      });

      this.peer.on("stream", (stream) => {
        this.emit("stream", stream);
      });

      this.peer.on("close", () => {
        // console.log(this._idToHuman(), "::webrtc:", "connection closed");
        this.webrtcConnected = false;
        // this.peer.off();

        // retry connection (if we didn't disconnect manually)
        this.retryWebRtcTimeout = setTimeout(() => {
          if (!this.isDestroyed && this.webrtcRetryCount < 5) {
            this.startWebrtc();
            this.webrtcRetryCount++;
          }
        }, 3000);
      });

      this.peer.on("error", (err) => {
        // console.log(this._idToHuman(), "::webrtc:", "connection error", err);
        this.webrtcConnected = false;
      });
    }
    // if we are host and this state is not for ourselves
    else if (this.isRenderServer() && this.myId !== this.id) {
      // ping checker (host only)
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = setInterval(() => {
        this.send({
          ping: Date.now(),
        });
      }, 5000);

      // for webrtc, we wait for the signal from this player before responding with a signal
      this.peer = new Peer({ objectMode: true });
      this.peer.on("signal", (data) => {
        this.send(
          {
            for: this.id,
            signal: data,
          },
          true
        );
      });

      this.peer.on("connect", () => {
        this.webrtcConnected = true;
        this.send({ ping: Date.now() });
        this.emit("webrtc_connected");
      });

      this.peer.on("data", (data) => {
        // got a data channel message
        // console.log(this._idToHuman(), "::webrtc:", data);
        data = JSON.parse(data);
        if (data.pinput) {
          // player sent their new inputs, process them
          this.handleInput(data.pinput);
        } else if (data.pstate) {
          // player is sending their own state change
          // TODO: have a allowlist here to prevent players from changing their pos, abilities etc
          this.setState(data.d[0], data.d[1]);
        } else if (data.pong) {
          // response to ping arrived
          this.handlePingResponse(data);
        }
      });

      this.peer.on("close", () => {
        this.webrtcConnected = false;
        // this.peer.off();
        // console.log(this._idToHuman(), "::webrtc:", "connection closed");
      });

      this.peer.on("error", (err) => {
        this.webrtcConnected = false;

        console.log(this._idToHuman(), "::webrtc:", "connection error", err);
      });
    }
    // // if we are host and this is our local state
    // else if (this.isHost && this.myId === this.id){
    //   // TODO: broadcast local state changes
    // }
    // // if we are not host and this state is not for us.
    // else if (!this.isHost && this.myId !== this.id){
    //   // TODO: read message from state and webrtc player updates and update local state
    // }
  }

  _idToHuman() {
    return `${this.playerIsSpectator ? "spectator" : "player"}(${this.id})`;
  }

  signal(data) {
    if (this.peer.destroyed && this.isRenderServer()) {
      // recreate the webrtc client
      this.startWebrtc();
    }

    try {
      this.peer.signal(data);
    } catch (e) {
      console.log(e);
    }
  }

  handlePingResponse(pongData) {
    var diff = Date.now() - pongData.pong;
    // console.log("ping", this.id, diff, "ms");
    this.setState("p", diff, false);
    this.emit("ping", diff);
  }

  // used for local players
  attachController(controller) {
    this.detachController();
    this.controller = controller;
    this.controller.on("keydown", this.handleKeyDown.bind(this));
    this.controller.on("keyup", this.handleKeyUp.bind(this));
    this.controller.on("dpad", this.handleDpad.bind(this));
    this.controller.on("gyro", this.handleGyro.bind(this));
  }

  detachController() {
    if (this.controller) {
      const controller = this.controller;
      this.controller.off("keydown", this.handleKeyDown);
      this.controller.off("keyup", this.handleKeyUp);
      this.controller.off("dpad", this.handleDpad);
      this.controller.off("gyro", this.handleGyro);
      delete this.controller;
      return controller;
    }
  }

  handleKeyDown(key) {
    this.handleInput({ keydown: key });
  }

  handleKeyUp(key) {
    this.handleInput({ keyup: key });
  }

  handleDpad(value) {
    this.handleInput({ dpad: value });
  }

  handleGyro(value) {
    this.handleInput({ gyro: value });
  }

  // handle the input, pass it to subscribers (usually the game logic which will use this to move players in engine)
  handleInput(data) {
    Object.keys(data).forEach((i) => {
      const key = data[i];
      if (i === "keydown") this.inputState[key] = true;
      if (i === "keyup") delete this.inputState[key];
      if (i === "dpad") this.inputState["dpad"] = data.dpad;
      if (i === "gyro") this.inputState["gyro"] = data.gyro;
    });
    // we just emit the input event
    console.log("inputEmit", data);
    this.emit("input", data);
  }

  isKeyDown(key) {
    return this.inputState[key];
  }

  on(name, fn, isTemporary) {
    if (name === "profile") {
      fn(this.state["profile"]);
    }
    if (name === "webrtc_connected" && this.webrtcConnected) {
      fn();
    }
    return super.on(name, fn, isTemporary);
  }

  getState(key) {
    if (!key) return this.state;
    return this.state[key];
  }

  // public method to change state object (used by host only or to change my own state). This is then synced with all clients.
  setState(key, newState, reliable = true) {
    // only set / send if the values are different

    // TODO: replace with something better than stringify
    if (JSON.stringify(this.state[key]) === JSON.stringify(newState)) return;
    // console.log("setState::", this.id, this.state[key], "->", newState)
    this.setLocalState(key, newState);
    // if (JSON.stringify(newState) === JSON.stringify(this.getState(key))) return;
    if (this.isRenderServer()) {
      // this.broadcast({
      //   pstate: this.id,
      //   d: [key, newState],
      //   o: Date.now() - this.bootDate,
      // });
    } else {
      this.send({ pstate: this.id, d: [key, newState] }, reliable);
    }
  }

  setRoundState(key, newState, reliable = true) {
    this.setState(`round.${key}`, newState, reliable);
  }

  getRoundState(key) {
    if (key) return this.getState(`round.${key}`);
    else {
      let roundState = {};
      Object.keys(this.getState()).forEach((key) => {
        if (key.startsWith("round.")) {
          roundState[key.substring(6)] = this.getState(key);
        }
      });
      return roundState;
    }
  }

  resetRoundState() {
    Object.keys(this.getState()).forEach((key) => {
      if (key.startsWith("round.")) {
        this.setState(key, undefined);
      }
    });
  }

  setFullLocalState(newState, updateOrder) {
    Object.keys(newState).forEach((key) => {
      this.setLocalState(key, newState[key], updateOrder);
    });

    // also check for any deleted state
    Object.keys(this.state).forEach((key) => {
      if (newState[key] === undefined) {
        this.setLocalState(key, undefined, updateOrder);
      }
    });
    // this.state = newState || {};
  }

  // just change local state without broadcasting
  // updateOrder: since we have two channels the state can come from,
  // we keep order in mind and only update if it's latest from host
  setLocalState(key, newState, updateOrder) {
    // skip if this is older update
    if (
      updateOrder &&
      this.stateKeyUpdateOrder[key] &&
      this.stateKeyUpdateOrder[key] > updateOrder
    ) {
      // console.log("skipping", key, this.stateKeyUpdateOrder[key] - updateOrder);
      return;
    }
    this.stateKeyUpdateOrder[key] = updateOrder || 0;
    var stateUpdated = false;
    if (JSON.stringify(this.state[key]) !== JSON.stringify(newState)) {
      stateUpdated = true;
    }
    this.state[key] = newState;

    if (stateUpdated) {
      this.emit("state", key, newState);
      if (key === "profile") {
        this.emit("profile", newState);
      }
    }
  }

  disconnect(eventCode) {
    console.log("[PlayerState] disconnecting with eventCode:", eventCode);
    this.detachController();
    clearInterval(this.heartbeatInterval);
    this.isDestroyed = true;
    if (this.peer) {
      this.peer.destroy();
    }
    this.emit("quit", eventCode);
  }
}
