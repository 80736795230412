import { EventEmitter } from "events";
export default class SuperEventEmitter extends EventEmitter {
  constructor() {
    super();
    this.temporaryListeners = [];
  }
  on(eventName, fn, isTemporary) {
    super.on(eventName, fn);
    if (isTemporary) this.temporaryListeners.push([eventName, fn]);
    return () => {
      this.off(eventName, fn);
    };
  }

  removeTemporaryListeners() {
    console.log(
      "removeTemporaryListeners CALLED",
      this,
      this.temporaryListeners
    );
    this.temporaryListeners.forEach(([eventName, fn]) => {
      this.removeListener(eventName, fn);
    });
    this.temporaryListeners = [];
  }
}
