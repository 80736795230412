import AvatarIcon from "common/components/AvatarIcon";
const Player = ({ name, score, color, state, containerRef, containerStyles }) => {
  const maxScoreForMaxHeight = 50;
  const maxHeight = 60; // in vh
  const curHeight = Math.min(
    maxHeight,
    (maxHeight * score) / maxScoreForMaxHeight
  );
  return (
    <div
      ref={containerRef}
      className="player-container"
      style={{
        ...{
          backgroundColor: color,
          paddingBottom: `${curHeight}vh`,
        },
        ...containerStyles,
      }}
    >
      {/* <div className="circle" style={{ backgroundColor: color }}></div> */}
      <AvatarIcon
        playerState={state}
        key={name}
        style={{
          width: "13rem",
          height: "13rem",
          marginTop: "-50%",
          backgroundColor: "#ffffff",
          border: "2.5rem solid " + color,
          borderRadius: "50%",
        }}
      />
      <span className="name">{name}</span>
      <span className="score">{score}</span>
    </div>
  );
};

export default Player;
