let GamesDirectory = [
  {
    id: "smashdown",
    title: "Smashdown",
    // logo: require("./SmashDown/img/logo.png"),
    screenshot: require("./SmashDown/img/screenshot.png"),
    instructions: require("./SmashDown/img/instructions.png"),
    accentColor: "#6BDCFF",
    resetStates: require("./SmashDown/resetStates").default,
    metadata: {
      description:
        "A 10-point competitive game of volley to show off your sporting skills.",
      type: "Arcade",
    },
    // background: "#6BDCFF",
    pages: [{ component: require("./SmashDown").default, slug: "smashdown" }],
  },
  {
    id: "icetruck",
    title: "IceTruck",
    // logo: require("./IceTruck/src/assets/logo.png"),
    screenshot: require("./IceTruck/src/assets/screenshot.png"),
    instructions: require("./IceTruck/src/assets/instructions.png"),
    accentColor: "#6BDCFF",
    resetStates: ["winner"],
    metadata: {
      description:
        "vroom vroom...The race is on. Complete 5 laps and get your truck to the finish line without getting freezed!",
      type: "Arcade",
    },
    // background: "#6BDCFF",
    pages: [{ component: require("./IceTruck").default, slug: "icetruck" }],
  },
  {
    id: "quickgolf",
    title: "Quick Golf",
    logo: require("./QuickGolf/src/assets/logo.png"),
    screenshot: require("./QuickGolf/src/assets/screenshot.png"),
    // instructions: require("./QuickGolf/img/instructions.png"),
    accentColor: "#6BDCFF",
    resetStates: ["winner"],
    metadata: {
      type: "Arcade",
    },
    // background: "#6BDCFF",
    pages: [{ component: require("./QuickGolf").default, slug: "quickgolf" }],
  },
  {
    id: "pickthelargest",
    title: "Capture the box",
    // logo: require("./PickTheLargest/img/logo.png"),
    screenshot: require("./PickTheLargest/img/screenshot.png"),
    instructions: require("./PickTheLargest/img/instructions.png"),
    accentColor: "#6BDCFF",
    resetStates: ["winner", "live", "selectedColors"],
    metadata: {
      description:
        "A competitive game of speed and reflexes to capture the largest box on the board.",
      type: "Reaction",
    },
    // background: "#6BDCFF",
    pages: [
      {
        component: require("./PickTheLargest").default,
        slug: "pickthelargest",
      },
    ],
  },
  {
    id: "rocketfuel",
    title: "Rocket Launch",
    // logo: require("./RocketFuel/img/logo.png"),
    screenshot: require("./RocketFuel/img/screenshot.png"),
    instructions: require("./RocketFuel/img/instructions.png"),
    accentColor: "#6BDCFF",
    resetStates: ["winner", "live"],
    metadata: {
      description:
        "Alright recruits, its go time. T-30, pump the fuel by tapping on phone and get your rocket off the ground.",
      type: "Fastest finger",
    },
    // background: "#6BDCFF",
    pages: [{ component: require("./RocketFuel").default, slug: "rocketfuel" }],
  },
  {
    id: "rockpaperscissors",
    title: "Rock, Paper, Scissors!",
    // logo: require("./RocketFuel/img/logo.png"),
    screenshot: require("./RockPaperScissors/img/screenshot.png"),
    instructions: require("./RockPaperScissors/img/instructions.png"),
    accentColor: "#6BDCFF",
    resetStates: ["winner", "live"],
    metadata: {
      description: "Basically a rock-paper-scissors battle royale.",
      type: "Cards",
    },
    // background: "#6BDCFF",
    pages: [
      {
        component: require("./RockPaperScissors").default,
        slug: "rockpaperscissors",
      },
      {
        component: require("./RockPaperScissors/game").default,
        slug: "rockpaperscissors/game",
      },
    ],
  },
  {
    id: "dogfight",
    title: "Dogfight",
    // logo: require("./DogFight/img/logo.png"),
    screenshot: require("./DogFight/img/screenshot.png"),
    instructions: require("./DogFight/img/instructions.png"),
    accentColor: "#6BDCFF",
    resetStates: require("./DogFight/resetState").default,
    metadata: {
      description:
        "The hunt for gold is on! Show off your flying skills and collect most coins in this timed game.",
      type: "Arcade",
    },
    // background: "#6BDCFF",
    pages: [{ component: require("./DogFight").default, slug: "dogfight" }],
  },
  {
    id: "fallingmnms",
    title: "Falling beans",
    logo: require("./FallingMnMs/img/logo.png"),
    screenshot: require("./FallingMnMs/img/screenshot.png"),
    instructions: require("./FallingMnMs/img/instructions.png"),
    accentColor: "#6BDCFF",
    resetStates: ["winner", "done", "live"],
    metadata: {
      description: "Collect the yumilicious beans of your color on the phone.",
      type: "Fastest finger",
    },
    // background: "#6BDCFF",
    pages: [
      { component: require("./FallingMnMs").default, slug: "fallingmnms" },
    ],
  },
  // {
  //   title: "Whack It",
  // logo: require("./WhackIt/img/logo.png"),
  //   screenshot: require("./WhackIt/img/screenshot.png"),
  //   accentColor: "#6BDCFF",
  //   // background: "#6BDCFF",
  //   pages: [{ component: require("./WhackIt").default, slug: "whackit" }],
  // },
  // {
  //   id: "whereisit",
  //   title: "Where Is It",
  // logo: require("./WhereIsIt/img/logo.png"),
  //   screenshot: require("./WhereIsIt/img/screenshot.png"),
  //   accentColor: "#6BDCFF",
  //   resetStates: ["winner"],
  //   // background: "#6BDCFF",
  //   pages: [{ component: require("./WhereIsIt").default, slug: "whereisit" }],
  // },
  // {
  //   title: "Who is Rich",
  // logo: require("./WhoIsRich/img/logo.png"),
  //   screenshot: require("./WhoIsRich/img/screenshot.png"),
  //   accentColor: "#6BDCFF",
  //   // background: "#6BDCFF",
  //   pages: [{ component: require("./WhoIsRich").default, slug: "whoisrich" }],
  // },
  {
    id: "richyrich",
    title: "Richy rich",
    logo: require("./RichyRich/img/logo.png"),
    screenshot: require("./RichyRich/img/screenshot.png"),
    instructions: require("./RichyRich/img/instructions.png"),
    accentColor: "#6BDCFF",
    resetStates: ["winner", "done", "live"],
    metadata: {
      description:
        "Contest for the richest! Collect falling bucks on your phone",
      type: "Fastest finger",
    },
    // background: "#6BDCFF",
    pages: [{ component: require("./RichyRich").default, slug: "richyrich" }],
  },
  {
    id: "shakethejar",
    title: "Shake The Jar",
    // logo: require("./IceTruck/src/assets/logo.png"),
    screenshot: require("./ShakeTheJar/src/assets/screenshot.png"),
    instructions: require("./ShakeTheJar/src/assets/instructions.png"),
    accentColor: "#6BDCFF",
    resetStates: ["winner"],
    metadata: {
      type: "Arcade",
    },
    // background: "#6BDCFF",
    pages: [
      { component: require("./ShakeTheJar").default, slug: "shakethejar" },
    ],
  },
  {
    id: "fastestfingers",
    title: "Fastest Fingers",
    // logo: require("./IceTruck/src/assets/logo.png"),
    screenshot: require("./FastestFingers/img/fastestfingers.png"),
    instructions: require("./FastestFingers/img/instructions.png"),
    accentColor: "#6BDCFF",
    resetStates: ["winner"],
    metadata: {
      type: "Arcade",
    },
    // background: "#6BDCFF",
    // pages: [
    //   { component: require("./FastestFingers").default, slug: "fastestfingers" },
    // ],
    pages: [
      {
        component: require("./FastestFingers").default,
        slug: "fastestfingers",
      },
      {
        component: require("./FastestFingers/game").default,
        slug: "fastestfingers/game",
      },
      {
        component: require("./FastestFingers/pages/RocketFuel/game").default,
        slug: "fastestfingers/rocketfuel/game",
      },
      {
        component: require("./FastestFingers/pages/FallingMnMs").default,
        slug: "fastestfingers/fallingmnms/game",
      },
    ],
  },
  {
    id: "unknown",
    title: "An Unknown Game",
    // logo: require("./IceTruck/src/assets/logo.png"),
    screenshot: require("./ShakeTheJar/src/assets/screenshot.png"),
    instructions: require("./ShakeTheJar/src/assets/instructions.png"),
    accentColor: "#6BDCFF",
    resetStates: ["winner"],
    metadata: {
      type: "Arcade",
    },
    pages: [],
  },
];

GamesDirectory.push(require("./MessyKeys").gameConfig);

export default GamesDirectory;
