import { usePlayersList } from "../../hooks/multiplayer";
import AvatarIcon from "../AvatarIcon";
import "./style.css";

export default function AvatarIconStrip({
  centered,
  bottom,
  showNames,
  mobileMode,
}) {
  const players = usePlayersList(true);
  console.log("pl", players);
  return (
    <div className={"player-avatar-strip" + (mobileMode ? " mobile" : "")}>
      {players.map((playerState) => (
        <div
          className="player-score-container"
          style={{ backgroundColor: playerState.getState("profile")?.color }}
        >
          <AvatarIcon key={playerState.id} playerState={playerState} />
          {showNames && <span>{playerState.getState("profile")?.name}</span>}
        </div>
      ))}
    </div>
  );
}
