export default class Grid {
  constructor({ width, height }) {
    this.width = width;
    this.height = height;
    this.cells = [];
    this.reset();
  }

  reset() {
    this.cells = Array(this.height)
      .fill(undefined)
      .map(() => Array(this.width).fill(undefined));
  }

  get(x, y) {
    if (x < 0 || x >= this.width || y < 0 || y >= this.height) return null;
    return this.cells[y][x];
  }

  set(x, y, value) {
    if (x < 0 || x >= this.width || y < 0 || y >= this.height) return;
    this.cells[y][x] = value;
    return true;
  }

  isFilled() {
    return this.cells.every((row) => row.every((cell) => cell !== undefined));
  }

  fillCount() {
    return this.cells.reduce(
      (count, row) => count + row.filter((cell) => cell !== undefined).length,
      0
    );
  }

  getAllOfValue(value, shuffle) {
    var cellsOfValue = [];
    this.cells.forEach((row, y) =>
      row.forEach((cell, x) => {
        if (cell === value) cellsOfValue.push({ x, y });
      })
    );
    return shuffle ? arrayShuffle(cellsOfValue) : cellsOfValue;
  }

  getEmptyCellNearValue(value) {
    const allCells = this.getAllOfValue(value);
    for (let cell of allCells) {
      // check all four sides around this cell that is empty
      if (this.get(cell.x - 1, cell.y) === undefined) {
        return { x: cell.x - 1, y: cell.y };
      }
      if (this.get(cell.x + 1, cell.y) === undefined) {
        return { x: cell.x + 1, y: cell.y };
      }
      if (this.get(cell.x, cell.y - 1) === undefined) {
        return { x: cell.x, y: cell.y - 1 };
      }
      if (this.get(cell.x, cell.y + 1) === undefined) {
        return { x: cell.x, y: cell.y + 1 };
      }
    }

    console.log(allCells);
    return false;
  }
}

function arrayShuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
