import { getFirestore } from "firebase/firestore";
import { collection, addDoc, setDoc, doc } from "firebase/firestore";
import Firebase from "./auth/firebase";
import Auth from "./auth";
import Analytics from "../analytics";
import { getMyId } from "./auth";
import Multiplayer from "./multiplayer";

const db = getFirestore(Firebase());
const WEEKDAY = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// Types of logs that are sent to log db
export const LOGTYPES = {
  ROOMSTATE: "ROOMSTATE",
  GAMESTATE: "GAMESTATE",
  USERSTATE: "USERSTATE",
};

const LOGTYPETOFRIENDLY = {
  ROOMSTATE: "Room",
  GAMESTATE: "Game",
  USERSTATE: "User",
};

export async function addLog(data) {
  data.t = Date.now();
  data.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  data.localTime = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  data.weekday = WEEKDAY[new Date().getDay()];
  if (process.env.NODE_ENV !== "production") {
    return console.log("analytics log:", data);
  }
  try {
    Analytics.track(
      `${LOGTYPETOFRIENDLY[data.type]}: ${data.event || ""}`,
      data
    );
    await addDoc(collection(db, "logs"), data);
    // console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export async function addUserLog(eventName, data) {
  const multiplayer = Multiplayer();
  try {
    data = data || {};
    data.type = LOGTYPES.USERSTATE;
    data.event = eventName;
    data.userId = getMyId();
    if (multiplayer.currentRoom) {
      data.roomId = multiplayer.currentRoom;
    }
    if (multiplayer.getState("uid")) {
      data.roomUid = multiplayer.getState("uid");
    }
    if (multiplayer.isConnected) {
      data.role = multiplayer.isHost
        ? "host"
        : multiplayer.isSpectator
        ? "spectator"
        : "player";
    }
    return addLog(data);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export async function setUserProperties(data) {
  if (process.env.NODE_ENV !== "production") return;
  try {
    const user = await Auth().getUser();
    if (user) {
      await setDoc(doc(db, "users", user.uid), data, { merge: true });
    }
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}
