import React, { useEffect, useRef, useState } from "react";
import ReactCodeInput from "react-code-input";
import Modal from "common/components/Modal";

import FingerPointer from "common/components/FingerPointer";

import "./style.css";

const inputStyleMobile = {
  fontFamily: "inherit",
  MozAppearance: "textfield",
  borderRadius: "20px",
  borderWidth: "3px",
  borderStyle: "solid",
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,.10)",
  margin: "4px",
  paddingLeft: "12px",
  paddingRight: 0,
  width: "52px",
  height: "69px",
  fontSize: "37px",
  boxSizing: "border-box",
  background: "transparent",
  outline: "none",
};

const inputStyleCastScreen = {
  ...inputStyleMobile,
  fontSize: "6rem",
  width: "8rem",
  textAlign: "center",
  height: "12rem",
  paddingLeft: "0",
  margin: "1rem",
};

export default function JoinRoomCodeModal({
  open,
  error,
  onClose,
  onFinish,
  fullScreen,
}) {
  const pinInputRef = useRef(null);
  const elementToBePointedAt = useRef(null);
  const [key, setKey] = useState(1); // rerender input on exit trick
  // const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    if (open) {
      setKey(key + 1);
      setTimeout(() => {
        if (pinInputRef && pinInputRef.current) {
          pinInputRef.current.textInput[0].focus();
        }
      }, 300);
    }
    // eslint-disable-next-line
  }, [open]);
  // if (!open) return false;
  return (
    <Modal
      className={"joinRoomModal " + (fullScreen ? "fullscreen" : "")}
      onClose={onClose}
      open={open}
      fullScreen={fullScreen}
    >
      {fullScreen && <div className="logo-small"></div>}
      {!fullScreen && <span className="desc">Enter room code</span>}
      {error ? (
        <span className="error">
          Could not connect to this room. Try again!
        </span>
      ) : (
        false
      )}

      {/* https://stackoverflow.com/questions/51963089/how-to-resolve-element-getboundingclientrect-is-not-a-function */}
      <div ref={elementToBePointedAt} 
      style={{
        height: "fit-content"
      }}
      >
      <ReactCodeInput
        key={key}
        ref={pinInputRef}
        // value={inputValue}
        onChange={(val) => {
          // setInputValue(val)
          if (val.length === 4) {
            onFinish(val);
          }
        }}
        inputStyle={fullScreen ? inputStyleCastScreen : inputStyleMobile}
        type="text"
        fields={4}
        forceUppercase={true}
      />
      </div>
      {fullScreen && (
      <>
        <span className="desc">
          Type the 4-digit room code shown on your Phone or shared by the host
          to start the game
        </span>
        <FingerPointer 
            pointAt={elementToBePointedAt}
            // text={"Cast on a second screen to play"}
            quickPositions={["bottom", "left"]}
            fingerPointerStyles={{
              transform: "translate(1rem, -1rem)",
            }}
            hideOnClick={true}
         />
        </>
      )}
    </Modal>
  );
}
