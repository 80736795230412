import React, { useEffect, useState } from "react";
import CardGame, { Card } from "common/components/CardGame";

import cardData from "../src/cardData";

import {
  usePlayersList,
  useGlobalState,
  useGlobalRoundState,
  usePlayerState,
  useOtherPlayerState,
  useMyPlayerState,
} from "common/hooks/multiplayer";

function randRange(minNum, maxNum) {
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}
function botMove(myState, handData) {
  const randomCard = handData[Math.floor(Math.random() * handData.length)];
  setTimeout(() => {
    if (myState.state.chosenCard) return;
    myState.setState("chosenCard", randomCard, true);
  }, randRange(3000, 8000));
}

const PlayerGame = ({ myState, multiplayer }) => {
  const handData = useOtherPlayerState(myState, "handData");
  const chosenCard = useOtherPlayerState(myState, "chosenCard");
  // const allPlayersChose = useGlobalRoundState("allPlayersChose", false);
  const live = useGlobalState("live");
  const winner = useGlobalState("winner");
  const throwDisabled =
    !live || // The game has not even started
    !!chosenCard || // You have already chosen a card
    // allPlayersChose || // Everyone else has selected (means you have too)
    winner !== undefined; // The game has ended and a winner has been announced
  if (window.ISBOT && !throwDisabled) botMove(myState, handData);

  return (
    <CardGame
      interactionMode={["throw"]}
      cardData={handData}
      onDropzoneCardsChange={(dropzoneData) => {
        if (dropzoneData.length === 0 || !dropzoneData[0]) {
          myState.setState("chosenCard", undefined, true);
          return;
        }

        // ----------- PRINT DEBUG -----------
        // console.log("Dropzone Data: ", dropzoneData[0]);
        // ----------- PRINT DEBUG -----------

        myState.setState("chosenCard", dropzoneData[0], true);
      }}
      onHandCardsChange={(handData) => {
        myState.setState("handData", handData, true);
      }}
      throwDisabled={throwDisabled}
      discardUsedCards={false}
      // disableThrowOnDropzone={true}
      dropzoneText={"Pick a card and throw here"}
      dropzoneStyles={{
        marginTop: "10.75rem",
        border: "6px dashed black",
        background: "transparent",
        width: "80%",
        height: "11.25rem",
        overflow: "visible",
        color: "#FFFB8F",
        visibility: live ? "visible" : "hidden",
      }}
      gameContainerStyles={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        height: "100vh",
        overflowX: "hidden",
        overflowY: "hidden",
        width: "100vw",
        marginTop: "0",
        marginLeft: "0",
        zIndex: "99",
      }}
      touchCarouselStyles={{
        overflowX: "hidden",
        display: "flex",
      }}
      loop={false}
      touchCarouselProps={{
        //                          We don't want this running on subsequent hand changes. Just the first time.
        defaultCursor:
          handData && cardData.length === handData.length
            ? -Math.floor(handData.length / 2)
            : 0,
      }}
    />
  );
};

export default PlayerGame;
