import BGMusic, { BackgroundMusic } from "./backgroundMusic";

// const _BGM = BGMusic("game");

// another singleton just for human voice parts
// so only one audio plays at a time.
// Also lowers bgmusic to play narration
export class BackgroundNarrator extends BackgroundMusic {
  constructor(channel, bgMusicChannel) {
    super(channel || 1000);
    this.lastBGSoundVolume = 1;
    this.channel = channel || 1000;
    console.log("bgMusicChannel:", bgMusicChannel);
    this.bgMusicChannel = bgMusicChannel
    // this.BGM = BGMusic(bgMusicChannel);
  }
  play(audio) {
    var bgVolume = BGMusic(this.bgMusicChannel).getVolume();
    this.lastBGSoundVolume = bgVolume;
    if (bgVolume > 0.1) BGMusic(this.bgMusicChannel).setVolume(0.1, true);
    super.play(audio, this.channel, false, false, () => {
      BGMusic(this.bgMusicChannel).setVolume(bgVolume, true);
    });
  }

  stop() {
    super.stop(this.channel);
    BGMusic(this.bgMusicChannel).setVolume(this.lastBGSoundVolume, true);
  }
}

export default function createSingleton() {
  if (!window._bgnarrator) {
    window._bgnarrator = new BackgroundNarrator();
  }

  return window._bgnarrator;
}
