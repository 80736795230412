import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import BackgroundMusic from "common/modules/backgroundMusic";
import "./style.css";

import {
  useGlobalState,
} from "common/hooks/multiplayer";

const StartScreen = ({ startScreenBackgroundMusic, InstructionsCard, videoLink, children, multiplayer }) => {
  
  const playInstructions = useGlobalState("playInstructions");
  
  useEffect(() => {
    if (startScreenBackgroundMusic) {
      BackgroundMusic().stop();
      BackgroundMusic("timer").stop();
      BackgroundMusic("game").stop();

      console.log("----------- startscreen music play...")
      setTimeout(() => {
        BackgroundMusic().play(startScreenBackgroundMusic);
      }, 2000);
    }

    return () => {
      if (startScreenBackgroundMusic) BackgroundMusic().stop();
    }
  }, [startScreenBackgroundMusic]);

  useEffect(() => {
    if (playInstructions){
      BackgroundMusic().setVolume(0);
    }
    else {
      BackgroundMusic().setVolume(1);
    }
  }, [playInstructions]);

  return (
    <>
      {playInstructions ? (
        <div
          style={{
            position: "fixed",
            zIndex: 99998,
            width: "100%",
            height: "100%",
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              width: "100%",
              height: "fit-content",
            }}
          >
            <iframe
              src="https://olafwempe.com/mp3/silence/silence.mp3"
              type="audio/mp3"
              allow="autoplay"
              id="audio"
              style={{
                display: "none",
              }}
            ></iframe>
              <ReactPlayer
                width="60vw"
                height="34vw"
                autoPlay
                playing={true}

                // Probably won't show Pasoori from now on,
                // but instead, other videos from Playroom YouTube channel.
                // https://www.maxlaumeister.com/articles/hide-related-videos-in-youtube-embeds/
                url={`${videoLink}${`${videoLink.includes("?") ? "&" : "?"}autoplay=1&rel=0`}`}

                style={{
                  borderRadius: "2rem",
                  overflow: "hidden",

                  // If only these could be applied together, in sort of a ratio.
                  // minHeight: "640px",
                  // minWidth: "360px",
                }}
              />
          </div>
        </div>
      ) : (
        <div
          className="centered-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: "1",
            width: "max-content",
          }}
        >
          {/* <div
            className="space-container dialog-container start-screen-dialog-container"
          > */}
            {InstructionsCard}
          {/* </div> */}
        </div>
      )}
      {children}
    </>
  );
};

export default StartScreen;