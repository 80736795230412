import React, { useEffect, useState } from "react";
// import { useLocation, useHistory } from "react-router-dom";
// import ResizeImage from "common/modules/resizeImage";
// import Multiplayer from "common/modules/multiplayer";
import { useGlobalState, usePlayerState } from "common/hooks/multiplayer";
// import BrowserStorage from "common/modules/localStorageWrapper";
// import * as loadImage from "blueimp-load-image";

import "./style.css";
import GamesMenu from "common/components/GamesMenu";

// const URLHash = require("common/modules/urlhash");

function randRange(minNum, maxNum) {
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}

function Profile() {
  // const location = useLocation();
  // const history = useHistory();
  // const multiplayer = Multiplayer();
  const currentURL = useGlobalState("iframeUrl");

  return (
    <>
      <GamesMenu
        key="gamesmenu"
        // gameInfo={gameInfo}
        hideRoomCode
      />
      <div className="sdk-iframe-container">
        <iframe src={currentURL}></iframe>
      </div>
    </>
  );
}

export default Profile;
