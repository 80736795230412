import Multiplayer from "common/modules/multiplayer";
import RoundsWrapper from "common/components/RoundsWrapper";
import {
  usePlayersList,
  useGlobalState,
  useMyPlayerState,
  useGlobalRoundState,
} from "common/hooks/multiplayer";

import Round from "./round";
import NativeMethods from "common/modules/NativeMethods";

import React, { useEffect, useState } from "react";
import GamesMenu from "common/components/GamesMenu";
import BackgroundGame from "common/components/BackgroundGame";
import BackgroundMusic from "common/modules/backgroundMusic";
import castingBackgroundImg from "./img/casting-background.png";
import ControllerHUD from "common/components/ControllerHUD";
import Timer from "common/components/Timer";
import PlayerGame from "./components/PlayerGame";

import PlayerIndicators from "./components/PlayerIndicators";
// import "./style.css";
// import "./card-fan.css";
// import "./starry-night.css";

import UIfx from "common/modules/uifx";

const BGMusic = BackgroundMusic();

function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export default function GamePage({ gameInfo }) {
  const multiplayer = Multiplayer();
  const [countdown, setCountdown] = useState(3);
  const players = usePlayersList();
  const myState = multiplayer.getMyPlayerState();
  const totalRounds = 3;
  const currentRound = useGlobalState("_round", 0);
  const winner = useGlobalState("winner");
  const timer = useGlobalState("timer");

  const myPoints = useMyPlayerState("points", 0);
  const [myPointsPrev, setMyPointsPrev] = useState(0);
  const [showPoints, setShowPoints] = useState(null);

  useEffect(() => {
    if (myPoints) {
      let scoreUpdatedBy = myPoints - myPointsPrev;
      console.log("scoreUpdatedBy: ", scoreUpdatedBy);
      console.log("My phone should vibrate!");
      NativeMethods.haptic("heavy");
      setMyPointsPrev(myPoints);
      setShowPoints(scoreUpdatedBy);
      setTimeout(() => {
        setShowPoints(null);
      }, 2500);
    }
  }, [myPoints]);

  useEffect(() => {
    async function startCountdown() {
      // start countdown.
      // setCountdown(3);
      // await sleep(1500);
      // setCountdown(2);
      // await sleep(1500);
      // setCountdown(1);
      // await sleep(1500);
      multiplayer.setState("live", true);
      // setCountdown(false);
    }

    // Reduce volume of playroom music
    // BGMusic.setVolume(0, true);

    console.log("loading game page...");

    if (multiplayer.isSpectator) {
      startCountdown();
    }
    return () => {
      if (multiplayer.isSpectator) {
        BackgroundMusic("timer").stop();
      }
    };
  }, []);

  return (
    <>
      <BackgroundGame
        style={{
          // backgroundImage: `url(${castingBackgroundImg})`,
          backgroundSize: "cover",
        }}
      ></BackgroundGame>

      <GamesMenu
        key="gamesmenu"
        gradient
        hideRoomCode
        gameInfo={gameInfo}
        showRestart={winner && multiplayer.isHost}
      />

      <RoundsWrapper
        totalRounds={totalRounds}
        RoundComponent={Round}
        gameInfo={gameInfo}
      />
    </>
  );
}
