import React, { useEffect, useState } from "react";
import PlayerIndicator from "./PlayerIndicator";

import calculatePlayerSizeAndPosition from "common/utils/scalePlayers";

import { useMeasure } from "react-use";

function PlayerIndicators({ players, winner, showAllCards, challengeWinners, showPlayerScore, delayBetweenPlayers}) {

  // ---------------- START SCALABLE ROW ---------------- 
  // we just need the width of the first player
  const [ref, { width }] = useMeasure();

  // We can use playerScale to scale down each player.
  // We don't need X, it will be automatically calculated with justifyContent.
  const [playerSizeAndPosition, setPlayerSizeAndPosition] = useState(calculatePlayerSizeAndPosition(
    players.length, 
    0, 
    window.innerWidth,
    width,
  ))

  useEffect(() => {
    setPlayerSizeAndPosition(calculatePlayerSizeAndPosition(
      players.length, 
      0, 
      window.innerWidth,
      width,
      50, 
      200,
    ))
  }, [width]);
  // ---------------- END SCALABLE ROW ---------------- 

  return (
    <div
      // className={"rockets-container " + (winner ? "won" : "")}
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-around",
        padding: "30px 50px",
      }}
    >
      {players.map((playerState, idx) => {
        const profile = playerState.getState("profile");
        // const myPoints = playerState.getState("points");

        const showTime = idx * delayBetweenPlayers;

        if (profile) {
          return (
            <PlayerIndicator
              containerRef={idx === 0 ? ref : undefined}
              playerName={profile.name}
              color={profile?.color || "red"}
              playerState={playerState}
              hasWon={winner === playerState.id}
              reveal={showAllCards}
              isActive={
                challengeWinners
                  ? challengeWinners.find(
                      (challengeWinner) => challengeWinner === playerState.id
                    )
                  : false
              }
              idx={idx}
              showPlayerScore={showPlayerScore}
              showTime={showTime}
              
              containerStyles={{
                transform: playerSizeAndPosition?.playerScale ? `scale(${playerSizeAndPosition.playerScale})` : undefined,
              }}
            />
          );
        }
        return <></>;
      })}
    </div>
  );
}

export default PlayerIndicators;
