import Phaser from "common/components/GameRenderer/phaser";
import Multiplayer from "common/modules/multiplayer";
import imgBean from "../img/bean.png";
import NativeMethods from "common/modules/NativeMethods";
import GameConfig from "common/components/GameRenderer/config";

import sfxPop from "../sfx/pop.mp3";

let SPAWN_EVERY = 400;

function randRange(minNum, maxNum) {
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}

// this is a control scene, no need to extend CommonGameScene
export class GameScene extends Phaser.Scene {
  constructor() {
    super({
      key: "GameScene",
    });
    window.currentScene = this;

    this.lastSpawn = 0;
    this.totalMyCirclesThatSpawn = 160;
    this.circles = [];
    this.multiplayer = Multiplayer();
    this.scaleFactor = 1;
    window.currentScene = this;
  }

  preload() {
    this.load.image("bean", imgBean);

    this.load.audio("pop", sfxPop);

    const playerPhoto = this.multiplayer
      .getMyPlayerState()
      .getState("profile").photo;
    if (playerPhoto) {
      this.textures.addBase64("player", playerPhoto);
    } else {
      this.load.image("player", "/avatars/default-photo.png");
    }
  }

  create() {
    this.gameSize = this.sys.game.scale.gameSize;
    this.scaleFactor = this.gameSize.width / GameConfig.baseWidth;
    //  A drop zone
    const dropZoneSize = this.gameSize.width * 0.1;
    const borderWidth = 30;
    const margin = 30;
    const x = margin + borderWidth + dropZoneSize / 2;
    const y = this.gameSize.height - dropZoneSize / 2 - borderWidth - margin;
    // this.add
    //   .zone(x, y, dropZoneSize, dropZoneSize)
    //   .setRectangleDropZone(dropZoneSize, dropZoneSize);

    //  Just a visual display of the drop zone
    // this.add.circle(
    //   x,
    //   y,
    //   dropZoneSize / 2 + borderWidth,
    //   Phaser.Display.Color.HexStringToColor(
    //     this.multiplayer.getMyPlayerState().getState("profile").color
    //   ).color
    // );

    // const playerAvatarImg = this.add.image(x, y, "player");
    // playerAvatarImg.displayWidth = dropZoneSize;
    // playerAvatarImg.displayHeight = dropZoneSize;
    // const circle = this.add
    //   .graphics()
    //   .setPosition(x, y)
    //   .fillCircle(0, 0, dropZoneSize / 2);
    // playerAvatarImg.setMask(circle.createGeometryMask());
    // playerAvatarImg.depth = 3;

    // this.input.on("dragstart", (pointer, gameObject) => {
    //   this.children.bringToTop(gameObject);
    //   gameObject.setStatic(true);
    // });

    // this.input.on("drag", (pointer, gameObject, dragX, dragY) => {
    //   gameObject.x = dragX;
    //   gameObject.y = dragY;
    // });

    // this.input.on("drop", (pointer, gameObject, dropZone) => {
    //   gameObject.x = dropZone.x;
    //   gameObject.y = dropZone.y;
    //   gameObject.alpha = 0;

    //   gameObject.input.enabled = false;
    //   const points = gameObject.data.get("points");
    //   const myState = this.multiplayer.getMyPlayerState();
    //   myState.setState("score", (myState.getState("score") || 0) + points);
    //   this.plusOneText.setText(`${points === 1 ? "+1" : "-1"}`);
    //   if (points === 1) {
    //     NativeMethods.haptic("rigid");
    //     this.plusOneText.setColor("#000000");
    //   } else {
    //     NativeMethods.haptic("heavy");
    //     // this.cameras.main.shake(100);
    //     this.plusOneText.setColor("#ff0000");
    //   }
    //   this.tweens.add({
    //     targets: this.plusOneText,
    //     alpha: { from: 1, to: 0 },
    //     duration: 1000,
    //     ease: "Power1",
    //     repeat: 0,
    //     yoyo: false,
    //     y: { from: y - dropZoneSize, to: y - dropZoneSize * 2 },
    //   });
    // });

    // this.input.on("dragend", (pointer, gameObject, dropped) => {
    //   if (!dropped) {
    //     gameObject.x = gameObject.input.dragStartX;
    //     gameObject.y = gameObject.input.dragStartY;
    //     gameObject.setStatic(false);
    //   }
    // });

    var style = {
      font: this.adjustByScale(80) + "px 'Lilita One'",
      fill: "#000000",
      wordWrap: true,
      align: "center",
      padding: {
        left: 20,
        right: 20,
        top: 10,
        bottom: 10,
      },
    };

    this.plusOneText = this.add.text(
      x + dropZoneSize / 2,
      y - dropZoneSize,
      "",
      style
    );

    this.hapticSpam();
  }

  waitFor(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async hapticSpam() {
    NativeMethods.haptic("heavy");
    await this.waitFor(100);
    NativeMethods.haptic("heavy");
    await this.waitFor(100);
    NativeMethods.haptic("heavy");
    await this.waitFor(100);
    NativeMethods.haptic("heavy");
    await this.waitFor(100);
    NativeMethods.haptic("heavy");
    await this.waitFor(100);
  }

  spawnCircle() {
    console.log("spawning circle");
    const players = this.multiplayer.getPlayers();
    const myColor = this.multiplayer
      .getMyPlayerState()
      .getState("profile").color;
    const otherColors = Object.keys(players)
      .map((playerId) => players[playerId].getState("profile").color)
      .filter((color) => color !== myColor);
    const color =
      this.totalMyCirclesThatSpawn > 0 && Math.random() < 0.7 // chance that it will be my color
        ? myColor // pick my color
        : otherColors[Math.floor(Math.random() * otherColors.length)]; // pick a random other color

    if (color === myColor) {
      this.totalMyCirclesThatSpawn--;
      console.log("spawning my circle", this.totalMyCirclesThatSpawn);
    }
    var bean = this.matter.add.image(
      randRange(0, this.gameSize.width),
      -300,
      "bean",
      null,
      { restitution: 0.6, frictionAir: 0, mass: 0.1 }
    );
    bean.setDataEnabled();
    if (
      color === this.multiplayer.getMyPlayerState().getState("profile").color
    ) {
      bean.data.set("points", 1);
    } else {
      bean.data.set("points", -1);
    }
    bean.setScale(this.adjustByScale(1.5));
    bean.setCollisionGroup(-1);
    bean.depth = 4;
    bean.setTint(Phaser.Display.Color.HexStringToColor(color).color);
    bean.setInteractive();

    bean.on("pointerdown", (pointer) => {
      bean.alpha = 0;

      bean.input.enabled = false;
      // bean.body.stop();
      bean.body.allowGravity = false;
      const points = bean.data.get("points");
      const myState = this.multiplayer.getMyPlayerState();
      if (!this.multiplayer.getRoundState("done")) {
        myState.setState("score", (myState.getState("score") || 0) + points);
        this.plusOneText.setText(`${points === 1 ? "+1" : "-1"}`);
      }
      if (points === 1) {
        // POSITIVE FEEDBACK: add pop sound on phone when beans are collected
        this.sound.play("pop");

        NativeMethods.haptic("rigid");
        this.plusOneText.setColor("#000000");
      } else {
        NativeMethods.haptic("heavy");
        // this.cameras.main.shake(100);
        this.plusOneText.setColor("#ff0000");
      }
      this.tweens.add({
        targets: this.plusOneText,
        alpha: { from: 1, to: 0 },
        duration: 1000,
        ease: "Power1",
        repeat: 0,
        yoyo: false,
        x: { from: pointer.x, to: pointer.x },
        y: { from: pointer.y, to: pointer.y - this.adjustByScale(100) },
      });
    });
    // this.input.setDraggable(bean);
    this.circles.push(bean);
  }

  cleanupCircles() {
    this.circles.forEach((circle, index) => {
      if (circle.y > this.gameSize.height) {
        circle.destroy();
        this.circles.splice(index, 1);
      }
    });
  }

  update() {
    super.update();
    const gameIsLive = this.multiplayer.getRoundState("live");
    const winner = this.multiplayer.getRoundState("winner");
    if (gameIsLive && !winner) {
      if (Date.now() - this.lastSpawn > SPAWN_EVERY) {
        this.cleanupCircles();
        this.spawnCircle();
        this.lastSpawn = Date.now();
        SPAWN_EVERY -= 5;
        if (SPAWN_EVERY <= 100) SPAWN_EVERY = 100;
        console.log("spawnevery", SPAWN_EVERY);
      }
    }
  }

  adjustByScale(value) {
    return value * this.scaleFactor;
  }
}
