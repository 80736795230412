// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAuth, signInAnonymously } from "firebase/auth";
let envVars = global.envVars;
if (!envVars) {
  envVars = process.env;
}
// Your web app's Firebase configuration
const firebaseConfig = {
  appId: envVars.REACT_APP_FIREBASE_APP_ID,
  apiKey: envVars.REACT_APP_FIREBASE_API_KEY,
  authDomain: envVars.REACT_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: envVars.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: envVars.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: envVars.REACT_APP_FIREBASE_PROJECT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default function getApp() {
  return app;
}
