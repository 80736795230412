import React, { useEffect, useState } from "react";
import ComposedAvatar from "./ComposedAvatar";

import { Card } from "common/components/CardGame";
import CartoonDialogBox from "common/components/CartoonDialogBox";

import tickImg from "../img/tick.png";

import UIfx from "common/modules/uifx";
import turnSound from "../sfx/turn-sound.mp3";

import point1Sound from "../sfx/point-1.mp3";
import point2Sound from "../sfx/point-2.mp3";

import NativeMethods from "common/modules/NativeMethods";
import {
  useGlobalState,
  useOtherPlayerState,
  useMyPlayerState,
  useGlobalRoundState,
} from "common/hooks/multiplayer";

import { setIntervalX } from "../utils";

export default function PlayerIndicator({
  color,
  fuelPercent,
  hasWon,
  // isDone,
  playerName,
  playerState,
  reveal,
  isActive,
  idx,
  showTime,
  showPlayerScore,
  containerRef,
  containerStyles,
  // myPoints,
}) {
  const [sfxTurn, setSfxTurn] = useState();
  const [sfxPoint1, setSfxPoint1] = useState();
  const [sfxPoint2, setSfxPoint2] = useState();

  useEffect(() => {
    setSfxTurn(UIfx(turnSound));
    setSfxPoint1(UIfx(point1Sound));
    setSfxPoint2(UIfx(point2Sound));
  }, []);

  const myPoints = useOtherPlayerState(playerState, "points");
  const chosenCard = useOtherPlayerState(playerState, "chosenCard");

  const [revealCard, setRevealCard] = useState(false);

  const [myPointsPrev, setMyPointsPrev] = useState(0);
  const [showPoints, setShowPoints] = useState(null);

  const allPlayersChose = useGlobalRoundState("allPlayersChose", false);

  const [scoreUpdated, setScoreUpdated] = useState(0);

  // play sound on flipping the card
  // have some delay when revealing the card
  useEffect(() => {
    if (allPlayersChose) {
      setTimeout(() => {
        console.log("revealing card");
        sfxTurn.play();
        setRevealCard(true);
      }, (showTime || 0.4 * idx) * 1000);
    } else {
      setRevealCard(false);
    }
  }, [allPlayersChose, showTime]);

  // if myPoints are changed, do some vibrations!
  useEffect(() => {
    if (myPoints) {
      let scoreUpdatedBy = myPoints - myPointsPrev;
      console.log("scoreUpdatedBy: ", scoreUpdatedBy);
      console.log("My phone should vibrate!");
      NativeMethods.haptic("heavy");
      setMyPointsPrev(myPoints);

      setScoreUpdated(scoreUpdatedBy);

      setIntervalX(
        () => {
          setShowPoints(0);
          if (scoreUpdatedBy <= 1) {
            sfxPoint1.play();
          } else if (scoreUpdatedBy > 1) {
            sfxPoint2.play();
          }
          setShowPoints(1);
        },
        450,
        scoreUpdatedBy,
        () => {
          setTimeout(() => {
            setShowPoints(0);
            setScoreUpdated(0);
          }, 1500);
        }
      );
    }
  }, [myPoints]);

  const [_isActive, setIsActive] = useState(isActive);

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        setIsActive(true);
      }, 700);
    } else {
      setIsActive(false);
    }
  }, [isActive]);

  return (
    <div
      style={{
        ...{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
          zIndex: 995,
          height: "100%",
          // paddingBottom: "12rem",
          overflow: "visible",
          height: "12rem",
          width: "3rem",
        },
        ...containerStyles,
      }}
      ref={containerRef}
    >
      {/* Tests for sound */}

      {/* <button onClick={() => {
        sfxPoint1.play();
      }}>Click me for points sound!</button> 
        <button onClick={() => {
        sfxTurn.play();
      }}>Click me for card turn sound!</button>  */}
      {/* <div style={{position:"absolute", top: "-10rem",}}> */}
      {allPlayersChose ? (
        chosenCard && revealCard ? (
          chosenCard.id < 0 ? (
            <h2 style={{ color: "white" }}>Turn missed!</h2>
          ) : (
            <div className="flippable-card-wrapper-1">
              <div className="flippable-card-wrapper-2">
                <div className={_isActive ? "scalable-card-wrapper-1" : ""}>
                  <div className={_isActive ? "scalable-card-wrapper-2" : ""}>
                    <Card
                      backgroundImage={chosenCard.backgroundImage}
                      backgroundColor={chosenCard.backgroundColor}
                      cardStyle={{
                        ...chosenCard.cardStyles,
                        ...{
                          // animation: `flip 0.5s`,
                          // transform: _isActive
                          //   ? "scale(1.55) translateY(-2rem)"
                          //   : undefined,
                          transition: "1s",
                          minHeight: "180px",
                          minWidth: "130px",
                        },
                      }}
                      cardImage={`url("${chosenCard.image}")`}
                      title={chosenCard.title}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="mini-card selected" style={{ opacity: 0 }}>
            <img src={`${tickImg}`} alt="" />
          </div>
        )
      ) : chosenCard ? (
        <div className="mini-card selected">
          <img src={`${tickImg}`} alt="" />
        </div>
      ) : (
        <div className="mini-card selected" style={{ opacity: 0 }}>
          <img src={`${tickImg}`} alt="" />
        </div>
      )}
      {/* </div> */}

      <div
        style={
          {
            // position: "absolute",
            // bottom: 0,
          }
        }
      >
        <ComposedAvatar
          color={color}
          size={10}
          playerState={playerState}
          showPlayerScore={showPlayerScore}
          idx={idx}
        />
      </div>

      {showPoints && showPoints > 0 ? (
        <div
          className="player-indicator-score"
          style={{
            animationDuration: scoreUpdated ? "1000ms" : "400ms",
          }}
        >
          <div
            className="space-container"
            style={{
              padding: "0.3rem 1.4rem",
              fontSize: "1.4rem",
            }}
          >
            {" "}
            {`+${showPoints}`}{" "}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
