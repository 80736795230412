import Phaser from "common/components/GameRenderer/phaser";
import Multiplayer from "common/modules/multiplayer";
import TouchJoystick from "common/modules/controllers/touch";
import GameConfig from "./config";

export default class CommonGameScene extends Phaser.Scene {
  constructor(props = { autoscale: true }) {
    super({
      key: "GameScene",
    });

    this.players = {};
    this.autoscale = props.autoscale;
    this._timer = null;
    this.scaleFactor = GameConfig.width / GameConfig.baseWidth;
    this.gameSize = null;
    window.currentScene = this;
  }
  create() {
    this.gameSize = this.sys.game.scale.gameSize;
    this.scaleFactor = this.gameSize.width / GameConfig.baseWidth;
    if (this.autoscale) this._resetCameraScaleZoom();
    this.multiplayer = Multiplayer();
    this.handlePlayerJoin = this.handlePlayerJoin.bind(this);
    this._handleStateChange = this._handleStateChange.bind(this);

    const muted = this.multiplayer.getState("muted");
    if (muted) this.sys.game.sound.volume = 0;

    this.multiplayer.on("state", this._handleStateChange);
    if (!this.multiplayer.isSpectator) {
      this.controller = new TouchJoystick();
      this.multiplayer.attachController(this.controller);
    }

    this.events.on("destroy", () => {
      this.multiplayer.off("state", this._handleStateChange);
      this.multiplayer.detachController();
      if (this.controller) this.controller.destroy();
    });
  }

  setAsBackground(gameObject, stretch) {
    this._background = gameObject;
    this._backgroundStretch = stretch;
    this._resetCameraScaleZoom();
  }

  _resetCameraScaleZoom() {
    const ratioX =
      window.innerWidth / this.sys.canvas.getBoundingClientRect().width;
    const ratioY =
      window.innerHeight / this.sys.canvas.getBoundingClientRect().height;
    const minRatio = Math.min(ratioX, ratioY);
    if (this.cameras.main.zoom !== minRatio) {
      this.cameras.main.setZoom(minRatio);
      setTimeout(() => {
        // need to do this because worldView is not updated immediately
        // and we need that to update the background
        this._resetCameraScaleZoom();
      }, 100);
    }

    if (this._background) {
      if (this._backgroundStretch) {
        // this._background.setDisplaySize(
        //   this.gameSize.width + (this.sys.canvas.getBoundingClientRect().width - window.innerWidth),
        //   this.gameSize.height + (this.sys.canvas.getBoundingClientRect().height - window.innerHeight)
        // );
        // this._background.setOrigin(0, 0);
        const cameraWorld = this.cameras.main.worldView;
        this._background.setDisplaySize(cameraWorld.width, cameraWorld.height);
        this._background.setOrigin(0, 0);
        this._background.x = cameraWorld.x;
        this._background.y = cameraWorld.y;
      } else {
        // fill while maintaining aspect ratio
        let inverseRatio = 1 / minRatio;
        this._background.setScale(inverseRatio);
        this._background.y =
          (this.gameSize.height -
            this.gameSize.height * this._background.scale) /
          2;
        this._background.x =
          (this.gameSize.width - this.gameSize.width * this._background.scale) /
          2;
      }
    }
    // const ratioX = window.innerWidth / this.gameSize.width;
    // const ratioY = window.innerHeight / this.gameSize.height;
    // this.cameras.main.setZoom(Math.max(ratioX, ratioY));
    // this.cameras.main.setPosition(
    //   (this.gameSize.width * ratioX) / 2,
    //   (window.innerHeight * ratioY) / 2
    // )
  }

  _handleStateChange(wholeState, key) {
    if (key === "muted") {
      this.sys.game.sound.volume = wholeState["muted"] ? 0 : 1;
    }
  }

  async handlePlayerJoin(playerState, i) {
    console.log("new player", playerState.id);

    // place just the state until sprite is ready. so update doesnt call this method again for this player.
    this.players[playerState.id] = { state: playerState };
    let sprite = await this.addPlayerSprite(
      playerState,
      playerState.getState("profile"),
      i
    );
    playerState.on("quit", () => {
      if (this.handlePlayerQuit) this.handlePlayerQuit(playerState);
      if (sprite) sprite.destroy();
      delete this.players[playerState.id];
    });

    // we load the profile for the first time and then add the player sprite
    // playerState.on('profile', async (profile) => {
    //   if (this.handleProfileChange){
    //     this.handleProfileChange(playerState, profile);
    //   }
    // });

    this.players[playerState.id] = { state: playerState, sprite };
    if (this.afterPlayerCreated)
      this.afterPlayerCreated(playerState.id, sprite, playerState, i);
  }

  async addPlayerSprite() {
    throw new Error("addPlayerSprite is not defined by derived class");
  }
  update() {
    // add any new players
    const players = this.multiplayer.getPlayers();
    Object.keys(players).forEach((playerId, i) => {
      if (!this.players[playerId] && players[playerId].getState("profile")) {
        this.handlePlayerJoin(players[playerId], i);
      }
    });

    Object.keys(this.players).forEach((playerId) => {
      const state = this.players[playerId].state;
      const player = this.players[playerId].sprite;
      if (!player) return;

      // RenderServer does all the computation and inputs, other players only render player at x,y
      if (this.multiplayer.isRenderServer()) {
        if (this.updatePlayerHost)
          this.updatePlayerHost(playerId, player, state);
      } else {
        if (this.updatePlayerNonHost)
          this.updatePlayerNonHost(playerId, player, state);
      }

      if (this.updateCommon) this.updateCommon(playerId, player, state);
      if (this.updatePlayer) this.updatePlayer(playerId, player, state);
    });
  }

  addTimer(durationInMs, callbackOnFinish, config) {
    // if (this.multiplayer) {
    //   // broadcast timer length to the global multiplayer state
    //   console.log("broadcast timer to multiplayer");
    //   this.multiplayer.setState("timer", durationInMs);

    //   // broadcast time elapsed since the timer was added
    //   const elapsedTimeIntervalInstance = setInterval(() => {
    //     this.multiplayer.setState("elapsedTime", this.multiplayer.getState("elapsedTime") !== undefined ?
    //       (Math.floor(this.multiplayer.getState("elapsedTime"))+1000) :
    //       0.1
    //     );

    //     // ------------- PRINT DEBUG -------------
    //     // console.log("elapsedTime: ", this.multiplayer.getState("elapsedTime"));
    //     // console.log("durationInMs: ", durationInMs);
    //     // ------------- PRINT DEBUG -------------

    //     // safety hatch incase things go south
    //     if (this.multiplayer.getState("elapsedTime") >= durationInMs) {
    //       // clear all global timer-related states
    //       this.multiplayer.setState("timer", undefined);
    //       this.multiplayer.setState("elapsedTime", undefined);
    //       clearInterval(elapsedTimeIntervalInstance);
    //       clearInterval(this.multiplayer.getState("elapsedTimeIntervalInstance"));
    //       this.multiplayer.setState("elapsedTimeIntervalInstance", undefined);
    //     }
    //   }, 1000);

    //   // save the interval instance so it can be safely cleared
    //   this.multiplayer.setState("elapsedTimeIntervalInstance", elapsedTimeIntervalInstance);

    // }
    const defaultTimerConfig = {
      posX: this.adjustByScale(50),
      posY: this.adjustByScale(50),
    };
    config = { ...defaultTimerConfig, ...config };
    const size = this.adjustByScale(38);
    const { posX, posY } = config;
    var graphics = this.add.graphics();
    graphics.setDepth(10000);
    this.tweens.addCounter({
      from: 0,
      to: 360,
      duration: durationInMs,
      onUpdate: (tween) => {
        graphics.clear();

        // draw bg
        graphics.fillStyle(0xffffff, 0.2);
        graphics.slice(posX, posY, size, 0, Math.PI * 2);
        graphics.fillPath();

        // draw progress
        graphics.fillStyle(0xf9da4b, 1);
        graphics.slice(
          posX,
          posY,
          size,
          Phaser.Math.DegToRad(-90),
          Phaser.Math.DegToRad(tween.getValue() - 90),
          Phaser.Math.DegToRad(360)
        );
        graphics.fillPath();
        if (this.multiplayer) {
          this.multiplayer.setState(
            "timer",
            parseInt((tween.getValue() / 360) * 100)
          );
        }
      },
      onComplete: () => {
        graphics.clear();
        // draw just bg
        graphics.fillStyle(0xffffff, 0.2);
        graphics.slice(posX, posY, size, 0, Math.PI * 2);
        graphics.fillPath();
        if (callbackOnFinish) callbackOnFinish();

        // // clear all global timer-related states
        if (this.multiplayer) {
          this.multiplayer.setState("timer", undefined);
          // this.multiplayer.setState("elapsedTime", undefined);
          // clearInterval(this.multiplayer.getState("elapsedTimeIntervalInstance"));
          // this.multiplayer.setState("elapsedTimeIntervalInstance", undefined);
        }
      },
    });
  }

  adjustByScale(value) {
    return value * this.scaleFactor;
  }
}
