var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

// Enable this line to see how game will look on TV via airplay
// isMobile = true;

isMobile = false;

const ASPECT_RATIO = 16 / 9;
const BASEWIDTH = 1040;
const GAMEWIDTH = BASEWIDTH * (isMobile ? 1 : 2);

let config = {
  baseWidth: BASEWIDTH,
  baseHeight: BASEWIDTH / ASPECT_RATIO,
  width: GAMEWIDTH,
  height: GAMEWIDTH / ASPECT_RATIO,
};

export default config;
