// helper to wrap the world horizontally:
// if player goes to the left of the world, it will come back from the right and so on
export default class WrapPortal {
  constructor(gameSize, padding) {
    this.gameSize = gameSize;
    this.padding = padding;
  }
  checkWrap(sprite) {
    if (
      sprite.x >=
      this.gameSize.width + sprite.displayWidth / 2 + this.padding
    ) {
      sprite.x = -sprite.displayWidth / 2;
    }

    if (sprite.x <= -(sprite.displayWidth / 2) - this.padding) {
      sprite.x = this.gameSize.width + sprite.displayWidth / 2;
    }
  }
}
