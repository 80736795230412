import CommonGameScene from "common/components/GameRenderer/gamescene";
import { AvatarPhaserHelper } from "common/modules/avatarCreator";
import resetGameStates from "../resetStates";
import ballImg from "../img/ball.png";
import courtImg from "../img/court.png";
import netImg from "../img/net.png";
import shadowImg from "../img/player-shadow.png";
import audienceBoxImg from "../img/audience-box.png";
import audienceImg from "../img/audience.png";

import shootSound from "../sfx/shot.mp3";
import jumpSound from "../sfx/jump.mp3";
const WIN_SCORE = 10;
const playerFloorY = 987;
export class GameScene extends CommonGameScene {
  constructor() {
    super();
    this.playerDepthOrder = [];
    this.playerNameTags = {};
    this.playerShadows = {};
    this.gameId = Math.random() * 10000;
    this.disableControls = false;
  }

  preload() {
    this.load.image("ball", ballImg);
    this.load.image("court", courtImg);
    this.load.image("net", netImg);
    this.load.image("shadow", shadowImg);
    this.load.image("audienceBox", audienceBoxImg);
    this.load.image("audience", audienceImg);

    this.load.audio("shoot", shootSound);
    this.load.audio("jump", jumpSound);
  }

  create() {
    super.create();
    resetGameStates(this.multiplayer);
    this.gameSize = this.sys.game.scale.gameSize;
    this.playerCollideGroup = this.matter.world.nextGroup(true);
    this.cameras.main.setBackgroundColor(0x6bdcff);

    this.addAudience();

    this.pointTextStyle = {
      font: "250px 'Lilita One'",
      fill: "#ffffff99",
      align: "center",
      shadow: { fill: false },
      strokeThickness: 0,
    };
    this.addPointsText();
    this.lastPointTime = 0;
    this.addCourt();

    this.ballSpawnPoints = [
      [900, 750],
      [1600, 750],
    ];
    this.addBall();
  }

  addPointsText() {
    var text1 = this.add.text(
      this.gameSize.width / 2 - 400,
      this.gameSize.height / 2 - 100,
      "07",
      this.pointTextStyle
    );
    text1.setOrigin(0.5, 0.5);
    var text2 = this.add.text(
      this.gameSize.width / 2 + 400,
      this.gameSize.height / 2 - 100,
      "07",
      this.pointTextStyle
    );
    text2.setOrigin(0.5, 0.5);

    this.pointsTexts = { team1: text1, team2: text2 };
  }

  addAudience() {
    this.audienceBoxLeft = this.add.image(
      this.gameSize.width * (1 / 4) - this.adjustByScale(100),
      this.gameSize.height * (2 / 4),
      "audienceBox"
    );
    this.audienceBoxLeft.setScale(this.adjustByScale(0.4));

    this.audienceBoxRight = this.add.image(
      this.gameSize.width * (3 / 4) + this.adjustByScale(100),
      this.gameSize.height * (2 / 4),
      "audienceBox"
    );
    this.audienceBoxRight.setScale(this.adjustByScale(0.4));
    this.audienceBoxRight.setFlip(true, false);

    let audienceLeft = [];
    let audienceRight = [];
    const rightStartingX = 120,
      rightStartingY = 300;
    audienceRight.push(
      this.addAudienceMember(
        this.adjustByScale(rightStartingX + 30),
        this.adjustByScale(rightStartingY + 20)
      )
    );
    audienceRight.push(
      this.addAudienceMember(
        this.adjustByScale(rightStartingX + 100),
        this.adjustByScale(rightStartingY)
      )
    );
    audienceRight.push(
      this.addAudienceMember(
        this.adjustByScale(rightStartingX),
        this.adjustByScale(rightStartingY + 60)
      )
    );
    audienceRight.push(
      this.addAudienceMember(
        this.adjustByScale(rightStartingX + 70),
        this.adjustByScale(rightStartingY + 40)
      )
    );

    const leftStartingX = 820,
      leftStartingY = 300;
    audienceLeft.push(
      this.addAudienceMember(
        this.adjustByScale(leftStartingX),
        this.adjustByScale(leftStartingY)
      )
    );
    audienceLeft.push(
      this.addAudienceMember(
        this.adjustByScale(leftStartingX + 70),
        this.adjustByScale(leftStartingY + 20)
      )
    );
    audienceLeft.push(
      this.addAudienceMember(
        this.adjustByScale(leftStartingX + 30),
        this.adjustByScale(leftStartingY + 40)
      )
    );
    audienceLeft.push(
      this.addAudienceMember(
        this.adjustByScale(leftStartingX + 100),
        this.adjustByScale(leftStartingY + 60)
      )
    );
    // }

    this.audienceLeft = audienceLeft;
    this.audienceRight = audienceRight;
  }

  jumpAudience(audienceArray) {
    audienceArray.forEach((audienceMember, index) => {
      this.tweens.add({
        targets: audienceMember,
        y: {
          value: "-=70",
        },
        ease: "Cubic", // 'Cubic', 'Elastic', 'Bounce', 'Back', 'Linear'
        duration: 500,
        repeat: 3,
        yoyo: true,
        delay: index * 100,
      });
    });
  }

  addAudienceMember(x, y) {
    let audienceMember = this.add.image(x, y, "audience");

    audienceMember.setScale(this.adjustByScale(0.4));
    return audienceMember;
  }

  addCourt() {
    this.court = this.add.image(
      this.gameSize.width / 2,
      this.gameSize.height - 50,
      "court"
    );
    this.court.setScale(0.7);
    this.matter.world.setBounds(
      (this.gameSize.width - this.court.displayWidth) / 2,
      this.adjustByScale(-150),
      this.court.displayWidth,
      this.sys.scale.height + this.adjustByScale(90)
    );
    // this.court.setCollisionGroup(this.courtCollideGroup);
    this.net = this.matter.add.image(
      this.gameSize.width / 2,
      this.gameSize.height - 235,
      "net"
    );
    this.net.setScale(0.8);
    this.net.setBody(
      {
        type: "rectangle",
        width: this.net.width / 2,
        height: this.net.displayHeight,
      },
      {
        isStatic: true,
      }
    );
  }

  // TODO: Use a structure like this.
  // addAudienceMember(x, y){

  // }

  addBall() {
    const ballSize = 200;
    var circ;
    if (this.multiplayer.isRenderServer()) {
      circ = this.matter.add.image(-900, 150, "ball");
      circ.displayWidth = ballSize;
      circ.displayHeight = ballSize;
      //  Change the body to a Circle with a radius of 48px
      circ.setBody(
        {
          type: "circle",
          radius: ballSize / 2,
        },
        {
          density: 0.01,
          restitution: 0.0,
          frictionAir: 0.025,
          // force: {x: 0.03, y: 0.03}
        }
      );

      // this.matter.world.walls.top
      circ.setOnCollide((pair) => {
        circ.setIgnoreGravity(false);
      });
      circ.setOnCollideWith(this.matter.world.walls.bottom, (pair) => {
        // this.sound.play("shoot");
        // check if already in celebration mode
        if (this.disableControls) return;
        //disable controls and CELEBRATE point
        this.disableControls = true;

        if (this.ball.x > this.net.x) {
          console.log("Team1 scored");
          this.scorePoint(1);
          setTimeout(this.resetBall.bind(this, this.ballSpawnPoints[1]), 2000);
        } else {
          console.log("Team2 scored");
          this.scorePoint(2);
          setTimeout(this.resetBall.bind(this, this.ballSpawnPoints[0]), 2000);
        }
      });

      //  Just make the body move around and bounce
      // circ.setVelocity(6, 3);
      // circ.setAngularVelocity(0.01);
      circ.setBounce(2);
      circ.setMass(0.001);
      circ.setToSleep();
      this.ball = circ;
      this.resetBall(this.ballSpawnPoints[0]);
      // circ.setFriction(0, 0, 0);
    } else {
      circ = this.add.image(200, 50, "ball");
      circ.displayWidth = ballSize;
      circ.displayHeight = ballSize;
      this.ball = circ;
    }
  }

  resetBall(pos) {
    const circ = this.ball;
    if (!circ && circ.body) return;
    circ.x = pos[0];
    circ.y = pos[1];
    circ.setVelocity(0, 0);
    circ.setAngularVelocity(0);
    circ.setIgnoreGravity(true);
    circ.setToSleep();
    // circ.setStatic(true);
    setTimeout(() => {
      if (!circ || !circ.body) return;
      circ.setAwake();
      this.disableControls = false;
    }, 100);
  }

  scorePoint(team) {
    var points = this.multiplayer.getState("points") || [];
    points = [...points]; // clone;
    // TODO: add last player who touched the ball.
    points.push({ t: Date.now(), team: team });

    this.multiplayer.setState("points", points);

    if (team === 2) {
      this.jumpAudience(this.audienceLeft);
    } else {
      this.jumpAudience(this.audienceRight);
    }

    // see if someone won
    const points1 = points.filter((p) => p.team === 1);
    const points2 = points.filter((p) => p.team === 2);
    if (points1.length >= WIN_SCORE || points2.length >= WIN_SCORE) {
      const winningTeam = points1.length >= WIN_SCORE ? 1 : 2;
      this.multiplayer.setState("winner", winningTeam);

      // also give points to everyone in winning team
      const spots = this.multiplayer.getState("spotsTaken");
      const winnerTeamMembers = winningTeam === 1 ? spots.team1 : spots.team2;
      winnerTeamMembers.forEach((playerId) => {
        this.multiplayer.addToWinLog("smashdown", playerId);
      });
    }
  }

  async addPlayerSprite(playerState, profile) {
    const team = this.getPlayerTeam(playerState.id);
    const newPos = this.getRandomSpawnPoint(team);

    var shadow = this.add.image(100, 100, "shadow");
    this.playerShadows[playerState.id] = shadow;

    let player = await AvatarPhaserHelper(
      "/avatars/ninja.png",
      playerState,
      profile,
      newPos,
      (posX, posY, textureId) => this.matter.add.image(posX, posY, textureId),
      (textureId) => this.textures.createCanvas(textureId, 252 * 0.5, 286 * 0.5)
      // this.physics.add.image.bind(this),
      // this.textures.createCanvas.bind(this)
    );

    player.setBody({
      type: "circle",
      radius: player.width / 2,
    });
    player.setFixedRotation();
    if (team === 2) {
      player.setFlipX(true);
    }
    // player.setToSleep();
    player.setCollisionGroup(this.playerCollideGroup);

    if (this.multiplayer.isRenderServer()) {
      player.setOnCollideWith(this.matter.world.walls.bottom, (pair) => {
        playerState.setState("jumping", false);
      });
      player.setOnCollideWith(this.ball, () => {
        console.log("ball");
      });
    }

    // add player nametag
    var style = {
      font: "20px Russo One",
      fill: "#000000",
      wordWrap: true,
      align: "center",
      backgroundColor: "#ffff0033",
      padding: {
        left: 20,
        right: 20,
        top: 10,
        bottom: 10,
      },
    };

    var text = this.add.text(
      100,
      100,
      profile ? profile.name : "Player",
      style
    );
    text.setOrigin(0.5, 0);
    this.playerNameTags[playerState.id] = text;

    return player;
  }

  handlePlayerQuit(playerState) {
    if (this.playerNameTags[playerState.id]) {
      this.playerNameTags[playerState.id].destroy();
      this.playerShadows[playerState.id].destroy();
      delete this.playerNameTags[playerState.id];
    }
  }

  update() {
    super.update();
    if (this.multiplayer.isRenderServer()) {
      // this.multiplayer.setState("ball", [
      //   parseInt(this.ball.x),
      //   parseInt(this.ball.y),
      //   parseInt(this.ball.angle),
      // ]);
      if (this.ball.y > this.gameSize.height * 2) {
        // if the ball escapes the world
        this.resetBall(this.ballSpawnPoints[0]);
      }
      const maxVelocity = 30;
      if (this.ball.body.velocity.x < -maxVelocity)
        this.ball.setVelocityX(-maxVelocity);
      if (this.ball.body.velocity.x > maxVelocity)
        this.ball.setVelocityX(maxVelocity);
      if (this.ball.body.velocity.y < -maxVelocity)
        this.ball.setVelocityY(-maxVelocity);
      if (this.ball.body.velocity.y > maxVelocity)
        this.ball.setVelocityY(maxVelocity);
      // this.pointsTexts['team1'].setText(`${parseInt(this.ball.body.velocity.x)} ${parseInt(this.ball.body.velocity.y)}`);
    } else {
      const ballState = this.multiplayer.getState("ball");
      this.ball.x = ballState[0];
      this.ball.y = ballState[1];
      this.ball.angle = ballState[2];
    }

    const points = this.multiplayer.getState("points") || [];
    const points1 = points.filter((p) => p.team === 1);
    const points2 = points.filter((p) => p.team === 2);
    const lastPoint = points[points.length - 1];
    // + "-" + parseInt(this.sys.game.loop.actualFps)

    if (this.multiplayer.getState("winner")) {
      const winner = this.multiplayer.getState("winner");
      const txt =
        winner === 1 ? this.pointsTexts.team1 : this.pointsTexts.team2;
      const txtOther =
        winner === 1 ? this.pointsTexts.team2 : this.pointsTexts.team1;
      var style = {
        ...this.pointTextStyle,
        strokeThickness: 5,
        fill: "#ffffff",
      };
      style["stroke"] = lastPoint.team === 1 ? "#FF67E7" : "#6CB560";
      style["shadow"] = {
        offsetY: 17,
        color: lastPoint.team === 1 ? "#FF7F56" : "#90FFB6",
        fill: true,
      };
      txt.setStyle(style);
      txt.setText("Winner!");
      txtOther.setText("");

      this.ball.alpha = 0;
    } else {
      this.pointsTexts.team1.setText("0" + points1.length);
      this.pointsTexts.team2.setText("0" + points2.length);
    }

    if (
      !this.multiplayer.getState("winner") &&
      lastPoint &&
      lastPoint.t !== this.lastPointTime
    ) {
      this.lastPointTime = lastPoint.t;
      const txt =
        lastPoint.team === 1 ? this.pointsTexts.team1 : this.pointsTexts.team2;
      style = { ...this.pointTextStyle, strokeThickness: 5, fill: "#ffffff" };
      style["stroke"] = lastPoint.team === 1 ? "#FF67E7" : "#6CB560";
      style["shadow"] = {
        offsetY: 17,
        color: lastPoint.team === 1 ? "#FF7F56" : "#90FFB6",
        fill: true,
      };
      txt.setStyle(style);
      setTimeout(() => {
        txt.setStyle(this.pointTextStyle);
      }, 3000);
    }
  }

  updateCommon(playerId, player, state) {
    this.maxX = Math.max(this.maxX || 0, this.ball.body.velocity.y);
    this.maxY = Math.max(this.maxY || 0, this.ball.body.velocity.y);
    const profile = state.getState("profile");
    const ping = state.getState("p") || 0;
    const text = this.playerNameTags[playerId];
    text.setText((profile ? profile.name : "") + ` [${ping}ms]`);
    text.x = Math.floor(player.x);
    text.y = Math.floor(player.y - player.height - text.height);
    text.setDepth(player.depth);

    const shadow = this.playerShadows[playerId];
    shadow.x = player.x + (player.flipX ? -5 : 5);
    shadow.y = playerFloorY + 65;
    shadow.setScale(2 * (player.y / playerFloorY)); // shadow is smaller when jumping
    shadow.setAlpha(1 * (player.y / playerFloorY)); // shadow is lighter when jumping
  }

  // host does all the computation and inputs, other players only render player at x,y
  updatePlayerHost(playerId, player, state) {
    const playerMoveSpeed = 20;
    const playerTeam = this.getPlayerTeam(playerId);
    player.setVelocityX(0);

    // dont allow players to move when point/celebration is happening.
    if (!this.disableControls && !this.multiplayer.getState("winner")) {
      if (state.isKeyDown("left")) {
        player.setVelocityX(-playerMoveSpeed);
      } else if (state.isKeyDown("right")) {
        player.setVelocityX(playerMoveSpeed);
      }

      if (!state.getState("jumping") && state.isKeyDown("b1")) {
        state.setState("jumping", true);
        this.sound.play("jump");
        player.setVelocityY(-20);
      }
    } else if (
      this.multiplayer.getState("winner") &&
      !state.getState("jumping")
    ) {
      if (playerTeam === this.multiplayer.getState("winner")) {
        state.setState("jumping", true);
        player.setVelocityY(-5);
      }
    } else if (!this.multiplayer.getState("winner")) {
      state.setState("jumping", false);
    }

    if (playerTeam === 2 && player.x < this.net.x + 110) {
      player.x = this.net.x + 110;
    } else if (playerTeam === 1 && player.x > this.net.x - 110) {
      player.x = this.net.x - 110;
    }
  }

  checkOverlap(spriteA, spriteB) {
    var dist = Math.sqrt(
      Math.pow(spriteA.x - spriteB.x, 2) + Math.pow(spriteA.y - spriteB.y, 2)
    );

    return dist <= 250;
  }

  getPlayerTeam(playerId) {
    const spots = this.multiplayer.getState("spotsTaken");

    // ----------- PRINT DEBUG -----------
    // console.log("spots in getPlayerTeam:", spots);
    // ----------- PRINT DEBUG -----------

    if (!spots) return; // no team selected yet.
    const team = spots.team1.indexOf(playerId) !== -1 ? 1 : 2;
    return team;
  }

  getRandomSpawnPoint(team) {
    // return [743, 1007]
    const SpawnPointsA = [
      [650, playerFloorY],
      [830, playerFloorY],
      [1010, playerFloorY],
    ];
    const SpawnPointsB = [
      [1430, playerFloorY],
      [1610, playerFloorY],
      [1790, playerFloorY],
    ];
    if (team === 1) {
      return SpawnPointsA[Math.floor(Math.random() * SpawnPointsA.length)];
    }
    return SpawnPointsB[Math.floor(Math.random() * SpawnPointsB.length)];
  }
}
