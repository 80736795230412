import AvatarIcon from "common/components/AvatarIcon";
import BackgroundRain from "common/components/BackgroundRain";
import "./style.css";
import star1 from "./img/star1.svg";
import star2 from "./img/star2.svg";
import { usePlayersList } from "common/hooks/multiplayer";
import CircularConfetti from "./CircularConfetti";
import FullscreenConfetti from "../FullscreenConfetti";

export const FullScreenWinnerCard = (props) => {
  console.log("props in winnercard", props);
  return (
    <>
      {props.showConfetti && <FullscreenConfetti />}
      <div
        style={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          height: "100vh",
          width: "100vw",

          backdropFilter: "blur(0.9rem) brightness(60%)",
          animation: "1s fadein",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <WinnerCard {...props} />
      </div>
    </>
  );
};

const WinnerCard = ({ winners, scores }) => {
  // ----------- PRINT DEBUG -----------
  // console.log("winners (from game):", winners);
  // ----------- PRINT DEBUG -----------

  // -------- failsafe
  const players = usePlayersList(true);
  const winnersArray = Array.isArray(winners) ? winners : [winners];
  const _winners = winnersArray[0]
    ? // are the winners actually "playerState" objects?
      winnersArray[0].getState
      ? winnersArray
      : players.filter((p) => winnersArray.includes(p.id))
    : // else if array length is zero
      [];
  // -------- failsafe

  return (
    <div
      className="winner-all-elements-container"
      style={{
        position: "relative",
        height: "auto",
        width: "48rem",
      }}
    >
      <BackgroundRain
        numDrops={20}
        className="winner-background-rain"
        containerStyles={{
          top: "-10rem",
        }}
      />
      <WinnerCardContainer
        style={{
          position: "absolute",
          fontSize: "1.2rem",
          width: "90%",
        }}
      >
        {_winners ? (
          _winners.length === 0 ||
          _winners[0] === undefined ||
          _winners[0] < 0 ? (
            <h1
              style={{
                fontSize: "1.8rem",
              }}
            >
              No one.
            </h1>
          ) : _winners.length === 1 ? (
            <WinnerCol player={_winners[0]} />
          ) : (
            _winners.map((winner, idx) => {
              return (
                <WinnerRow
                  scale={idx === 0 ? true : false}
                  player={winner}
                  score={scores ? (scores[idx] ? scores[idx] : false) : 1}
                />
              );
            })
          )
        ) : (
          <></>
        )}
      </WinnerCardContainer>
      {_winners.length ? <Stars playersLength={_winners.length} /> : <></>}
    </div>
  );
};
export default WinnerCard;

const Stars = ({ playersLength }) => {
  // console.log("playersLength:", playersLength);
  return (
    <div
      className="rotating-stars"
      style={{
        position: "absolute",
        top: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          position: "relative",
        }}
      >
        <img
          className="rotating-star"
          src={star1}
          style={{
            position: "absolute",
            top: 40 + "px",
            left: (playersLength ? -2 : -1) + "rem",
            transform: `scale(${0.2 + (Math.random() % 0.8)})`,
            animation: `${2 + (Math.random() % 0.8)}s spin ${
              0.2 + (Math.random() % 0.8)
            }s infinite alternate ease-in-out`,
          }}
        />
        <img
          className="rotating-star"
          src={star2}
          style={{
            position: "absolute",
            top: 130 + "px",
            left: (playersLength ? -3 : -1) + "rem",
            transform: `scale(${0.3 + (Math.random() % 0.5)})`,
            animation: `${3 + (Math.random() % 0.8)}s spin ${
              0.3 + (Math.random() % 0.8)
            }s infinite alternate-reverse ease-in-out`,
          }}
        />
      </div>

      <div
        style={{
          position: "relative",
        }}
      >
        <img
          className="rotating-star"
          src={star2}
          style={{
            position: "absolute",
            top: 20 + "px",
            right: (playersLength ? -3 : -2) + "rem",
            transform: `scale(${0.4 + (Math.random() % 0.5)})`,
            animation: `${4 + (Math.random() % 0.8)}s spin ${
              0.4 + (Math.random() % 0.8)
            }s infinite alternate-reverse ease-in-out`,
          }}
        />
        <img
          className="rotating-star"
          src={star1}
          style={{
            position: "absolute",
            top: 140 + "px",
            right: +(playersLength ? -4 : -3) + "rem",
            transform: `scale(${0.2 + (Math.random() % 0.8)})`,
            animation: `${2 + (Math.random() % 0.8)}s spin ${
              0.2 + (Math.random() % 0.8)
            }s infinite alternate ease-in-out`,
          }}
        />
      </div>
    </div>
  );
};

const WinnerCardContainer = ({ title = "Winner", children }) => {
  return (
    <div
      className="winner-card-container"
      style={{
        position: "relative",
        padding: "5rem 3.5rem",
        borderRadius: "1.8rem",
        backgroundColor: "#000000",
        minWidth: "fit-content",
        width: "80%",
        maxWidth: "80vw",
        color: "white",
        border: "0.21rem solid #FFA531",
        boxShadow: "0rem 0.3rem 0rem #FF7F56, inset 0rem 0.2rem 0rem #777777",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <span
        className="winner-fish"
        style={{
          position: "absolute",
          left: "0",
          right: "0",
          marginLeft: "auto",
          marginRight: "auto",
          top: "-2rem",
          width: "70%",
          borderRadius: "1.2rem",
          border: "0.21rem solid #FFA531",
          boxShadow: "0rem 0.3rem 0rem #FF7F56, inset 0rem 0.2rem 0rem #777777",
        }}
      >
        <span
          style={{
            padding: "0rem 3rem",
          }}
        >
          {title}
        </span>
      </span>
      {children}
    </div>
  );
};

const WinnerRow = ({ player, scale, score = 1 }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        // margin: "1rem 2rem",
        // width: "fit-content",
        // padding: "1rem 3rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          width: "fit-content",
          alignItems: "center",
          justifyContent: "center",
          transform: scale ? "scale(1.2) translateX(-0.25rem)" : undefined,
          transformOrigin: scale ? "left" : undefined,
        }}
      >
        <CircularConfetti animate={scale}>
          <AvatarIcon
            style={{
              height: "2.7rem",
              width: "2.7rem",
              borderRadius: "1.5rem",
              backgroundColor: "red",
              border: "0.21rem solid #FFA531",
              zIndex: 12,
            }}
            playerState={player}
          />
        </CircularConfetti>

        <h2
          style={{
            padding: 0,
            minWidth: "8.5rem",
            flex: "1 1 auto",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "left",
            margin: scale ? "0rem 3rem 0rem 0.4rem" : "0rem 3rem 0rem 1rem",
          }}
        >
          {player.getState("profile").name}
        </h2>
      </div>

      <h2>{score ? `+${score}` : ""}</h2>
    </div>
  );
};

const WinnerCol = ({ player }) => {
  // debugger
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "auto",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AvatarIcon
          style={{
            height: "2.7rem",
            width: "2.7rem",
            borderRadius: "1.5rem",
            backgroundColor: "red",
            border: "0.21rem solid #FFA531",
          }}
          playerState={player}
        />
        <h2
          style={{
            padding: 0,
            margin: "1rem 0rem 0rem",
          }}
        >
          {player.getState("profile").name}
        </h2>
      </div>
    </div>
  );
};
