import React, { useEffect, useState } from "react";
import cardFanImg from "../../img/card-fan.png";
import ReactPlayer from "react-player";
import CardData, { VersusCardsData } from "../../src/cardData";
import CardGame, { Card } from "common/components/CardGame";

import BackgroundMusic from "common/modules/backgroundMusic";
import rpsBackgroundMusic from "../../sfx/RPS-background.mp3";
import UIfx from "common/modules/uifx";
import "./style.css";

import {
  usePlayersList,
  useGlobalState,
  useGlobalRoundState,
  usePlayerState,
} from "common/hooks/multiplayer";

const StartScreen = ({ children, multiplayer }) => {
  const [firstThreeCards] = useState([CardData[0], CardData[1], CardData[2]]);

  const playInstructions = useGlobalState("playInstructions");

  useEffect(() => {
    BackgroundMusic().stop();
    BackgroundMusic("timer").stop();
    BackgroundMusic("game").stop();

    console.log("----------- startscreen music play...");
    setTimeout(() => {
      BackgroundMusic().play(rpsBackgroundMusic);
    }, 2000);

    return () => {
      // BackgroundMusic().stop();
    };
  }, [rpsBackgroundMusic]);

  useEffect(() => {
    if (playInstructions === true) {
      BackgroundMusic().stop();
      // alert("decrease volume!");
    } else if (playInstructions === false) {
      BackgroundMusic().play(rpsBackgroundMusic);
      // BackgroundMusic().setVolume(1);
    }
  }, [playInstructions]);

  return (
    <>
      {playInstructions ? (
        <div
          className="centered-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: "1",
            // position: "fixed",
            zIndex: 99999,
            width: "100vw",
            height: "100vh",
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              width: "100%",
              height: "fit-content",
            }}
          >
            <iframe
              src="https://olafwempe.com/mp3/silence/silence.mp3"
              type="audio/mp3"
              allow="autoplay"
              id="audio"
              style={{
                display: "none",
              }}
            ></iframe>
            {/* <div className="player-wrapper" style={{
              height: "28.125vw",
              width: "60vw"
            }}> */}
            <ReactPlayer
              width="60vw"
              height="34vw"
              // className="react-player"
              autoPlay
              playing={true}
              // Probably won't show Pasoori from now on,
              // but instead, other videos from Playroom YouTube channel.
              // https://www.maxlaumeister.com/articles/hide-related-videos-in-youtube-embeds/
              url="https://www.youtube.com/watch?v=KxUuF6rz8WM&autoplay=1&rel=0"
              style={{
                borderRadius: "2rem",
                overflow: "hidden",

                // If only these could be applied together, in sort of a ratio.
                // minHeight: "640px",
                // minWidth: "360px",
              }}
            />
            {/* </div> */}
          </div>
        </div>
      ) : (
        <div
          className="centered-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: "1",
            width: "max-content",
          }}
        >
          {/* TODO: Add cards with fan animation here */}
          {/* <div className="card-spread">
        {
          firstThreeCards.map((card, idx) => {
            console.log("Card in sliced array:", card);
            return (
              <FanCard card={card} idx={idx} />
            )
          })
        }
        </div> */}

          <div className="space-container dialog-container start-screen-dialog-container">
            {/* <img
              src={cardFanImg}
              style={{
                position: "absolute",
                top: "-8%",
                height: "16rem",
                width: "auto",
              }}
            /> */}

            <div
              style={{
                position: "absolute",
                top: "-13.8rem",
                // marginBottom: "10rem",
                height: "10rem",
                width: "10rem",
              }}
            >
              <CardFanAnimation />
            </div>

            <div>
              <h2
                style={{
                  padding: 0,
                  margin: 0,
                }}
              >
                Every round, throw a card from your hand: Rock, Paper or
                Scissors. <br />
                At the end of 3 rounds, the player with the most points wins!
              </h2>
            </div>
          </div>
        </div>
      )}
      {children}
    </>
  );
};

export default StartScreen;

const CardFanAnimation = () => {
  const cards = [CardData[0], CardData[1], CardData[2]];
  const centerIndex = Math.floor(cards.length / 2);

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setTimeout(() => setAnimate(true), 1200);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
      }}
    >
      {cards.map((card, idx) => {
        const modIdx = idx - centerIndex;
        return (
          <CardFanImage
            card={card}
            modIdx={modIdx}
            idx={idx}
            animate={animate}
          />
        );
      })}
    </div>
  );
};

const CardFanImage = ({ card, animate, modIdx, idx }) => {
  return (
    <Card
      backgroundColor={card.backgroundColor}
      backgroundImage={card.backgroundImage}
      cardImage={`url("${card.image}")`}
      className="card"
      cardStyle={{
        ...card.cardStyles,
        ...{
          height: "13.2rem",
          width: "9.4rem",
          padding: "0px 0px",
          position: "absolute",
          transformOrigin: "center 400px",
          transition: "0.6s all ease-in-out",
          zIndex: 10 - Math.abs(modIdx),
          transform: `rotate(${(animate ? 15 : 2) * modIdx}deg)`,
          marginTop: `${(animate ? 13 : 0) * Math.abs(modIdx)}px`,
        },
      }}
      // style={{
      // }}
    />
  );
};
