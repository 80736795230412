import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BackgroundRain from "common/components/BackgroundRain";
import BackgroundMusic from "common/modules/backgroundMusic";
import Multiplayer from "common/modules/multiplayer";
import RoomCodeWithQR from "common/components/RoomCodeWithQR";
// import BackgroundMusic from "common/modules/backgroundMusic";
// import BackgroundNarrator from "common/modules/backgroundNarrator";
import Spritesheet from "react-responsive-spritesheet";
import AvatarCreator from "common/modules/avatarCreator";
import avatarNormal from "./img/avatar.png";
import avatarWinner from "./img/avatar-winner.png";
import avatarShadow from "./img/shadow.png";
import avatarEyes from "../games/Lobby/img/avatar-eyes.png";

import Toast from "common/components/Toast";

import "./style.scss";

let blinkTimeout = {};

function randRange(minNum, maxNum) {
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}

function calculateScores(winslogs) {
  const scores = {};
  if (winslogs) {
    winslogs.forEach((log) => {
      scores[log[1]] = (scores[log[1]] || 0) + 1;
    });
  }
  return scores;
}

export default function GamePage() {
  const history = useHistory();
  const BGMusic = BackgroundMusic();
  // const BGNarrator = BackgroundNarrator();
  const multiplayer = Multiplayer();
  const [players, setPlayers] = useState([]);
  const [countdown, setCountdown] = useState(10);
  const [countdownInterval, setCountdownInterval] = useState(null);

  // add blink animation randomly
  const blink = (spritesheet, key) => {
    if (blinkTimeout[key]) clearTimeout(blinkTimeout[key]);
    blinkTimeout[key] = setTimeout(() => {
      if (spritesheet) {
        spritesheet.goToAndPlay(1);
      }
      blink(spritesheet, key);
    }, randRange(3000, 7000));
  };

  const startCountdown = () => {
    let c = 10;
    setCountdown(c);
    const interval = setInterval(() => {
      c -= 1;
      setCountdown(c);
      if (c === 0) {
        clearInterval(interval);
        setCountdownInterval(null);
        if (multiplayer.isSpectator) {
          // multiplayer.setState("winslog", undefined);
          multiplayer.setState("playlistCurIndex", undefined);
          if (multiplayer.isRenderServer()) {
            history.push("/games/lobby");
          }
        }
      }
    }, 1000);
    setCountdownInterval(interval);
  };
  useEffect(() => {
    if (!multiplayer.isSpectator) {
      // window.RotateScreen("landscape");
    } else {
      BGMusic.setVolume(0.6, true);
      // calculate player scores
      const winslog = multiplayer.getState("winslog");
      const leaderboard = calculateScores(winslog);
      let highestScore = 1;
      Object.keys(leaderboard).forEach((playerId) => {
        if (leaderboard[playerId] > highestScore)
          highestScore = leaderboard[playerId];
      });
      // set playerNames and scores
      Promise.all(
        Object.keys(multiplayer.getPlayers()).map(async (playerId) => {
          const profile = multiplayer
            .getPlayers()
            [playerId].getState("profile");
          const score = leaderboard[playerId] ? leaderboard[playerId] : 0;
          if (!profile.color || !profile.name) return false;
          const winner = score >= highestScore;
          console.log("a", avatarWinner);
          const av = new AvatarCreator(winner ? avatarWinner : avatarNormal);
          await av.fillColor(profile.color);

          const avShadow = new AvatarCreator(avatarShadow);
          await avShadow.fillColor(profile.color);
          return {
            id: playerId,
            name: profile.name,
            score,
            color: profile.color,
            crown: winner,
            avatar: av.toDataURL(),
            shadow: avShadow.toDataURL(),
          };
        })
      ).then((list) => {
        console.log("list", list);
        setPlayers(list.filter(Boolean).sort((a, b) => b.score - a.score));
        startCountdown();
      });
    }

    return () => {
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className={"end-screen-container"}>
        <BackgroundRain key="rain" className="back-gradient" numDrops={30} />

        {multiplayer.isSpectator ? (
          <>
            <RoomCodeWithQR />
            <Toast bottom right visible>
              Loading Lobby <b>{countdown}</b>
            </Toast>
            <div className="player-tiles">
              {players.map((player) => (
                <div className="player-tile">
                  <div className="player-name">{player.name}</div>
                  <div className="player-score">{player.score}</div>
                  <div
                    className={
                      "player-avatar " + (player.crown ? "winner" : "")
                    }
                    style={{ backgroundImage: `url(${player.avatar})` }}
                  >
                    <Spritesheet
                      image={avatarEyes}
                      style={{
                        marginTop: player.crown ? "-1rem" : "-3.3rem",
                        transform: "scale(0.4)",
                      }}
                      widthFrame={220}
                      heightFrame={128}
                      steps={6}
                      fps={20}
                      autoplay={true}
                      getInstance={(spritesheet) => {
                        blink(spritesheet, player.id);
                      }}
                    />
                  </div>
                  <div
                    className="player-shadow"
                    style={{ backgroundImage: `url(${player.shadow})` }}
                  ></div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <Toast center middle visible>
            Lobby is loading
          </Toast>
        )}
      </div>
    </>
  );
}
