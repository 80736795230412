export const PlaylistThumbnailContainer = ({
  playlist,
  setPlaylist,
  playlistContainerStyles = {},
  disabled,
  imgStyles = {},
}) => {
  const isDisabled = disabled || playlist.locked;
  return (
    <div
      key={playlist.id}
      className="playlist-container"
      disabled={isDisabled}
      style={{
        ...playlistContainerStyles,
      }}
      onClick={
        setPlaylist
          ? () => {
              if (!isDisabled && !playlist.comingsoon) {
                console.log("The user has chosen a new playlist:", playlist);

                setPlaylist(playlist.id);
              }
            }
          : null
      }
    >
      {playlist.locked ? (
        <div className="playlist-locked-overlay">
          <img src="/lock-icon.png" alt="lock" />
        </div>
      ) : (
        <></>
      )}
      {/* TODO: might need these soon */}
      {/* <div className="playlist-info-overlay">
        {playlist.title}
      </div> */}
      <img
        src={playlist.thumbnail}
        alt={playlist.id}
        style={{
          ...{
            opacity: playlist.locked ? 0.3 : 1,
            width: "100%",
          },
          ...imgStyles,
        }}
      />
    </div>
  );
};

export default PlaylistThumbnailContainer;
