import React, { useEffect, useState } from "react";
import Multiplayer from "common/modules/multiplayer";

import FallingMnMs from "./pages/FallingMnMs";
import RocketFuel from "./pages/RocketFuel";
import ShakeTheJar from "./pages/ShakeTheJar";

import { FullScreenWinnerCard } from "common/components/WinnerCard";

import { AnimatePresence } from "framer-motion";

// for page transitions
import { motion } from "framer-motion";

import {
  usePlayersList,
  useGlobalState,
  useGlobalRoundState,
  usePlayerState,
  useMyPlayerState,
} from "common/hooks/multiplayer";

const roundDurationInS = 150;

const resetGame = () => {
  const multiplayer = Multiplayer();
  multiplayer.setRoundState("challengeWinners", []);
  multiplayer.setRoundState("currentChallenge", undefined);
  multiplayer.setRoundState("showAllCards", false);
  multiplayer.setRoundState("allPlayersChose", false);
};

const gameInRound = [
  {
    id: "rocketfuel",
    Component: RocketFuel,
  },
  {
    id: "fallingmnms",
    Component: FallingMnMs,
  },
  {
    id: "shakethejar",
    Component: ShakeTheJar,
  },
];

/*
Benefits of this approach:
- Can add transitions in the middle of round changes
*/

function Round({ currentRound, totalRounds, endRound, isLastRound, gameInfo }) {
  const multiplayer = Multiplayer();
  const _tempWinLog = useGlobalState("_tempWinLog");

  // this is the winner of each ROUND
  const winner = useGlobalRoundState("winner");

  const myState = multiplayer.getMyPlayerState();

  const [_currentRound, setCurrentRound] = useState();

  const players = usePlayersList(true);

  const [winners, setWinners] = useState();

  function roundLogic(winner, isLastRound) {
    console.log("in round logic");
    const _tempWinLog = multiplayer.getState("_tempWinLog");

    if (!multiplayer.isSpectator) return;

    const __winner = Array.isArray(winner) ? winner : [winner];

    // standardizing winners
    let winners = __winner;

    const __tempWinLog = _tempWinLog?.length
      ? [..._tempWinLog, ...winners]
      : winners;

    console.log("winner:", winner);
    console.log("winners:", winners);
    console.log("__tempWinLog", __tempWinLog);

    multiplayer.setState("_tempWinLog", __tempWinLog);
    setWinners(winners);

    if (isLastRound) lastRound(__tempWinLog, multiplayer);

    // multiplayer.setState("_tempWinLog", [..._tempWinLog, winLogEntry]);
    setTimeout(
      () => {
        // end the round after some delay.
        // why?
        // 1. show winner card
        // 2. transition animation
        endRound();

        // reset round state here.
        multiplayer.resetRoundState();
      },
      isLastRound ? 4000 : 7000
    );
  }

  function lastRound(__tempWinLog, multiplayer) {
    // console.log("checking last round", currentRound, totalRounds, endRound, isLastRound, gameInfo);
    console.log(
      "Games have ended! Time to upload the following to winlogs :D",
      __tempWinLog
    );

    // Calculate overall scores for each player
    // https://bobbyhadz.com/blog/javascript-get-sum-of-array-object-values
    const scoresDict = __tempWinLog.reduce((prev, curr, idx) => {
      prev[curr.id] = prev[curr.id] ? prev[curr.id] + curr.score : curr.score;
      return prev;
    }, {});

    // https://stackoverflow.com/questions/27376295/getting-key-with-the-highest-value-from-object
    const maxPlayer = Object.keys(scoresDict).reduce((a, b) =>
      scoresDict[a] > scoresDict[b] ? a : b
    );

    console.log("maxPlayer", maxPlayer);

    multiplayer.setState("winner", maxPlayer);
    multiplayer.addToWinLog("fastestfingers", maxPlayer);
  }

  useEffect(() => {
    if (!multiplayer.isSpectator) return;
    if (currentRound >= gameInRound.length) {
      console.log("Games have ended!");
      /* TODO: Insert win logic here */
    } else {
      console.log(
        "Round has changed. Reset states and show a transition (if applicable)"
      );
    }
  }, [gameInRound, currentRound]);

  return (
    <>
      {winners ? (
        <FullScreenWinnerCard
          showConfetti={winners[0]?.id === myState.id}
          winners={winners.map((w) => w?.id || w)}
          scores={winners.map((w) => w?.score || 1)}
        />
      ) : (
        <></>
      )}
      <AnimatePresence>
        {gameInRound[currentRound] ? (
          /*
      References:
      - Clip-path: https://bennettfeely.com/clippy/
      - Animation: https://codesandbox.io/s/framer-motion-keyframes-0fzv21?from-embed=&file=/src/App.tsx:145-466
      - Transition: https://www.framer.com/docs/animation/
      */

          <motion.div
            key={
              gameInRound[currentRound]
                ? "rounds-" + gameInRound[currentRound].id
                : "rounds-end-screen"
            }
            initial={{
              // scale: 0,
              // opacity: 0,
              // borderRadius: "50%",
              clipPath:
                currentRound === 0 ? undefined : "circle(10% at 50% 25%)",
            }}
            // animate={{ opacity: 1 }}
            animate={{
              // scale: [0, 2, 2, 1, 1],
              // opacity: 1,
              // rotate: [0, 0, 180, 180, 0],
              // borderRadius: ["50%", "50%", "50%", "0%", "0%"],
              clipPath:
                currentRound === 0 ? undefined : "circle(350% at 50% 50%)",
            }}
            transition={{
              ease: "easeOut",
              duration: 1.5,
            }}
            // transition={{
            //   duration: 2,
            //   ease: "easeInOut",
            //   times: [0, 0.2, 0.5, 0.8, 1],
            //   // repeat: Infinity,
            //   // repeatDelay: 1
            // }}
            exit={{
              scale: 0,
              opacity: 0,
              borderRadius: "50%",
              clipPath: "circle(10% at 50% 50%)",
            }}
          >
            {React.createElement(gameInRound[currentRound].Component, {
              currentRound: currentRound,
              totalRounds: totalRounds,
              isLastRound: isLastRound,
              endRound: isLastRound
                ? (winner) => roundLogic(winner, true)
                : (winner) => roundLogic(winner, false),
              gameInfo: gameInfo,
              key: gameInRound[currentRound].id,
              _animationKey: gameInRound[currentRound].id,
            })}
          </motion.div>
        ) : (
          <motion.div>
            <div key="rounds-ended"></div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default Round;
