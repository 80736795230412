import { useEffect, useState, useRef } from "react";
import "./style.css";

import CartoonDialogBox from "../CartoonDialogBox";

import handImg from "./img/hand-shadow.png";

import PropTypes from "prop-types";

const includesMultiple = (arrayA, arrayB) => {
  return arrayA.every((val) => {
    return arrayB.indexOf(val) !== -1;
  });
};

/*
References:
- Playroom UI

Research: 
- Box-shadow without blur: https://stackoverflow.com/questions/4561097/css-box-shadow-bottom-only
- Get positional information of a div: https://stackoverflow.com/questions/32667847/get-divs-offsettop-positions-in-react
- includesMultiple: https://stackoverflow.com/questions/9204283/how-to-check-whether-multiple-values-exist-within-an-javascript-array
- As usual, I forgot how to make basic animations: https://developer.mozilla.org/en-US/docs/Web/CSS/animation

How to use:
```
<FingerPointer 
pointAt={elToPointAt}  // the element you want to point at
text="I am pointing, ustaad g 🫡" // the text you want to be displayed
quickPositions={["bottom", "left"]} // quickly position the hand, relative to the element you want to point at
animate={true} // do you want the hand to shake?
fingerPointerStyles={{ // add any customised styles
    margin: "-5px",
}}
/>
```

*/

const DEFAULT_POS = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
};

const FingerPointer = ({
  pointAt,
  text = "",
  children,
  hideOnClick = false,
  hideOnClickOk = false,
  hideOnClickRef = null,
  buttonText = "Ok!",
  buttonStyles = {},
  quickPositions = ["bottom", "left"],
  animate = true,
  fingerPointerStyles = {},
  textSpanStyles = {},
}) => {
  const [hiderRef, setHiderRef] = useState(
    hideOnClick ? pointAt : hideOnClickRef ? hideOnClickRef : null
  );
  const fingerPointerRef = useRef(null);
  const [position, setPosition] = useState(DEFAULT_POS);
  const [show, setShow] = useState(true);

  const onPointAtClick = () => {
    setShow(false);
  };

  useEffect(() => {
    setHiderRef(hideOnClick ? pointAt : hideOnClickRef ? hideOnClickRef : null);
  }, [hideOnClick, pointAt, hideOnClickRef, hideOnClickRef]);

  // any interaction with the target element -> hide the finger pointer!
  // https://stackoverflow.com/questions/26122115/detect-any-user-interaction
  useEffect(() => {
    if (hiderRef?.current) {
      hiderRef.current.addEventListener("keydown", onPointAtClick);
      hiderRef.current.addEventListener("click", onPointAtClick);
      hiderRef.current.addEventListener("touchstart", onPointAtClick);
    }

    return () => {
      if (hiderRef?.current) {
        hiderRef.current.removeEventListener("keydown", onPointAtClick);
        hiderRef.current.removeEventListener("click", onPointAtClick);
        hiderRef.current.removeEventListener("touchstart", onPointAtClick);
      }
    };
  }, [hiderRef]);

  useEffect(() => {
    if (pointAt?.current) {
      var currentElPosition = DEFAULT_POS;
      try {
        // ----------- PRINT DEBUG -----------
        // console.log("pointAt:", pointAt);
        // console.log("pointAt.current:", pointAt.current);
        // console.log("pointAt.current.getBoundingClientRect():", pointAt.current ? pointAt.current.getBoundingClientRect() : "cant get");
        // ----------- PRINT DEBUG -----------
        currentElPosition = pointAt.current.getBoundingClientRect();
      } catch (e) {
        console.log("error:", e);
        // var currentElPosition = pointAt.current.node.getBoundingClientRect();
      }

      setPosition(currentElPosition);
    }
  }, [pointAt]);

  if (!pointAt || !pointAt.current || !show) {
    return <></>;
  } else {
    return (
      <div
        className={"finger-pointer"}
        ref={fingerPointerRef}
        style={{
          ...{
            top:
              position.top +
              (quickPositions.includes("bottom") ? position.height : 0),
            left:
              position.left +
              (quickPositions.includes("right") ? position.width : 0),
            right: position.right,
            bottom: position.bottom,
            flexDirection: quickPositions.includes("top")
              ? "column-reverse"
              : "column",
            display: "flex",
            alignItems: "center",
          },
          ...fingerPointerStyles,
        }}
      >
        <div
          style={{
            height: "3rem",
            width: "fit-content",
            animation: animate
              ? `0.5s ${
                  quickPositions.includes("bottom") ? "ease-out" : "ease-in"
                } 0s infinite alternate ${
                  includesMultiple(quickPositions, ["bottom", "right"]) ||
                  includesMultiple(quickPositions, ["top", "left"])
                    ? "oscillate-diagonal-tr-br"
                    : "oscillate-diagonal-tl-bl"
                }`
              : undefined,
          }}
        >
          <img
            alt="Hand"
            src={handImg}
            style={{
              height: "100%",
              width: "auto",
              transform: `scale(${quickPositions.includes("right") ? -1 : 1}, ${
                quickPositions.includes("top") ? -1 : 1
              })`,
            }}
          />
        </div>

        {text || children ? (
          <CartoonDialogBox
            containerStyles={{
              ...{
                margin: animate ? "5px" : 0,
              },
              ...textSpanStyles,
            }}
            text={text}
          >
            {children}
            {hideOnClickOk ? (
              <button
                onClick={() => setShow(false)}
                style={{ ...buttonStyles }}
              >
                {buttonText}
              </button>
            ) : (
              <></>
            )}
          </CartoonDialogBox>
        ) : (
          <></>
        )}
      </div>
    );
  }
};

// for storybook
FingerPointer.propTypes = {
  // pointAt: PropTypes.bool,
  text: PropTypes.string,
  // children: PropTypes.bool,
  hideOnClick: PropTypes.bool,
  hideOnClickOk: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  quickPositions: PropTypes.oneOfType([PropTypes.array]),
  animate: PropTypes.bool,
  fingerPointerStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  textSpanStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

//   FingerPointer.defaultProps = {
//     backgroundColor: null,
//     primary: false,
//     size: 'medium',
//     onClick: undefined,
//   };

export default FingerPointer;
