import { useEffect, useState } from "react";
import BackgroundGame from "common/components/BackgroundGame";
import Player from "./player";
import { useGlobalState } from "common/hooks/multiplayer";

import CircularProgress from "common/components/CircularProgress";


import calculatePlayerSizeAndPosition from "common/utils/scalePlayers";
import { useMeasure } from "react-use";

const Viewer = ({ players, multiplayer, gameIsLive }) => {
  const [count, setCount] = useState(30);
  const hasWon = multiplayer.getState("winner");

  // ---------------- START SCALABLE ROW ---------------- 
  // we just need the width of the first player
  const [ref, { width }] = useMeasure();

  // We can use playerScale to scale down each player.
  // We don't need X, it will be automatically calculated with justifyContent.
  const [playerSizeAndPosition, setPlayerSizeAndPosition] = useState(calculatePlayerSizeAndPosition(
    players.length, 
    0, 
    window.innerWidth,
    width,
  ))

  useEffect(() => {
    setPlayerSizeAndPosition(calculatePlayerSizeAndPosition(
      players.length*5, 
      0, 
      window.innerWidth,
      width,
      50, 
      200,
    ))
  }, [width]);
  // ---------------- END SCALABLE ROW ---------------- 
  const timer = useGlobalState("timer");

  useEffect(() => {
    multiplayer.setState("timer", count);
  }, []);


  useEffect(() => {
    let interval;
    if (gameIsLive) {
      interval = setInterval(() => {
        if (gameIsLive) {
          setCount((count) => {
            if (count === 0) {
              clearInterval(interval);
              multiplayer.setState("done", true);
              return count;
            } else {
              return count - 1;
            }
          });
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [gameIsLive]);

  return (
    <div className="viewer-container">
      <BackgroundGame
        style={{
          background: `linear-gradient(180deg, ${
            hasWon && hasWon.color ? hasWon.color : "#D4FAFF"
          } 0%, #FED8FF 100%)`,
        }}
      ></BackgroundGame>
      
      {timer !== undefined && (
        <div style={{
          position: "fixed",
          top: "3rem",
          left: "2rem",
        }}>
        <CircularProgress 
          height={60} 
          width={60} 
          color={"#2ECE5B"} 
          timer={timer} 
        />
        </div>
      )}




      {!hasWon && (
        <div className="counter-container">
          {count > 25 ? (
            <>
              Collect beans
              <br />
              on your phone
            </>
          ) : (
            count
          )}
        </div>
      )}
      {hasWon && (
        <div className="winner-container">{hasWon && hasWon.name} wins!</div>
      )}
      {players.map((playerState, i) => {
        const profile = playerState.getState("profile");
        return (
          <Player
            containerRef={i===0 ? ref : undefined}
            containerStyles={{
              transform: playerSizeAndPosition?.playerScale ? `scale(${playerSizeAndPosition.playerScale})` : undefined,
              margin: playerSizeAndPosition?.playerScale ? `0rem ${playerSizeAndPosition.playerScale * 2}rem` : "0rem 4rem",
            }}
            state={playerState}
            name={profile.name}
            score={playerState.getState("score") || 0}
            color={profile?.color || "red"}
            key={i}
            
          />
        );
      })}
    </div>
  );
};

export default Viewer;