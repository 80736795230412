import { Portal } from "react-portal";
import React, { useEffect, useState, useRef } from "react";
import Collapsible from "react-collapsible";
import Modal from "../../components/Modal";
import Multiplayer from "../../modules/multiplayer";
import BackgroundNarrator from "../../modules/backgroundNarrator";
import GamesDirectory from "../../../pages/games/directory";
import Playlists, { getPlaylistFromId } from "../../../pages/games/playlists";

import BrowserStorage from "../../modules/localStorageWrapper";

import PlaylistThumbnailContainer from "../../components/PlaylistThumbnailContainer";

import soundWinner from "../../sfx/winner.mp3";
import imgFlag from "../../imgs/flag-color.gif";
import imgWelcome from "./img/welcome.gif";
import imgStar from "./img/star.png";
import AvatarIcon from "../AvatarIcon";

import soundOffImg from "../../imgs/soundoff.png";
import soundOnImg from "../../imgs/soundon.png";
import quitImg from "./img/quit.png";

import FingerPointer from "../FingerPointer";

import { useGlobalState } from "../../hooks/multiplayer";
import { resetAllNonPlayroomStates } from "../../modules/multiplayer/state";

import "./style.css";

export default function GamesMenu({
  keepOpen,
  gameInfo,
  showIfHost,
  showWelcomePopup,
  showStartGamesButtonIfHost,
  showRestart,
  showCastingInstructions,
  disableNavigation,
  onStartGames,
}) {
  const multiplayer = Multiplayer();

  const myState = multiplayer.getMyPlayerState();
  const profile = myState?.getState("profile") || {};
  const myColor = profile?.color || null;

  const [isHost, setIsHost] = useState(multiplayer.isHost);
  const [isCasting, setIsCasting] = useState(
    multiplayer.getState("casting") && !showCastingInstructions
  );
  const [playerCount, setPlayerCount] = useState(1);
  const BGNarrator = BackgroundNarrator();
  const gamesDisabled = !isHost || disableNavigation;
  // const history = useHistory();
  const [menuVisible, setMenuVisible] = useState(
    (isHost && showIfHost) || keepOpen
  );
  const [showStartGamesButton, setShowStartGamesButton] = useState(
    isHost && showStartGamesButtonIfHost
  );
  const [isSpectator, setIsSpectator] = useState(false);
  const [playerName, setPlayerName] = useState(null);

  const showDevPlaylists =
    BrowserStorage.get("invitecode") === "dev" ||
    BrowserStorage.get("invitecode") === "ponka" ||
    window.DEV;

  const [isAboutToAutoplay, setIsAboutToAutoplay] = useState(false);
  const [welcomePopupVisible, setWelcomePopupVisible] =
    useState(showWelcomePopup);

  const clearGameState = (isQuit = true) => {
    // prevent the next game in the playlist from launching
    // when it has already been quitted
    if (isQuit) {
      multiplayer.setState("playlistCurIndex", undefined);

      // reset player scores
      multiplayer.setState("winslog", undefined);
      multiplayer.setState("skip", undefined);
    }

    // takes time for state changes to propagate hence killing after 1s
    setTimeout(() => {
      resetAllNonPlayroomStates(multiplayer);
    }, 2000);
  };

  // Use the below code for maintaining local state which could be updated
  // whenever the global state changes

  // const [playlist, setPlaylist] = useState(()=>{
  //     // By default, when a game is created, the "demo" playlist is chosen
  //     // comment this to eliminate
  //     // TODO: Add check for demo e.g. if demo-mode, then do this.
  //     if (multiplayer.getState("playlist")){
  //       return multiplayer.getState("playlist");
  //     }
  //     let defaultPlaylist = Playlists.find(p => p.id === "demo");
  //     multiplayer.setState("playlist", defaultPlaylist);
  //     return defaultPlaylist
  //   }
  // );

  const curPlaylist = useGlobalState("playlist");
  const playlist = getPlaylistFromId(curPlaylist);
  const defaultPlaylist = window.isDemoMode ? "demo" : "fastestfingersfullgame";

  const setPlaylistGlobally = (playlist, returnToMenu = true) => {
    console.log("Setting Playlist:", playlist);
    multiplayer.setState("playlist", playlist);

    // hide the menu on playlist change
    if (returnToMenu) {
      setTimeout(() => {
        setMenuVisible(false);
      }, 300);
    }
  };

  // console.log("playlist:", playlist);

  useEffect(() => {
    console.log("Playlist, at the beginning, is:", playlist);
    if (playlist === undefined) {
      setPlaylistGlobally(defaultPlaylist, false);
    }

    // eslint-disable-next-line
  }, []);

  // UNCOMMENT: To test if playlist dynamically updates

  // -------------- TEST --------------
  // useEffect(() => {
  //  setTimeout(()=>{
  //   setPlaylistGlobally(
  //
  //     Playlists[1]
  //   )
  //     console.log("The playlist should auto-change");
  // }, 25000);
  // }, []);
  // -------------- TEST --------------

  useEffect(() => {
    const fnOnHostUpdated = multiplayer.on("host_updated", (isHost) => {
      console.log("host_updated", isHost);
      setIsHost(isHost);
      setMenuVisible(isHost && showIfHost);
      setShowStartGamesButton(isHost && showStartGamesButtonIfHost);
    });
    const fnOnState = multiplayer.on("state", (state, key) => {
      if (multiplayer.getState("casting") !== isCasting) {
        setIsCasting(
          multiplayer.getState("casting") && !showCastingInstructions
        );
      }
      // This means that the playlist has changed
      if (multiplayer.getState("playlist") !== curPlaylist) {
        console.log("state.path.pathname:", state.path.pathname);
        // This means that another game is already running
        if (gameInfo) {
          console.log("Seems like we're not in the lobby anymore...", state);
          if (!isAboutToAutoplay) {
            // BGNarrator.play(soundWinner);
            setIsAboutToAutoplay(true);
            setTimeout(() => {
              console.log("NAVIGATE FROM MENU after PLAYLIST CHANGE");

              // 1. clear game leaderboard and playlist current game index
              multiplayer.setState("winslog", undefined);
              multiplayer.setState("playlistCurIndex", undefined);

              // 2. navigate to the autoplay screen with fresh settings
              multiplayer.navigate("/autoplaygame", {
                firstTime: true,
                currentGameId: undefined,
                nextGameId: undefined,
              });
              setIsAboutToAutoplay(false);
            }, 1000);
          }
        }
      }
    });
    const fnOnPlayerJoined = multiplayer.on("joined", (state, key) => {
      setPlayerCount(Object.keys(multiplayer.getPlayers()).length);
    });
    if (menuVisible) {
      // load profile photo
      const state = multiplayer.getMyPlayerState();
      if (
        state &&
        state.getState("profile") &&
        state.getState("profile").name
      ) {
        setPlayerName(state.getState("profile").name);
      }
    } else {
      setIsSpectator(multiplayer.isSpectator);
    }

    return () => {
      fnOnHostUpdated();
      fnOnState();
      fnOnPlayerJoined();
    };
    // eslint-disable-next-line
  }, [menuVisible]);

  useEffect(() => {
    // when game is won or has ended (by countdown, etc)
    if (showRestart && !isAboutToAutoplay) {
      BGNarrator.play(soundWinner);
      setIsAboutToAutoplay(true);
      setTimeout(() => {
        console.log("NAVIGATE FROM MENU");
        console.log("gameInfo", gameInfo);
        // debugger
        multiplayer.navigate("/autoplaygame", { currentGameId: gameInfo.id });
        setIsAboutToAutoplay(false);
      }, 4000);
    }
    // eslint-disable-next-line
  }, [showRestart]);

  if (!menuVisible)
    return (
      <>
        {/* {showLogo ? <div className="logo-small"></div> : false} */}
        {!isSpectator && (
          <BottomBar
            hostMenuDisabled={!isHost}
            controllerDisabled={keepOpen}
            activeTab="controller"
            onChange={(tabKey) => {
              if (tabKey === "host") {
                setMenuVisible(true);
              }
            }}
          />
        )}
        {!isSpectator && showStartGamesButton ? (
          <>
            <button
              className="button-menu start-button"
              onClick={async () => {
                if (onStartGames) onStartGames();
              }}
            >
              <svg
                width="15"
                height="18"
                viewBox="0 0 15 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 7.26795C15.3333 8.03775 15.3333 9.96225 14 10.7321L3.5 16.7942C2.16667 17.564 0.500001 16.6018 0.500001 15.0622L0.500002 2.93782C0.500002 1.39822 2.16667 0.435971 3.5 1.20577L14 7.26795Z"
                  fill="white"
                />
              </svg>
              <span>Tap to start</span>
            </button>
          </>
        ) : (
          false
        )}
      </>
    );
  return (
    <>
      {!isSpectator && (
        <BottomBar
          hostMenuDisabled={!isHost}
          controllerDisabled={keepOpen}
          activeTab="host"
          onChange={(tabKey) => {
            if (tabKey === "controller") {
              setMenuVisible(false);
            }
          }}
        />
      )}
      {/* <ForceOrientation portrait /> */}
      <Portal>
        <Modal
          open={welcomePopupVisible}
          onClose={() => setWelcomePopupVisible(false)}
          className="menu-welcome-popup"
        >
          <img src={imgWelcome} alt="welcome" className="welcome-image" />
          <h3>
            Welcome to Playroom
            <br />
            <img src={imgStar} alt="star" />
            Early access
            <img src={imgStar} alt="star" />
          </h3>
          <p>
            Our game makers are working hard to bring exciting and silly party
            games every week. Have fun!
          </p>

          <div
            className="main-cta"
            onClick={() => setWelcomePopupVisible(false)}
          >
            Let{"'"}s go!
          </div>
        </Modal>
        <div className="games-menu-container">
          <div className="topbar">
            <div className="menu-and-logo">
              <div className="logo-small"></div>
              <div className="right-container">
                <span className="player-username">@{playerName}</span>
              </div>
            </div>
          </div>
          {gameInfo ? (
            <NoPreviewContainer
              multiplayer={multiplayer}
              onPlay={async () => {
                if (!keepOpen) {
                  // await window.RotateScreen(orientation || "landscape");
                  setMenuVisible(false);
                }
              }}
              myColor={myColor}
              isHost={isHost}
              onQuit={() => {
                clearGameState();
                multiplayer.navigate("/games/lobby", {
                  stayAtController: true,
                });
                // call it again - safai nisf iman hai.
                clearGameState();
                console.log("Navigated to lobby.");
              }}
            />
          ) : !isCasting ? (
            <WaitingForCastScreen
              multiplayer={multiplayer}
              isHost={isHost}
              myColor={myColor}
              // onQuit={()=>{
              //   setMenuVisible(false);
              // }}
            />
          ) : isCasting && playerCount < 2 ? (
            <WaitingForPlayers
              multiplayer={multiplayer}
              isHost={isHost}
              // onQuit={() => {
              //   setMenuVisible(false);
              // }}
              myColor={myColor}
            />
          ) : (
            <NoPreviewContainer
              multiplayer={multiplayer}
              onPlay={async () => {
                if (!keepOpen) {
                  // await window.RotateScreen(orientation || "landscape");
                  setMenuVisible(false);
                }
              }}
              myColor={myColor}
              isHost={isHost}
              onQuit={() => {
                setMenuVisible(false);
              }}
            />
          )}

          {isCasting &&
            playerCount >= 2 &&
            // only if playlist has been initialized / it exists
            playlist &&
            (BrowserStorage.get("invitecode") === "dev" ||
              BrowserStorage.get("invitecode") === "ponka" ||
              window.DEV ||
              window.isDemoMode) && (
              <GamesList
                playlist={playlist}
                gamesDisabled={gamesDisabled}
                disableNavigation={disableNavigation}
                setMenuVisible={setMenuVisible}
                showPlaylistItems={showDevPlaylists || window.isDemoMode}
                gameInfo={gameInfo}
              >
                <CurrentPlaylistThumbnail playlist={playlist} />
              </GamesList>
            )}

          {gameInfo ? (
            <></>
          ) : (
            <div
              className={
                "mood-container " +
                (!(
                  (isCasting && playerCount >= 2)
                  //  && !window.isDemoMode
                )
                  ? "disabled"
                  : "")
              }
            >
              <h3>Free Party challenges</h3>
              <div className="mood-list">
                {Playlists.filter(
                  (p) =>
                    !(
                      (p.id === "demo" && !window.isDemoMode) ||
                      (!showDevPlaylists && p.dev)
                    )
                ).map((playlist) => {
                  return (
                    <PlaylistThumbnailContainer
                      playlist={playlist}
                      disabled={
                        !(isCasting && playerCount >= 2) || window.isDemoMode
                      }
                      setPlaylist={setPlaylistGlobally}
                      playlistContainerStyles={{
                        // maxWidth: "90vw",
                        width: "66vw",
                        height: "auto",
                        // flex: "1 0 30px",
                        // width: "fit-content",
                      }}
                      imgStyles={{
                        width: "66vw",
                        height: "auto",
                      }}
                    />
                  );
                })}
              </div>

              {/* <h3>New games by Creators</h3>
            <div className="mood-list">
            {
                PopularPlaylists.map((playlist, idx) => {
                  return (
                    <PlaylistThumbnailContainer playlist={playlist} setPlaylist={setPlaylistGlobally} />
                );
              })
            }
            </div> */}
            </div>
          )}
        </div>
      </Portal>
    </>
  );
}

const WaitingForCastScreen = ({ multiplayer, isHost, onQuit, myColor }) => {
  const airPlayRef = useRef(null);
  const roomCodeRef = useRef(null);
  const [showFingerPointer, setShowFingerPointer] = useState(true);

  return (
    <>
      <div className="roomcode-container playing">
        <span className="status">Waiting</span>
        <div className="code-play-container">
          <div className="inner-container">
            <span className="roomcode-title">ROOM CODE</span>
            <span className="roomcode" ref={roomCodeRef}>
              {multiplayer.currentRoom.split("").map((ch, idx) => (
                <span key={idx}>{ch}</span>
              ))}
            </span>
          </div>
          <img alt="flag icon" className="icon-flag" src={imgFlag} />
        </div>
      </div>
      {/* TODO: Might not be scalable when multiple fingerPointers come in play */}
      {window.isDemoMode && showFingerPointer ? (
        <FingerPointer
          pointAt={roomCodeRef}
          text={"Type this code on screen"}
          quickPositions={["bottom", "left"]}
          fingerPointerStyles={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: "1rem",
            marginLeft: "1rem",
            height: "fit-content",
          }}
          hideOnClick={true}
          // hideOnClickAnywhere={true}
        />
      ) : (
        <></>
      )}
      {isHost ? <MenuControls onQuit={onQuit} /> : <></>}
      {
        // Demo mode: cast options(tv, laptop) should be hidden.
        !window.isDemoMode ? (
          <>
            <Collapsible
              trigger={
                <span className="instruction-title laptop">
                  joinplayroom.com/R{multiplayer.currentRoom}
                </span>
              }
            >
              <p className="instruction-text">
                <center>
                  Join on laptop or iPad with the room link above.
                </center>
              </p>
            </Collapsible>
            <Collapsible
              onOpen={() => setShowFingerPointer(false)}
              trigger={
                <span className="instruction-title airplay" ref={airPlayRef}>
                  Join via Airplay on TV
                </span>
              }
            >
              <p className="instruction-text">
                <ol>
                  <li>
                    Connect your iPhone, iPad, or iPod touch to the same Wi-Fi
                    network as your Apple TV.
                  </li>
                  <li>
                    Swipe down from the upper-right corner of the screen to open
                    Control Center on this phone.
                  </li>
                  <li>
                    Tap Screen Mirroring{" "}
                    <span className="icon-screenmirror"></span>.
                  </li>
                  <li>
                    Select your Apple TV or any AirPlay compatible device.
                  </li>
                  <li>
                    If an AirPlay passcode appears on your TV screen, enter the
                    passcode on your iOS or iPadOS device.
                  </li>
                  <li>Return to this app to continue.</li>
                </ol>
              </p>
            </Collapsible>

            {/* TODO: Might not be scalable when multiple fingerPointers come in play */}
            {showFingerPointer ? (
              <FingerPointer
                pointAt={airPlayRef}
                text={"Cast on a second screen to play"}
                quickPositions={["bottom", "left"]}
                fingerPointerStyles={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "1rem",
                  height: "fit-content",
                }}
                hideOnClick={true}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          false
        )
      }
    </>
  );
};

const WaitingForPlayers = ({ multiplayer, isHost, onQuit }) => (
  <>
    <div className="roomcode-container playing">
      <span className="status">Waiting for Players</span>
      <div className="code-play-container">
        <div className="inner-container">
          <span className="roomcode-title">ROOM CODE</span>
          <span className="roomcode">
            {multiplayer.currentRoom.split("").map((ch, idx) => (
              <span key={idx}>{ch}</span>
            ))}
          </span>
        </div>
        <img alt="flag icon" className="icon-flag" src={imgFlag} />
      </div>
    </div>
    {isHost ? <MenuControls onQuit={onQuit} /> : <></>}
  </>
);

const NoPreviewContainer = ({
  multiplayer,
  onPlay,
  isHost,
  onQuit,
  myColor,
}) => (
  <>
    <div className="roomcode-container playing">
      <span className="status">Playing</span>
      <div className="code-play-container">
        <div className="inner-container">
          <span className="roomcode-title">ROOM CODE</span>
          <span className="roomcode">
            {multiplayer.currentRoom.split("").map((ch, idx) => (
              <span key={idx}>{ch}</span>
            ))}
          </span>
        </div>
        <div
          onClick={onPlay}
          className="btn-play"
          style={{
            backgroundColor: myColor ? myColor : undefined,
          }}
        >
          Play
        </div>
      </div>
    </div>
    {isHost ? <MenuControls onQuit={onQuit} /> : <></>}
  </>
);

const MenuControls = ({ onQuit }) => {
  const multiplayer = Multiplayer();
  const isMuted = useGlobalState("muted");
  return (
    <div className="menu-control-buttons">
      <button
        onClick={() => {
          multiplayer.setState(
            "muted",
            multiplayer.getState("muted") ? undefined : true
          );
        }}
      >
        <img src={isMuted ? soundOffImg : soundOnImg} alt="Sound on/off icon" />
        <span>Sound</span>
      </button>

      <button onClick={onQuit} disabled={onQuit ? false : true}>
        <img src={quitImg} alt="Door" />
        <span>Quit to Lobby</span>
      </button>
    </div>
  );
};

const BottomBar = ({
  activeTab,
  onChange,
  hostMenuDisabled,
  controllerDisabled,
}) => {
  const multiplayer = Multiplayer();
  return (
    <div className="menu-bottombar">
      <div
        onClick={!hostMenuDisabled ? () => onChange("host") : null}
        className={
          "btn btn-host" +
          (activeTab === "host" ? " active" : "") +
          (hostMenuDisabled ? " disabled" : "")
        }
      />
      <div
        onClick={!controllerDisabled ? () => onChange("controller") : null}
        className={
          "btn btn-controller" +
          (activeTab === "controller" ? " active" : "") +
          (controllerDisabled ? " disabled" : "")
        }
      />
      <div
        onClick={() => onChange("profile")}
        className={
          "btn btn-profile" + (activeTab === "profile" ? " active" : "")
        }
      >
        <AvatarIcon
          onClick={() => onChange("profile")}
          playerState={multiplayer.getMyPlayerState()}
        />
      </div>
    </div>
  );
};

const CurrentPlaylistThumbnail = ({ playlist }) => {
  return (
    <div className="playlist-header">
      <img src={playlist.thumbnail} alt="playlist thumbnail" />
      <div className="playlist-header-text">
        <h3>{playlist.title}</h3>
        <span>
          {playlist.gamesList.length} game
          {playlist.gamesList.length === 1 ? "" : "s"}
        </span>
        {playlist.dev ? <span className="dev-indicator-fish">dev</span> : <></>}
      </div>
    </div>
  );
};

const GamesList = ({
  playlist,
  gamesDisabled,
  disableNavigation,
  setMenuVisible,
  showPlaylistItems,
  gameInfo,
  children,
}) => {
  const multiplayer = Multiplayer();
  return (
    <>
      <div className="games-list">
        {children}
        {showPlaylistItems &&
          playlist.gamesList.map((currentGame) => {
            const currentGameInfo = GamesDirectory.find(
              (g) => g.id === currentGame.id
            );
            console.log("currentGameInfo:", currentGameInfo);
            if (currentGameInfo) {
              return (
                <button
                  key={currentGameInfo.pages[0].slug}
                  className={"game-tile" + (gamesDisabled ? " disabled" : "")}
                  onClick={async () => {
                    if (gamesDisabled) {
                      if (!multiplayer.isHost) {
                        alert("Only host can launch games.");
                      } else if (disableNavigation) {
                        alert(disableNavigation);
                      }
                      return;
                    }
                    // window.RotateScreen("landscape");
                    setMenuVisible(false);

                    multiplayer.navigate("/autoplaygame", {
                      currentGameId: gameInfo ? gameInfo.id : null,
                      nextGameId: currentGameInfo.id,
                    });
                  }}
                >
                  <div className="game-info">
                    <span className="title">{currentGameInfo.title}</span>
                    <span className="subtitle">
                      {currentGameInfo.metadata?.type}
                    </span>
                  </div>
                </button>
              );
            } else {
              return (
                <div className="game-info" style={{ background: "#ff4d4d20" }}>
                  <span className="title" style={{ color: "red" }}>
                    {"The game could not be loaded."}
                  </span>
                  <span className="subtitle" style={{ color: "red" }}>
                    {"Try refreshing your browser."}
                  </span>
                </div>
              );
            }
          })}
      </div>
    </>
  );
};
