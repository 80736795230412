import Rating from "react-rating";
import "./style.css";

const RateGame = ({ onRatingClick, rating }) => {
  return (
    <div className="rate-game-container">
      <div className="rate-game-modal">
        <span className="rate-game-modal-span">Fun meter</span>
        <h3>Make our games better</h3>
        <p>How much did you enjoy your last game?</p>
        <Rating
          className="rating"
          initialRating={rating}
          readonly={rating !== undefined}
          emptySymbol={
            <span
              className="icon-text"
              style={{ marginLeft: "25px", opacity: 0.25, fontSize: "25px" }}
            >
              ❤️
            </span>
          }
          fullSymbol={
            <span
              className="icon-text"
              style={{ marginLeft: "25px", fontSize: "25px" }}
            >
              ❤️
            </span>
          }
          onClick={onRatingClick}
        />
      </div>
    </div>
  );
};

export default RateGame;
