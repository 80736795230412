import CommonGameScene from "common/components/GameRenderer/gamescene";
import sfxJoin from "./assets/playerjoin.mp3";
import { AvatarPhaserHelper } from "common/modules/avatarCreator";
import sfxPush from "./assets/push.mp3";
import car from "./assets/carnew.png";
import SJ from "./assets/ring.json";
import Background from "./assets/bgnew.png";
import Ring from "./assets/ringx0.6.png";
import icecreamIcon from "./assets/icecreamIcon.png";
import icecream from "./assets/icecream.png";

function randRange(minNum, maxNum) {
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}
class MyGame extends CommonGameScene {
  constructor() {
    super();
    this.playerNameTags = {};
    this.PlayersStartPositions = [];
    this.playersIcecream = {};
    this.playerHasButtonPressed = {};
    this.spawningIcecream = false;
    this.addingRandomIcecreamTimeout = null;
    this.playerFrozenTill = {};
    this.player_index = 0;
    this.BigText = null;
    this.GameStart = false;
    this.GameOver = false;
    this.GameSpeed = 1;
    this.Ring = null;
  }

  preload() {
    this.load.audio("join", sfxJoin);
    this.load.audio("push", sfxPush);

    //game assets
    this.load.image("Background", Background);
    this.load.image("Ring", Ring);
    this.load.image("icecreamIcon", icecreamIcon);
    this.load.image("icecream", icecream);
    this.load.image("car", car);
    this.load.json("shapes", SJ);
  }

  create() {
    super.create();
    this.matter.world.setBounds(
      0,
      0,
      this.cameras.main.width,
      this.cameras.main.height,
      15
    );
    this.CreateRace();
  }

  startAddingIcecream() {
    var icecreamIcon = this.matter.add
      .sprite(
        this.Ring.x - this.Ring.displayWidth * 0.17,
        this.Ring.y + (this.Ring.displayHeight * randRange(17, 40)) / 100,
        "icecreamIcon"
      )
      .setScale(0.4);
    icecreamIcon.setSensor(true);
    icecreamIcon.setData({ type: "icecreamIcon" });
    if (this.addingRandomIcecreamTimeout)
      clearTimeout(this.addingRandomIcecreamTimeout);
    this.addingRandomIcecreamTimeout = setTimeout(() => {
      if (!this.multiplayer.getState("winner")) {
        this.startAddingIcecream();
      }
    }, randRange(10000, 15000));
  }

  async addPlayerSprite(playerState, profile) {
    // console.log("addPlayerSprite",playerState, profile,this.players);
    console.log("addPlayerSprite , this.player_index", this.player_index);

    let sprite = await AvatarPhaserHelper(
      car,
      playerState,
      profile,
      [0, 0],
      (posX, posY, textureId) => this.matter.add.image(posX, posY, textureId),
      (textureId) => {
        return this.textures.createCanvas(textureId, 433, 340);
      }
    );

    sprite.setScale(0.25);

    sprite.setPosition(
      this.PlayersStartPositions[this.player_index].x,
      this.PlayersStartPositions[this.player_index].y
    );
    this.player_index++;

    sprite.setFrictionAir(0.1);
    sprite.setMass(30);
    sprite.setFixedRotation();
    sprite.setFrictionStatic(1);
    sprite.setName(playerState.id);

    // add text for player name
    var style = {
      font: "24px Arial",
      fill: "#ffffff",
      wordWrap: true,
      align: "center",
    };

    // var text = this.add.text(
    //   sprite.x - sprite.displayWidth * 0.75,
    //   sprite.y,
    //   profile.name + "\nL:0",
    //   style
    // );
    // text.setOrigin(0.5, 0.5);

    sprite.setData({
      laps: 0,
      player_name: profile.name,
      player_id: playerState.id,
    });

    // this.playerNameTags[playerState.id] = text;
    if (!this.GameStart) {
      //start count down animation
      this.time.addEvent({
        delay: 600,
        callback: () => {
          this.BigText.setText("3");
          this.Zoom();
        },
        callbackScope: this,
        loop: false,
      });
      this.time.addEvent({
        delay: 1200,
        callback: () => {
          this.BigText.setText("2");
          this.Zoom();
        },
        callbackScope: this,
        loop: false,
      });
      this.time.addEvent({
        delay: 1800,
        callback: () => {
          this.BigText.setText("1");
          this.Zoom();
        },
        callbackScope: this,
        loop: false,
      });
      this.time.addEvent({
        delay: 2400,
        callback: () => {
          this.BigText.setText("Go!");
          this.Zoom();
        },
        callbackScope: this,
        loop: false,
      });
      this.time.addEvent({
        delay: 3000,
        callback: () => {
          this.BigText.setText("");
          this.BigText.setScale(1);
          this.GameStart = true;
        },
        callbackScope: this,
        loop: false,
      });
    }

    return sprite;
  }
  Zoom() {
    this.BigText.setScale(4);
    this.BigText.setAlpha(0.5);
    this.tweens.add({
      targets: this.BigText,
      scale: 2,
      alpha: 1,
      delay: 50,
      ease: "Power1",
      duration: 500,
    });
  }
  shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  updateCommon(playerId, sprite, state) {
    if (!this.GameStart || this.GameOver) {
      return;
    }

    if (this.playersIcecream[playerId]) {
      this.playersIcecream[playerId].setPosition(sprite.x, sprite.y);
      this.playersIcecream[playerId].setAngle(sprite.angle);
    }

    const isFrozen =
      this.playerFrozenTill[playerId] &&
      this.playerFrozenTill[playerId] > Date.now();

    var desiredDegree = sprite.angle;

    if (state.inputState.dpad && state.inputState.dpad !== "off") {
      desiredDegree = parseInt((-1 * state.inputState.dpad) % 360); //convert clockwise to counterclockwise which phaser uses (no idea how math works, but it does)
    }

    if (isNaN(desiredDegree)) {
      desiredDegree = sprite.angle;
    }

    if (sprite.angle !== desiredDegree && !isFrozen) {
      console.log(
        Math.abs(sprite.angle - desiredDegree),
        sprite.angle,
        desiredDegree
      );
      const dir = getLerpDirectionBetweenAngles(sprite.angle, desiredDegree);
      if (dir > 0) {
        sprite.setAngularVelocity(0.08);
      } else if (dir < 0) {
        sprite.setAngularVelocity(-0.08);
      }
    }

    if (state.inputState.dpad && state.inputState.dpad !== "off" && !isFrozen) {
      sprite.thrust(0.1 * this.GameSpeed);
    }

    if (state.isKeyDown("b1") && this.playersIcecream[playerId] && !isFrozen) {
      this.playerHasButtonPressed[playerId] = true;
    }

    if (
      !state.isKeyDown("b1") &&
      this.playerHasButtonPressed[playerId] &&
      this.playersIcecream[playerId]
    ) {
      this.playerHasButtonPressed[playerId] = false;
      const angle = sprite.angle;
      const compX = Math.cos((angle * Math.PI) / 180);
      const compY = Math.sin((angle * Math.PI) / 180);
      this.playersIcecream[playerId].setVelocity(
        compX * this.adjustByScale(15),
        compY * this.adjustByScale(15)
      );
      // this.playersIcecream[playerId].destroy();
      delete this.playersIcecream[playerId];
    }

    if (isFrozen) {
      sprite.setVelocity(0, 0);
      sprite.setAngularVelocity(0);
      sprite.alpha = 0.5;
    } else {
      sprite.alpha = 1;
    }

    // this.playerNameTags[playerId].x = sprite.x - sprite.displayWidth * 0.75;
    // this.playerNameTags[playerId].y = sprite.y;
  }

  handlePlayerQuit(playerState) {
    if (this.player[playerState.id]) {
      console.log("player quit");
      this.player_index--;
      // this.playerNameTags[playerState.id].destroy();
      // delete this.playerNameTags[playerState.id];
    }
  }

  /***************game code  ****************/
  CreateRace() {
    console.log("CreateRace");

    //background image
    let BG = this.add.image(0, 0, "Background");
    //backgrund fit the canvas
    BG.setDisplaySize(this.cameras.main.width, this.cameras.main.height);
    this.bg = BG;
    // this.setAsBackground(BG);

    //ring
    var shapes = this.cache.json.get("shapes");
    // console.log(shapes)
    let Ring = this.matter.add.sprite(0, 0, "Ring", null, {
      isStatic: true,
      shape: shapes.ringx0,
    });
    this.Ring = Ring;
    Ring.setPosition(
      this.cameras.main.width * 0.5,
      this.cameras.main.height * 0.5
    );

    // adjust bg to ring
    this.bg.x = this.Ring.x;
    this.bg.y = this.Ring.y;
    this.bg.displayHeight = this.Ring.displayHeight;
    this.bg.displayWidth = this.cameras.main.width * 1.5;

    //rectangle inside the ring
    this.matter.add.rectangle(
      Ring.x - Ring.displayWidth * 0.015,
      Ring.y - Ring.displayHeight * 0.01,
      Ring.displayWidth * 0.57,
      Ring.displayHeight * 0.16,
      {
        isStatic: true,
        chamfer: { radius: [10, 10, 10, 10] },
      }
    );

    // this.startline = this.matter.add.rectangle(
    //   Ring.x - Ring.displayWidth * 0.17,
    //   Ring.y - Ring.displayHeight * 0.27,
    //   16,
    //   Ring.displayHeight * 0.4,
    //   {
    //     isSensor: true,
    //     label: "startline",
    //   }
    // );

    this.startline = this.matter.add.rectangle(
      this.Ring.x - this.Ring.displayWidth * 0.17,
      this.Ring.y -
        this.Ring.displayHeight * 0.27 -
        (this.cameras.main.height - this.Ring.displayHeight) / 2,
      16,
      this.Ring.displayHeight * 0.4 +
        (this.cameras.main.height - this.Ring.displayHeight),
      {
        isSensor: true,
        label: "startline",
      }
    );

    // this.checkline = this.matter.add.rectangle(
    //   Ring.x + Ring.displayWidth * 0.2,
    //   Ring.y + Ring.displayHeight * 0.28,
    //   16,
    //   Ring.displayHeight * 0.4,
    //   {
    //     isSensor: true,
    //     label: "checkline",
    //   }
    // );

    this.checkline = this.matter.add.rectangle(
      Ring.x + Ring.displayWidth * 0.2,
      Ring.y +
        Ring.displayHeight * 0.28 +
        (this.cameras.main.height - this.Ring.displayHeight) / 2,
      16,
      Ring.displayHeight * 0.4 +
        (this.cameras.main.height - this.Ring.displayHeight),
      {
        isSensor: true,
        label: "checkline",
      }
    );

    this.matter.world.on(
      "collisionstart",
      (event, bodyA, bodyB) => {
        this.CheckRace(bodyA, bodyB);
        this.checkIcecream(bodyA, bodyB);
        this.checkIcecreamIcon(bodyA, bodyB);
      },
      this
    );
    //this.matter.add.mouseSpring();

    this.PlayersStartPositions = [
      {
        x: Ring.x - Ring.displayWidth * 0.22,
        y: Ring.y - Ring.displayHeight * 0.45,
      },
      {
        x: Ring.x - Ring.displayWidth * 0.22,
        y: Ring.y - Ring.displayHeight * 0.3,
      },
      {
        x: Ring.x - Ring.displayWidth * 0.22,
        y: Ring.y - Ring.displayHeight * 0.15,
      },

      {
        x: Ring.x - Ring.displayWidth * 0.38,
        y: Ring.y - Ring.displayHeight * 0.3,
      },
      {
        x: Ring.x - Ring.displayWidth * 0.38,
        y: Ring.y - Ring.displayHeight * 0.15,
      },
    ];

    //randomize positions
    this.PlayersStartPositions = this.shuffle(this.PlayersStartPositions);

    var style = {
      font: "bold 54px Arial",
      fill: "#ffffff",
      align: "center",
      stroke: "#000000",
      strokeThickness: 6,
    };

    this.BigText = this.add.text(
      Ring.x - Ring.displayWidth * 0.015,
      Ring.y + Ring.displayHeight * 0.25,
      "First to complete 5 laps wins!",
      style
    );
    this.BigText.setOrigin(0.5, 0.5);
  }

  checkIcecreamIcon(bodyA, bodyB) {
    // check if car and icecream icon collide
    if (!bodyA.gameObject || !bodyB.gameObject) return;
    const icecreamIcon =
      bodyB.gameObject.getData("type") === "icecreamIcon" ? bodyB : bodyA;
    const maybeCar =
      bodyB.gameObject.getData("type") === "icecreamIcon" ? bodyA : bodyB;
    if (
      maybeCar.gameObject.getData("player_id") &&
      icecreamIcon.gameObject.getData("type") === "icecreamIcon"
    ) {
      const pid = maybeCar.gameObject.getData("player_id");
      // check if player already has an icecream
      if (this.playersIcecream[pid]) return;

      // create icecream and add to player
      icecreamIcon.gameObject.destroy();
      this.playersIcecream[pid] = this.matter.add.image(0, 0, "icecream");
      this.playersIcecream[pid].setSensor(true);
      this.playersIcecream[pid].setScale(0.4);
      this.playersIcecream[pid].setData({ playerId: pid, type: "icecream" });
    }
  }

  checkIcecream(bodyA, bodyB) {
    // check if car and icecream collide
    if (!bodyA.gameObject || !bodyB.gameObject) return;

    const iceCream =
      bodyB.gameObject.getData("type") === "icecream" ? bodyB : bodyA;
    const maybeCar =
      bodyB.gameObject.getData("type") === "icecream" ? bodyA : bodyB;
    if (
      maybeCar.gameObject.getData("player_id") &&
      iceCream.gameObject.getData("type") === "icecream" &&
      iceCream.gameObject.getData("playerId") !==
        maybeCar.gameObject.getData("player_id")
    ) {
      const pid = maybeCar.gameObject.getData("player_id");
      this.playerFrozenTill[pid] = Date.now() + 3000;
      iceCream.gameObject.destroy();
    }
  }

  CheckRace(bodyA, bodyB) {
    if (!bodyA.gameObject && !bodyB.gameObject) return;
    const maybeCar = bodyB.gameObject.getData("player_id") ? bodyB : bodyA;
    const maybeLine = bodyB.gameObject.getData("player_id") ? bodyA : bodyB;
    if (!maybeCar.gameObject || !maybeCar.gameObject.getData("player_id"))
      return;
    // check if car check the line
    if (maybeLine.label === "checkline") {
      console.log(maybeCar.label, "pass check line");
      maybeCar.gameObject.setData({ passcheckline: true });
    }
    //check if car made a turn
    if (maybeLine.label === "startline") {
      let _a =
        maybeCar.gameObject.rotation -
        Math.round(maybeCar.gameObject.rotation / Math.PI) * Math.PI;
      //console.log('s1',_a)
      if (_a > -Math.PI / 2 && _a < Math.PI / 2) {
        //console.log('s2',maybeCar.gameObject.getData('passcheckline'))
        if (maybeCar.gameObject.getData("passcheckline")) {
          maybeCar.gameObject.setData({ passcheckline: false });
          maybeCar.gameObject.setData({
            laps: parseInt(maybeCar.gameObject.getData("laps")) + 1,
          });
          console.log("@1", maybeCar.gameObject.getData("player_name"));
          console.log("@2", maybeCar.gameObject.getData("laps"));
          // this.playerNameTags[maybeCar.gameObject.getData("player_id")].setText(
          //   maybeCar.gameObject.getData("player_name") +
          //     "\nL: " +
          //     maybeCar.gameObject.getData("laps")
          // );

          this.players[maybeCar.gameObject.getData("player_id")].state.setState(
            "laps",
            maybeCar.gameObject.getData("laps")
          );
          // start adding icecream after lap 2
          if (
            maybeCar.gameObject.getData("laps") >= 2 &&
            !this.spawningIcecream
          ) {
            this.spawningIcecream = true;
            this.startAddingIcecream();
          }

          //check end of the game
          if (parseInt(maybeCar.gameObject.getData("laps")) >= 5) {
            this.GameOver = true;
            this.BigText.setText(
              maybeCar.gameObject.getData("player_name") + " wins!"
            );
            this.multiplayer.setState(
              "winner",
              bodyB.gameObject.getData("player_id")
            );
            this.multiplayer.addToWinLog(
              "icetruck",
              bodyB.gameObject.getData("player_id")
            );
          }
        }
      }
    }
  }
}

// https://gamedev.stackexchange.com/questions/72348/how-do-i-lerp-between-values-that-loop-such-as-hue-or-rotation
function getLerpDirectionBetweenAngles(startAngle, endAngle) {
  var MAX_ANGLE = 360.0;
  function normalizeAngle(angle) {
    while (angle < 0) angle += MAX_ANGLE;
    while (angle >= MAX_ANGLE) angle -= MAX_ANGLE;
    return angle;
  }

  var distanceForward = 0.0; // Clockwise
  var distanceBackward = 0.0; // Counter-Clockwise

  // Calculate both distances, forward and backward:
  distanceForward = endAngle - startAngle;
  distanceBackward = startAngle - endAngle;

  if (normalizeAngle(distanceForward) < normalizeAngle(distanceBackward)) {
    // Adjust for 360/0 degree wrap
    if (endAngle < startAngle) endAngle += MAX_ANGLE; // Will be above 360
    return 1;
  }
  // Backward? (normalized to 285)
  else {
    // Adjust for 360/0 degree wrap
    if (endAngle > startAngle) endAngle -= MAX_ANGLE; // Will be below 0
    return -1;
  }
}

export default MyGame;
