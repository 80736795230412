import SceneRenderer from "common/components/SceneRenderer";
import { useEffect, useRef, useState } from "react";
import PhaserScene from "./phaserscene";
import dict from "./dict.json";
import "./style.css";
import RoundsWrapper from "common/components/RoundsWrapper";
import ControllerHUD from "common/components/ControllerHUD";
import {
  useStateIsSetForAllPlayers,
  useGlobalRoundState,
  useMyPlayerState,
} from "common/hooks/multiplayer";
import BackgroundMusic from "../../../common/modules/backgroundMusic";
import Multiplayer from "common/modules/multiplayer";
import Animation from "common/components/Element";
import { FullscreenCountdownAsync } from "common/components/Countdown";
import * as AsyncStateHelpers from "common/modules/multiplayer/asyncHelpers";
import PlayerColoredImage from "common/components/PlayerColoredImage";
import NativeMethods from "common/modules/NativeMethods";
import { waitUntilGlobalStateIsSet } from "../../../common/modules/multiplayer/asyncHelpers";
import { CompleteStartScreen } from "common/components/StartScreen";
// import StarBG from "./starbg";
import imgClock from "./img/mode-time.png";
import imgSingleCut from "./img/mode-knife.png";
import imgDoubleCut from "./img/mode-knifes.png";
import imgRopeCut from "./img/mode-rope.png";
import imgInstructions from "./img/modeinstructions.png";
import imgPlayerAvatar from "./img/avatar.png";
import sfxBoardTurn from "./sfx/board-turn.mp3";
import UIfx from "common/modules/uifx";
import sfxBgMusic from "./sfx/bg2.mp3";

export const gameConfig = {
  id: "messykeys",
  title: "Messy Keys",
  screenshot: require("./img/messykeys.png"),
  accentColor: "#6BDCFF",
  metadata: {
    description: "",
    type: "Fastest fingers",
  },
  background: {
    music: sfxBgMusic,
  },
  // background: "#6BDCFF",
  pages: [
    {
      component: StartScreen,
      slug: "messykeys",
    },
    {
      component: MainScene,
      slug: "messykeys/game",
    },
  ],
};

const sceneConfig = {
  background: {
    color: "#A14600",
  },
  controller: {
    type: "gyro",
    component: MessyKeysController,
    background: {
      color: "#A14600",
    },
  },
  phaser: {
    onTop: true,
    scene: PhaserScene,
    physics: {
      default: "matter",
      matter: {
        gravity: {
          y: 2,
        },
      },
    },
  },
  timer: {
    color: "#ffffff",
    style: {
      left: "initial",
      right: "1rem",
    },
  },
};

const startSceneConfig = {
  ...sceneConfig,
  controller: {
    ...sceneConfig.controller,
    component: CompleteStartScreen,
  },
};

function StartScreen() {
  const bgm = BackgroundMusic("game");
  const soundBoardTurn = UIfx(sfxBoardTurn);
  const multiplayer = Multiplayer();

  return (
    <SceneRenderer gameConfig={gameConfig} sceneConfig={startSceneConfig}>
      <>
        <Animation
          id="board-container"
          ref={(wordboardRef) => {
            if (!wordboardRef) return;
            setTimeout(async () => {
              bgm.play(sfxBgMusic);
              soundBoardTurn.play();
              await wordboardRef.play("show");
              await waitUntilGlobalStateIsSet("startGame");
              soundBoardTurn.play();
              await wordboardRef.play("hide");
              multiplayer.setState("startGame", undefined);
              multiplayer.navigate("/games/messykeys/game");
            }, 500);
            // window.wordboard = ref;
            // wordboardRef.current = ref;
          }}
          key="wordboard"
          className="board-container startscreen"
          originY="100%"
          x={-80}
          animations={{
            show: {
              rotate: [-180, -15],
              duration: 500,
              // loop: true,
              easing: "spring(1, 80, 10, 0)",
            },
            hide: {
              rotate: 180,
              duration: 500,
              loop: false,
              easing: "easeInOutCubic",
            },
          }}
        >
          <div className="word" key="word">
            Hanged
          </div>
        </Animation>
        <Animation x={750} y={280}>
          <img src={imgInstructions} width={250} />
        </Animation>
      </>
    </SceneRenderer>
  );
}

export default function MainScene() {
  const [show, setShow] = useState(true);
  useEffect(() => {
    window.reload = () => {
      setShow(false);
      setTimeout(() => {
        setShow(true);
      }, 400);
    };
  }, []);
  if (!show) return null;
  return (
    <SceneRenderer gameConfig={gameConfig} sceneConfig={sceneConfig}>
      <RoundsWrapper RoundComponent={Round} totalRounds={3}></RoundsWrapper>
    </SceneRenderer>
  );
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function getRoundMode(round) {
  const progression = [
    "time",
    "singlecut",
    "singlecut",
    "doublecut",
    "ropecut",
    "singlecut",
  ];
  const times = [60, 60, 50, 50, 40, 30, 20, 10, 10];
  return {
    type: progression[round % progression.length],
    time: times[round] || 5,
  };
}

function Round({ currentRound, totalRounds, endRound, isLastRound }) {
  const word = useGlobalRoundState("word");
  const gameMode = useGlobalRoundState("mode");
  // const allPlayersHaveAnswered = useStateIsSetForAllPlayers("round.done");
  const wordboardRef = useRef(null);
  const gameModeContainerRef = useRef(null);
  const countdownRef = useRef(null);
  const multiplayer = Multiplayer();

  const soundBoardTurn = UIfx(sfxBoardTurn);

  useEffect(() => {
    window.endRound = endRound;
    console.log("useEffect called rerender");
    requestAnimationFrame(async () => {
      if (
        wordboardRef.current &&
        countdownRef.current &&
        gameModeContainerRef.current
      ) {
        // choose word and setup keyboard keys
        const word = dict[Math.floor(Math.random() * dict.length)];
        multiplayer.setRoundState("word", word);
        multiplayer.setRoundState("keys", generate9Keys(word));

        // set the round mode
        const curMode = getRoundMode(currentRound);
        multiplayer.setRoundState("mode", curMode);

        // multiplayer.setRoundState("timer", curMode.time);

        // start the round countdown
        await countdownRef.current.start();
        await gameModeContainerRef.current.play("show", { endDelay: 1000 });
        AsyncStateHelpers.startTimer(curMode.time, true).then(async () => {
          if (!AsyncStateHelpers.stateIsSetForAllPlayers("round.done")) {
            // time ended, end the round by setting high time to players that didn't answer
            Object.values(multiplayer.getPlayers()).forEach((player) => {
              if (!player.getRoundState("done")) {
                player.setRoundState("done", 999999);
              }
            });
          }
        });

        // show the wordboard
        multiplayer.setRoundState("live", true);
        soundBoardTurn.play();
        await wordboardRef.current.play("show");

        // wait until all players have entered their answers
        await AsyncStateHelpers.waitUntilStateIsSetForAllPlayers("round.done");
        const playersSortedByTime = Object.values(multiplayer.getPlayers())
          .sort((a, b) => {
            return a.getRoundState("done") - b.getRoundState("done");
          })
          .map((state) => state.id);
        multiplayer.setRoundState("playersSortedByTime", playersSortedByTime);

        // let things play out on phaser side ie. limb cutting.
        await sleep(7000);
        soundBoardTurn.play();
        await wordboardRef.current.play("hide");

        // check if winner is found
        const playersNotDead = Object.values(multiplayer.getPlayers()).filter(
          (player) => !player.getState("dead")
        );
        if (playersNotDead.length === 1) {
          multiplayer.setState("winner", playersNotDead[0].id);
          multiplayer.addToWinLog("messykeys", playersNotDead[0].id);
        } else {
          endRound();
        }
      }
    });
  }, [wordboardRef, countdownRef]);

  console.log("rerender of messy key?");
  return (
    <>
      {/* <StarBG /> */}
      <FullscreenCountdownAsync ref={countdownRef} />
      <Animation
        id="board-container"
        ref={wordboardRef}
        key="wordboard"
        className="board-container"
        originY="100%"
        animations={{
          show: {
            rotate: [-180, 0],
            duration: 500,
            // loop: true,
            easing: "spring(1, 80, 10, 0)",
          },
          hide: {
            rotate: 180,
            duration: 500,
            loop: false,
            easing: "easeInOutCubic",
          },
        }}
      >
        <div className="word" key="word">
          {word}
        </div>
        <div className="instruction">Type the word shown on screen</div>
      </Animation>

      <Animation
        ref={gameModeContainerRef}
        key="gameModeContainer"
        className="game-mode-container"
        animations={{
          show: {
            translateX: ["0px", "320px"],
            duration: 500,
            // loop: true,
            easing: "spring(1, 80, 10, 0)",
          },
          hide: {
            translateX: ["320px", "0px"],
            duration: 500,
            loop: false,
            easing: "easeInOutCubic",
          },
        }}
      >
        {gameMode?.type === "time" && (
          <>
            <img
              src={imgClock}
              style={{
                position: "absolute",
                top: "18px",
                left: "13px",
                width: "30px",
              }}
            ></img>
            <div>
              <span>Timed</span>
              Type the word before time runs out
            </div>
          </>
        )}
        {gameMode?.type === "singlecut" && (
          <>
            <img
              src={imgSingleCut}
              style={{
                position: "absolute",
                top: "18px",
                left: "13px",
                width: "30px",
              }}
            ></img>
            <div>
              <span>Single cut</span>
              Player finishing last gets knifed once
            </div>
          </>
        )}
        {gameMode?.type === "doublecut" && (
          <>
            <img
              src={imgDoubleCut}
              style={{
                position: "absolute",
                top: "18px",
                left: "13px",
                width: "30px",
              }}
            ></img>
            <div>
              <span>Double cut</span>
              Player finishing last gets knifed twice
            </div>
          </>
        )}
        {gameMode?.type === "ropecut" && (
          <>
            <img
              src={imgRopeCut}
              style={{
                position: "absolute",
                top: "18px",
                left: "13px",
                width: "30px",
              }}
            ></img>
            <div>
              <span>Rope cut</span>
              Danger! Player finishing last is out of the game
            </div>
          </>
        )}
      </Animation>
    </>
  );
}

function MessyKeysController() {
  const word = useGlobalRoundState("word");
  const keys = useGlobalRoundState("keys");
  const live = useGlobalRoundState("live");
  const done = useMyPlayerState("round.done");
  const dead = useMyPlayerState("dead");
  const [timerStart, setTimerStart] = useState(Date.now());
  const [text, setText] = useState("");
  const multiplayer = Multiplayer();
  const myState = multiplayer.getMyPlayerState();

  // note the time when the word was set.
  // this will be used to calculate the time spent by the user
  // to solve the problem.
  useEffect(() => {
    if (word) {
      setTimerStart(Date.now());
      setText("");
    }
  }, [word]);

  return (
    <>
      <ControllerHUD
        breadcrumbs={["Messy Keys"]}
        backgroundStyle={{
          background: `linear-gradient(180deg, #FFFBA3 0%, rgba(255, 255, 255, 0) 100%), #FF7F56`,
        }}
        avatar={
          <PlayerColoredImage
            src={imgPlayerAvatar}
            style={{ maxHeight: "10rem" }}
          ></PlayerColoredImage>
        }
        // avatarStyle={{
        //   background: `#ffffff`,
        // }}
        // avatarUrl={imgPlane}
      ></ControllerHUD>
      {live && (
        <KeyboardController
          text={text}
          disabled={done || dead}
          subtitle={
            dead ? (
              "GAME OVER"
            ) : done ? (
              done >= 999999 ? (
                "TIME UP"
              ) : (
                (done / 1000).toFixed(2)
              )
            ) : (
              <>&nbsp;</>
            )
          }
          onChange={setText}
          keys={keys}
          onEnter={() => {
            if (text.toUpperCase() === word.toUpperCase()) {
              myState.setRoundState("done", Date.now() - timerStart);
              NativeMethods.haptic("rigid");
            } else {
              setText("");
              NativeMethods.haptic("heavy");
            }
          }}
        />
      )}
    </>
  );
}

function KeyboardController({
  text,
  keys,
  onChange,
  onEnter,
  subtitle,
  disabled,
}) {
  return (
    <div className="keyboard-controller">
      <div className="input-row">{text}</div>
      <div className="timer-row">{subtitle}</div>
      <div className="keyboard-row">
        <div className="keys">
          {keys &&
            keys.map((key, i) => (
              <div
                className="key"
                key={key + i}
                onClick={
                  !disabled
                    ? () => {
                        NativeMethods.haptic("light");
                        onChange((text || "") + key);
                      }
                    : undefined
                }
              >
                {key}
              </div>
            ))}
        </div>
        <div onClick={!disabled ? onEnter : undefined} className="enter"></div>
      </div>
    </div>
  );
}

function generate9Keys(word) {
  const keys = word.split("");
  if (keys.length > 9) throw new Error("word too long");
  const remaining = 9 - keys.length;

  for (let i = 0; i < remaining; i++) {
    keys.push(String.fromCharCode(65 + Math.floor(Math.random() * 26)));
  }
  return randomize(keys).map((e) => e.toUpperCase());
}

function randomize(arr) {
  return arr.sort(() => Math.random() - 0.5);
}
