import React, { useEffect, useState } from "react";
import "./style.css";
import Multiplayer from "common/modules/multiplayer";
import {
  usePlayersList,
  useGlobalState,
  usePlayerState,
} from "common/hooks/multiplayer";

import GamesMenu from "common/components/GamesMenu";
import BackgroundGame from "common/components/BackgroundGame";
import Control from "./components/control";
import Viewer from "./components/viewer";

import { FullscreenCountdown } from "common/components/Countdown";

function useForceUpdate() {
  // eslint-disable-next-line
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

function resetGame() {
  const multiplayer = Multiplayer();
  multiplayer.setState("live", undefined);
  multiplayer.setState("winner", undefined);
  multiplayer.setState("done", undefined);
  const players = multiplayer.getPlayers();
  Object.keys(players).forEach((playerId) => {
    const playerState = players[playerId];
    playerState.setState("score", 0);
  });
}

function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

function randRange(minNum, maxNum) {
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}

function bot() {
  const multiplayer = Multiplayer();
  const myState = multiplayer.getMyPlayerState();
  const interval = setInterval(() => {
    if (multiplayer.getState("winner")) {
      clearInterval(interval);
      return;
    }
    if (multiplayer.getState("live")) {
      const curScore = myState.getState("score");
      myState.setState("score", curScore + 1, true);
    }
  }, randRange(3000, 5000));
}

export default function GamePage({ gameInfo }) {
  const multiplayer = Multiplayer();
  const forceUpdate = useForceUpdate();
  const myState = multiplayer.getMyPlayerState();
  const players = usePlayersList();
  const [countdown, setCountdown] = useState(3);
  const winner = useGlobalState("winner");
  const gameIsLive = useGlobalState("live");

  usePlayerState((playerId, changedKey, playerState, playerObj) => {
    forceUpdate();
    if (multiplayer.isSpectator) {
      if (multiplayer.getState("done")) {
        if (!multiplayer.getState("winner")) {
          const wonPlayer = { score: 0, name: "No one", id: -1 };
          players.forEach((playerState) => {
            const score = playerState.getState("score");
            if (score > wonPlayer.score) {
              wonPlayer.score = score;
              wonPlayer.name = playerState.getState("profile").name;
              wonPlayer.id = playerState.id;
              wonPlayer.color = playerState.getState("profile").color;
            }
          });
          multiplayer.setState("winner", wonPlayer);
          multiplayer.addToWinLog("richyrich", wonPlayer.id);
        }
        forceUpdate();
      }
    }
  });

  const stopCountdown = () => {
    multiplayer.setState("live", true);
    setCountdown(false);
  }

  // reset game state if host requests it (and the first time)
  useEffect(() => {
    if (multiplayer.isSpectator) {
      resetGame();
      stopCountdown();
    } else {
      if (window.ISBOT) bot();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <GamesMenu
        key="gamesmenu"
        gameInfo={gameInfo}
        hideRoomCode
        showRestart={winner && multiplayer.isHost}
      />

      {multiplayer.isSpectator ? ( // tv/laptop screen
        countdown ? (
          <>
            <BackgroundGame
              style={{
                background: "linear-gradient(180deg, #D5FAFF 0%, #FED8FF 100%)",
              }}
            ></BackgroundGame>
            <FullscreenCountdown key={multiplayer.getState("live") || "fullscreen-countdown"} onCountdownChange={setCountdown} onFinish={stopCountdown} />
          </>
        ) : (
          <Viewer
            players={players}
            multiplayer={multiplayer}
            gameIsLive={gameIsLive}
          />
        )
      ) : (
        // player screens with falling money
        <Control
          currentPlayer={myState}
          playerColor={myState.state.profile.color}
          players={players}
          gameIsLive={gameIsLive}
          winner={winner}
          colorCollected={(moneyValue) => {
            var lastScore = myState.getState("score") || 0;
            if (multiplayer.getState("live")) {
              // if (color === myState.state.profile.color) {
              myState.setState("score", lastScore + moneyValue, true);
              return {
                scoreText:
                  (moneyValue > 0 ? "+" : moneyValue !== 0 ? "-" : "") +
                  moneyValue,
                t: Date.now(),
              };
            }
          }}
        />
      )}
    </>
  );
}
