import Phaser from "common/components/GameRenderer/phaser";
// import { AvatarPhaserHelper } from "common/modules/avatarCreator";
import checkInsidePolygon from "./insidePolygon";
import CommonGameScene from "common/components/GameRenderer/gamescene";
// import { AvatarPhaserHelper } from "common/modules/avatarCreator";
// import Chroma from "chroma-js";
// import avatarImg from "../img/avatar-tint.png";
import avatarGradientImg from "../img/avatar-gradient.png";
import avatarEyesImg from "../img/avatar-eyes.png";
import avatarShadowImg from "../img/shadow.png";
import bgImg from "../img/bg.svg";
import roomImg from "../img/room.png";
import roomShape from "../img/room-shape.json";
// import floorImg from "../img/floor.png";
import sfxFall from "../sfx/fallsound.mp3";
import sfxPush from "../sfx/push.mp3";
import sfxJoin from "../sfx/playerjoin-high.mp3";

// import rain pngs
import drop1 from "../../../../common/components/BackgroundRain/drops/1.png";
import drop2 from "../../../../common/components/BackgroundRain/drops/2.png";
import drop3 from "../../../../common/components/BackgroundRain/drops/3.png";
import drop4 from "../../../../common/components/BackgroundRain/drops/4.png";

import avatarSheetSvg from "../img/avatar-spritesheet.svg";
import avatarCreatorSVG from "common/modules/avatarCreatorSVG";

// function randRange(minNum, maxNum) {
//   return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
// }
function toRadians(degrees) {
  var pi = Math.PI;
  return degrees * (pi / 180);
}
export class GameScene extends CommonGameScene {
  constructor() {
    super();
    this.playerDepthOrder = [];
    this.playerNameTags = {};
    this.playerHadukens = {};
    this.playerHands = {};
    this.playerGradients = {};
    this.playerFacingX = {};
    this.playerFacingY = {};
    this.playerEyes = {};
    this.playerShadows = {};
    this.playerNextBlink = {};
    this.playerLastColor = {};
    this.floorCollisionPolygon = [];
    this.playerHadukenTweening = {};
    this.gameId = Math.random() * 10000;
  }

  preload() {
    // this.load.spritesheet("player", avatarImg, {
    //   frameWidth: 280,
    //   frameHeight: 280,
    // });
    this.load.image("bg", bgImg);
    this.load.image("player-gradient", avatarGradientImg);
    this.load.image("haduken", "/games/lobby/haduken.png");
    // this.load.image("floor", floorImg);
    this.load.image("room", roomImg);
    // this.load.json("roomshape", roomShape);

    // load rain drops images into phaser
    this.load.image("drop1", drop1);
    this.load.image("drop2", drop2);
    this.load.image("drop3", drop3);
    this.load.image("drop4", drop4);

    this.load.spritesheet("eyes", avatarEyesImg, {
      frameWidth: 220,
      frameHeight: 128,
    });

    this.load.spritesheet("shadow", avatarShadowImg, {
      frameWidth: 220,
      frameHeight: 128,
    });

    this.load.audio("fall", sfxFall);
    this.load.audio("join", sfxJoin);
    this.load.audio("push", sfxPush);
  }

  create() {
    this.gameSize = this.sys.game.scale.gameSize;
    // this.add.image(400, 300, 'sky');

    // Add bg gradient
    // var graphics = this.add.graphics();
    // const topColor = Phaser.Display.Color.HexStringToColor("f8a0f2").color;
    // const bottomColor = Phaser.Display.Color.HexStringToColor("6a3eec").color;
    // graphics.fillGradientStyle(topColor, topColor, bottomColor, bottomColor, 1);
    // graphics.fillRect(0, 0, this.gameSize.width, this.gameSize.height);
    // this.setAsBackground(graphics);
    const bg = this.add.image(0, 0, "bg");
    // bg.setDisplaySize(this.gameSize.width, this.gameSize.height);
    this.bg = bg;
    this.setAsBackground(bg, true);

    this.addRain();
    this.addFloor();

    // this.anims.create({
    //   key: "walk",
    //   frames: this.anims.generateFrameNumbers("player", {
    //     start: 0,
    //     end: 1,
    //     first: 0,
    //   }),
    //   frameRate: 15,
    //   repeat: -1,
    //   yoyo: false,
    // });

    this.anims.create({
      key: "blink",
      frames: this.anims.generateFrameNumbers("eyes", {
        start: 0,
        end: 5,
        first: 0,
      }),
      frameRate: 8,
      repeat: 0,
      yoyo: false,
    });

    // Add fade gradient
    // var graphics2 = this.add.graphics();
    // // const topColor = Phaser.Display.Color.HexStringToColor("f8a0f2").color;
    // // const bottomColor = Phaser.Display.Color.HexStringToColor("6a3eec").color;
    // const transparentColor = Phaser.Display.Color.transparent();

    // graphics2.fillGradientStyle( bottomColor, transparentColor, transparentColor, topColor, 1);
    // graphics2.fillRect(0, 0, this.gameSize.width, this.gameSize.height);

    super.create();
  }

  addRain() {
    var dropImages = ["drop1", "drop2", "drop3", "drop4"];

    this.rainEmitters = [];

    dropImages.forEach((dropImage) => {
      let rainParticle = this.add.particles(dropImage);
      var emitter = rainParticle.createEmitter({
        x: { min: 0, max: this.gameSize.width },
        y: -1 * (200 + Math.random() * 200),
        lifespan: { min: 20000, max: 60000 },
        speedY: 1100,
        // gravityX: Math.ceil((Math.random() - 0.5) * 2) < 1 ? -10 : 10,
        gravityY: 1300,
        minVelocityY: 700,
        maxVelocityY: 1300,
        minVelocityX: 0,
        maxVelocityX: 0,
        quantity: 1,
        scale: { min: this.adjustByScale(0.3), max: this.adjustByScale(0.7) },
        frequency: 300,
        // blendMode: "ADD",
      });

      /*
      Notes:
      Random generation Algo: ```Math.floor(Math.random() * (max - min)) + min;```

      References:
      - https://www.educative.io/answers/how-to-generate-a-random-number-between-a-range-in-javascript
      - https://photonstorm.github.io/phaser3-docs/Phaser.GameObjects.Particles.ParticleEmitter.html#setSpeedY
      */

      // setInterval(function () {emitter.setSpeedY(Math.floor(Math.random() * (1500 - 200)) + 200);}, 1000);

      this.rainEmitters.push(emitter);
    });
  }

  // addFloorImg() {
  //   const floor = this.add.image(0, 0, "floor");
  //   floor.setOrigin(0, 0);
  //   floor.setScale(0.75);
  //   const floorX = (this.gameSize.width - floor.displayWidth) / 2,
  //     floorY = (this.gameSize.height - floor.displayHeight) / 2 + this.adjustByScale(100);
  //     floor.x = floorX;
  //     floor.y = floorY;
  //     this.floor = floor;
  // }

  addFloor() {
    var scale = this.adjustByScale(0.55);

    let room = this.matter.add.sprite(0, 0, "room", null, {
      isStatic: true,
      shape: roomShape,
    });
    this.room = room;
    room.setScale(scale);
    // room.setOrigin(0.5, 1);

    room.setPosition(
      this.cameras.main.width * 0.5 + this.adjustByScale(35),
      this.gameSize.height - room.displayHeight * 0.26
    );

    var floorPoly = [
      [123, 123],
      [178, 0],
      [896, 0],
      [765, 309],
      [415, 309],
      [376, 391],
      [1, 391],
    ].map((point) => [point[0] * scale, point[1] * scale]);

    var floorBackPoly = [
      [123, 123],
      [178, 0],
      [896, 0],
      [896, 200],
      [798, 457],
      [420, 457],
      [359, 500],
      [1, 500],
      [0, 391],
    ].map((point) => [point[0] * scale, point[1] * scale]);
    var polygon = new Phaser.Geom.Polygon(floorPoly);
    var polygonBack = new Phaser.Geom.Polygon(floorBackPoly);
    var floorBounds = Phaser.Geom.Polygon.GetAABB(polygon);
    var floorBackBounds = Phaser.Geom.Polygon.GetAABB(polygonBack);
    const floorX = (this.gameSize.width - floorBounds.width) / 2,
      floorY = (this.gameSize.height - floorBounds.height) / 2,
      floorBackDiffX = 2,
      floorBackDiffY = 0;

    // render floor's back area first

    // var graphicsFloorBackMask = this.add.graphics();
    // graphicsFloorBackMask.fillStyle(0x00ffff, 0);
    // graphicsFloorBackMask.fillPoints(polygonBack.points, true);
    // graphicsFloorBackMask.setPosition(
    //   floorX + floorBackDiffX,
    //   floorY + floorBackDiffY
    // );
    // var floorBackMask = graphicsFloorBackMask.createGeometryMask();

    // var graphicsFloorBackGradient = this.add.graphics();
    // graphicsFloorBackGradient.fillStyle(0x00ffff, 0);
    // graphicsFloorBackGradient.fillGradientStyle(
    //   Phaser.Display.Color.HexStringToColor("DF82FF").color,
    //   Phaser.Display.Color.HexStringToColor("DF82FF").color,
    //   Phaser.Display.Color.HexStringToColor("DF82FF").color,
    //   Phaser.Display.Color.HexStringToColor("DF82FF").color,
    //   1,
    //   1,
    //   0,
    //   0
    // );
    // graphicsFloorBackGradient.fillRect(
    //   floorX + floorBackDiffX,
    //   floorY + floorBackDiffY,
    //   floorBackBounds.width,
    //   floorBackBounds.height
    // );
    // graphicsFloorBackGradient.setMask(floorBackMask);

    // render actual floor walkable area (front/top of it)
    this.floorCollisionPolygon = floorPoly.map((xy) => [
      xy[0] + floorX,
      xy[1] + floorY,
    ]);

    // var graphicsFloorMask = this.add.graphics();
    // graphicsFloorMask.fillStyle(0x00ffff);
    // graphicsFloorMask.fillPoints(polygon.points, true);
    // graphicsFloorMask.setPosition(floorX, floorY);
    // var floorMask = graphicsFloorMask.createGeometryMask();

    // var graphicsFloorGradient = this.add.graphics();
    // graphicsFloorGradient.fillGradientStyle(
    //   0xffda93,
    //   0xffda93,
    //   0xff9680,
    //   0xff9680,
    //   1
    // );
    // graphicsFloorGradient.fillRect(
    //   floorX,
    //   floorY,
    //   floorBounds.width,
    //   floorBounds.height
    // );
    // graphicsFloorGradient.setMask(floorMask);
    // graphicsFloorGradient.setDepth(10);
  }

  loadPlayerSpriteWithColor(svgUrl, key, color) {
    return new Promise(async (resolve, reject) => {
      const spriteSheetDataUri = await avatarCreatorSVG(svgUrl, color);
      const fullKey = `${key}-${Math.random()}`;
      console.log("loadPlayerSpriteWithColor", fullKey, color);
      var img = new Image();
      img.onload = () => {
        this.textures.addSpriteSheet(fullKey, img, {
          frameWidth: 400,
          frameHeight: 400,
        });
        resolve(fullKey);
      };
      img.src = spriteSheetDataUri;
    });
  }

  async generatePlayerSprite(playerState, profile) {
    const spriteSheetKey = await this.loadPlayerSpriteWithColor(
      avatarSheetSvg,
      "player",
      profile.color
    );

    this.anims.create({
      key: profile.color + "-walk",
      frames: this.anims.generateFrameNumbers(spriteSheetKey, {
        start: 0,
        end: 15,
        first: 0,
      }),
      frameRate: 30,
      repeat: -1,
      yoyo: false,
    });

    this.anims.create({
      key: profile.color + "-spawn",
      frames: this.anims.generateFrameNumbers(spriteSheetKey, {
        start: 16,
        end: 30,
        first: 16,
      }),
      frameRate: 30,
      repeat: 0,
      yoyo: false,
    });

    const player = this.matter.add.sprite(0, 0, spriteSheetKey);
    // player.setOrigin(0.5, 1);
    // playerGradient.setOrigin(0.5, 1);
    player.setBody({
      type: "rectangle",
      width: 160,
      height: 160,
    });
    player.setScale(this.adjustByScale(0.26));
    player.setFixedRotation();
    // player.setFrictionAir(0.05);
    player.setFriction(this.adjustByScale(0.09), this.adjustByScale(0.2), 0); // value, air, fstatic
    player.setMass((60 * 1) / this.scaleFactor); // tweak for airplay (which runs in 1x res, and laptop runs in 2x res)

    // playerGradient.setScale(this.adjustByScale(0.32));
    // player.setCollideWorldBounds(true);
    player.setBounce(-1);
    return player;
  }

  async addPlayerSprite(playerState, profile) {
    let player = await this.generatePlayerSprite(playerState, profile);
    // let player = await AvatarPhaserHelper(
    //   imgAvatar,
    //   playerState,
    //   profile,
    //   newPos,
    //   (posX, posY, textureId) => this.physics.add.sprite(posX, posY, textureId),
    //   (textureId) => {
    //     return this.textures.createCanvas(
    //       textureId,
    //       1840,
    //       800
    //     );
    //   }
    // );

    if (!this.playerFacingX[playerState.id])
      this.playerFacingX[playerState.id] = -1;
    if (!this.playerFacingY[playerState.id])
      this.playerFacingY[playerState.id] = -1;

    const newPos = this.getRandomSpawnPoint();
    player.setPosition(newPos[0], newPos[1]);

    // let player = this.physics.add.sprite(newPos[0], newPos[1], "player");
    this.playerLastColor[playerState.id] = profile.color;
    // player.setTint(
    //   Phaser.Display.Color.HexStringToColor(
    //     this.playerLastColor[playerState.id]
    //   ).color
    // );

    // TODO:
    // let player = await AvatarPhaserHelper(
    //   avatarImg,
    //   playerState,
    //   profile,
    //   newPos,
    //   (posX, posY, textureId) => {
    //     var playerSprite = this.physics.add.sprite(posX, posY, textureId);
    //     // playerSprite.frame = 0;
    //     return playerSprite;
    //   },
    //   (textureId) => {
    //     console.log("render player");
    //     return this.textures.createCanvas(
    //       textureId,
    //       // 1636 * this.adjustByScale(PLANE_SCALE),
    //       // 924 * this.adjustByScale(PLANE_SCALE)
    //     );
    //   }
    //   // this.physics.add.image.bind(this),
    //   // this.textures.createCanvas.bind(this)
    // );
    // player.play("walk");
    // player.stop();

    // let playerGradient = this.add.image(
    //   newPos[0],
    //   newPos[1],
    //   "player-gradient"
    // );
    // this.playerGradients[playerState.id] = playerGradient;

    // Player eyes
    // let playerEyes = this.add.sprite(newPos[0], newPos[1] + 80, "eyes");
    // this.playerEyes[playerState.id] = playerEyes;
    // playerEyes.setScale(this.adjustByScale(0.2));

    // Player shadow
    // let playerShadow = await AvatarPhaserHelper(
    //   avatarShadowImg,
    //   playerState,
    //   profile,
    //   newPos,
    //   (posX, posY, textureId) => this.physics.add.image(posX, posY, textureId),
    //   (textureId) => {
    //     console.log("render player shadow");
    //     return this.textures.createCanvas(
    //       textureId,
    //       216 * this.adjustByScale(0.4),
    //       116 * this.adjustByScale(0.4)
    //     );
    //   }
    //   // this.physics.add.image.bind(this),
    //   // this.textures.createCanvas.bind(this)
    // );

    // playerShadow.alpha = 0.7;
    // playerShadow.setScale(this.adjustByScale(0.3));
    // let playerShadow = this.add.image(newPos[0], newPos[1] - 20, "shadow");
    // this.playerShadows[playerState.id] = playerShadow;

    // this.playerNextBlink[playerState.id] = Date.now() + randRange(3000, 5000);

    // player.setScale(0.6);
    // use avatar creator to create customized avatar for this player
    // let player = await AvatarPhaserHelper(
    //   avatarImg,
    //   playerState,
    //   profile,
    //   newPos,
    //   (posX, posY, textureId) => {
    //     const tex = this.textures.get(textureId);
    //     // tex.add(0, 0, 0, 0, 466, 466);
    //     // tex.add(1, 0, 466, 0, 466, 466);
    //     // tex.refresh();
    //     console.log("tex", this.anims.generateFrameNumbers(textureId, { start: 0, end: 1, first: 0 }));
    //     this.anims.create({
    //       key: 'walk',
    //       frames: this.anims.generateFrameNumbers(textureId, { start: 0, end: 1, first: 0 }),
    //       frameRate: 10,
    //       repeat: -1,
    //       yoyo: false
    //   });
    //     return this.physics.add.sprite(posX, posY, textureId)//.play('walk');
    //   },
    //   (textureId) => {
    //     const canvas = this.textures.createCanvas(textureId, 932, 466);
    //     const orgFrames = this.textures.get("player").getFramesFromTextureSource(0, false);
    //     for (const frame of orgFrames) {
    //       canvas.add(
    //         frame.name,
    //         0,
    //         frame.cutX,
    //         frame.cutY,
    //         frame.width,
    //         frame.height
    //       );
    //     }
    //     // https://rexrainbow.github.io/phaser3-rex-notes/docs/site/canvas-texture/#add-frame
    //     // .add(name, sourceIndex, x, y, width, height);
    //     // canvas.add(0, 0, 0, 0, 466, 466);
    //     // canvas.add(1, 0, 466, 0, 466, 466);
    //     return canvas;
    //   },
    //   null,
    //   // { width: 82, x: 76, y: 50, imgWidth: 82 },
    //   // (profile) => [
    //   //   4,
    //   //   171,
    //   //   2,
    //   //   120,
    //   //   170,
    //   //   120,
    //   //   Chroma(profile.color).darken().hex(),
    //   //   profile.color,
    //   // ]
    // );

    // add player nametag
    var style = {
      font: this.adjustByScale(10) + "px Russo One",
      fill: "#ffffff",
      wordWrap: true,
      align: "center",
      stroke: "#000000",
      strokeThickness: 5,
      // backgroundColor: "#ffff0033",
      padding: {
        left: 20,
        right: 20,
        top: 10,
        bottom: 4,
      },
    };

    var text = this.add.text(
      100,
      100,
      profile ? profile.name : "Player",
      style
    );
    text.setOrigin(0.5, 0);
    this.playerNameTags[playerState.id] = text;

    this.playerHadukens[playerState.id] = this.add.image(100, 100, "haduken");
    this.playerHadukens[playerState.id].setDepth(10);
    this.playerHadukens[playerState.id].setScale(this.adjustByScale(0.2));

    // this.playerHadukens[playerState.id].setOrigin(0.5, 0.5);

    var hand = this.add.circle(10, 10, 10, 0xffffff);
    hand.setScale(this.adjustByScale(0.25));
    // hand.setOrigin(,0.1)
    this.playerHands[playerState.id] = hand;

    this.sound.play("join");
    player.play(profile.color + "-spawn");
    return player;
  }

  handlePlayerQuit(playerState) {
    if (this.playerNameTags[playerState.id]) {
      this.playerNameTags[playerState.id].destroy();
      delete this.playerNameTags[playerState.id];
    }

    if (this.playerHadukens[playerState.id]) {
      this.playerHadukens[playerState.id].destroy();
      delete this.playerHadukens[playerState.id];
    }

    if (this.playerHands[playerState.id]) {
      this.playerHands[playerState.id].destroy();
      delete this.playerHands[playerState.id];
    }

    if (this.playerGradients[playerState.id]) {
      this.playerGradients[playerState.id].destroy();
      delete this.playerGradients[playerState.id];
    }

    if (this.playerEyes[playerState.id]) {
      this.playerEyes[playerState.id].destroy();
      delete this.playerEyes[playerState.id];
    }

    if (this.playerShadows[playerState.id]) {
      this.playerShadows[playerState.id].destroy();
      delete this.playerShadows[playerState.id];
    }
  }

  update() {
    super.update();
    // sort depth by y (lower y = lower depth) for a fake isometric view
    this.playerDepthOrder = Object.keys(this.players);
    this.playerDepthOrder.sort((playerId1, playerId2) => {
      if (!this.players[playerId1].sprite || !this.players[playerId2].sprite)
        return 0;
      return (
        this.players[playerId1].sprite.y - this.players[playerId2].sprite.y
      );
    });
  }

  updateCommon(playerId, player, state) {
    const profile = state.getState("profile");
    // const ping = state.getState("p") || 0;
    const text = this.playerNameTags[playerId];
    text.setText(profile ? profile.name : "" /*+ ` [${ping}ms]`*/);
    text.x = Math.floor(player.x);
    text.y = Math.floor(
      player.y - player.displayHeight * 0.5 - text.displayHeight
    );
    text.setDepth(player.depth);

    if (this.playerLastColor[playerId] !== profile.color) {
      this.playerLastColor[playerId] = profile.color;
      this.generatePlayerSprite(state, profile).then((sprite) => {
        this.players[state.id].sprite = sprite;
        this.players[state.id].sprite.setDepth(player.depth);
        this.players[state.id].sprite.x = player.x;
        this.players[state.id].sprite.y = player.y;
        player.destroy();
      });
      // player.setTint(
      //   Phaser.Display.Color.HexStringToColor(this.playerLastColor[playerId])
      //     .color
      // );
      // this.playerShadows[playerId].setTint(
      //   Phaser.Display.Color.HexStringToColor(this.playerLastColor[playerId])
      //     .color
      // );
    }

    // this.playerGradients[playerId].x = player.x;
    // this.playerGradients[playerId].y = player.y;
    // this.playerGradients[playerId].setDepth(player.depth);

    // this.playerEyes[playerId].x = player.x;
    // this.playerEyes[playerId].y = player.y - player.displayHeight * 0.8;
    // this.playerEyes[playerId].setDepth(player.depth);

    // this.playerShadows[playerId].x = player.x;
    // this.playerShadows[playerId].y = player.y - player.displayHeight * 0.2;
    // this.playerShadows[playerId].setDepth(player.depth);

    // if (this.playerNextBlink[playerId] < Date.now()) {
    //   this.playerEyes[playerId].play("blink");
    //   this.playerNextBlink[playerId] = Date.now() + randRange(3000, 5000);
    // }

    const haduken = this.playerHadukens[playerId];
    const hx = player.x,
      hy = player.y - haduken.displayHeight / 2;

    if (state.getState("hitting") && !this.playerHadukenTweening[playerId]) {
      // set rotation of the haduken
      const facingX = this.playerFacingX[playerId];
      const facingY = this.playerFacingY[playerId];
      var degree = this.getDegreeFromFacing(facingX, facingY);
      haduken.setAngle(degree);

      this.playerHands[playerId].setFillStyle(
        Phaser.Display.Color.ValueToColor(profile.color).color,
        1
      );
      this.tweens.add({
        targets: this.playerHands[playerId],
        x: player.x + facingX * this.adjustByScale(20),
        y: player.y - this.adjustByScale(20) + facingY * this.adjustByScale(15),
        duration: 100,
      });

      this.sound.play("push");

      this.playerHadukenTweening[playerId] = Date.now();
      haduken.setAlpha(0);
      const dist =
        facingX !== 0 && facingY !== 0
          ? this.adjustByScale(75)
          : this.adjustByScale(100);
      this.tweens.add({
        targets: haduken,
        x: hx + facingX * dist,
        y: hy + facingY * dist,
        alpha: 1,
        duration: 200,
        ease: "Sine.easeIn",
        onComplete: () => {
          // delete this.playerHadukenTweening[playerId];
          haduken.setAlpha(0);
        },
      });
    } else if (
      !this.playerHadukenTweening[playerId] ||
      Date.now() - this.playerHadukenTweening[playerId] > 500
    ) {
      haduken.x = hx;
      haduken.y = hy;
      if (!state.getState("hitting"))
        delete this.playerHadukenTweening[playerId];
      haduken.setAlpha(0);

      this.playerHands[playerId].x = player.x;
      this.playerHands[playerId].y = player.y - this.adjustByScale(0);
      this.playerHands[playerId].setDepth(player.depth);
      this.playerHands[playerId].setFillStyle(
        Phaser.Display.Color.ValueToColor(profile?.color || "#000000").color,
        0
      );
    }
  }

  getDegreeFromFacing(facingX, facingY) {
    if (facingX === -1 && facingY === 0) {
      return 0;
    } else if (facingX === -1 && facingY === -1) {
      return 45;
    } else if (facingX === 0 && facingY === -1) {
      return 90;
    } else if (facingX === 1 && facingY === -1) {
      return 135;
    } else if (facingX === 1 && facingY === 0) {
      return 180;
    } else if (facingX === 1 && facingY === 1) {
      return 225;
    } else if (facingX === 0 && facingY === 1) {
      return 270;
    } else if (facingX === -1 && facingY === 1) {
      return 315;
    }
  }

  // host does all the computation and inputs, other players only render player at x,y
  updatePlayerHost(playerId, player, state) {
    const lastVelocity = player.body.velocity;
    if (!state.getState("killed"))
      player.setDepth(11 + this.playerDepthOrder.indexOf(playerId));
    // player.setTint(playerTintColors[Object.keys(this.players).indexOf(playerId) % 4]);

    if (
      !checkInsidePolygon(
        [player.x, player.y - 30],
        this.floorCollisionPolygon
      ) &&
      false
    ) {
      // y is shifted a bit up to aim for legs center
      // player is outside the ground, kill player
      if (!state.getState("killed") && !state.getState("isHit")) {
        state.setState("killed", true);
        const slideX =
          lastVelocity.x > 0
            ? this.adjustByScale(120)
            : lastVelocity.x < 0
            ? this.adjustByScale(-120)
            : 0;
        const slideY =
          lastVelocity.y > 0
            ? this.adjustByScale(150)
            : lastVelocity.y < 0
            ? this.adjustByScale(-10)
            : 0;
        // 'slip' them in the direction they are moving.
        this.tweens.add({
          targets: player,
          x: player.x + slideX,
          y: player.y + slideY,
          duration: 200,
          // ease: 'Sine.easeIn',
          onComplete: () => {
            // now make them fall down
            player.setDepth(0);
            this.sound.play("fall");
            // this.playerShadows[playerId].setVisible(false);
            this.tweens.add({
              targets: player,
              y: 1100,
              duration: 300,
              alpha: 0,
              ease: "Sine.easeIn",
              onComplete: () => {
                // reset / make them alive again
                const newPos = this.getRandomSpawnPoint();
                console.log("newPos", newPos, state.profile);
                player.setPosition(newPos[0], newPos[1]);
                player.setAlpha(0);
                // this.playerShadows[playerId].setVisible(true);
                this.playerNameTags[playerId].setVisible(false);
                setTimeout(() => {
                  player.setAlpha(1);
                  state.setState("killed", undefined);
                  this.playerNameTags[playerId].setVisible(true);
                  player.play(playerId + "-spawn");
                }, 1500);
              },
            });
          },
        });
      }
      // return;
    }

    // player.setVelocity(0);

    // console.log("facing", this.playerFacingX[playerId], this.playerFacingY[playerId]);

    // check if this player is already hit and is slipping
    if (state.getState("isHit") && Date.now() - state.getState("isHit") > 500) {
      state.setState("isHit", false);
      // this.players[playerId].sprite.body.acceleration.x = 0;
      // this.players[playerId].sprite.body.acceleration.y = 0;
    }

    // if this player is still slipping or just killed, or is pushing someone, their movement controls are disabled.
    if (
      !state.getState("isHit") &&
      !state.getState("killed") &&
      (!this.playerHadukenTweening[playerId] ||
        Date.now() - this.playerHadukenTweening[playerId] > 500)
    ) {
      const isPressingArrows =
        state.isKeyDown("left") ||
        state.isKeyDown("right") ||
        state.isKeyDown("up") ||
        state.isKeyDown("down");
      // reset facing value if any arrow key is pressed
      // don't reset if player is idle
      const walkAnimKey = state.getState("profile").color + "-walk";
      if (isPressingArrows) {
        this.playerFacingX[playerId] = 0;
        this.playerFacingY[playerId] = 0;

        if (
          !player.anims.isPlaying ||
          player.anims.currentAnim.key !== walkAnimKey
        ) {
          player.play(walkAnimKey);
        }
      } else {
        if (
          player.anims.isPlaying &&
          player.anims.currentAnim.key === walkAnimKey
        ) {
          player.stop();
        }
      }

      var desiredDegree = 0;
      if (state.inputState.dpad && state.inputState.dpad !== "off") {
        desiredDegree = parseInt((-1 * state.inputState.dpad) % 360); //convert clockwise to counterclockwise which phaser uses (no idea how math works, but it does)
      }
      // player.angle = desiredDegree;
      if (isNaN(desiredDegree)) {
        desiredDegree = 0;
      }

      const xComp = Math.cos(toRadians(desiredDegree));
      const yComp = Math.sin(toRadians(desiredDegree));
      if (isPressingArrows) {
        console.log("xComp", xComp, "yComp", yComp, state.inputState.dpad);
        // player.setVelocity(
        //   xComp * this.adjustByScale(3),
        //   yComp * this.adjustByScale(3)
        // );
        player.applyForce({
          x: xComp * this.adjustByScale(0.15),
          y: yComp * this.adjustByScale(0.15),
        });
      }

      if (state.isKeyDown("left")) {
        this.playerFacingX[playerId] = -1;
        // player.setVelocityX(
        //   state.isKeyDown("up") || state.isKeyDown("down")
        //     ? this.adjustByScale(-125)
        //     : this.adjustByScale(-200)
        // );
      } else if (state.isKeyDown("right")) {
        this.playerFacingX[playerId] = 1;
        // player.setVelocityX(
        //   state.isKeyDown("up") || state.isKeyDown("down")
        //     ? this.adjustByScale(125)
        //     : this.adjustByScale(200)
        // );
      }

      if (state.isKeyDown("up")) {
        this.playerFacingY[playerId] = -1;
        // player.setVelocityY(
        //   state.isKeyDown("left") || state.isKeyDown("right")
        //     ? this.adjustByScale(-125)
        //     : this.adjustByScale(-200)
        // );
      } else if (state.isKeyDown("down")) {
        this.playerFacingY[playerId] = 1;
        // player.setVelocityY(
        //   state.isKeyDown("left") || state.isKeyDown("right")
        //     ? this.adjustByScale(125)
        //     : this.adjustByScale(200)
        // );
      }
    }

    // check if I hit someone, only if I am not killed
    if (
      !state.getState("killed") &&
      (state.isKeyDown("b1") || state.isKeyDown("b2")) &&
      !state.getState("hitting")
    ) {
      state.setState("hitting", true);
      Object.keys(this.players).forEach((otherPlayerId) => {
        if (
          otherPlayerId !== playerId &&
          this.checkOverlap(player, this.players[otherPlayerId].sprite)
        ) {
          // TODO: check if facing other player
          console.log(playerId, "HITTING", otherPlayerId);

          this.players[otherPlayerId].state.setState("isHit", Date.now());
          const diffX = this.players[otherPlayerId].sprite.x - player.x,
            diffY = this.players[otherPlayerId].sprite.y - player.y;
          console.log(
            diffX,
            diffY,
            this.playerFacingX[playerId],
            this.playerFacingY[playerId],
            Math.abs(diffX) + Math.abs(diffY)
          );
          setTimeout(() => {
            let accX = 0,
              accY = 0;
            if (diffX > 0 && this.playerFacingX[playerId] === 1) {
              // facing player on right
              accX = this.adjustByScale(3);
            } else if (diffX < 0 && this.playerFacingX[playerId] === -1) {
              // facing player on left
              accX = this.adjustByScale(-3);
            }
            if (diffY > 0 && this.playerFacingY[playerId] === 1) {
              // facing player on bottom
              accY = this.adjustByScale(3);
            } else if (diffY < 0 && this.playerFacingY[playerId] === -1) {
              // facing player on top
              accY = this.adjustByScale(-3);
            }
            this.players[otherPlayerId].sprite.applyForce({
              x: accX,
              y: accY,
            });
          }, Math.abs(diffX) + Math.abs(diffY));
        }
      });
    } else if (!state.isKeyDown("b1")) {
      state.setState("hitting", false);
    }
  }

  checkOverlap(spriteA, spriteB) {
    var dist = Math.sqrt(
      Math.pow(spriteA.x - spriteB.x, 2) + Math.pow(spriteA.y - spriteB.y, 2)
    );

    return dist <= this.adjustByScale(150);
  }

  getRandomSpawnPoint() {
    const roomWidth = this.room.displayWidth,
      roomHeight = this.room.displayHeight,
      x = Math.min(
        Math.max(Math.random() * roomWidth, this.adjustByScale(300)),
        roomWidth - this.adjustByScale(300)
      ),
      y = Math.min(
        Math.max(Math.random() * roomHeight, this.adjustByScale(300)),
        roomHeight - this.adjustByScale(300)
      );

    return [
      x + (this.room.x - roomWidth / 2),
      y + (this.room.y - roomHeight / 2),
    ];
    // var floorBounds = Phaser.Geom.Polygon.GetAABB(
    //   new Phaser.Geom.Polygon(this.floorCollisionPolygon)
    // );
    // const randX = 50 + floorBounds.x + Math.random() * (floorBounds.width - 50);
    // const randY =
    //   50 + floorBounds.y + Math.random() * (floorBounds.height - 50);

    // if (checkInsidePolygon([randX, randY], this.floorCollisionPolygon)) {
    //   return [randX, randY];
    // } else {
    //   return this.getRandomSpawnPoint();
    // }
  }
}
