import Phaser from "phaser";
import CommonGameScene from "common/components/GameRenderer/gamescene";
import { AvatarPhaserHelper } from "common/modules/avatarCreator";
// import imgCloud1 from "../img/cloud1.png";
// import imgCloud2 from "../img/cloud2.png";
// import imgCloud3 from "../img/cloud3.png";
import imgBullet from "../img/bullet1.png";
import imgExplosion from "../img/explosion.png";
import imgPlatform from "../img/platform.png";
import imgCoin from "../img/coin.png";
import imgSmoke from "../img/smoke-puff.png";
import imgTrail from "../img/trail.png";
import imgAvatar from "../img/avatar.png";
import imgArrowUp from "../img/arrow-up.png";
import imgBg from "../img/bg.svg";
import imgCannonIcon from "../img/cannon-icon.png";
import imgCannon from "../img/cannon.png";
import Bullet from "./bullet";
import WrapPortal from "./wrapWorld";
import gunSound from "../sfx/gun.mp3";
import engineSound from "../sfx/engine.mp3";
import thumpSound from "../sfx/thump.mp3";
import crashSound from "../sfx/crash.mp3";
import coinSound from "../sfx/coin.mp3";
import hitSound from "../sfx/metal.mp3";

function randRange(minNum, maxNum) {
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}

const PLANE_SPEED = 300;
// going updard will reduce speed upto this level and going down will increase speed upto this level
const PLANE_SPEED_VARIATION_LEVEL = 100;
const PLANE_ROTATION_STEP = 3;
const FREEFALL_DURATION = 1000;

const PLANE_REAL_TEXTURE_DIMENSION = [1634, 924];
const PLANE_SCALE = 0.03;

export class GameScene extends CommonGameScene {
  constructor() {
    super();
    this.playerNameTags = {};
    this.playerLastInput = {};
    this.playerDownwardStartTime = {};
    this.playerExplosions = {};
    this.playerLocalState = {};
    this.playerArrowUps = {};
    this.playerSmokes = {};
    this.playerTrails = {};
    this.playerSuperweapons = {};
    this.playerUpwardStartTime = {};
    this.playerWeapons = {};
    this.playerIsDead = {};
    this.playerIsFreefall = {};
    this.playerLastBulletFired = {};
    this.playerHasButtonPressed = {};
    this.playerIsOnRunway = {};
    this.playerIsInverted = {};
    this.coinGroup = null;
    this.addingRandomCoinTimeout = null;
    this.disableControls = false;
    this.wrapPortal = undefined;
  }

  preload() {
    this.load.image("ground", imgPlatform);
    this.load.image("bullet", imgBullet);
    this.load.spritesheet("explosion", imgExplosion, {
      frameWidth: 595,
      frameHeight: 512,
      endFrame: 8,
    });
    // this.load.image("arrow-up", imgArrowUp);
    this.load.spritesheet("coin", imgCoin, {
      frameWidth: 128,
      frameHeight: 128,
    });
    this.load.image("dark-smoke", imgSmoke);
    this.load.image("trail", imgTrail);
    this.load.image("cannon", imgCannon);
    this.load.image("cannon-icon", imgCannonIcon);
    // this.load.image("cloud1", imgCloud1);
    // this.load.image("cloud2", imgCloud2);
    // this.load.image("cloud3", imgCloud3);
    this.load.svg("bg", imgBg);
    this.load.audio("crash", crashSound);
    this.load.audio("coin", coinSound);
    this.load.audio("gun", gunSound);
    this.load.audio("engine", engineSound);
    this.load.audio("thump", thumpSound);
    this.load.audio("hit", hitSound);
  }

  checkWinner() {
    if (!this.multiplayer.getState("winner")) {
      if (this.addingRandomCoinTimeout) {
        clearTimeout(this.addingRandomCoinTimeout);
      }
      var winnerScore = 0;
      var winnerId = null;
      Object.keys(this.players).forEach((playerId) => {
        if (this.players[playerId].state.getState("coins") > winnerScore) {
          winnerId = playerId;
          winnerScore = this.players[playerId].state.getState("coins");
        }
      });

      if (winnerId) {
        this.multiplayer.setState("winner", winnerId);
        this.multiplayer.addToWinLog("dogfight", winnerId);
        this.cameras.main.startFollow(
          this.players[winnerId].sprite,
          true,
          0.09,
          0.09,
          0,
          100
        );
        this.cameras.main.zoomTo(2);

        this.winnerText.setText(
          this.players[winnerId].state.getState("profile").name + " wins!"
        );
      } else {
        this.multiplayer.setState("winner", true);
        this.winnerText.setText("No one wins!");
      }
    }
  }

  create() {
    super.create();
    this.gameSize = this.sys.game.scale.gameSize;
    this.wrapPortal = new WrapPortal(this.gameSize, this.adjustByScale(5));
    this.bg = this.add.image(0, 0, "bg");
    this.bg.setDisplaySize(this.gameSize.width, this.gameSize.height);
    this.bg.setOrigin(0, 0);

    // this.debugText = this.add.text(-100, -100, "DEBUG", {
    //   font: "20px Russo One",
    //   fill: "#000000",
    //   wordWrap: true,
    //   align: "center",
    //   backgroundColor: "#ffff0033",
    //   padding: {
    //     left: 20,
    //     right: 20,
    //     top: 10,
    //     bottom: 10,
    //   },
    // });

    this.addTimer(120000, () => this.checkWinner());

    this.winnerText = this.add.text(
      this.gameSize.width / 2,
      this.gameSize.height / 2 - this.adjustByScale(100),
      "",
      {
        font: this.adjustByScale(60) + "px Russo One",
        align: "center",
        shadow: { fill: false },
        strokeThickness: 0,
        fill: "#ffffff",
      }
    );

    this.winnerText.setOrigin(0.5, 0.5);
    this.winnerText.setScrollFactor(0);
    this.platforms = this.physics.add.staticGroup();
    this.playerGroup = this.physics.add.group();
    var floor = this.platforms.create(
      this.adjustByScale(-500),
      this.gameSize.height - this.adjustByScale(65),
      "ground"
    );
    floor.setOrigin(0, 0);
    floor.setScale(0.5);
    floor.displayWidth = this.gameSize.width + this.adjustByScale(1000);
    floor.displayHeight = this.adjustByScale(65);
    floor.refreshBody();
    this.floor = floor;

    // add explosion animation
    var config = {
      key: "explode",
      frames: this.anims.generateFrameNumbers("explosion", {
        start: 0,
        end: 8,
        first: 0,
      }),
      frameRate: 20,
      repeat: 0,
    };

    this.anims.create(config);

    // add coin animation
    var coinConfig = {
      key: "coin",
      frames: this.anims.generateFrameNumbers("coin", {
        start: 0,
        end: 5,
        first: 0,
      }),
      frameRate: 15,
      repeat: -1,
    };

    this.anims.create(coinConfig);

    this.coinGroup = this.physics.add.group();
    this.physics.add.collider(
      this.coinGroup,
      this.playerGroup,
      (coin, player) => {
        coin.destroy();
        console.log(player._gameId, "coin++");
        this.sound.play("coin");
        const state = this.players[player._gameId].state;
        state.setState("coins", (state.getState("coins") || 0) + 1);
      }
    );
    this.startAddingRandomCoins();

    this.sound.play("engine", { loop: true, volume: 0.5 });

    this.cannonIconGroup = this.physics.add.group();
    this.physics.add.collider(
      this.cannonIconGroup,
      this.playerGroup,
      (cannonIcon, player) => {
        console.log(player._gameId, "cannon++");
        cannonIcon.destroy();
        if (this.playerSuperweapons[player._gameId]) {
          this.playerSuperweapons[player._gameId].destroy();
        }
        this.playerSuperweapons[player._gameId] = this.add.sprite(
          player.x,
          player.y,
          "cannon-icon"
        );
        this.playerSuperweapons[player._gameId].setScale(0.25);
      }
    );

    this.cannonFireGroup = this.physics.add.group();
    this.physics.add.collider(
      this.cannonFireGroup,
      this.playerGroup,
      (cannon, player) => {
        console.log(player._gameId, "cannonFIRE++");
        if (this.players[player._gameId].state.getState("health") !== 0) {
          this.sound.play("thump");
          this.players[player._gameId].state.setState("health", 0);
        }
      }
    );

    this.physics.add.collider(
      this.cannonFireGroup,
      this.floor,
      (floor, cannon) => {
        cannon.destroy();
      }
    );

    this.startAddingRandomCannonIcons();
  }

  startAddingRandomCannonIcons() {
    if (this.addingRandomCannonTimeout)
      clearTimeout(this.addingRandomCannonTimeout);
    this.addingRandomCannonTimeout = setTimeout(() => {
      if (!this.multiplayer.getState("winner")) {
        this.addCannonIcon(
          randRange(
            this.adjustByScale(200),
            this.gameSize.width - this.adjustByScale(200)
          ),
          this.adjustByScale(-100)
        );
        this.startAddingRandomCannonIcons();
      }
    }, randRange(15000, 25000));
  }

  addCannonIcon(x, y) {
    var cannon = this.add.sprite(x, y, "cannon-icon");
    console.log("adding cannon");
    this.tweens.add({
      targets: cannon,
      y: {
        from: y,
        to: randRange(
          this.adjustByScale(200),
          this.gameSize.height - this.adjustByScale(300)
        ),
      },
      alpha: { from: 0, to: 1 },
      scale: { from: this.adjustByScale(0.25), to: this.adjustByScale(0.5) },
      ease: "Linear", // 'Cubic', 'Elastic', 'Bounce', 'Back'
      duration: 1000,
      repeat: 0,
      yoyo: false,
      onComplete: () => {
        this.cannonIconGroup.add(cannon);
      },
    });
  }

  addCannonFire(x, y, angle) {
    var cannon = this.physics.add.sprite(x, y, "cannon");
    cannon.setScale(0.5);
    this.cannonFireGroup.add(cannon);
    cannon.setVelocity(
      Math.cos(angle) * this.adjustByScale(1000),
      Math.sin(angle) * this.adjustByScale(1000)
    );
    cannon.body.gravity.y = 200;
  }

  startAddingRandomCoins() {
    this.addCoin(
      randRange(
        this.adjustByScale(200),
        this.gameSize.width - this.adjustByScale(200)
      ),
      this.adjustByScale(-100)
    );
    if (this.addingRandomCoinTimeout)
      clearTimeout(this.addingRandomCoinTimeout);
    this.addingRandomCoinTimeout = setTimeout(() => {
      if (!this.multiplayer.getState("winner")) {
        this.startAddingRandomCoins();
      }
    }, randRange(3000, 7000));
  }

  addCoin(x, y) {
    var coin = this.add.sprite(x, y, "coin").play("coin");
    // this.coinGroup.add(coin);

    this.tweens.add({
      targets: coin,
      y: {
        from: y,
        to: randRange(
          this.adjustByScale(200),
          this.gameSize.height - this.adjustByScale(300)
        ),
      },
      alpha: { from: 0, to: 1 },
      scale: { from: this.adjustByScale(0.25), to: this.adjustByScale(0.5) },
      ease: "Linear", // 'Cubic', 'Elastic', 'Bounce', 'Back'
      duration: 1000,
      repeat: 0,
      yoyo: false,
      onComplete: () => {
        this.coinGroup.add(coin);
      },
    });
  }

  async addPlayerSprite(playerState, profile) {
    // create the player's tint
    const playerTint = Phaser.Display.Color.HexStringToColor(
      profile.color
    ).color;

    // set default values if not found.
    if (this.multiplayer.isRenderServer()) {
      playerState.setState("health", 100);
    }
    const newPos = this.getRandomSpawnPoint();

    let player = await AvatarPhaserHelper(
      imgAvatar,
      playerState,
      profile,
      newPos,
      (posX, posY, textureId) => this.physics.add.image(posX, posY, textureId),
      (textureId) => {
        console.log("render");
        return this.textures.createCanvas(
          textureId,
          1636 * this.adjustByScale(PLANE_SCALE),
          924 * this.adjustByScale(PLANE_SCALE)
        );
      }
      // this.physics.add.image.bind(this),
      // this.textures.createCanvas.bind(this)
    );

    player.setDepth(1);
    player.setVisible(true);
    // player.setOrigin(0.5, 1);

    // add player nametag
    var style = {
      font: this.adjustByScale(11) + "px Russo One",
      fill: "#ffffff",
      wordWrap: true,
      align: "center",
      stroke: "#000000",
      strokeThickness: this.adjustByScale(3),
      padding: {
        left: 20,
        right: 20,
        top: 10,
        bottom: 10,
      },
    };

    var text = this.add.text(
      100,
      100,
      profile ? profile.name : "Player",
      style
    );
    text.setOrigin(0.5, 0);
    this.playerNameTags[playerState.id] = text;
    this.playerIsOnRunway[playerState.id] = true;

    // player.setCollideWorldBounds(true);
    // this.physics.add.collider(player, this.platforms, ()=>{
    //   if (!this.playerIsDead[playerState.id]) {
    //     this.playerIsDead[playerState.id] = true;
    //   }

    // });

    this.playerIsInverted[playerState.id] = false;
    this.addPlayerWeapon(playerState.id, player);

    // add explosion
    this.playerExplosions[playerState.id] = this.add.sprite(
      400,
      300,
      "explosion"
    );
    this.playerExplosions[playerState.id].alpha = 0;
    this.playerExplosions[playerState.id].setScale(this.adjustByScale(0.4));

    // add player arrow
    let playerArrow = await AvatarPhaserHelper(
      imgArrowUp,
      playerState,
      profile,
      newPos,
      (posX, posY, textureId) => this.physics.add.image(posX, 30, textureId),
      (textureId) => {
        console.log("render arrow");
        return this.textures.createCanvas(
          textureId,
          1000 * this.adjustByScale(PLANE_SCALE * 1.5),
          1200 * this.adjustByScale(PLANE_SCALE * 1.5)
        );
      }
      // this.physics.add.image.bind(this),
      // this.textures.createCanvas.bind(this)
    );

    this.playerArrowUps[playerState.id] = playerArrow;
    this.playerArrowUps[playerState.id].setVisible(false);
    this.playerArrowUps[playerState.id].setTint(playerTint);

    // add smoke
    this.playerSmokes[playerState.id] = this.add
      .particles("dark-smoke")
      .createEmitter({
        x: player.x,
        y: player.y,
        speed: { min: 100, max: 300 },
        angle: { min: -85, max: -95 },
        scale: { start: 0, end: 1, ease: "Back.easeOut" },
        alpha: { start: 0.7, end: 0, ease: "Quart.easeOut" },
        blendMode: "SCREEN",
        lifespan: 2000,
        //active: false
      });

    // add trail
    this.playerTrails[playerState.id] = this.add
      .particles("trail")
      .createEmitter({
        x: player.x,
        y: player.y,
        scale: {
          start: this.adjustByScale(0.12),
          end: this.adjustByScale(0.12),
        },
        tint: { start: playerTint, end: playerTint },
        speed: { min: 100, max: 100 },
        angle: { min: 0, max: 0 },
        alpha: { start: 1, end: 0, ease: "Quart.easeOut" },
        // blendMode: "SCREEN",
        lifespan: 1000,
        //active: false
      });

    this.playerGroup.add(player);
    player._gameId = playerState.id;
    return player;
  }

  addPlayerWeapon(playerId, playerSprite) {
    const bullets = this.physics.add.group({
      classType: Bullet,
      maxSize: 20,
      runChildUpdate: true,
    });
    this.playerWeapons[playerId] = bullets;

    // when bullets hit the other players

    this.physics.add.collider(bullets, this.playerGroup, (bullet, player) => {
      if (player._gameId !== playerId) {
        console.log(
          "bullet for",
          player._gameId,
          this.players[player._gameId].state.getState("health")
        );
        if (this.playerIsDead[player._gameId]) return; // don't kill dead players
        bullet.destroy();
        this.players[player._gameId].state.setState(
          "health",
          Math.max(this.players[player._gameId].state.getState("health") - 10)
        );
        this.sound.play("hit");
      }
    });
  }

  handlePlayerQuit(playerState) {
    if (this.playerNameTags[playerState.id]) {
      this.playerNameTags[playerState.id].destroy();
      delete this.playerNameTags[playerState.id];
    }
  }

  // update() {
  //   super.update();

  //   var txt = "";
  //   Object.keys(this.players).forEach((playerId) => {
  //     const player = this.players[playerId].sprite;
  //     if (!player) return;
  //     // console.log("POLAYER", player, this.players[playerId]);
  //     // this.physics.collide(player, this.floor, (player, floor)=> {
  //     //   txt += `TRUE\n`
  //     // } )
  //     txt += `${this.physics.world.collide(player, this.platforms)}\n`;
  //     // txt += this.checkOverlap(player, this.platforms.children.entries[0]) + "\n";
  //   });

  //   // this.debugText.setText(txt);
  // }

  updateCommon(playerId, player, state) {
    const text = this.playerNameTags[playerId];
    // text.setText((profile ? profile.name : "") + ` []`);
    text.x = Math.floor(player.x);
    text.y = Math.floor(player.y - player.height - text.height);

    this.playerTrails[playerId].setPosition(
      player.x,
      player.y + this.adjustByScale(5)
    );

    if (!this.playerIsDead[playerId] && !this.playerIsOnRunway[playerId]) {
      this.playerTrails[playerId].start();
    } else {
      this.playerTrails[playerId].stop();
    }

    if (this.playerSuperweapons[playerId]) {
      this.playerSuperweapons[playerId].setPosition(
        player.x + this.adjustByScale(50) * Math.cos(player.rotation),
        player.y + this.adjustByScale(50) * Math.sin(player.rotation)
      );
    }

    const smoke = this.playerSmokes[playerId];
    smoke.setPosition(player.x, player.y);
    if (state.getState("health") <= 50) {
      smoke.start();
    } else {
      smoke.stop();
    }

    // smoke.x = Math.floor(player.x);
    // smoke.y = Math.floor(player.y);

    if (this.playerIsInverted[playerId]) {
      player.setFlipY(true);
    } else {
      player.setFlipY(false);
    }
  }

  // host does all the computation and inputs, other players only render player at x,y
  updatePlayerHost(playerId, player, state) {
    const profile = state.getState("profile");

    // resolve x,y components using current rotation
    const xComp = Math.cos(player.rotation);
    const yComp = Math.sin(player.rotation);

    // check if player is dead
    if (
      // crashed into floor
      (this.physics.world.collide(player, this.platforms) &&
        !this.playerIsDead[playerId] &&
        !this.playerIsOnRunway[playerId]) ||
      // health is zero and is on the runway
      (state.getState("health") <= 0 &&
        this.playerIsOnRunway[playerId] &&
        !this.playerIsDead[playerId])
    ) {
      this.playerIsDead[playerId] = true;
      player.setVisible(false);
      state.setState("health", 0);
      this.playerExplosions[playerId].setPosition(player.x, player.y);
      this.playerExplosions[playerId].alpha = 1;
      this.playerExplosions[playerId].play("explode");
      this.sound.play("crash");
      // state.setState("lives", state.getState("lives") - 1);
      this.addCoin(player.x, player.y);
      if (this.playerSuperweapons[playerId]) {
        this.playerSuperweapons[playerId].destroy();
        delete this.playerSuperweapons[playerId];
        delete this.playerHasButtonPressed[playerId];
      }
      // this.checkWinner();
      setTimeout(() => {
        // const livesLeft = state.getState("lives");
        this.playerIsDead[playerId] = false;
        this.playerIsOnRunway[playerId] = true;
        player.setVisible(true);
        const newPost = this.getRandomSpawnPoint();
        player.setPosition(newPost[0], newPost[1]);
        this.playerSmokes[playerId].stop();
        this.playerExplosions[playerId].stop();
        state.setState("health", 100);
        player.setVelocity(0, 0);
        player.angle = 0;
        this.playerExplosions[playerId].alpha = 0;
      }, 3000);
    }

    // if (
    //   // player is out of game bounds

    if (this.playerIsDead[playerId]) {
      player.setVelocity(0, 0);
      return;
    }

    if (
      this.playerIsFreefall[playerId] &&
      Date.now() - this.playerIsFreefall[playerId] > FREEFALL_DURATION
    ) {
      this.playerIsFreefall[playerId] = 0;
    }

    if (
      this.playerIsOnRunway[playerId] &&
      !this.physics.world.collide(player, this.platforms)
    ) {
      this.playerIsOnRunway[playerId] = false;
    }
    // CONTROLS
    // find out which degree is desired based on input
    var keyUp = state.isKeyDown("up"),
      keyDown = state.isKeyDown("down"),
      keyLeft = state.isKeyDown("left"),
      keyRight = state.isKeyDown("right");

    if (keyUp || keyDown || keyLeft || keyRight) {
      this.playerLastInput[playerId] = Date.now();
    }

    var desiredDegree = player.angle;
    if (keyRight && !keyUp && !keyDown) {
      desiredDegree = 0;
    } else if (keyRight && keyDown) {
      desiredDegree = 45;
    } else if (keyDown && !keyLeft && !keyRight) {
      desiredDegree = 90;
    } else if (keyDown && keyLeft) {
      desiredDegree = 135;
    } else if (keyLeft && !keyUp && !keyDown) {
      desiredDegree = -180;
    } else if (keyLeft && keyUp) {
      desiredDegree = -135;
    } else if (keyUp && !keyRight && !keyLeft) {
      desiredDegree = -90;
    } else if (keyUp && keyRight) {
      desiredDegree = -45;
    }

    if (state.inputState.dpad && state.inputState.dpad !== "off") {
      desiredDegree = parseInt((-1 * state.inputState.dpad) % 360); //convert clockwise to counterclockwise which phaser uses (no idea how math works, but it does)
    }

    if (isNaN(desiredDegree)) {
      desiredDegree = player.angle;
    }

    if (player.angle !== desiredDegree) {
      const dir = getLerpDirectionBetweenAngles(player.angle, desiredDegree);
      if (dir > 0 && !this.playerIsOnRunway[playerId]) {
        player.angle += dir * PLANE_ROTATION_STEP;
      } else if (dir < 0) {
        player.angle += dir * PLANE_ROTATION_STEP;
      }
    }

    // if health is zero, and player is not on runway, then player is in freefall to ground
    if (state.getState("health") <= 0 && !this.playerIsOnRunway[playerId]) {
      if (xComp > 0) {
        player.angle = 70;
      } else if (xComp < 0) {
        player.angle = 120;
      }
    }

    if (
      this.playerLastInput[playerId] &&
      Date.now() - this.playerLastInput[playerId] > 500
    ) {
      if (xComp < 0) {
        this.playerIsInverted[playerId] = true;
      } else {
        this.playerIsInverted[playerId] = false;
      }
    }

    // fire button
    if (
      state.isKeyDown("b1") &&
      (!this.playerLastBulletFired[playerId] ||
        Date.now() > this.playerLastBulletFired[playerId])
    ) {
      var bullet = this.playerWeapons[playerId].get();
      if (this.playerSuperweapons[playerId]) {
        this.playerHasButtonPressed[playerId] = true;
      } else if (bullet) {
        bullet.setWrapper(this.wrapPortal);
        bullet.fire(player.x, player.y, player.rotation);
        this.sound.play("gun");
        this.playerLastBulletFired[playerId] = Date.now() + 100;
      }
    }

    if (
      !state.isKeyDown("b1") &&
      this.playerSuperweapons[playerId] &&
      this.playerHasButtonPressed[playerId]
    ) {
      delete this.playerHasButtonPressed[playerId];
      this.playerSuperweapons[playerId].destroy();
      delete this.playerSuperweapons[playerId];
      console.log("player shot cannon", playerId);
      this.addCannonFire(
        player.x + this.adjustByScale(50) * Math.cos(player.rotation),
        player.y + this.adjustByScale(50) * Math.sin(player.rotation),
        player.rotation
      );
      this.playerLastBulletFired[playerId] = Date.now() + 1000;
    }

    this.cannonFireGroup.children.entries.forEach((cannonFire) => {
      this.wrapPortal.checkWrap(cannonFire);
    });

    // wrapping portal from right to left and vice versa
    this.wrapPortal.checkWrap(player);

    // apply gravity
    // if (xComp>0)
    //   player.angle += 0.3;
    // else if(xComp < 0)
    //   player.angle -= 0.3;

    // determine plane speed
    if (yComp < -0.3) {
      if (!this.playerUpwardStartTime[playerId]) {
        this.playerUpwardStartTime[playerId] = Date.now();
        delete this.playerDownwardStartTime[playerId];
      }
    } else {
      delete this.playerUpwardStartTime[playerId];
    }

    if (yComp > 0.3) {
      if (!this.playerDownwardStartTime[playerId]) {
        this.playerDownwardStartTime[playerId] = Date.now();
        delete this.playerUpwardStartTime[playerId];
      }
    } else {
      delete this.playerDownwardStartTime[playerId];
    }

    var planeSpeed = PLANE_SPEED;
    if (this.playerUpwardStartTime[playerId]) {
      const ratio = Math.min(
        1,
        (Date.now() - this.playerUpwardStartTime[playerId]) / 1000
      ); // going upward for X ms will make plane slowest.
      planeSpeed -= PLANE_SPEED_VARIATION_LEVEL * ratio;
    }
    if (this.playerDownwardStartTime[playerId]) {
      const ratio = Math.min(
        1,
        (Date.now() - this.playerDownwardStartTime[playerId]) / 1000
      ); // going downward for X ms will make plane fastest.
      planeSpeed += PLANE_SPEED_VARIATION_LEVEL * ratio;
    }
    const xVel = Math.floor(xComp * planeSpeed);
    const yVel = Math.floor(yComp * planeSpeed);
    player.setVelocityX(this.adjustByScale(xVel));
    player.setVelocityY(this.adjustByScale(yVel));

    // if (
    //   this.playerIsOnRunway[playerId] &&
    //   !state.isKeyDown("up") &&
    //   !state.isKeyDown("down")
    // ) {
    //   // player.setVelocityX(0);
    //   // player.setVelocityY(0);
    //   player.angle = 0;
    // }

    // player.angle = (state.inputState?.dpad!=="off" ? (state.inputState?.dpad):0)

    // free fall if player is above screen
    if (player.y <= -(player.displayWidth * 3)) {
      // console.log("Above screen.", player.y);
      this.playerIsFreefall[playerId] = Date.now();
      player.setVelocityY(PLANE_SPEED);
      player.angle = 90;
    }

    // Show arrow if player is above screen
    if (player.y <= 0) {
      // console.log("Above display.", player.y);
      this.playerArrowUps[playerId].setPosition(player.x, 30);
      this.playerArrowUps[playerId].setVisible(true);
    } else {
      this.playerArrowUps[playerId].setVisible(false);
    }

    state.setState("pos", [parseInt(player.x), parseInt(player.y)]);

    // update player name text
    const text = this.playerNameTags[playerId];
    text.setText(profile ? profile.name : "");
  }

  getRandomSpawnPoint() {
    // return [743, 1007]
    const playerFloorY =
      this.gameSize.height -
      this.floor.displayHeight -
      (PLANE_REAL_TEXTURE_DIMENSION[1] / 2) * PLANE_SCALE +
      1;

    return [randRange(100, this.gameSize.width - 100), playerFloorY];
  }
}

// https://gamedev.stackexchange.com/questions/72348/how-do-i-lerp-between-values-that-loop-such-as-hue-or-rotation
function getLerpDirectionBetweenAngles(startAngle, endAngle) {
  var MAX_ANGLE = 360.0;
  function normalizeAngle(angle) {
    while (angle < 0) angle += MAX_ANGLE;
    while (angle >= MAX_ANGLE) angle -= MAX_ANGLE;
    return angle;
  }

  var distanceForward = 0.0; // Clockwise
  var distanceBackward = 0.0; // Counter-Clockwise

  // Calculate both distances, forward and backward:
  distanceForward = endAngle - startAngle;
  distanceBackward = startAngle - endAngle;

  if (normalizeAngle(distanceForward) < normalizeAngle(distanceBackward)) {
    // Adjust for 360/0 degree wrap
    if (endAngle < startAngle) endAngle += MAX_ANGLE; // Will be above 360
    return 1;
  }
  // Backward? (normalized to 285)
  else {
    // Adjust for 360/0 degree wrap
    if (endAngle > startAngle) endAngle -= MAX_ANGLE; // Will be below 0
    return -1;
  }
}
