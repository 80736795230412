import React, { useEffect, useState, useRef } from "react";
import Modal from "common/components/Modal";
import BrowserStorage from "common/modules/localStorageWrapper";
import { addUserLog } from "common/modules/db";
import "./style.css";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

// async function sendEmailToGSheet(email) {
//   // return new Promise((resolve, reject) => {
//   //   setTimeout(resolve, 3000);
//   // });
//   // return;
//   const scriptURL =
//     "https://script.google.com/macros/s/AKfycbwcqAKAO1NyIQfxgppb3HJ43Vb7wv2t3mcoWSTImnJxYskM4HpRlwYThYAAiNDcUaAi/exec";
//   var form = new FormData();
//   form.append("email", email);
//   return fetch(scriptURL, { method: "POST", body: form });
//   // .then(response => console.log('Success!', response))
//   // .catch(error => console.error('Error!', error.message))
// }

async function sendEmailToMailchimp(email) {
  const scriptURL =
    "https://joinplayroom.us17.list-manage.com/subscribe/post?u=bddc4cbacc3afeb80362a2914&amp;id=6efc079adf&amp;f_id=000352e0f0";
  var form = new FormData();
  form.append("EMAIL", email);
  form.append("REFSOURCE", "app");
  form.append("tags", "6450296");
  form.append("b_bddc4cbacc3afeb80362a2914_6efc079adf", "");
  return fetch(scriptURL, { method: "POST", body: form, mode: "no-cors" });
}

const SendEmailForm = ({ onEmailSubmitted, onClose }) => {
  const emailInputRef = useRef(null);

  const [emailSubmitted, setEmailSubmitted] = useState(
    BrowserStorage.get("inviteemail")
  );

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (emailSubmitted) {
      if (onEmailSubmitted) {
        onEmailSubmitted(emailSubmitted);
      }
    }
  }, [emailSubmitted, onEmailSubmitted]);

  return (
    <>
      <span className="desc">What's your email?</span>
      {errorMessage ? (
        <span className="error">{errorMessage}. Try again!</span>
      ) : (
        false
      )}
      <input
        // class="input-text"
        ref={emailInputRef}
        name="email"
        type="email"
        placeholder="Email"
        required
      />
      <button
        // class="button-black"
        onClick={() => {
          if (loading) return;
          setLoading(true);
          const emailGiven = emailInputRef.current.value;
          console.log("emailGiven", emailGiven, validateEmail(emailGiven));
          if (
            emailGiven.toLowerCase() === "ponka" ||
            emailGiven.toLowerCase() === "ponka2"
          ) {
            setEmailSubmitted(true);
            BrowserStorage.set("inviteemail", "ponka@joinplayroom.com");
            BrowserStorage.set("invitecode", emailGiven.toLowerCase());
            addUserLog("creation_unlock", {
              inviteCode: emailGiven.toLowerCase(),
              unlockType: "code",
            });
            onClose();
            return;
          }
          if (!validateEmail(emailGiven)) {
            setLoading(false);
            setErrorMessage("Invalid email");
            return;
          }
          sendEmailToMailchimp(emailGiven).then(() => {
            setEmailSubmitted(emailGiven);
            setLoading(false);
            setErrorMessage(false);
            BrowserStorage.set("inviteemail", emailGiven);
          });
        }}
      >
        {loading ? "Wait..." : "Enter"}
      </button>
    </>
  );
};

const SendInviteCodeForm = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <>
      <span className="desc">Do you have an invite code?</span>
      {errorMessage ? (
        <span className="error">{errorMessage}. Try again!</span>
      ) : (
        false
      )}
      <input
        // class="input-text"
        name="invitecode"
        type="text"
        placeholder="3l1t35"
        required
      />
      <button
        onClick={() => {
          if (loading) return;
          setLoading(true);
          const codeGiven = document
            .querySelector("input[name='invitecode']")
            .value.toLowerCase();
          setTimeout(() => {
            if (
              codeGiven === "ponka" ||
              codeGiven === "ponka2" ||
              codeGiven === "septest"
            ) {
              setLoading(false);
              BrowserStorage.set("invitecode", codeGiven);
              addUserLog("creation_unlock", {
                inviteCode: codeGiven,
                unlockType: "code",
              });
              onClose();
            } else {
              setLoading(false);
              setErrorMessage("Invalid code");
            }
          }, 3000);
        }}
      >
        {loading ? "Wait..." : "Enter"}
      </button>
    </>
  );
};

export const CreateRoomInviteCodeModal = ({ open, error, onClose }) => {
  const [emailSubmitted, setEmailSubmitted] = useState(
    BrowserStorage.get("inviteemail")
  );

  // const [open, setOpen] = useState(false);
  // const [inputValue, setInputValue] = useState("");

  const [key, setKey] = useState(1);

  useEffect(() => {
    if (open) {
      setKey(key + 1);
      setEmailSubmitted(BrowserStorage.get("inviteemail"));
      // setLoading(false);
      // setErrorMessage(false);
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <Modal onClose={onClose} open={open} className="inviteCodeModal">
      {!emailSubmitted && (
        <SendEmailForm
          key={"SendEmailForm" + key}
          onClose={onClose}
          onEmailSubmitted={setEmailSubmitted}
        />
      )}

      {emailSubmitted && (
        <SendInviteCodeForm
          key={"SendInviteCodeForm" + key}
          onClose={onClose}
        />
      )}
    </Modal>
  );
};

export const JoinRoomEmailModal = ({ open, error, onClose }) => {

  const [key, setKey] = useState(1);

  useEffect(() => {
    if (open) {
      setKey(key + 1);
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <Modal onClose={onClose} open={open} className="inviteCodeModal">
      <SendEmailForm
        key={"SendEmailForm" + key}
        onClose={onClose}
        onEmailSubmitted={(email) => {
          if (!open) return;
          onClose(email);
        }}
      />
    </Modal>
  );
};
