import LocalStorageWrapper from "../../modules/localStorageWrapper";
import { useEffect } from "react";

const StartButtonWithGyroPermissions = ({
  onPermissionGranted,
  buttonText = "Tap to grant sensors permission",
  ...rest
}) => {
  useEffect(() => {
    if (
      window._gyro_permission_asked
    )
      return onPermissionGranted(true);
  }, []);

  const handleBtnStart = (e) => {
    e.preventDefault();
    if (typeof DeviceMotionEvent.requestPermission === "function") {
      // Handle iOS 13+ devices.
      DeviceMotionEvent.requestPermission()
        .then((state) => {
          window._gyro_permission_asked = true;
          if (state === "granted") {
            LocalStorageWrapper.set("gyro_permission", "granted");
            onPermissionGranted(true);
          } else {
            console.error("Request to access the orientation was rejected");
            alert("You must allow permissions to play this game.");
            onPermissionGranted(false);
          }
        })
        .catch(console.error);
    } else {
      // Handle regular non iOS 13+ devices.
      window._gyro_permission_asked = true;
      LocalStorageWrapper.set("gyro_permission", "granted");
      onPermissionGranted(true);
    }
  };

  return (
    <button
      className="button-black btn-start"
      onClick={handleBtnStart}
      {...rest}
    >
      {buttonText}
    </button>
  );
};

export default StartButtonWithGyroPermissions;
