import "./style.css";

/*
Reference:
- https://codepen.io/cassie-codes/pen/ZaeaoO
*/

const SmokeLayer = ({n=12}) => {
    return (
        <div className="smoke-layer">
            {
            [...Array(n)].map((item, idx) => {
                return <div className="smoke-layer-bubble" id={"sb"+(idx+1)}
                style={{
                    opacity: idx % 3 ? idx % 4? 0.94 : 0.97 : 1, 
                }}
                ></div>
            })
            }
        </div>
    )
}

export default SmokeLayer;